import React from 'react'

const ShippingPolicy = () => {
    return (
        <div className='px-10 py-5 w-[80%] mx-auto'>
            <p>
                Domestic:
                <p>
                    The timeline for delivery of products is minimum of 12 days and a maximum of 21 days for customised products or on the date as mentioned on the product page while placing the order.
                    The delivery date mentioned on the website is a tentative date based on major cities and primary locations.
                    We offer shipping through our shipping partners for any order only within India. Your order will be delivered to you with full insurance.
                    We urge all customers to inspect the package for any damages or tamper before receiving or signing for receipt.
                    Products are shipped post receipt of full payment against the order except in the case of COD.
                    We urge you to prepare a video of the unboxing of your shipment for security policy.
                    Orders can be delivered to only Residential and Commercial Locations. The delivery of the orders cannot be made to any public places like Malls, Hotels and Restaurants or nearby streets etc.
                </p>
                Once your order has been shipped, you will receive an e-mail from sales@kyrahstar.com containing your tracking number and a link to the website's order-tracking page. You can also check the status of your order once it has been shipped by visiting the website of our logistics partner and following the instructions by using your tracking number. Your tracking number can be found in the e-mail confirming shipment of your order or within the Order Status page on sales@kyrahstar.com.
            </p>

            <br />
            <p>
                Recipient Identification:
                <p>
                    Customers will have to enter the correct details of the Consignee / Recipient Name (as it is stated in their photo identification that is approved by the Government) with complete Address, nearby landmark, pin code and contact number for hassle-free delivery.
                    For your protection, all orders require a signature upon delivery, regardless of any signature waiver on file.
                    We have started One-time password (OTP) verification at the time of delivery with some of our delivery partners, to add an additional layer of security to your order. Please keep Proof of Identity handy to be shown to the courier person while receiving your package.
                    For any customer request that entails handing over the parcel or delivery to a third person, an email confirmation for the same will be required from the buyer for the authorization of the person in question.
                    For our Indian customers, after your item has been packaged, it will be shipped and delivered via one of the following carriers.
                    BVC
                    SEQUEL
                    ANGADIA
                </p>
            </p>

            <br />
            <p>
                International:
                <p>
                    The timeline for delivery of products is minimum of 30 days and a maximum of 45 days for customised products or on the date as mentioned on the product page while placing the order.
                    International orders do not qualify for free shipping.
                    When ordering outside India, the recipient is responsible for assuring that the product can be legally imported to the destination country.
                    To avoid any delay in receiving your order from customs, we strongly suggest you to:
                    Please provide a phone number where you can be reached by customs when your product arrives.
                    This phone number will be listed on the commercial invoice and will be used by customs to clear your order.
                    Please do not refuse a shipment or ignore requests from carriers regarding duty and/or tax liability charges. Be aware that most carriers will hold a shipment for only 10 -15 days after arrival in your country before they return it to the sender.
                    Your order will be delivered to you with full insurance.
                    Any local custom duties and taxes as applicable will be borne by the buyer as per actuals.
                    UPS
                    FEDEX
                    DHL
                </p>
            </p>
        </div>
    )
}

export default ShippingPolicy
