import React from 'react'
import { Images } from '../components/Image'

const PageNotFound = () => {
    return (
        <div>
            <img src={Images.PageNotFound} alt='Kyrah star' className='mx-auto mt-5' />
        </div>
    )
}

export default PageNotFound
