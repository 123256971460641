// ImageModal.js
import React from 'react';
import './ImageModal.css'; // Optional, for styles
import { RxCross1 } from 'react-icons/rx';

const ImageModal = ({ isOpen, imageUrl, onClose }) => {
    if (!isOpen) return null;

    return (
        <div className="modal-overlay" onClick={onClose}>
            <div className="modal-content relative" onClick={(e) => e.stopPropagation()}>
                <div className='absolute right-5 top-3 cursor-pointer flex' onClick={onClose} ><span>Close</span><RxCross1 className='mt-1' /></div>
                <div>
                    {/* <img src={imageUrl} alt="Enlarged" className='pt-10' /> */}
                    {/* <embed src={imageUrl} className='w-[1200px] h-[700px] pt-10 bg-white' type="application/pdf" /> */}
                    <iframe src={imageUrl + '#toolbar=0'} toolbar={1} className='w-[1200px] h-[765px] pt-10' view=''></iframe>
                </div>
            </div>
        </div>
    );
};

export default ImageModal;
