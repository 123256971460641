import React from 'react'

const ReturnPolicy = () => {
    return (
        <div className='px-10 py-5 w-[80%] mx-auto'>
            7 Days Return Policy
            <p>
                At kyrahstar, we offer 7 days return policy, we want you to know that our highest priority is to make sure your purchase is right. If for any reason arising because of technical and manufacturing defect and simply return the product/s within 7 days for an exchange or full refund. Product once returned under the 7 days policy (Date of Shipping Invoice) will be refunded 100% of the value in your payment source (Credit Card/Debit Card/Net banking) based on the below mentioned eligibility criteria.
            </p>
            THE 7 DAY RETURN POLICY is as under:
            <p>
                The product to be returned must be accompanied by / with
            </p>
            <p>
                It’s original Packaging in sellable condition with no scratches, wear and tear, damage or alteration.
            </p>
            <p>
                The tag should not be removed.
            </p>
            <p>
                Original certificate if any.
            </p>
            <p>
                Original Invoice Copy
            </p>
            <p>
                Promotional material (if any)
                The Refund amount will be 80% of the actual amount paid provided all the above conditions in point (1) is fulfilled. In case of returning the products, the making charges, labour charges and government taxes if any applied will get deducted from the actual sum of the products.  In case, any coupon, discount or promo codes were used / applied during the original purchase, the exchange amount will be reduced by an amount equivalent to the coupon, discount or promo codes, as applicable.
                In case of a refund on 7 Days Return Policy, payment of the refund amount shall be done via online bank transfer to the customer’s bank account within 15 business days of receipt of the product. If the mode of the payment was through online debit/ credit card/ net banking, the refund will be processed by reversal of the transaction.
                On inspection of the product by the company and if found that the product returned is scratched, damaged, having signs of wear and tear or altered and/or any of the conditions in point (1) is not fulfilled then the company can deduct an amount or reserves the right to refuse a refund under the 7 days Return Policy for such scratches, damages, wear and tear or alteration to the product.
                For hygiene and other reasons, we shall be unable to accept returns of any altered or used products. If any of the tag from the product has been removed, we shall be unable to accept returns.
                The 7 days Refund Policy will not be applicable for any Bespoke / customized product.
                The 7 days Refund Policy will not be applicable for International orders i.e. orders that is shipped to any other country from India.
                The 7 days Refund Policy will not be applicable on new products purchased against exchange of old.
                In case the exchanged value is higher than the value of the old product, the customer will have to pay the difference between the new product chosen and the amount paid towards the old product. If the exchange value is lesser than the value of the old product, the difference will be held as a credit for future purchases.
                One order can be exchanged only once, post which it will fall under our Lifetime Exchange Policy.
            </p>
        </div>
    )
}

export default ReturnPolicy
