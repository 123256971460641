import { createSlice } from "@reduxjs/toolkit";

export const dataSlice = createSlice({
    name: 'data',
    initialState: {
        home: {
            loading: false,
            data: {}
        }
    },
    reducers: {
        setHomeLoading: (state) => {
            state.home.loading = true
        },
        addHomePageData: (state, action) => {
            const { data } = action.payload
            state.home.data = data;
            state.home.loading = false;
        },
    }
}
)

export const { addHomePageData, setHomeLoading } = dataSlice.actions
export default dataSlice.reducer