import React, { useEffect, useState } from 'react'
import { GoHeartFill } from 'react-icons/go'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { ClipLoader } from 'react-spinners'
import { ToastContainer } from 'react-toastify'
import { showErrorToast, showSuccessToast } from '../../components/ToastNotification'
import { axiosInstance } from '../../redux/api'
import { addWishlistCount } from '../../redux/slices/wishList.slice'
import { decryptData } from '../../components/decrypt'
import { encryptURL } from '../../utils/encrypt'

const WishList = () => {
    const navigate = useNavigate()
    // const user = JSON.parse(localStorage.getItem('user-info'))
    const [wishlistData, setWishListData] = useState([])
    const [isLoading, setIsLoading] = useState(false)

    const userInfo = JSON.parse(localStorage.getItem('user-info'))

    const dispatch = useDispatch()

    const getWishListData = async () => {
        const payload = { USERID: userInfo?.USERID, CURR: userInfo?.CURRENCY }
        try {
            setIsLoading(true);
            let response = await axiosInstance.post('/wishlist', payload);

            setIsLoading(false);
            const isDecryption = process.env.REACT_APP_IS_DECRYPT
            setWishListData(isDecryption == 'true' ? decryptData(response.data).data : response.data.data.data)
            // setWishListData(response.data.data)

            // dispatch(addWishlistCount(totalProduct))

            // Handle successful response, e.g., show a success message or navigate
        } catch (error) {
            setIsLoading(false);
            showErrorToast(error.response.data.message)
            // Handle errors, e.g., show an error message
            console.error('There was a problem with the fetch operation:', error);
        } finally {
            setIsLoading(false);
        }
    }
    useEffect(() => {
        getWishListData()
    }, [])

    const removeFromWishList = async (data) => {
        if (userInfo?.USERID) {
            const payload = {
                "USERID": userInfo?.USERID,
                "SKUNO": data?.SKUNO || '',
                "PRD_VARIANT_ID": data?.PRD_VARIANT_ID || 0,
                "S_CODE": data?.S_CODE ? data?.S_CODE : "",
                "STOCK_ID": data?.STOCK_ID || data?.StockID ? data?.STOCK_ID ?? data?.StockID : "",
                "IS_ONLY_DIAMOND": data?.StockID ? true : false,
                "IS_REMOVE": true
            }
            try {
                const response = await axiosInstance.post('wishlist/add', payload);
                // const jsonResponse = await axiosInstance.post('wishlist/add', payload);
                // const isDecryption = process.env.REACT_APP_IS_DECRYPT
                // const response = Decryption == 'true' ? decryptData(jsonResponse.data) : jsonResponse.data.data
                if (response.data.success) {
                    showSuccessToast(response.data.message)
                    getWishListData()
                    dispatch(addWishlistCount(response?.data?.data?.[0]?.[0]?.WISH_COUNT))
                }
            } catch (e) {
                showErrorToast(e.response.data.message)
            }
        } else {
            navigate('/login')
        }
    }

    return (
        <div className=' text-start w-[90%] md:w-[80%] 2xl:w-[60%] mx-auto mb-10'>
            <ToastContainer />
            {/* <div className=' md:pt-3 pt-2  text-start '>
                <span className='text-12 text-lightBlack font-inter '>
                    <span onClick={() => navigate("/")} className="cursor-pointer hover:underline">Home</span> /  <span >Wish List</span>
                </span>
            </div> */}
            <div className='flex justify-between font-inter items-center'>
                <p className='text-20 md:text-34 my-5  font-medium'>{userInfo?.FIRST_NAME}'s Wish List</p>
                {/* <button
                    className='hover:bg-footerBackground cursor-pointer hover:text-white text-12 md:text-16 w-[40%] md:w-[30%] lg:w-[20%]  py-1.5 rounded-xl flex justify-center gap-2 items-center border-2 border-footerBackground'
                >
                    Share Wish List <PiShareFatBold />
                </button> */}
            </div>
            <div className='mt-5'>
                {isLoading ? <div className=' text-center h-[100vh] mt-20'>
                    <ClipLoader />
                </div> :
                    (wishlistData?.[0]?.length > 0 || wishlistData?.[1]?.length > 0) ? <div className='font-inter'>
                        {wishlistData?.[0]?.length > 0 && <>
                            <h1 className='text-16 md:text-20 border-b-2 mt-5'>Rings</h1>
                            <div className='grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4  gap-3 md:gap-5 mt-5'>
                                {wishlistData?.[0]?.map((item, index) => {
                                    return (
                                        <div key={index} className='w-full cursor-pointer' onClick={() => navigate(item?.STOCKID ? `/product-detail/${encryptURL(item?.SKUNO)}-${item?.PRD_VARIANT_ID}${item?.S_CODE}/${item?.Shape.toLowerCase()}/${encryptURL(item?.STOCKID)}/` : `/product-detail/${encryptURL(item?.SKUNO)}-${item?.PRD_VARIANT_ID}${item?.S_CODE}`)}>
                                            <div className='relative w-full  overflow-hidden object-cover '>
                                                <img src={item?.IMG_URL} alt='Kyrah Star' />
                                                <GoHeartFill className={`size-5 md:size-6 cursor-pointer text-footerBackground absolute top-3 right-2`} onClick={(e) => { e.stopPropagation(); removeFromWishList(item) }} />
                                            </div>
                                            <div className='text-center text-12 md:text-14  mt-1'>
                                                {item?.SKU_NAME && <p>{item?.SKU_NAME}</p>}
                                                {item?.Carat && <p>{item?.Carat + ' Carat ' + item?.Shape + ' Diamond'}</p>}
                                                {item?.PRICE && <p>{item?.PRICE?.toLocaleString()}{item?.CURR_SYMBOL}</p>}
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </>
                        }
                        {wishlistData?.[1]?.length > 0 && <>
                            <h1 className='text-16 md:text-20 border-b-2 mt-5'>Diamonds</h1>
                            <div className='flex flex-wrap gap-3 md:gap-5 mt-5'>
                                {wishlistData?.[1]?.map((item, index) => {
                                    console.log(item)
                                    return (
                                        <div key={index} className='w-[48%] md:w-[31%] lg:w-[23%] cursor-pointer' onClick={() => navigate(`/rings/vd/${encryptURL(item?.StockID)}`)}>
                                            <div className='relative w-full h-[70%] md:h-[85%] overflow-hidden object-cover '>
                                                <img src={item?.ImgURL} alt='Kyrah Star' />
                                                <GoHeartFill className={`size-5 md:size-6 cursor-pointer text-footerBackground absolute top-3 right-2`} onClick={(e) => { e.stopPropagation(); removeFromWishList(item) }} />
                                            </div>
                                            <div className='text-center text-12 md:text-14  mt-1'>
                                                {item?.Carat && <p>{item?.Carat + ' Carat ' + item?.Shape + ' Diamond'}</p>}
                                                {item?.Price && <p>{item?.Price?.toLocaleString()}{item?.CURR_SYMBOL}</p>}
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </>}
                    </div> :
                        <div className='text-17 font-inter'>Your Wish List is Empty. Please Explore our Products!</div>
                }
            </div>
        </div>
    )
}

export default WishList
