import React from "react";
import Banner from "../../components/Banner";
import FlexView from "../../components/FlexView";
import Offer from "../../components/Offer";
import ProductSlider from "../../components/ProductSlider";
import SliderView from "../../components/Slider";
import {
    OFFER_DATA,
    PopularRings,
    SHOP_BY_STYLE,
    SIGNATURE_COLLECTION,
    WEDDING_RINGS_CATEGORY,
    WeddingRings_Banner
} from "../../helpers/jsonData";

const WeddingRings = () => {
    return (
        <div className="overflow-auto ">
            <div className=' md:pt-3 pt-2 md:pl-8 pl-5 text-start'>
                <span className="text-12 text-lightBlack font-inter">
                    Home / Wedding Rings
                </span>
            </div>

            {/* Banner Section */}
            <Banner data={WeddingRings_Banner} />

            {/* Wedding Rings Category Section  */}
            <div className="mx-auto mt-16 gap-5 flex flex-col md:flex-row w-[95%] sm:w-[97%] lg:w-[90%] justify-between ">
                {WEDDING_RINGS_CATEGORY?.map((item, index) => {
                    return (
                        <div className="w-full" key={index}>
                            <img src={item.image} alt="namew" className="w-full" />
                            <div className="flex flex-col mt-6">
                                <span className="font-medium font-inter text-30 sm:text-32 md:text-36 text-black">
                                    {item?.title}
                                </span>
                                <span className="font-inter mt-2 font-normal text-14 ">
                                    {item?.content}
                                </span>
                                <div
                                    className={`w-[100%] sm:w-[100%] mt-5 border border-1 border-black px-6 justify-center text-black cursor-pointer hover:bg-black hover:text-white flex flex-row py-3`}
                                >
                                    <span className="text-14 font-inter text-center">{`Shop ${item.title.split(" ")[0]
                                        }`}</span>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>

            {/* Offer Section */}
            <div className="w-[95%] sm:w-[97%] lg:w-[90%] mx-auto">
                <Offer data={OFFER_DATA} />
            </div>

            {/* Shop by style section */}
            <div className="mx-auto mt-10 w-[95%] sm:w-[97%] lg:w-[90%]">
                <h1 className="text-30 sm:text-32 md:text-36 text-center md:text-start font-medium w-full mb-10 font-inter">
                    Shop By Style
                </h1>
                <SliderView data={SHOP_BY_STYLE} />
            </div>


            <div className="  mx-auto w-[95%] sm:w-[97%] lg:w-[90%]">
                <div className="flex flex-col-reverse lg:flex-row justify-between lg:gap-20 mx-auto w-full">
                    <div className="px-12 py-6 lg:py-[15%] lg:w-[50%]">
                        <p className="text-30 font-inter ">Find My Matching Wedding Ring</p>
                        <p className="my-3 font-inter">
                            Select your engagement ring, then discover the perfect wedding
                            ring to match.
                        </p>
                        <div
                            className={`w-full mt-5 border border-1 border-black px-6 justify-center text-black cursor-pointer hover:bg-black hover:text-white flex flex-row py-3`}
                        >
                            <span className="text-14 font-inter text-center">
                                Get Started
                            </span>
                        </div>
                    </div>
                    <video
                        src="https://cdn.builder.io/o/assets%2F9f2a69003c86470ea05deb9ecb9887be%2F8259017d6f7442d895e921e9bc70ebe8%2Fcompressed?apiKey=9f2a69003c86470ea05deb9ecb9887be&token=8259017d6f7442d895e921e9bc70ebe8&alt=media&optimized=true"
                        muted
                        autoPlay
                        loop
                        className="lg:w-[50%]"
                    />
                </div>
            </div>

            {/*  Popular Wedding Rings Section */}
            <div className="mx-auto w-[95%] sm:w-[97%] lg:w-[90%]">
                <h1 className="text-30 md:text-36 text-center w-full md:text-start  font-inter mt-10">
                    Popular Wedding Rings
                </h1>
                <div>
                    <ProductSlider data={PopularRings} />
                </div>
            </div>

            {/* Signature Collections Section */}
            <div className="mx-auto mt-10 w-[95%] sm:w-[97%] lg:w-[90%]">
                <h1 className="text-30 text-center md:text-start md:text-36 w-full mb-5 font-inter">
                    Explore Our Signature Collections
                </h1>
                <FlexView data={SIGNATURE_COLLECTION} divStyle={true} />
            </div>

            <div className="w-full h-140 w-[95%] sm:w-[97%] lg:w-[90%] mt-10 mx-auto overflow-hidden">
                <div className="flex justify-between flex-col-reverse lg:flex-row mx-auto w-full">
                    <div className="w-[90%] lg:w-[50%] mx-auto my-6 lg:pt-[8%]">
                        <p className="text-38">Ready, Set, Stack</p>
                        <p className="my-3">Go for a set that speaks to your style.</p>
                        <div
                            className={`md:w-[50%] mt-5 border border-1 border-black px-6 justify-center text-black cursor-pointer hover:bg-black hover:text-white flex flex-row py-3`}
                        >
                            <span className="text-14 font-inter text-center">Shop Now</span>
                        </div>
                    </div>
                    <img
                        src="https://image.brilliantearth.com/cdn-cgi/image/width=720,height=545,quality=100,format=auto/https://cdn.builder.io/api/v1/image/assets%2F9f2a69003c86470ea05deb9ecb9887be%2F5795a55864ef42e49dafe7e31560596e"
                        alt="kyrah star"
                        className="lg:w-[50%]"
                    />
                </div>
            </div>
        </div>
    );
};

export default WeddingRings;