import React from 'react'
import { Shape_Images } from '../helpers/jsonData'
import { useNavigate } from 'react-router-dom'

const GemAndShapeViewer = ({ data, ...props }) => {
    const navigate = useNavigate()
    const handleShapeClick = (value) => {
        console.log(value)
        navigate(`diamonds/${value.S_NAME.toLowerCase()}`)
    }
    return (
        <div className='flex justify-between mx-auto gap-10  lg:gap-5 w-[95%] sm:w-[90%] 2xl:w-[90%] mt-5 overflow-x-auto lg:overflow-x-hidden scrollbar-hide' {...props}>
            {data?.map((item, index) => (
                <div className="cursor-pointer hover:text-hover  flex-shrink-0" key={index} onClick={() => handleShapeClick(item)}>
                    <img src={Shape_Images[item.S_NAME]} className=' mx-auto' alt="Kyrah Star" />
                    <div className="mt-4 text-center">
                        <span className='font-inter lg:text-14 text-15' >
                            {item.S_NAME}
                        </span>
                    </div>
                </div>
            ))}
        </div>

    )
}

export default GemAndShapeViewer
