import { createSlice } from "@reduxjs/toolkit";

export const wishList = createSlice({
    name: 'wishList',
    initialState: {
        wishListCount: 0
    },
    reducers: {
        addWishlistCount: (state, action) => {
            state.wishListCount = action.payload;
        },
    }
}
)

export const { addWishlistCount } = wishList.actions
export default wishList.reducer