import { Drawer } from 'antd';
import React, { useEffect, useState } from 'react';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa6';
import { RxCross2 } from 'react-icons/rx';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Slider from 'react-slick';
import { ClipLoader } from 'react-spinners';
import { decryptData } from '../../components/decrypt';
import DesignRingComponent from '../../components/DesignRingComponent';
import ProductComponent from '../../components/Product';
import RangeSelector from '../../components/RangeSelector';
import { Shape_Images } from '../../helpers/jsonData';
import { axiosInstance } from '../../redux/api';
import { addFilters } from '../../redux/slices/filters.slice';
import { encryptURL } from '../../utils/encrypt';
import { addSelectedSize } from '../../redux/slices/sizeData.slice';


export const defaultFilter = {
    PAGE_NUMBER: 1,
    STYLE_CODE: '',
    METAL_CODE: 0,
    S_CODE: "",
    WIDTH: "",
    PRONG_TYPE: "",
    F_PRICE: 0.00,
    T_PRICE: 0.00,
    SORT_BY: "PRICE_ASC"
};

const ShapeEngagementRings = () => {
    const navigate = useNavigate()
    const [isDropdown, setIsDropdown] = useState(false);
    const [open, setOpen] = useState(false);
    const [settingStyleFilter, setsettingStyleFilter] = useState(true);
    const [metalFilter, setMetalFilter] = useState(true);
    const [shapeFilter, setShapeFilter] = useState(true);
    const [widthFilter, setWidthFilter] = useState(true);
    const [prongFilter, setProngFilter] = useState(true);
    const [priceFilter, setPriceFilter] = useState(true);
    const [sortByValue, setSortByValue] = useState("Low to High")
    const [showProductData, setShowProductData] = useState();
    const [loading, setLoading] = useState(false);
    const [storeIndex, setStoreIndex] = useState()
    const { diamond, shapeName } = useParams();
    const data = useParams();
    const { pathname } = useLocation()

    const dispatch = useDispatch()
    const { filtersData } = useSelector(state => state.filterData)
    // const currency = JSON.parse(localStorage.getItem('user-info'))?.CURRENCY ?? 'INR'
    const userInfo = JSON.parse(localStorage.getItem('user-info'))
    const currency = userInfo?.CURRENCY ?? 'INR'
    // console.log(data, 'data')
    //-------- FILTER DATA HANLDER --------
    const [filters, setFilters] = useState(filtersData?.data)

    const { WEDDING_RINGS } = useSelector(state => state.navigation.navigationMenu.data)
    const { JEWELRY } = useSelector(state => state.navigation.navigationMenu.data)

    const selectedShape = filters?.Shape?.find(item => item.Items.toLowerCase() === data?.shape)
    const selectedStyle = filters?.Setting_Style?.find(item => item.Items.toLowerCase() === data?.shape)
    // console.log(selectedShape, 'shape')
    const [filterData, setFilterData] = useState({
        PAGE_NUMBER: 1,
        STYLE_CODE: JEWELRY?.find((ele) => ele?.Item?.toLowerCase() === pathname?.split('/')[2]?.replaceAll('-', ' ') && ele?.DISP === 'STYL')?.Code?.toString() || selectedStyle?.Code.toString() || "",
        METAL_CODE: +data?.metal || 0,
        S_CODE: selectedShape?.Code || "",
        WIDTH: "",
        PRONG_TYPE: "",
        F_PRICE: 0.00,
        T_PRICE: 0.00,
        SORT_BY: "PRICE_ASC",
    });

    // useEffect(() => {
    //     const selectedShape = filters?.Shape?.find(item => item.Items.toLowerCase() === data?.shape)
    //     setFilterData({ ...filterData, S_CODE: selectedShape?.Code || '' })
    // }, [data])

    useEffect(() => {
        const selectedStyle = filters?.Setting_Style?.find(item => item.Items.toLowerCase() === data?.shape?.replaceAll('-', ' '));
        const selectedShape = filters?.Shape?.find(item => item.Items.toLowerCase() === data?.shape);
        const s_code = filterData?.S_CODE || selectedShape?.Code || ""
        const style = filterData?.STYLE_CODE || selectedStyle?.Code.toString() || ""

        const newFilterData = { ...filterData, S_CODE: s_code, STYLE_CODE: style };

        // Only update if there's an actual change
        if (JSON.stringify(filterData) !== JSON.stringify(newFilterData)) {
            console.log('a')
            setFilterData(newFilterData);
        }
    }, [data, filters, filterData]);

    const fetchFilterData = async () => {
        try {
            const response = await axiosInstance.post('menu/filter')
            const isDecryption = process.env.REACT_APP_IS_DECRYPT
            dispatch(addFilters({ data: isDecryption == 'true' ? decryptData(response.data).data : response.data.data.data }))
            // dispatch(addFilters({ data: response.data.data }))
            setFilters(isDecryption == 'true' ? decryptData(response.data).data : response.data.data.data)
            // setFilters(response.data.data)
        } catch (e) {
            console.log(e)
        }
    }

    useEffect(() => {
        Object.keys(filtersData).length === 0 && fetchFilterData()
    }, [])

    //----------- PRODUCT API HANDLER -----------
    // console.log(data.shape, filters?.Shape.find(item => item.Items.toLowerCase() === data.shape), 'aaaa')
    // const fetchProductData = async () => {
    //     setLoading(true)
    //     try {
    //         const selectedShape = filters?.Shape?.find(item => item.Items.toLowerCase() === data?.shape)
    //         const payload = {
    //             PAGE_NUMBER: 1,
    //             STYLE_CODE: filterData?.STYLE_CODE || '',
    //             METAL_CODE: filterData?.METAL_CODE || 0,
    //             S_CODE: selectedShape?.Code || filterData?.S_CODE || "",
    //             WIDTH: filterData?.WIDTH || "",
    //             PRONG_TYPE: filterData?.PRONG_TYPE || "",
    //             F_PRICE: filterData?.F_PRICE || 0.00,
    //             T_PRICE: filterData?.T_PRICE || 0.00,
    //             SORT_BY: filterData?.SORT_BY || "PRICE_ASC",
    //             CURR: currency
    //         }
    //         const response = await axiosInstance.post('data/products', payload)
    //         const isDecryption = process.env.REACT_APP_IS_DECRYPT
    //         setShowProductData(isDecryption == 'true' ? decryptData(response.data).data : response.data.data.data)
    //         // setShowProductData(response.data.data)
    //         setLoading(false)
    //     } catch (e) {
    //         console.log(e)
    //         setLoading(false)
    //     }
    // }

    useEffect(() => {
        // Debounce the API call
        const delayDebounceFn = setTimeout(() => {
            const selectedStyle = filters?.Setting_Style?.find(item => item.Items.toLowerCase() === data?.shape?.replaceAll('-', ' '));
            const selectedShape = filters?.Shape?.find(item => item.Items.toLowerCase() === data?.shape);
            // const s_code = selectedStyle === undefined ? filterData?.S_CODE || selectedShape?.Code || "" : ''
            // const style = selectedShape === undefined ? filterData?.STYLE_CODE || selectedStyle?.Code.toString() || "" : ''
            const s_code = filterData?.S_CODE || selectedShape?.Code || ""
            const style = filterData?.STYLE_CODE || selectedStyle?.Code.toString() || ""

            const ringCode = WEDDING_RINGS?.find((ele) => ele?.Item?.toLowerCase() === pathname?.split('/')[3]?.replaceAll('-', ' '))?.Code
            const jewCode = JEWELRY?.find((ele) => ele?.Item?.toLowerCase() === pathname?.split('/')[2]?.replaceAll('-', ' ') && ele?.DISP === 'JEW')?.Code
            const shapeCode = JEWELRY?.find((ele) => ele?.Item?.toLowerCase() === pathname?.split('/')[2]?.replaceAll('-', ' ') && ele?.DISP === 'STYL')?.Code
            const payload = {
                PAGE_NUMBER: 1,
                STYLE_CODE: style ?? shapeCode?.toString(),
                METAL_CODE: filterData?.METAL_CODE || 0,
                S_CODE: s_code,
                WIDTH: filterData?.WIDTH || "",
                PRONG_TYPE: filterData?.PRONG_TYPE || "",
                F_PRICE: filterData?.F_PRICE || 0.00,
                T_PRICE: filterData?.T_PRICE || 0.00,
                SORT_BY: filterData?.SORT_BY || "PRICE_ASC",
                CURR: currency,
                IS_FINAL_PRODUCT: pathname.split('/')[1] === 'engagement-rings' ? false : true,
                CAT_CODE: pathname.split('/')[1] === 'engagement-rings' ? '' : pathname.split('/')[2] === 'womens' ? '1' : pathname.split('/')[2] === 'mens' ? '1' : pathname.split('/')[1] === 'jewelry' ? '2' : '',
                GENDER: pathname.split('/')[2] === 'womens' ? 2 : pathname.split('/')[2] === 'mens' ? 1 : 0,
                TYPE_CODE: ringCode ?? jewCode ?? ''
            };

            fetchProductData(payload);
            // console.log(filterData, 'filter')
        }, 300); // Adjust debounce delay as needed

        return () => clearTimeout(delayDebounceFn);
    }, [filterData, data, filters]);

    const fetchProductData = async (payload) => {
        setLoading(true);
        try {
            const response = await axiosInstance.post('data/products', payload);
            const isDecryption = process.env.REACT_APP_IS_DECRYPT;
            setShowProductData(isDecryption === 'true' ? decryptData(response.data).data : response.data.data.data);
        } catch (e) {
            console.log(e);
        } finally {
            setLoading(false);
        }
    };

    // useEffect(() => {
    //     // if()
    //     fetchProductData()
    // }, [filterData, data])

    const changeFilterData = (value, filter) => {
        if (
            filter === "STYLE_CODE" ||
            filter === "WIDTH" ||
            filter === "PRONG_TYPE"
        ) {
            if (filterData?.[filter]?.split("-")?.includes(value.toString())) {
                setFilterData((prev) => ({
                    ...prev,
                    [filter]: prev[filter]
                        .split("-")
                        .filter((data) => data.toString() !== value.toString())
                        .join("-"),
                }));
            } else {
                setFilterData((prev) => ({
                    ...prev,
                    [filter]:
                        prev[filter]?.length > 0 ? prev[filter] + "-" + value : value.toString(),
                }));
            }
        } else {
            setFilterData({ ...filterData, [filter]: value });
        }
    };

    const priceChange = (range) => {
        if ((range.min === 1 || range.min === '1') && (range.max === 9000000 || range.max === '9000000')) {
            setFilterData((prev) => ({ ...prev, F_PRICE: 0.00, T_PRICE: 0.00 }));
        } else {
            setFilterData((prev) => ({
                ...prev,
                F_PRICE: range.min,
                T_PRICE: range.max,
            }));
        }
    };


    const showDrawer = () => {
        setOpen(true);
    };

    const toggleDropdown = () => {
        setIsDropdown(true);
    };
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    const [slidesToShow, setSlidesToShow] = useState();

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth >= 1024) {
                setSlidesToShow(8.5);
            } else if (window.innerWidth >= 768) {
                setSlidesToShow(8);
            } else if (window.innerWidth >= 500) {
                setSlidesToShow(7);
            }
            else {
                setSlidesToShow(5);
            }
        };
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const CustomPrevArrow = (props) => {
        const { currentSlide, ...rest } = props;
        return (
            currentSlide !== 0 && <button button {...rest} className="slick-arrow rounded-full bg-white p-2 lg:p-3  absolute  left-[3%] md:top-[35%] lg:top-[40%] top-[30%] translate-y-[-50%] z-10 shadow-xl" >
                <FaChevronLeft className='text-10 md:text-14 lg:text-16' color="black" />
            </button >
        )
    }
    const CustomNextArrow = (props) => {
        const { currentSlide, slideCount, ...rest } = props
        return (
            currentSlide !== slideCount - slidesToShow && <button {...rest} className="slick-arrow rounded-full  p-2 lg:p-3 bg-white  absolute  right-[3%] md:top-[35%] lg:top-[40%] top-[30%] translate-y-[-50%] z-10 shadow-xl">
                <FaChevronRight className='text-10 md:text-14 lg:text-16' color="black" />
            </button>
        );
    }
    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: slidesToShow,
        slidesToScroll: 2,
        prevArrow: <CustomPrevArrow />,
        nextArrow: <CustomNextArrow slidesToShow={3.5} />
    };

    const { shape } = useParams();

    // const viewProductDetail = (index, variantId, skuName, SHP_SEL) => {
    //     const Shape = SHP_SEL ? `-${SHP_SEL}` : ''
    //     if (shape && diamond) {
    //         navigate(`/product-detail/${skuName}-${variantId}${Shape}/${shape}/${diamond}`)
    //     } else {
    //         navigate(`/product-detail/${skuName}-${variantId}${Shape}`)
    //     }
    // }

    const viewProductDetail = (index, variantId, skuName, SHP_SEL) => {
        dispatch(addSelectedSize(0))
        // console.log(decryptData(encryptData(skuName)), 'sku')
        const Shape = SHP_SEL ? `-${SHP_SEL}` : ''
        if (shape && diamond) {
            navigate(`/product-detail/${encryptURL(skuName)}-${(variantId)}${(Shape)}/${(shape)}/${(diamond)}`)
        } else {
            navigate(`/product-detail/${encryptURL(skuName)}-${(variantId)}${(Shape)}`)
        }
    }

    // const formattedShape = shape?.charAt(0).toUpperCase() + shape?.slice(1);
    const sortProductData = (value, lable) => {
        setSortByValue(lable);
        setFilterData((prev) => ({
            ...prev,
            SORT_BY: value,
        }));
        setShowProductData()
        // if (value === 'High to Low') {
        //     setFilterProductData(PopularRings.sort((a, b) => b.mappings.core.price - a.mappings.core.price))
        // } else if (value === 'Low to High') {
        //     setFilterProductData(PopularRings.sort((a, b) => a.mappings.core.price - b.mappings.core.price))
        // } else {
        //     console.log(value)
        // }
        setIsDropdown(false)

    }

    return (
        <div>
            {/* <div className=' md:pt-3 pt-2 md:pl-8 pl-5 text-start'>
                <span className='text-12 text-lightBlack font-inter word-spa tracking-wide capitalize'>
                    {`Home / Engagement Rings / ${shape}`}
                </span>
            </div> */}
            {/* <ProductDetail filters={filters} /> */}

            <div className={`${pathname.split('/')[1] === 'engagement-rings' ? 'hidden md:block' : 'hidden'}`}>
                <DesignRingComponent
                    // settings={skuName && "true"}
                    stockId={diamond}
                    // skuno={skuName}
                    // variantId={variantId}
                    diamondInfo={{}}
                />
            </div>
            {/* <div className='mx-auto w-full mt-2'>
                <h1 className='text-bannerHeading font-bannerDescription text-center w-full font-inter capitalize'>
                    {Ring_Shape_Heading_Data[shape].title}
                </h1>

                <div className='mt-2 text-bannerDescription text-center font-normal  mx-auto font-inter'>
                    {Ring_Shape_Heading_Data[shape].subtitle}
                </div>
            </div> */}

            {/* STYLE COMPOENETS */}
            <div div className='mx-auto mt-5 w-[95%] lg:w-[90%] 2xl:w-[70%] container hidden' >
                <div className="flex flex-row justify-between mx-auto w-full mt-6">
                    <Slider {...settings} className='w-full flex flex-row items-center mx-2'>
                        {filters?.Setting_Style?.map((item, index) => {
                            return (
                                <div key={index} className={`cursor-pointer h-50 px-1.5 outline-none text-[#2c2c2c] hover:text-footerBackground`} onClick={() => changeFilterData(item.Code, 'STYLE_CODE')}>
                                    <img src={item.BIG_IMG_URL} className={`h-16 w-16 md:w-20 md:h-20 lg:h-28 lg:w-28 xl:w-36 xl:h-36 mx-auto rounded-full lg:rounded-none   hover:border-footerBackground border-[2px] hover:border-solid object-cover  ${filterData.STYLE_CODE?.split('-')?.includes(item.Code.toString()) ? 'border-gray-500 border-2 border-solid' : ' lg:border-none'}`} alt="Kyrah Star" />
                                    <div className={`${filterData?.STYLE_CODE?.split('-')?.includes(item.Code.toString()) ? 'text-footerBackground ' : ''} text-center`}>
                                        <span className='font-inter  text-12 md:text-13'>
                                            {item.Items}
                                        </span>
                                    </div>
                                </div>
                            )
                        })}
                    </Slider>
                </div>
            </div>

            {/* Filter Sections */}
            <div div className='mx-auto relative mt-5 w-[95%] 2xl:w-[70%] container'>
                <div className='flex  flex-row justify-between items-center'>
                    <button onClick={showDrawer} className='bg-footerBackground rounded-full flex gap-1 flex-row justify-around items-center text-12 md:text-14   text-white font-inter px-3 py-1 md:px-4 md:py-2 lg:px-5 '>
                        Filters
                        <img
                            src='https://image.brilliantearth.com/static/img/icon/svg/filters-white.svg'
                            alt='filter'
                            className='w-5 md:w-6 '
                        />
                    </button>

                    <div onMouseEnter={toggleDropdown} onMouseLeave={() => setIsDropdown(false)} className='border rounded-full flex flex-row justify-between cursor-pointer items-center border-footerBackground text-12 md:text-14 px-3 py-1 md:px-4 md:py-2 lg:px-5 '>
                        <span className='font-karla5'>
                            Sort By: {sortByValue}
                        </span>

                        {
                            isDropdown ?
                                <FaChevronUp className='ml-4' />
                                :
                                <FaChevronDown className='ml-4' />
                        }
                        {isDropdown && (
                            <div className='absolute rounded-xl right-0 mt-2 text-12 md:text-14  py-1.5  md:py-2  bg-white border top-5 md:top-7 lg:top-7 border-footerBackground shadow-lg z-10'>
                                <ul className='py-[2px]'>
                                    {[
                                        { label: 'Low to High', value: 'PRICE_ASC' },
                                        { label: 'High to Low', value: 'PRICE_DESC' },
                                    ].map((item, index) => (
                                        <li
                                            key={index}
                                            className='pr-[88px] pl-[10px] sm:pr-20 sm:pl-4  md:pr-24 lg:pl-4 lg:pr-[104px] py-[5px] hover:bg-gray-100 cursor-pointer'
                                            onClick={() => sortProductData(item.value, item.label)}
                                        >
                                            {item.label}
                                        </li>
                                    ))}

                                    {/* <li className='px-[17px] md:px-[19px] lg:px-[21px] py-[5px] hover:bg-gray-100 cursor-pointer' onClick={sortProductData}>Best Sellers</li>
                                    <li className='px-[17px] md:px-[19px] lg:px-[21px] py-[5px] hover:bg-gray-100 cursor-pointer' onClick={sortProductData}>Newest</li>
                                    <li className='px-[17px] md:px-[19px] lg:px-[21px] py-[5px] hover:bg-gray-100 cursor-pointer' onClick={sortProductData}>Price: Low to High</li>
                                    <li className='px-[17px] md:px-[19px] lg:px-[21px] py-[5px] hover:bg-gray-100 cursor-pointer' onClick={sortProductData}>Price: High to Low</li> */}
                                </ul>
                            </div>
                        )}
                    </div>

                </div>
            </div>

            <div className='text-14  font-inter text-designRing  items-center flex flex-wrap gap-2 mx-auto relative mt-3 w-[95%] 2xl:w-[70%] container'>
                <span className='flex flex-wrap text-12'>{showProductData?.length ? showProductData?.length : 0} Results</span>
                {filterData.F_PRICE === 0.00 && filterData.T_PRICE === 0.00 ? null : <div className="text-12 cursor-pointer" onClick={() => priceChange({ min: 1, max: 9000000 })}>{<span className="flex gap-[3px] items-center">{'$' + filterData.F_PRICE + ' - $' + filterData.T_PRICE + ' Price'} <RxCross2 className="" /></span>}</div>}
                {filterData.STYLE_CODE ? <div className="flex flex-wrap items-center gap-[3px]">{filterData.STYLE_CODE.split("-").map((data) => {
                    const index = filters?.Setting_Style.findIndex((item) => item.Code === +data)
                    return (
                        <span className="flex gap-[3px] items-center text-12 cursor-pointer" onClick={() => changeFilterData(data, "STYLE_CODE")}>
                            <span >{filters?.Setting_Style[index].Items}</span> <RxCross2 className="" />
                        </span>
                    )
                })}</div> : null}
                {filterData.METAL_CODE ? <div className="flex flex-wrap gap-[3px]">
                    <span className="flex gap-[3px] items-center text-12 cursor-pointer" onClick={() => setFilterData({ ...filterData, METAL_CODE: '' })}>
                        <span>{filters?.Metal[filters?.Metal?.findIndex((data) => data?.Code === filterData?.METAL_CODE)]?.Items}</span>
                        <RxCross2 className="" />
                    </span>
                </div> : null}
                {filterData.S_CODE ? <div className="flex flex-wrap gap-[3px]">
                    <span className="flex gap-[3px] items-center text-12 cursor-pointer" onClick={() => setFilterData({ ...filterData, S_CODE: '' })} >
                        <span >
                            {
                                filters?.Shape[filters?.Shape.findIndex((data) => data.Code === filterData.S_CODE)].Items
                            }</span> <RxCross2 className="" />
                    </span>
                </div> : null}
                {filterData.WIDTH ? <div className="flex flex-wrap gap-[3px]">{filterData.WIDTH.split("-").map((data) => {
                    const index = filters?.Width.findIndex((item) => item.Code === +data)
                    return (
                        <span className="flex gap-[3px] items-center text-12 cursor-pointer" onClick={() => changeFilterData(data, "WIDTH")}>
                            <span >{filters?.Width[index].Item}</span> <RxCross2 className="" />
                        </span>
                    )
                })}</div> : null}
                {filterData.PRONG_TYPE ? <div className="flex flex-wrap gap-[3px]">{filterData.PRONG_TYPE.split("-").map((data) => {
                    const index = filters?.Prong_type.findIndex((item) => item.Code === +data)
                    return (
                        <span className="flex gap-[3px] items-center text-12 cursor-pointer" onClick={() => changeFilterData(data, "PRONG_TYPE")}>
                            <span >{filters?.Prong_type[index].Item}</span> <RxCross2 className="" />
                        </span>
                    )
                })}</div> : null}
                {(filterData.STYLE_CODE || filterData.S_CODE || filterData.METAL_CODE || filterData.WIDTH || filterData.PRONG_TYPE || filterData.F_PRICE || filterData.T_PRICE) ? <span className={`flex gap-[3px] items-center text-12 cursor-pointer `} onClick={() => {
                    setFilterData({
                        PAGE_NUMBER: 1,
                        STYLE_CODE: selectedStyle?.Code.toString() || JEWELRY?.find((ele) => ele?.Item?.toLowerCase() === pathname?.split('/')[2]?.replaceAll('-', ' ') && ele?.DISP === 'STYL')?.Code?.toString() || "",
                        METAL_CODE: +data?.metal || 0,
                        S_CODE: filters?.Shape?.find(item => item.Items.toLowerCase() === data?.shape)?.Code || "",
                        WIDTH: "",
                        PRONG_TYPE: "",
                        F_PRICE: 0.00,
                        T_PRICE: 0.00,
                        SORT_BY: "PRICE_ASC"
                    })
                    setSortByValue("Low to High");
                }}>
                    <span>Reset All</span> <RxCross2 className="" />
                </span> : null}
            </div>

            <div className='mx-auto mt-2 mb-10 container w-[95%] 2xl:w-[70%] '>
                <div className="flex flex-row flex-wrap gap-2 gap-y-5 sm:gap-5 mx-auto w-full ">
                    {
                        loading ? <div className='grid xl:grid-col-4 grid-col-2 lg:grid-col-3 gap-x-4 justify-center w-full'> <ClipLoader className='my-[3%]' /></div> :
                            showProductData?.length > 0 ? showProductData?.map((item, index) => {
                                return (
                                    <div key={index} className={` w-[47%] sm:w-[48%] lg:w-[31.5%] xl:w-[23.5%] outline-none text-[#2c2c2c] mb-0-2 sm:mb-10`}
                                        onClick={() => { setStoreIndex() }}
                                    >
                                        {/* <div className='absolute left-2 top-1.5'>
                    <p className='text-10 px-3 py-1 bg-footerborder-footerBackground text-white rounded-full'>Signature Collections</p>
                </div>
                <div className='absolute right-2 top-1.5'>
                    <GoHeart />
                </div> */}
                                        <ProductComponent
                                            item={item}
                                            hoverIndex={index}
                                            productData={showProductData}
                                            viewProductDetail={viewProductDetail}
                                            variant={item.Variants}
                                        />
                                    </div>
                                )
                            })
                                : <div className='text-center mx-auto my-5 font-inter text-20'>
                                    {/* Product Not Found! */}
                                </div>
                    }

                </div>
            </div>

            <Drawer
                closable
                // destroyOnClose
                title={<span className='text-16 font-inter text-designRing '>Filters</span>}
                placement="left"
                open={open}
                width={420}
                onClose={() => setOpen(false)}
            >
                <div className='z-8'>
                    <div className='text-12 mb-4 font-inter text-designRing  flex flex-wrap  gap-2 sticky top-[-24px] bg-white '>
                        <div className='flex flex-wrap gap-[4px]'>
                            <span className='text-filters'>{showProductData?.length} Results</span>
                            {filterData.STYLE_CODE ?
                                filterData.STYLE_CODE.split("-").map((data) => {
                                    const index = filters?.Setting_Style.findIndex((item) => item.Code === +data)
                                    return (
                                        <span className="flex gap-[3px] items-center text-filters cursor-pointer" onClick={() => changeFilterData(data, "STYLE_CODE")}>
                                            <span >{filters?.Setting_Style[index].Items}</span> <RxCross2 className="" />
                                        </span>
                                    )
                                })
                                : null}
                            {filterData.METAL_CODE ?
                                <span className="flex gap-[3px] items-center text-filters cursor-pointer" onClick={() => setFilterData({ ...filterData, METAL_CODE: '' })}>
                                    <span>{filters?.Metal[filters?.Metal?.findIndex((data) => data?.Code === filterData?.METAL_CODE)]?.Items}</span>
                                    <RxCross2 className="" />
                                </span> : null}
                            {filterData.S_CODE ?
                                <span className="flex gap-[3px] items-center text-filters cursor-pointer" onClick={() => setFilterData({ ...filterData, S_CODE: '' })} >
                                    <span >
                                        {
                                            filters?.Shape[filters?.Shape.findIndex((data) => data.Code === filterData.S_CODE)].Items
                                        }</span> <RxCross2 className="" />
                                </span> : null}
                            {filterData.WIDTH ? filterData.WIDTH.split("-").map((data) => {
                                const index = filters?.Width.findIndex((item) => item.Code === +data)
                                return (
                                    <span className="flex gap-[3px] items-center text-filters cursor-pointer" onClick={() => changeFilterData(data, "WIDTH")}>
                                        <span >{filters?.Width[index].Item}</span> <RxCross2 className="" />
                                    </span>
                                )
                            }) : null}
                            {filterData.prong ? filterData.prong.split("-").map((data) => {
                                const index = filters?.Prong_type.findIndex((item) => item.Code === +data)
                                return (
                                    <span className="flex gap-[3px] items-center text-filters cursor-pointer" onClick={() => changeFilterData(data, "prong")}>
                                        <span >{filters?.Prong_type[index].Item}</span> <RxCross2 className="" />
                                    </span>
                                )
                            }) : null}
                            {filterData.F_PRICE === 0.00 && filterData.T_PRICE === 0.00 ? null : <div className="text-filters cursor-pointer flex-flex-wrap" onClick={() => priceChange({ min: 1, max: 9000000 })}>{<span className="flex gap-[3px] items-center">{'$' + filterData.F_PRICE + ' - $' + filterData.T_PRICE + ' Price'} <RxCross2 className="" /></span>}</div>}
                            {(filterData.STYLE_CODE || filterData.S_CODE || filterData.METAL_CODE || filterData.WIDTH || filterData.PRONG_TYPE || filterData.F_PRICE || filterData.T_PRICE) ? <span className={`flex gap-[3px] items-center text-12 cursor-pointer `} onClick={() => setFilterData({
                                PAGE_NUMBER: 1,
                                STYLE_CODE: '',
                                METAL_CODE: 0,
                                S_CODE: filters?.Shape?.find(item => item.Items.toLowerCase() === data?.shape)?.Code || "",
                                WIDTH: "",
                                PRONG_TYPE: "",
                                F_PRICE: 0.00,
                                T_PRICE: 0.00,
                                SORT_BY: "PRICE_ASC"
                            })}>
                                <span>Reset All</span> <RxCross2 className="" />
                            </span> : null}
                        </div>
                        <hr className='w-full h-[0.5px] border border-[#e2e2e2]' />
                    </div>


                    {/* SETTINGS_STYLE */}
                    <div>
                        <div onClick={() => { setsettingStyleFilter(!settingStyleFilter) }} className='flex cursor-pointer mt-4 flex-row justify-between items-center'>
                            <div className='text-filterHeading font-inter text-filterHeadingtext font-filterHeading'>
                                Settings Style
                            </div>
                            {
                                settingStyleFilter ?
                                    <FaChevronUp />
                                    :
                                    <FaChevronDown />
                            }
                        </div>

                        <div
                            className={`mt-2 mx-auto grid grid-cols-4 gap-2 w-[100%] transition-all duration-300 ease-in-out overflow-hidden ${settingStyleFilter ? 'max-h-[500px] opacity-100' : 'max-h-0 opacity-0'}`}>
                            {filters?.Setting_Style?.map((item, index) => {
                                return (
                                    <div key={index} className={`cursor-pointer px-3  outline-none text-[#808080] hover:text-filterHeadingtext `} onClick={() => changeFilterData(item.Code, 'STYLE_CODE')}>
                                        <img src={item.IMG_URL ?? 'https://encrypted-tbn0.gstatic.com/shopping?q=tbn:ANd9GcRPuYsU0j4m4V4qKFA-AjLYSSt6FOOrpybg-5tWe9_MTUJcVxfvtB3QQOslFeN6VrHcpzJcT52WPkec6bFNEcx1oClr56T1Nf2USL3xFGrblTbMvwF5thnv'} className={`h-10 w-10 mx-auto rounded-full   ${filterData.STYLE_CODE?.split('-')?.includes(item.Code.toString()) ? 'border-footerBackground border-2 border-solid ' : 'hover:border-[#808080] hover:border-2 hover:border-solid'}`} alt="Kyrah Star" />
                                        <div className={`${filterData.STYLE_CODE?.split('-')?.includes(item.Code.toString()) ? 'text-footerBackground ' : ''} text-center px-2`}>
                                            <span className='font-inter font-filterMenu leading-[1] text-filterMenu'>
                                                {item.Items}
                                            </span>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>

                    <hr className='w-full mt-2 h-[0.5px] border border-[#e2e2e2]' />

                    {/* METAL */}
                    <div>
                        <div onClick={() => { setMetalFilter(!metalFilter) }} className='flex cursor-pointer mt-4 flex-row justify-between items-center'>
                            <div className='text-filterHeading font-inter text-filterHeadingtext font-filterHeading'>
                                Metal
                            </div>
                            {metalFilter ? <FaChevronUp /> : <FaChevronDown />}
                        </div>

                        <div className={`mt-4 mx-auto grid grid-cols-4 gap-y-1 w-[100%] transition-all duration-300 ease-in-out overflow-hidden ${metalFilter ? 'max-h-[500px] opacity-100 mb-4' : 'mb-0 max-h-0 opacity-0'}`}>
                            {filters?.Metal?.map((METAL_CODE, index) => {
                                const isSelected = filterData.METAL_CODE === METAL_CODE.Code;

                                return (
                                    <div key={index} className={`flex-col px-1.5  hover:border-[#eae0e0] flex justify-start`}>
                                        <div
                                            className={`cursor-pointer h-8 w-8 rounded-full mx-auto ${isSelected ? 'border-footerBackground border-2' : 'hover:border-[#808080] hover:border-2'} ${METAL_CODE.Items.replace('18K ', '') === "White Gold" ? 'bg-silver-gradient' : METAL_CODE.Items.replace('14K ', '') === "White Gold" ? 'bg-silver-gradient' : METAL_CODE.Items.replace('18K ', '') === "Yellow Gold" ? 'bg-gold-gradient' : METAL_CODE.Items.replace('14K ', '') === "Yellow Gold" ? 'bg-gold-gradient' : METAL_CODE.Items.replace('14K ', '') === "Rose Gold" ? 'bg-rose-gradient' : 'bg-platinum-gradient'}`}
                                            onClick={() => changeFilterData(isSelected ? null : METAL_CODE.Code, 'METAL_CODE')}
                                        ></div>

                                        <div className={`text-center text-[#808080] ${isSelected ? 'text-footerBackground' : ''}`}>
                                            <span className='font-inter leading-[1] font-filterMenu text-filterMenu'>
                                                {METAL_CODE.Items}
                                            </span>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                    <hr className='w-full h-[0.5px] border border-[#e2e2e2]' />


                    {/*---------- DIAMOND SHAPE FILTER -----------*/}

                    <div>
                        <div onClick={() => { setShapeFilter(!shapeFilter) }} className='flex cursor-pointer mt-4 flex-row justify-between items-center'>
                            <div className='text-filterHeading font-inter text-filterHeadingtext font-filterHeading'>
                                Diamond Shape
                            </div>
                            {shapeFilter ? <FaChevronUp /> : <FaChevronDown />}
                        </div>

                        <div className={`mt-2 mx-auto grid grid-cols-4 w-[100%] transition-all duration-300 ease-in-out overflow-hidden ${shapeFilter ? 'max-h-[500px] opacity-100' : 'max-h-0 opacity-0'}`}>
                            {filters?.Shape?.map((item, index) => {
                                const isSelected = filterData.S_CODE === item.Code;
                                const isDisabled = (diamond !== undefined);


                                return (
                                    <div
                                        key={index}
                                        className={`cursor-pointer py-3 px-1.5 outline-none text-[#2c2c2c] ${isDisabled ? 'opacity-55 cursor-not-allowed' : 'hover:text-footerBackground'}`}
                                        onClick={() => !isDisabled && changeFilterData(isSelected ? null : item.Code, 'S_CODE')}
                                    >
                                        <img
                                            src={Shape_Images[item.Items]}
                                            className={`h-12 w-12 p-1 mx-auto rounded-full ${isSelected ? 'border-footerBackground border-2 border-solid' : 'hover:border-2 hover:border-[#808080] hover:border-solid'}`}
                                            alt="Kyrah Star"
                                        />
                                        <div className="text-center">
                                            <span className={`font-inter font-filterMenu text-[#808080] ${isSelected ? 'text-footerBackground' : ''}`}>
                                                {item.Items}
                                            </span>
                                        </div>
                                    </div>
                                );
                            })}

                        </div>
                    </div>
                    <hr className='w-full mt-2 h-[0.5px] border border-[#e2e2e2]' />

                    {/* WIDTH FILTER */}
                    <div>
                        <div onClick={() => { setWidthFilter(!widthFilter) }} className='flex cursor-pointer mt-4 flex-row justify-between items-center'>
                            <div className='text-filterHeading font-inter text-filterHeadingtext font-filterHeading'>
                                Width
                            </div>
                            {
                                widthFilter ?
                                    <FaChevronUp />
                                    :
                                    <FaChevronDown />
                            }
                        </div>

                        <div
                            className={`mt-2  mx-w-[100%] transition-all duration-300 ease-in-out font-inter text-filterMenu font-filterMenu overflow-hidden ${widthFilter ? 'max-h-[500px] opacity-100' : 'max-h-0 opacity-0'}`}>
                            {filters?.Width?.map((value, index) => {
                                return (<div key={index} className='flex items-center cursor-pointer' onClick={() => changeFilterData(value.Code, 'WIDTH')}>
                                    <input type='checkbox' value={value.Item} checked={filterData.WIDTH?.split('-')?.includes(value.Code.toString())} />
                                    <span className={`ml-1 text-[#808080] ${filterData.WIDTH?.split('-')?.includes(value.Code.toString()) ? 'text-footerBackground' : ''}`}>{value.Item}</span><br />
                                </div>)
                            })}
                            {/* <input type='checkbox' value='2-3mm' onChange={(e) => changeFilterData(e.target.value, 'width')} checked={(e) => filterData.prong.includes(e.target.value)} />
                            <span className='ml-2'>2-3mm</span><br />
                            <input type='checkbox' value='over 3mm' onChange={(e) => changeFilterData(e.target.value, 'width')} checked={(e) => filterData.prong.includes(e.target.value)} />
                            <span className='ml-2'>Over 3mm</span><br /> */}

                        </div>
                    </div>

                    <hr className='w-full mt-2 h-[0.5px] border border-[#e2e2e2]' />


                    {/* PRONG TYPE FILTER */}
                    <div>
                        <div onClick={() => { setProngFilter(!prongFilter) }} className='flex cursor-pointer mt-4 flex-row justify-between items-center'>
                            <div className='text-filterHeading font-inter text-filterHeadingtext font-filterHeading'>
                                Prong Type
                            </div>
                            {
                                prongFilter ?
                                    <FaChevronUp />
                                    :
                                    <FaChevronDown />
                            }
                        </div>

                        <div
                            className={`mt-2  mx-w-[100%] transition-all duration-300  font-inter text-filterMenu font-filterMenu ease-in-out overflow-hidden ${prongFilter ? 'max-h-[500px] opacity-100' : 'max-h-0 opacity-0'}`}>
                            {filters?.Prong_type?.map((value, index) => {
                                return (<div key={index} className='flex items-center cursor-pointer' onClick={() => changeFilterData(value.Code, 'PRONG_TYPE')}>
                                    <input type='checkbox' value={value.Item} checked={filterData.PRONG_TYPE?.split('-')?.includes(value.Code.toString())} />
                                    <span className={`ml-1 text-[#808080] ${filterData.PRONG_TYPE?.split('-')?.includes(value.Code.toString()) ? 'text-footerBackground' : ''}`}>{value.Item}</span><br />
                                </div>)
                            })}
                            {/* <input type='checkbox' value='4 Prong' onChange={(e) => changeFilterData(e.target.value, 'prong')} checked={(e) => filterData.prong.includes(e.target.value)} />
                            <span className='ml-2'>4 Prong</span><br />
                            <input type='checkbox' value='6 Prong' onChange={(e) => changeFilterData(e.target.value, 'prong')} checked={(e) => filterData.prong.includes(e.target.value)} />
                            <span className='ml-2'>6 Prong</span><br />
                            <input type='checkbox' value='Double Prong' onChange={(e) => changeFilterData(e.target.value, 'prong')} checked={(e) => filterData.prong.includes(e.target.value)} />
                            <span className='ml-2'>Double Prong</span><br /> */}

                        </div>
                    </div>

                    <hr className='w-full mt-2 h-[0.5px] border border-[#e2e2e2]' />

                    {/* PRICE FILTER */}
                    <div>
                        <div onClick={() => { setPriceFilter(!priceFilter) }} className='flex cursor-pointer mt-4 flex-row justify-between items-center'>
                            <div className='text-filterHeading font-inter text-filterHeadingtext font-filterHeading'>
                                Setting Price
                            </div>
                            {
                                priceFilter ?
                                    <FaChevronUp />
                                    :
                                    <FaChevronDown />
                            }
                        </div>

                        <div
                            className={`mt-2 pr-5 mx-w-[100%] text-[#808080] font-filterMenu font-inter transition-all duration-300 ease-in-out overflow-hidden ${priceFilter ? 'max-h-[500px] opacity-100' : 'max-h-0 opacity-0'}`}>

                            <RangeSelector
                                min={1}
                                max={9000000}
                                step={1}
                                label={showProductData?.[0]?.Variants?.[0]?.CURR_SYMBOL || '$'}
                                onChange={priceChange}
                                input={{ min: filterData.F_PRICE, max: filterData.T_PRICE }}
                            />
                        </div>
                    </div>

                    <hr className='w-full mt-2 mb-16 h-[0.5px] border border-[#e2e2e2]' />
                </div>
                <div className='w-full px-5 py-3  bg-white absolute bottom-0  shadow-[0_35px_60px_-15px_rgba(0,0,0,0.3)] left-0 z-[20]'>
                    <button className='w-[50%] bg-footerBackground rounded-full text-white p-3 text-md' onClick={() => { setOpen(false) }}>View Result</button>
                </div>

            </Drawer>

        </div >
    )
}

export default ShapeEngagementRings