import React, { useEffect, useState } from 'react';
import ReactCountryFlag from 'react-country-flag';
import { MdOutlineKeyboardArrowDown } from 'react-icons/md';
import { RotatingLines } from 'react-loader-spinner';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import AccountsSideBar from '../../components/AccountsSideBar';
import ToastNotification, { showErrorToast, showSuccessToast } from '../../components/ToastNotification';
import { countryCodes } from '../../helpers/jsonData';
import { axiosInstance } from '../../redux/api';

const Account = () => {
    const navigate = useNavigate()
    const userInfo = JSON.parse(localStorage.getItem('user-info'))
    const [isOpen, setIsOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    // const defaultCountry = countryCodes?.find(country => country?.name === userInfo?.COUNTRY);
    // const [selectedCountry, setSelectedCountry] = useState(defaultCountry);
    const [filteredCountries, setFilteredCountries] = useState([]);

    const [fNameError, setFNameError] = useState('')
    const [lNameError, setLNameError] = useState('')
    const [addresssError, setAddressError] = useState('')
    const [pincodeError, setPincodeError] = useState('')
    const [companyError, setCompanyError] = useState('')
    const [cityError, setCityError] = useState('')
    const [countryError, setCountryError] = useState('')
    const [stateError, setStateError] = useState('')
    const [updateLoader, setUpdateLoader] = useState(false)

    const [isDropdown, setIsDropdown] = useState(false);
    const currency = useSelector(state => state.navigation)


    const [countries, setCountries] = useState([]);
    const [filterCountries, setFilterCountries] = useState([]);
    const [states, setStates] = useState([]);
    const [filterState, setFilterState] = useState([]);
    const [cities, setCities] = useState([]);
    const [filterCity, setFilterCity] = useState([]);
    const [pincodes, setPincodes] = useState([]);

    const [selectedCountry, setSelectedCountry] = useState(userInfo?.COUNTRY);
    const [selectedState, setSelectedState] = useState(userInfo?.STATE);
    const [selectedCity, setSelectedCity] = useState(userInfo?.CITY);
    const [selectedPincode, setSelectedPincode] = useState(userInfo?.PINCODE);
    const [user, setUser] = useState({ firstName: userInfo?.FIRST_NAME, lastName: userInfo?.LAST_NAME, company: userInfo?.COMPANY_NAME, contactPerson: userInfo?.CONTACT_PERSON, gst: userInfo?.GSTNO, address: userInfo?.ADDRESS, city: selectedCity, state: selectedState, country: selectedCountry, pincode: selectedPincode, gender: userInfo?.GENDER, currency: userInfo?.CURRENCY })

    const [countyDropdown, setCountyDropdown] = useState(false);
    const [stateDropdown, setStateDropdown] = useState(false);
    const [cityDropdown, setCityDropdown] = useState(false);
    const [pincodeDropdown, setpincodeDropdown] = useState(false);
    const [auth_token, setAuth_token] = useState()
    // const auth_token = "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7InVzZXJfZW1haWwiOiJhbHBpdHBldGhhbmlAZ21haWwuY29tIiwiYXBpX3Rva2VuIjoiTFZhWGQ4ekJrUXNpd1lFdDVVLVdPaUdCWVNwcUdrWFE5WmVkLWNYRGlBYUxVeG91UHNNM2gtUkdieUNqM0lpTE1QZyJ9LCJleHAiOjE3MzIzMzk5NDR9.Q6wfsnkPY5Nx9QyTtXllh6TPHXpyTJoCBjZAViUwavc";
    const headers = new Headers();
    headers.append('Authorization', `Bearer ${auth_token}`);
    headers.append("Accept", "application/json");

    const optionObject = { headers: headers };

    const fetchData = async (url, options) => {
        try {
            const response = await fetch(url, options);
            const responseJSON = await response.json();
            return responseJSON;
        } catch (error) {
            console.log(error);
        }
    };

    const getToken = async () => {
        try {
            const headers = {
                "Accept": "application/json",
                'api-token': "LVaXd8zBkQsiwYEt5U-WOiGBYSpqGkXQ9Zed-cXDiAaLUxouPsM3h-RGbyCj3IiLMPg",
                'user-email': "alpitpethani@gmail.com"
            };

            // Make the request using fetch
            const response = await fetch('https://www.universal-tutorial.com/api/getaccesstoken', {
                method: 'GET',  // or 'POST' depending on the API requirements
                headers: headers
            });

            // Check if the response is OK (status 200)
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            // Parse the response body as JSON
            const data = await response.json();

            if (data.auth_token) {
                setAuth_token(data.auth_token); // Store the auth_token
            } else {
                throw new Error("auth_token not found in response");
            }

        } catch (e) {
            console.error("Error:", e);
        }
    }
    useEffect(() => {
        getToken()
    }, [])

    // Fetch countries when component mounts
    useEffect(() => {
        const fetchCountries = async () => {
            try {
                const response = await fetchData('https://www.universal-tutorial.com/api/countries', optionObject);
                setCountries(response);
                setFilterCountries(response);
            } catch (error) {
                console.error('Error fetching countries:', error);
            }
        };
        auth_token && fetchCountries();
    }, [auth_token]);

    const getStates = async () => {
        const timer = setTimeout(async () => {
            try {
                const response = await fetchData(`https://www.universal-tutorial.com/api/states/${selectedCountry}`, optionObject);
                setStates(response);
                setFilterState(response);
            } catch (error) {
                console.error('Error fetching states:', error);
            }
        }, 500); // Delay of 500ms

        // Clean up the timer if selectedCountry changes before the timeout
        return () => clearTimeout(timer);
    }
    // UseEffect for debouncing fetching states when selectedCountry changes
    useEffect(() => {
        if (selectedCountry) {
            auth_token && getStates()
        }
    }, [selectedCountry, auth_token]);

    const getCities = async () => {
        const timer = setTimeout(async () => {
            try {
                const response = await fetchData(`https://www.universal-tutorial.com/api/cities/${selectedState}`, optionObject);
                setCities(response);
                setFilterCity(response);
            } catch (error) {
                console.error('Error fetching cities:', error);
            }
        }, 500); // Delay of 500ms

        return () => clearTimeout(timer);
    }

    // UseEffect for debouncing fetching cities when selectedState changes
    useEffect(() => {
        if (selectedState) {
            auth_token && getCities()
        }
    }, [selectedState, auth_token]);

    // UseEffect for debouncing fetching pincodes when selectedCity changes
    useEffect(() => {
        if (selectedCity) {
            const timer = setTimeout(async () => {
                try {
                    // const response = await fetchData(`https://api.example.com/pincodes?city=${selectedCity}`);
                    setPincodes([]);
                } catch (error) {
                    console.error('Error fetching pincodes:', error);
                }
            }, 500); // Delay of 500ms

            return () => clearTimeout(timer);
        }
    }, [selectedCity]);

    // useEffect(() => {
    //     if (selectedCountry) {
    //         auth_token && getStates()
    //     }
    //     if (selectedState) {
    //         auth_token && getCities()
    //     }
    // }, [auth_token])

    // Handlers for input changes (for triggering debounced fetch)
    const handleCountryChange = (e) => {
        const value = e.target.value;
        setSelectedCountry(value);
        setSelectedState('')
        setSelectedCity('')
        setFilterState([])
        setFilterCity([])
        setSelectedPincode('')
        setUser({ ...user, country: value, state: '', city: '', pincode: '' });
        if (value.trim() === '') {
            setCountryError('Country is Required.');
        } else {
            setCountryError('');
        }

        // Filter countries based on input
        const filtered = countries.filter((country) =>
            country.country_name.toLowerCase().includes(value.toLowerCase())
        );
        setFilterCountries(filtered);
    };
    const handleStateChange = (e) => {
        const value = e.target.value;
        setSelectedState(value);
        setSelectedCity('')
        setFilterCity([])
        setSelectedPincode('')
        setUser({ ...user, state: value, city: '', pincode: '' });
        if (value.trim() === '') {
            setStateError('State is Required.');
        } else {
            setStateError('');
        }
        const filtered = states.filter((state) =>
            state.state_name.toLowerCase().includes(value.toLowerCase())
        );
        setFilterState(filtered);
    };
    const handleCityChange = (e) => {
        const value = e.target.value;
        setSelectedCity(e.target.value);
        setSelectedPincode('')
        setUser({ ...user, city: e.target.value, pincode: '' });
        if (value.trim() === '') {
            setCityError('City is Required.');
        } else {
            setCityError('');
        }
        const filtered = cities.filter((city) =>
            city.city_name.toLowerCase().includes(value.toLowerCase())
        );
        setFilterCity(filtered);
    };
    const handlePincodeChange = (e) => {
        const value = e.target.value;
        setSelectedPincode(e.target.value);
        setUser({ ...user, pincode: e.target.value });
        if (value.trim() === '') {
            setPincodeError('Pincode is Required.');
        } else {
            setPincodeError('');
        }
    };


    useEffect(() => {
        const debounceTimeout = setTimeout(() => {
            let filtered = countryCodes;
            if (searchTerm) {
                filtered = filtered.filter(item =>
                    item.name.toLowerCase().includes(searchTerm.toLowerCase())
                );
            }



            // Use a Map to group items by their code
            const codeMap = new Map();

            filtered.forEach(item => {
                if (!codeMap.has(item.code)) {
                    codeMap.set(item.code, item); // Store the first item with this code
                } else {
                    // If this code already exists, keep the existing one
                    // This keeps the first item found with this code
                    // You could also decide to keep a specific item based on some logic
                }
            });

            // Convert the Map back to an array
            const uniqueFiltered = Array.from(codeMap.values());

            setFilteredCountries(uniqueFiltered);
        }, 300); // Wait for 300ms after user stops typing

        return () => clearTimeout(debounceTimeout); // Cleanup timeout on each render
    }, [searchTerm]);

    const saveInformation = async () => {
        if (!user.firstName) setFNameError('First Name is Required')
        if (!user.lastName) setLNameError('Last Name is Required')
        // if (user.company === '') setCompanyError('Company Name is Required')
        if (!user.pincode) setPincodeError('Pin Code is Required')
        if (!user.address) setAddressError('Address is Required')
        if (!user.country) setCountryError('Country is Required')
        if (!user.city) setCityError('City is Required')
        if (!user.state) setStateError('State is Required')
        if (user.firstName && user.lastName && user.address && user.pincode && user.city && user.state && user.country &&
            !fNameError && !lNameError && !addresssError && !countryError && !stateError && !cityError && !pincodeError
        ) {

            const payload = {
                "USERID": userInfo.USERID,
                "FIRST_NAME": user?.firstName?.trim() || "",
                "LAST_NAME": user?.lastName?.trim() || '',
                "GENDER": user?.gender || '',
                "COUNTRY": selectedCountry?.trim() || '',
                "CURRENCY": user?.currency || "INR",
                "COMPANY_NAME": user?.company?.trim() || '',
                "CONTACT_PERSON": user?.contactPerson?.trim() || '',
                "ADDRESS": user?.address?.trim() || '',
                "CITY": selectedCity?.trim() || '',
                "STATE": selectedState?.trim() || '',
                "GSTNO": user?.gst?.trim() || '',
                "PINCODE": user?.pincode || '',
            }

            try {
                setUpdateLoader(true)
                let response = await axiosInstance.post('account/update', payload);

                if (response.data.success === true) {
                    showSuccessToast(response.data.message)
                    setUpdateLoader(false)
                    localStorage.setItem('user-info', JSON.stringify(response.data.data))
                    window.location.reload()
                } else {
                    showErrorToast(response.data.message)
                    setUpdateLoader(false)
                }
                // console.log(response)
            } catch (error) {
                showErrorToast(error.response.data.message)
                setUpdateLoader(false)
            }
        }
    }

    const changeCurrency = async (value) => {
        setUser({ ...user, currency: value.Items })
        setIsDropdown(false)
    }

    return (
        <div className=' text-start 2xl:w-[55%] mx-auto mb-5'>
            <ToastNotification />
            <div className='px-5 md:px-0 md:pt-3 pt-2  text-start '>
                <span className='text-12 text-lightBlack font-inter '>
                    <span onClick={() => navigate("/")} className="cursor-pointer hover:underline">Home</span> / My Account
                </span>
            </div>
            <div className='flex gap-5 flex-col md:flex-row'>
                <div className='mx-auto w-[80%] md:w-[22%]'>
                    <AccountsSideBar />
                </div>
                <div className='w-[90%] md:w-[78%] mx-auto font-inter'>
                    <p className='text-22 py-1 font-semibold border-b'>Account Overview</p>
                    <div className='my-3 flex gap-x-5 gap-y-3 md:gap-y-5 flex-wrap'>
                        <div className='w-[100%] md:w-[47%]'>
                            <label className='w-[100%]'>Email : </label><input type='text' placeholder='Email Address' value={userInfo?.EMAIL} className='bg-headerMenuBackground py-1.5 px-3 border w-[100%]' disabled />
                        </div>
                        <div className='w-[100%] md:w-[47%]'>
                            <label className='w-[100%]'>Phone Number : </label><input type='text' placeholder='Phone Number' value={userInfo?.CON_CODE + ' ' + userInfo?.PHONE} className='bg-headerMenuBackground py-1.5 w-[100%] px-3 border' disabled />
                        </div>
                        <div className='w-[100%] md:w-[47%]'>
                            <label className='w-[100%]'>First Name : </label><input type='text' placeholder='First Name' value={user.firstName} className='border-b py-1.5 w-[100%] outline-none' onChange={(e) => { setUser({ ...user, firstName: e.target.value }); if (e.target.value.trim() === '') setFNameError('First Name is Required'); else setFNameError('') }} />
                            {fNameError && <p className='text-14 text-red-500'>{fNameError}</p>}
                        </div>
                        <div className='w-[100%] md:w-[47%]'>
                            <label className='w-[100%]'>Last Name : </label><input type='text' placeholder='Last Name' value={user.lastName} className='border-b py-1.5 w-[100%] outline-none' onChange={(e) => { setUser({ ...user, lastName: e.target.value }); if (e.target.value.trim() === '') setLNameError('Last Name is Required'); else setLNameError('') }} />
                            {lNameError && <p className='text-14 text-red-500'>{lNameError}</p>}
                        </div>
                        <div className='w-[100%] md:w-[47%]'>
                            <label className='w-[100%]'>Company : </label><input type='text' placeholder='Company Name' value={user.company} className='border-b py-1.5 w-[100%] outline-none' onChange={(e) => { setUser({ ...user, company: e.target.value }); }} />
                            {companyError && <p className='text-14 text-red-500'>{companyError}</p>}
                        </div>
                        <div className='w-[100%] md:w-[47%]'>
                            <label className='w-[100%]'>Contact Person : </label><input type='text' placeholder='Contact Person' value={user.contactPerson} className='border-b py-1.5 w-[100%] outline-none' onChange={(e) => setUser({ ...user, contactPerson: e.target.value })} />
                        </div>
                        <div className='w-[100%] md:w-[47%]'>
                            <label className='w-[100%]'>GST Number : </label><input type='text' placeholder='GST Number' value={user.gst} className='border-b py-1.5 w-[100%] outline-none' onChange={(e) => setUser({ ...user, gst: e.target.value })} />
                        </div>
                        <div className='w-[100%] md:w-[47%] '>
                            <label className='w-[100%] relative'>Address :  </label><input type='text' placeholder='Address' value={user.address} className='border-b py-1.5 w-[100%] outline-none' onChange={(e) => { setUser({ ...user, address: e.target.value }); if (e.target.value.trim() === '') setAddressError('Address is Required'); else setAddressError('') }} />
                            {addresssError && <p className='text-14 text-red-500'>{addresssError}</p>}
                        </div>
                        <div className='w-[100%] md:w-[47%]'>
                            <label class=" font-inter ">Country :</label>
                            <div class="relative mt-1" onClick={() => setCountyDropdown(!countyDropdown)} >
                                <input
                                    type="text"
                                    value={selectedCountry}
                                    className="relative  border-b py-1.5 w-[100%] outline-none"
                                    placeholder="Select Country"
                                    onChange={handleCountryChange}
                                    onBlur={() => setTimeout(() => setCountyDropdown(false), 200)} // Close dropdown on blur with a delay
                                />

                                {countyDropdown && (
                                    <>
                                        <div class="absolute z-10 bg-white border border-[#c0cece] rounded-lg w-full mt-0  ">
                                            <ul class="max-h-[30vh] overflow-auto">
                                                {filterCountries.map(item => (
                                                    <li
                                                        key={item.Items}
                                                        className="cursor-pointer px-3 py-1 hover:bg-gray-100 rounded-lg"
                                                        onClick={() => {
                                                            setSelectedCountry(item.country_name);
                                                            setCountyDropdown(false)
                                                            setCountryError('')
                                                            setSelectedState('')
                                                            setFilterState([])
                                                            setFilterCity([])
                                                            setSelectedCity('')
                                                            setSelectedPincode('')
                                                            setUser({ ...user, country: item.country_name, state: '', city: '', pincode: '' });
                                                            // document.getElementById('metal-input').value = item.Items; // Update input
                                                        }}
                                                    >
                                                        {item.country_name}
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                        {/* <div className='absolute z-[]'>
                                <option value="" className='text-12 bg:white text-black rounded-lg'>Select Shape</option>
                                {dropdown[1].map(item => <option value={`${item.Items}`} className='text-12 bg:white text-black'>{item.Items}</option>)}
                            </div> */}
                                    </>
                                )}
                            </div>
                            {countryError && <p className='text-14 text-red-500'>{countryError}</p>}
                        </div>
                        <div className='w-[100%] md:w-[47%]'>
                            <label class=" font-inter ">State:</label>
                            <div class="relative mt-1" onClick={() => setStateDropdown(!stateDropdown)} >
                                <input
                                    type="text"
                                    value={selectedState}
                                    className="relative  border-b py-1.5 w-[100%] outline-none"
                                    placeholder="Select State"
                                    onChange={handleStateChange}
                                    onBlur={() => setTimeout(() => setStateDropdown(false), 200)} // Close dropdown on blur with a delay
                                />

                                {stateDropdown && (
                                    <>
                                        <div class="absolute z-10 bg-white border border-[#c0cece] rounded-lg w-full mt-0  ">
                                            <ul class="max-h-[30vh] overflow-auto">
                                                {filterState?.length > 0 ? filterState.map(item => (
                                                    <li
                                                        key={item.Items}
                                                        className="cursor-pointer px-3 py-1 hover:bg-gray-100 rounded-lg"
                                                        onClick={() => {
                                                            setSelectedState(item.state_name);
                                                            setStateDropdown(false)
                                                            setStateError('')
                                                            setSelectedCity('')
                                                            setFilterCity([])
                                                            setSelectedPincode('')
                                                            setUser({ ...user, state: item.state_name, city: '', pincode: '' });
                                                            // document.getElementById('metal-input').value = item.Items; // Update input
                                                        }}
                                                    >
                                                        {item.state_name}
                                                    </li>
                                                )) : <li className='cursor-pointer px-3 py-1 rounded-lg'>State Not Found</li>}
                                            </ul>
                                        </div>
                                        {/* <div className='absolute z-[]'>
                                <option value="" className='text-12 bg:white text-black rounded-lg'>Select Shape</option>
                                {dropdown[1].map(item => <option value={`${item.Items}`} className='text-12 bg:white text-black'>{item.Items}</option>)}
                            </div> */}
                                    </>
                                )}
                            </div>
                            {stateError && <p className='text-14 text-red-500'>{stateError}</p>}
                        </div>
                        <div className='w-[100%] md:w-[47%]'>
                            <label class=" font-inter ">City:</label>
                            <div class="relative mt-1" onClick={() => setCityDropdown(!cityDropdown)} >
                                <input
                                    type="text"
                                    value={selectedCity}
                                    className="relative  border-b py-1.5 w-[100%] outline-none"
                                    placeholder="Select City"
                                    onChange={handleCityChange}
                                    onBlur={() => setTimeout(() => setCityDropdown(false), 200)} // Close dropdown on blur with a delay
                                />

                                {cityDropdown && (
                                    <>
                                        <div class="absolute z-10 bg-white border border-[#c0cece] rounded-lg w-full mt-0  ">
                                            <ul class="max-h-[20vh] overflow-auto">
                                                {filterCity?.length > 0 ? filterCity.map(item => (
                                                    <li
                                                        key={item.Items}
                                                        className="cursor-pointer px-3 py-1 hover:bg-gray-100 rounded-lg"
                                                        onClick={() => {
                                                            setSelectedCity(item.city_name);
                                                            setCityDropdown(false)
                                                            setCityError('')
                                                            setSelectedPincode('')
                                                            setUser({ ...user, city: item.city_name, pincode: '' });
                                                            // document.getElementById('metal-input').value = item.Items; // Update input
                                                        }}
                                                    >
                                                        {item.city_name}
                                                    </li>
                                                )) : <li className='cursor-pointer px-3 py-1 rounded-lg'>City Not Found</li>}
                                            </ul>
                                        </div>
                                        {/* <div className='absolute z-[]'>
                                <option value="" className='text-12 bg:white text-black rounded-lg'>Select Shape</option>
                                {dropdown[1].map(item => <option value={`${item.Items}`} className='text-12 bg:white text-black'>{item.Items}</option>)}
                            </div> */}
                                    </>
                                )}
                                {cityError && <p className='text-14 text-red-500'>{cityError}</p>}
                            </div>
                        </div>
                        <div className='w-[100%] md:w-[47%]'>
                            <label class=" font-inter ">Pin Code:</label>
                            <div class="relative mt-1" onClick={() => setpincodeDropdown(!pincodeDropdown)} >
                                <input
                                    type="text"
                                    value={selectedPincode}
                                    className="relative  border-b py-1.5 w-[100%] outline-none "
                                    placeholder="Pin Code"
                                    onChange={handlePincodeChange}
                                    onBlur={() => setTimeout(() => setpincodeDropdown(false), 200)} // Close dropdown on blur with a delay
                                />

                                {/* {pincodeDropdown && (
                                    <>
                                        <div class="absolute z-10 bg-white border border-[#c0cece] rounded-lg w-full mt-0  ">
                                            <ul class="max-h-[30vh] overflow-auto">
                                                {pincodes?.length > 0 ? pincodes.map(item => (
                                                    <li
                                                        key={item.Items}
                                                        className="cursor-pointer px-3 py-1 hover:bg-gray-100 rounded-lg"
                                                        onClick={() => {
                                                            setSelectedPincode(item.pincode);
                                                            setpincodeDropdown(false)
                                                            setPincodeError('')
                                                            // document.getElementById('metal-input').value = item.Items; // Update input
                                                        }}
                                                    >
                                                        {item.pincode}
                                                    </li>
                                                )) : <li className='cursor-pointer px-3 py-1 rounded-lg'>Pin Code Not Found</li>}
                                            </ul>
                                        </div>
                                    </>
                                )} */}
                            </div>
                            {pincodeError && <p className='text-14 text-red-500'>{pincodeError}</p>}
                        </div>
                        <div className='w-[100%] md:w-[47%]'>
                            <div className='w-[100%]'>
                                <label>Gender :</label>
                                <div className='flex flex-row w-full  items-center gap-5 mt-2'>
                                    {/* Gender Radio buttons */}
                                    <div className='flex flex-row justify-start items-center gap-5 w-[30%]'>
                                        <div className='flex flex-row items-center'>
                                            <input
                                                type='radio'
                                                value='Male'
                                                checked={user.gender?.toLowerCase() === 'male'}
                                                onChange={(e) => setUser({ ...user, gender: e.target.value })}
                                                name='gender'
                                                className='mr-1 w-5 h-5'
                                            /> Male
                                        </div>
                                        <div className='flex flex-row items-center'>
                                            <input
                                                type='radio'
                                                value='Female'
                                                checked={user.gender?.toLowerCase() === 'female'}
                                                onChange={(e) => setUser({ ...user, gender: e.target.value })}
                                                name='gender'
                                                className='mr-1 w-5 h-5'
                                            /> Female
                                        </div>
                                        <div className='flex flex-row items-center'>
                                            <input
                                                type='radio'
                                                value='Other'
                                                checked={user.gender?.toLowerCase() === 'other'}
                                                onChange={(e) => setUser({ ...user, gender: e.target.value })}
                                                name='gender'
                                                className='mr-1 w-5 h-5'
                                            /> Other
                                        </div>
                                        {/* {genderError && <p className=" text-red-500 text-14 font-inter">{genderError}</p>} */}
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className=' relative w-[100%] md:w-[47%]'>
                            <label>Currency : </label>
                            <div onClick={() => setIsDropdown(!isDropdown)} className='flex gap-1 justify-between items-center cursor-pointer border border-[#ccc] rounded-lg py-1.5 mt-1 px-2'>
                                <span className='font-inter flex items-center gap-1 text-12 lg:text-16'>
                                    <ReactCountryFlag countryCode={user.currency === 'USD' ? 'US' : 'IN'} svg className='text-24' />{user.currency}
                                </span>

                                <span className={`transform transition-transform ${isDropdown ? 'rotate-180' : 'rotate-0'}`}>
                                    <MdOutlineKeyboardArrowDown size={20} />
                                </span>
                            </div>

                            {isDropdown && (
                                <div className='absolute w-full left-0 border border-[#ccc] rounded-lg text-14 py-2  px-3 bg-white  top-16 md:top-7 lg:top-16 shadow-lg z-10'>
                                    <ul className='py-[2px] px-1'>
                                        <li className='font-medium '>Select Currency</li>
                                        {currency?.navigationMenu?.data?.CURRENCY?.map((value, index) => <li key={index} className=' hover:bg-gray-100 cursor-pointer flex gap-1 items-center my-1.5' onClick={() => changeCurrency(value)}><p className='flex items-center'> <ReactCountryFlag countryCode={value.Items === 'USD' ? 'US' : 'IN'} svg /></p><p>{`${value.Items === 'USD' ? 'United State' : 'India'}   (${value.Items})`}</p></li>)}
                                        {/* <li className='px-[17px] md:px-[19px] lg:px-[21px] py-[5px] hover:bg-gray-100 cursor-pointer' onClick={sortProductData}>Best Sellers</li>
                                    <li className='px-[17px] md:px-[19px] lg:px-[21px] py-[5px] hover:bg-gray-100 cursor-pointer' onClick={sortProductData}>Newest</li>
                                    <li className='px-[17px] md:px-[19px] lg:px-[21px] py-[5px] hover:bg-gray-100 cursor-pointer' onClick={sortProductData}>Price: Low to High</li>
                                    <li className='px-[17px] md:px-[19px] lg:px-[21px] py-[5px] hover:bg-gray-100 cursor-pointer' onClick={sortProductData}>Price: High to Low</li> */}
                                    </ul>
                                </div>
                            )}
                        </div>
                    </div>
                    {/* <div className='flex justify-center w-full mt-5 rounded-xl'>
                        <button className='bg-footerBackground cursor-pointer text-white w-[20%] py-1.5 rounded-xl' onClick={saveInformation}>Save</button>
                    </div> */}
                    <div className='flex justify-center w-full mt-5 rounded-xl'>

                        {
                            updateLoader ?
                                <button
                                    className='bg-footerBackground cursor-pointer flex justify-center text-white w-[20%] py-1.5 rounded-full'
                                >
                                    <RotatingLines
                                        visible={true}
                                        height="20"
                                        width="20"
                                        strokeWidth="5"
                                        strokeColor='white'
                                        animationDuration="0.75"
                                        ariaLabel="rotating-lines-loading"
                                        wrapperStyle={{}}
                                        wrapperClass=""
                                    />
                                </button>
                                :
                                <button
                                    className='bg-footerBackground cursor-pointer text-white w-[20%] py-1.5 rounded-full'
                                    onClick={saveInformation}
                                >
                                    Save
                                </button>
                        }
                    </div>
                </div>
            </div>
        </div >
    )
}

export default Account
