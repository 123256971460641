import React, { Fragment, useState } from 'react'
import { FaChevronDown, FaChevronUp } from 'react-icons/fa'
import Banner from '../../components/Banner'
import FlexView from '../../components/FlexView'
import ProductSlider from '../../components/ProductSlider'
import SliderView from '../../components/Slider'
import { Design_Your_Own_Jewelry_Gift, Gift_Education, Gifts_Banner, Jewelry_Gift_By_Recipient, Jewelry_Gift_With_Meaning, Jewelry_Information, More_Way_To_Shop, PopularRings, Shop_By_Occasion, Shop_By_Price, Top_Jewelry_Gifts } from '../../helpers/jsonData'

const Gifts = () => {
    const [isDropdown, setIsDropdown] = useState(false)
    return (
        <div>
            <div className=' md:pt-3 pt-2 md:pl-8 pl-5 text-start'>
                <span className='text-12 text-lightBlack font-inter'>
                    Home / Gifts
                </span>
            </div>

            <Banner data={Gifts_Banner} className={'text-black'} />

            <div className=" mx-auto w-[95%] 2xl:w-[70%] mt-16 ">
                <h3 className='text-30 font-inter mb-5'>Top Jewelry Gifts</h3>
                <SliderView data={Top_Jewelry_Gifts} />
            </div>

            <div className=' mx-auto w-[95%] 2xl:w-[70%] mt-20'>
                {Shop_By_Price.map((data, index) => {
                    return (
                        <div key={index} className='flex justify-between flex-col-reverse lg:flex-row lg:gap-10 gap-5'>
                            <div className='lg:pl-10 lg:pt-[8%] w-[90%] mx-auto'>
                                <p className='text-34 my-2 font-inter'>{data.title}</p>
                                <div className='flex flex-wrap gap-2 mt-10'>
                                    {data.buttons.map((button, index) => {
                                        return (
                                            <div key={index} className={`md:w-[48%] w-full  px-6 justify-center border-[1px] border-black border-solid flex flex-row p-3 hover:bg-black hover:text-white bg-white text-black-900 ' `}>
                                                <span className={`text-13 font-inter text-center  hover:text-white `}>
                                                    {button}
                                                </span>
                                            </div>

                                        )
                                    })}
                                </div>
                            </div>
                            <img src={data.image} alt="Kyrah Star" className='lg:w-[50%] w-full' />
                        </div>
                    )
                })}
            </div>


            <div className=" mx-auto w-[95%] 2xl:w-[70%] mt-16 ">
                <h3 className='text-30 font-inter mb-5'>Jewelry Gifts By Recipient</h3>
                <SliderView data={Jewelry_Gift_By_Recipient} />
            </div>


            <div className="mx-auto mt-20 w-[95%] 2xl:w-[70%]">
                <h1 className="text-30 text-start w-full font-inter">
                    Popular Jewelry Gifts
                </h1>
                <div>
                    <ProductSlider data={PopularRings} />
                </div>
            </div>


            <div className=' mx-auto w-[95%] 2xl:w-[70%] mt-10 flex flex-col md:flex-row justify-between flex-wrap gap-3'>
                {Design_Your_Own_Jewelry_Gift.map((data, index) => {
                    return (
                        <div key={index} className='md:w-[49%] w-full my-5'>
                            <img src={data.image} alt="Kyrah Star" className='w-full' />
                            <div >
                                <p className='text-34 my-1 font-inter'>{data.title}</p>
                                <p>{data.subtitle}</p>
                                {data.buttons.map((button, index) => {
                                    return (
                                        <div key={index} className={`lg:w-1/2 w-full  mt-3 px-6 relative text-center border-[1px] border-black border-solid  p-3 hover:bg-black hover:text-white bg-white text-black-900 ' ${isDropdown && button.type === 'dropdown' ? 'bg-black text-white' : ''} `} onClick={button.type === 'dropdown' ? () => setIsDropdown(!isDropdown) : undefined}>
                                            <span className={`text-13 font-inter   hover:text-white   ${button.type === 'dropdown' ? 'flex justify-end md:gap-[28%] lg:gap-[13%] gap-[30%] sm:gap-[38%]' : ''}`} >
                                                {button.text}
                                                {button.type === 'dropdown' ? isDropdown ?
                                                    <FaChevronUp className='ml-1 lg:ml-1' />
                                                    :
                                                    <FaChevronDown className='ml-1 lg:ml-1' /> : ''}
                                                {button.type === 'dropdown' ? isDropdown && (
                                                    <div className='absolute text-black right-0 mt-2 w-full bg-white text-left border top-9 border-[#e2e2e2] shadow-lg z-10'>
                                                        <ul className='py-2'>
                                                            <li className='px-4 py-2 hover:bg-gray-100 cursor-pointer'>Naturel Diamond necklace</li>
                                                            <li className='px-4 py-2 hover:bg-gray-100 cursor-pointer'>Lab Diamond necklace</li>
                                                        </ul>
                                                    </div>
                                                ) : ''}
                                            </span>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    )
                })}
            </div>

            <div className=" mx-auto w-[95%] 2xl:w-[70%] mt-16 ">
                <h3 className='text-30 font-inter mb-5'>Jewelry Gifts With Meaning</h3>
                <div className='flex justify-between gap-5'>
                    <FlexView data={Jewelry_Gift_With_Meaning} />
                </div>
            </div>

            <div className='mx-auto w-[95%] 2xl:w-[70%] mt-24'>
                {Shop_By_Occasion.map((data, index) => {
                    return (
                        <div key={index} className='flex justify-between flex-col-reverse lg:flex-row lg:gap-10 gap-5'>
                            <div className='lg:pl-5 lg:pt-[8%] w-[90%] mx-auto'>
                                <p className='text-34 my-2 font-inter'>{data.title}</p>
                                <div className='flex flex-wrap gap-2 mt-10'>
                                    {data.buttons.map((button, index) => {
                                        return (
                                            <div key={index} className={`md:w-[48%] w-full  px-6 justify-center border-[1px] border-black border-solid flex flex-row p-3 hover:bg-black hover:text-white bg-white text-black-900 ' `}>
                                                <span className={`text-13 font-inter text-center  hover:text-white `}>
                                                    {button}
                                                </span>
                                            </div>

                                        )
                                    })}
                                </div>
                            </div>
                            <img src={data.image} alt="Kyrah Star" className='lg:w-[50%] w-full' />
                        </div>
                    )
                })}
            </div>

            <div className=" mx-auto w-[95%] 2xl:w-[70%] mt-16 ">
                <h3 className='text-30 font-inter mb-5'>More Ways to Shop</h3>
                <FlexView data={More_Way_To_Shop} divStyle={true} />
            </div>

            <div className=" mx-auto w-[95%] 2xl:w-[70%] p-10 sm:p-14 mt-16 bg-[url('https://image.brilliantearth.com/cdn-cgi/image/width=1440,height=400,quality=100,format=auto/https://www.brilliantearth.com/bee2-ui/img/mission/sustainability/SustainabilityHeroDesktop.jpg')] bg-cover">
                {
                    Jewelry_Information.map((data, index) => {
                        return (
                            <Fragment key={index}>
                                <div className='text-center text-white'>
                                    <p className='font-bold text-30 my-2'>{data.title}</p>
                                    <p className='w-full sm:w-[50%] mx-auto'>{data.subtitle}</p>
                                </div>
                                <div className={`md:w-1/5 w-1/3 mx-auto mt-6 px-6 justify-center  flex flex-row p-3 hover:bg-black hover:text-white bg-white text-black-900 ' `}>
                                    <span className={`text-13 font-inter text-center  hover:text-white truncate`}>
                                        {data.buttons[0]}
                                    </span>
                                </div>
                            </Fragment>
                        )
                    })
                }
            </div>

            <div className=" mx-auto w-[95%] 2xl:w-[70%] mt-16 ">
                <h3 className='text-30 w-full font-inter mb-5'>Gifting Education</h3>
                <SliderView data={Gift_Education} />
            </div>

            <div className=" mx-auto w-[80%] 2xl:w-[60%] mt-32 ">
                <h3 className='text-30 w-full text-center font-inter mb-5'>Ethically-Sourced Gemstones</h3>
                <p className='mt-5 text-[#a0a0a0]-500 font-inter'>Gemstone rings are an excellent alternative to more traditional <span className='underline'>diamond</span> styles. <span className='underline'>Morganite,</span> <span className='underline'>emerald,</span> ruby, aquamarine, moissanite, sapphire, and amethyst are all fabulous gemstones that are the perfect choice for someone looking for a look with a pop of color. Gemstone engagement rings are a special way to celebrate your unique, anything-but-colorless love story – or simply as a colorful gift for yourself.</p>
                <p className='mt-5 text-[#a0a0a0]-500 font-inter'>Once you have selected the perfect semi precious gemstone, it is time to find a setting to match it. Our curated selection of designs ranges from nature-inspired to ultra-modern. Plain bands give way to luxe diamond-accented styles, while other gemstone ring settings are unique enough to catch the eye. Choose a setting for your stone that will complement and enhance its beauty and color, creating the perfect semi precious gemstone ring.</p>
            </div>
        </div >
    )
}

export default Gifts
