import React, { useEffect, useLayoutEffect, useState } from 'react'
import { IoStar } from 'react-icons/io5'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { ClipLoader } from 'react-spinners'
import { decryptData } from '../components/decrypt'
import DesignRingComponent from '../components/DesignRingComponent'
import { showErrorToast, showSuccessToast } from '../components/ToastNotification'
import { axiosInstance } from '../redux/api'
import { addCartListCount } from '../redux/slices/cartList.slice'
import { decryptURL } from '../utils/encrypt'



const PurchaseReview = () => {
    // const { state } = useLocation()
    // const [DiamondCarat, setDiamondCarat] = useState(3)
    // const [text, setText] = useState(`Shown with ${selectedDiamondCarat[DiamondCarat - 1]} Carat Diamond`)
    // const [selectedDiamondOrigin, setSelectedDiamondOrigin] = useState('')

    // const [selectedHoverDiamondOrigin, setSelectedHoverDiamondOrigin] = useState('')
    // const [range, setRange] = useState(50)
    // const [ringDetail, setRingDetail] = useState(false)
    // const [recommendedBridalSet, setRecommendedBridalSet] = useState(false)
    // const [showMenu, setShowMenu] = useState()
    // const [hoverIndex, setHoverIndex] = useState()
    const [productDetails, setProductDetails] = useState();
    const [diamondDetails, setDiamondDetails] = useState();
    const [priceDetails, setPriceDetails] = useState();
    const [loading, setLoading] = useState(false);
    // const [isReadMore, setIsReadMore] = useState(true);
    // const [hoveredMetal, setHoveredMetal] = useState(null);
    // const [selectedMetal, setSelectedMetal] = useState("White Gold");
    // const [showVariants, setShowVariants] = useState();
    // const [selectedVariantId, setSelectedVariantId] = useState(null);
    const navigate = useNavigate()
    const data = useParams();
    const { diamond } = useParams();

    const [skuName, variantId, shape] = data?.skuname?.split('-');

    // const currency = JSON.parse(localStorage.getItem('user-info'))?.CURRENCY ?? 'INR'
    const currency = (localStorage.getItem('currency')) ?? 'INR'
    const { selectedSize } = useSelector(state => state.sizeData)

    const dispatch = useDispatch()
    const userInfo = JSON.parse(localStorage.getItem('user-info'))

    //----------- PRODUCT API HANDLER -----------
    const fetchProductDetailsData = async () => {
        setLoading(true)
        try {
            const requestBody = {
                PRD_VARIANT_ID: variantId,
                SKUNO: decryptURL(skuName),
                S_CODE: shape ? shape : "",
                StockID: decryptURL(diamond),
                CURR: currency,
                SIZE: selectedSize
            }
            const response = await axiosInstance.post('data/product/final', requestBody);
            const isDecryption = process.env.REACT_APP_IS_DECRYPT
            setProductDetails(isDecryption == 'true' ? decryptData(response.data).data[1]?.[0] : response.data.data.data[1]?.[0])
            setPriceDetails(isDecryption == 'true' ? decryptData(response.data).data[2]?.[0] : response.data.data.data[2]?.[0])
            setDiamondDetails(isDecryption == 'true' ? decryptData(response.data).data[0]?.[0] : response.data.data.data[0]?.[0])
            // setProductDetails(response?.data?.data[1]?.[0])
            // setPriceDetails(response?.data?.data[2]?.[0])
            // setDiamondDetails(response?.data?.data[0]?.[0])
            setLoading(false)
        } catch (e) {
            console.log(e)
            setLoading(false)
        }
    }

    useLayoutEffect(() => {
        fetchProductDetailsData()
    }, [variantId, shape, diamond])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [variantId, skuName, shape, diamond])

    const addToCart = async (product, diamond) => {
        console.log(product, diamond, 'data')
        if (userInfo?.USERID) {
            // const shape = shapes.Shape.find(data => data.Items === diamondDetails?.[0]?.Shape)
            const payload = {
                "USERID": userInfo?.USERID,
                "SKUNO": product?.SKUNO || '',
                "PRD_VARIANT_ID": product?.PRD_VARIANT_ID || 0,
                "S_CODE": shape ? shape : "",
                "STOCK_ID": diamond?.StockID || '',
                "IS_ONLY_DIAMOND": false,
                "IS_REMOVE": false,
                "QUANTITY": 1,
                "PRD_SIZE": selectedSize
            }


            try {
                const response = await axiosInstance.post('cart/add', payload);
                // const jsonResponse = await axiosInstance.post('wishlist/add', payload);
                // const isDecryption = process.env.REACT_APP_IS_DECRYPT
                // const response = Decryption == 'true' ? decryptData(jsonResponse.data) : jsonResponse.data.data
                if (response.data.success) {
                    showSuccessToast(response.data.message)
                    dispatch(addCartListCount(response?.data?.data?.[0]?.[0]?.CART_COUNT))
                    navigate('/shop/cart')
                }
            } catch (e) {
                showErrorToast(e.response.data.message)
                // setLoading(false)
            }
        } else {
            navigate('/login')
        }
    }

    return (
        <div >
            {/* <div className=' md:pt-3 pt-2 md:pl-8 pl-5 text-start'>
                <span className='text-12 text-lightBlack font-inter capitalize'>
                </span>
            </div> */}

            {!loading && <div className='hidden md:block'>
                <DesignRingComponent
                    // settings={skuName && "true"}
                    stockId={diamond}
                    skuno={skuName}
                    variantId={variantId}
                    Shape={shape ? shape : ""}
                    ringInfo={{
                        "SKU_NAME": productDetails?.SKU_NAME,
                        "PRICE": productDetails?.PRICE,
                        "AVG_WIDTH": productDetails?.AVG_WIDTH,
                        "AVG_LENGTH": productDetails?.AVG_LENGTH,
                        "PRD_SIZE": productDetails?.PRD_SIZE,
                        "IMG_URL": productDetails?.IMG_URL,
                        CURR_SYMBOL: productDetails?.CURR_SYMBOL,
                    }}
                    diamondInfo={diamondDetails || { data: '' }}
                />
            </div>}

            {loading ? <div className='flex flex-row justify-center w-full  '> <ClipLoader className='my-[3%]' /></div> : <div className=' w-[95%] 2xl:w-[70%] container mx-auto flex flex-col lg:flex-row  my-5 md:mt-10'>
                <div className='lg:w-[60%] w-[100%] h-fit lg:sticky top-0 static mb-5 '>

                    <div className='flex '>
                        {/*------------------ First Image ------------------*/}
                        {productDetails?.IMG_URL && <div className='relative text-center mr-2 w-[50%]'>
                            <div className='absolute left-2 top-1.5'>
                                {/* <p className='text-10 px-3 py-1 bg-[#183E40] text-white rounded-full'>Signature Collections</p> */}
                            </div>
                            <img src={productDetails?.IMG_URL || 'placeholder.jpg'} alt='Kyrah Star' className='w-full h-full' />
                            {/* <p className='absolute bottom-1 left-[10%] text-12 sm:text-14'>{text}</p> */}
                        </div>}

                        {/*---------- Second Image (HOVER_IMG_URL) -----------*/}
                        {diamondDetails?.VideoURL && <div className='w-[50%]'>
                            <iframe src={diamondDetails?.VideoURL} className='w-[108%] lg:w-[95%] xl:w-[95%]  2xl:w-full 2xl:h-[301px] h-[271px] mx-auto scrollbar-hide overflow-hidden' ></iframe>
                        </div>}
                    </div>

                    <div className='flex mt-2'>
                        {/*---------- Third Image ----------*/}
                        {diamondDetails?.ImgURL && <div className='w-[50%] mr-2'>
                            <img
                                src={diamondDetails?.ImgURL || 'placeholder.jpg'}
                                alt='Kyrah Star Engagement Ring Additional View'
                                className='w-full h-full object-cover'
                            />
                        </div>}
                        {/*---------- Fourth Image ----------*/}
                        {productDetails?.HOVER_IMG_URL && <div className='relative text-center w-[50%]'>
                            <img src={productDetails?.HOVER_IMG_URL || 'placeholder.jpg'} alt='Kyrah Star' />
                            {/* <p className='absolute bottom-1 left-[10%] text-12 sm:text-14'>{text}</p> */}
                        </div>}
                    </div>

                    <div className='flex  mt-2'>
                        {productDetails?.THIRD_IMG_URL && <div className='relative text-center w-[50%]'>
                            <img src={productDetails?.THIRD_IMG_URL || 'placeholder.jpg'} alt='Kyrah Star' />
                            {/* <p className='absolute bottom-1 left-[10%] text-12 sm:text-14'>{text}</p> */}
                        </div>}

                        {productDetails?.FOURTH_IMG_URL && <div className='relative text-center w-[50%]'>
                            <img src={productDetails?.FOURTH_IMG_URL || 'placeholder.jpg'} alt='Kyrah Star' />
                            {/* <p className='absolute bottom-1 left-[10%] text-12 sm:text-14'>{text}</p> */}
                        </div>}
                    </div>

                    <div className='flex  mt-2'>
                        {productDetails?.VIDEO_URL && <div className='relative text-center w-[50%] mr-2'>
                            <iframe src={productDetails?.VIDEO_URL} className='w-[108%] lg:w-[95%] xl:w-[95%]  2xl:w-full 2xl:h-[301px] h-[271px] mx-auto scrollbar-hide overflow-hidden' ></iframe>
                        </div>}

                        {productDetails?.SECOND_VIDEO_URL && <div className='w-[50%]'>
                            <iframe src={productDetails?.SECOND_VIDEO_URL} className='w-[108%] lg:w-[95%] xl:w-[95%]  2xl:w-full 2xl:h-[301px] h-[271px] mx-auto scrollbar-hide overflow-hidden' ></iframe>
                        </div>}

                    </div>

                    {/* <div className='mt-2 w-[50%]'>
                        <div className={`w-full relative `}>
                            <img src='https://image.brilliantearth.com/media/diamond_ring_vto/A8/BE18959_yellow_Round_hand_zo_2_carat.png' alt='Kyrah Star' className='absolute top-0' />
                            {range < 100 && range > 0 ? <img src='https://css.brilliantearth.com/static/img/handskin/female_hand_visualizer_zo_dark2_updated.jpg' alt='Kyrah Star' className={`opacity-${range}`} /> :
                                range === 200 ? <img src='https://css.brilliantearth.com/static/img/handskin/female_hand_visualizer_zo_light_updated.jpg' alt='Kyrah Star' /> :
                                    range === 0 ? <img src='https://css.brilliantearth.com/static/img/handskin/female_hand_visualizer_zo_dark2_updated.jpg' alt='Kyrah Star' /> :
                                        <img src='https://css.brilliantearth.com/static/img/handskin/female_hand_visualizer_zo_dark_updated.jpg' alt='Kyrah Star' className={`opacity-${range - 100}`} />
                            }
                            <div className='absolute bottom-0 left-[10%]'>
                                <span className=''> Darker</span><input type='range' min={0} max={200} value={range} onChange={changeRangeHandler} className='accent-hover ' /><span>Lighter</span>
                            </div>
                        </div>
                    </div> */}
                </div>

                <div className='lg:w-[40%] w-full lg:pl-10  mt-5 lg:mt-0'>
                    <p className='text-22  font-medium mb-2 font-inter text-filterHeadingtext'>Your One-of-a-Kind Ring</p>
                    <hr />
                    <p className='text-14 md:text-16 font-normal mt-2 font-inter text-filterHeadingtext'>The total diamond carat weight of your ring is {priceDetails?.TOTAL_WEIGHT} CT.</p>

                    <div className='mt-5 flex flex-row items-center'>
                        <div className=' w-[15%] mx-auto'>
                            <img
                                src={`https://css.brilliantearth.com/static/img/svg/ring-green-y23.svg`}
                                alt='Settings'
                                className='w-16 h-16 mx-auto'
                            />
                        </div>
                        <div className='ml-4 w-[80%]'>
                            <p className='text-14 md:text-16 font-normal mt-2 font-inter text-filterHeadingtext'>{productDetails?.METAL} {productDetails?.SKU_NAME}</p>
                            <span className='flex mt-3'>
                                <IoStar />
                                <IoStar />
                                <IoStar />
                                <IoStar />
                                <IoStar />
                            </span>
                            <p className='text-14 md:text-16 font-light mt-3 font-inter text-black'>
                                {productDetails?.PRICE?.toLocaleString()}{productDetails?.CURR_SYMBOL}
                            </p>
                        </div>
                    </div>

                    <div className='mt-5 flex flex-row items-center'>
                        <div className='w-[15%] mx-auto'>
                            <img
                                src={`https://css.brilliantearth.com/static/img/svg/Sparkling-Diamond-green-y23.svg`}
                                alt='Settings'
                                className='w-12 h-12 mx-auto'
                            />
                        </div>
                        <div className='ml-4 w-[80%]'>
                            <p className='text-14 md:text-16 font-normal mt-2 font-inter text-filterHeadingtext'>{diamondDetails?.Carat} Carat {diamondDetails?.Shape} Diamond</p>
                            <div className='mt-2 font-inter text-16 font-normal text-filterHeadingtext'>
                                {diamondDetails?.Lab} • {diamondDetails?.Color} Color • {diamondDetails?.Cut} Cut • {diamondDetails?.Floro} Floro • {diamondDetails?.Pol} Polish • {diamondDetails?.Sym} Sym
                            </div>
                            <p className='text-14 md:text-16 font-light mt-3 font-inter text-black'>
                                {diamondDetails?.Price?.toLocaleString()}{diamondDetails?.CURR_SYMBOL}
                            </p>
                        </div>
                    </div>

                    <div>
                        <div className='text-22 font-inter text-filterHeadingtext font-medium'>
                            <span className='font-medium mr-2'>{priceDetails?.FINAL_PRICE?.toLocaleString()}{priceDetails?.CURR_SYMBOL}</span>
                        </div>
                    </div>
                    <div className='mt-5  gap-5 items-center text-15 flex'>
                        <button className='w-[60%] bg-footerBackground rounded-full text-white p-2 text-md ' onClick={() => addToCart(productDetails, diamondDetails)}>ADD TO BAG</button>
                        {/* <GoHeart className='size-6' /> */}
                    </div>
                </div>
            </div>}

            {/* <div className='w-[100%] 2xl:w-[70%] container mx-auto   mt-10'>
                {
                    MAD_FOR_YOU.map((data, index) => {
                        return (
                            <div key={index} className='flex flex-col lg:flex-row justify-between bg-[#183E40] text-white py-10 sm:px-20'>
                                <div className='lg:w-[40%] w-[90%] mx-auto'>
                                    <p className='text-32 my-1 text-center lg:text-left'>{data.title}</p>
                                    <p className='text-14'>{data.content}</p>
                                </div>
                                <div className='lg:w-[45%] w-[90%] mx-auto mt-3 lg:mt-0'>
                                    {data.menu.map((menu, index) => {
                                        return (
                                            <>
                                                <hr />
                                                <div onClick={() => { index === showMenu ? setShowMenu() : setShowMenu(index) }} className='flex cursor-pointer my-2 flex-row justify-between items-center w-[100%] relative'>
                                                    <div className={`text-14 font-inter text-black font-normal `}>
                                                        <div key={index} className='flex text-white '>
                                                            <div className=''>
                                                                {menu.image === 'leaves' ? <GiThreeLeaves className='lg:size-6 size-5 lg:mt-2 mt-0 mr-2' /> : menu.image === 'recycle' ? <FaRecycle className='lg:size-6 size-5 lg:mt-2 mt-0 mr-2' /> : menu.image === 'people' ? <IoPeopleCircleOutline className='lg:size-6 size-5 lg:mt-2 mt-0 mr-2' /> : ''}
                                                            </div>
                                                            <div className='flex justify-between items-center w-100'>
                                                                <div className=''>
                                                                    <p>{menu.menu_title}</p>
                                                                    <p className='hidden lg:block'>{menu.menu_subtitle}</p>
                                                                    <div className={`mt-2 pr-5 mx-w-[100%] transition-all duration-300 ease-in-out overflow-hidden ${showMenu === index ? 'max-h-[1000px] opacity-100' : 'max-h-0 opacity-0'}}`}>
                                                                        <p>{menu.menu_content}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='absolute lg:top-2 top-1 right-0' onClick={() => setShowMenu(index)}>
                                                                {showMenu === index ? <FaChevronUp /> : <FaChevronDown />}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div >
                                            </>
                                        )
                                    })}
                                </div>
                            </div>
                        )
                    })
                }
            </div> */}

            {/* <div className='mx-auto mt-12 container w-[95%] 2xl:w-[70%]'>
                <h1 className='text-30 text-start w-full font-inter'>
                    Other Engagement Rings You'll Love
                </h1>

                <div>
                    <div className="flex flex-row gap-6 justify-between mx-auto w-full mt-6">
                        <Slider {...settings3} className='w-full flex flex-row mx-2 gap-5'>
                            {PopularRings?.map((item, index) => {
                                return (
                                    <div key={index} className={`relative px-1.5 outline-none text-[#2c2c2c]  ${index === 3 ? 'w-1/2' : 'w-full'}`}>
                                        <div className='absolute left-2 top-1.5'>
                                            <p className='text-10 px-3 py-1 bg-[#183E40] text-white rounded-full'>Signature Collections</p>
                                        </div>
                                        <ProductComponent
                                            item={item}
                                            hoverIndex={index}
                                        />
                                    </div>
                                )
                            })}
                        </Slider>
                    </div>
                </div>
            </div> */}

            {/* <div className=' bg-[#f8f8f8] mt-10'>
                <div className='w-[95%] 2xl:w-[70%] mx-auto lg:pl-5 lg:py-10 '>
                    <p className='lg:text-32 text-20 pt-3 lg:my-5 font-inter'>Reviews</p>
                    {
                        REVIEW.map((review, index) => {
                            return (
                                <div key={index} className='w-full'>
                                    <div className='flex w-full'>
                                        <div className='w-[30%]'>
                                            <p className='lg:my-2  font-inter text-24'>{review.heading.rating}</p>
                                            <div className='flex'>
                                                {[1, 2, 3, 4, 5].map((star, index) => {
                                                    return (
                                                        <IoMdStar key={index} className='size-6' />
                                                    )
                                                })}
                                            </div>
                                            <p className='my-2 text-12'>{review.heading.total_review} Reviews</p>
                                            <a className='my-2 underline' href='/product-detail'>Leave a Reaview</a>
                                        </div>
                                        <div className=" flex-row gap-6 justify-between mx-auto w-[85%] px-3 hidden lg:flex ">
                                            <Slider {...settings4} className='w-full flex flex-row mx-2 gap-5'>
                                                {review.heading.images?.map((image, index) => {
                                                    return (
                                                        <div key={index} className={`px-1 outline-none text-[#2c2c2c]  ${index === 3 ? 'w-1/2' : 'w-full'}`}>
                                                            <img src={image} alt='Kyrah Star' className='w-32 h-32' />
                                                        </div>
                                                    )
                                                })}
                                            </Slider>
                                        </div>
                                    </div>
                                    <hr className='h-2 mt-10 mb-5' />
                                    {review.reviews.map((review, index) => {
                                        return (
                                            <>
                                                <div key={index} className='pl-2 text-14 relative'>
                                                    <p>{review.user_name}</p>
                                                    <div className='flex gap-3 justify-between'>
                                                        <div className='w-[80%]'>
                                                            <div className='flex'>
                                                                {
                                                                    [1, 2, 3, 4, 5].map((star, index) => {
                                                                        return (
                                                                            <IoMdStar key={index} className='my-2' />

                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                            <p className='font-medium my-1'>{review.review_heading} </p>
                                                            <p className='line-clamp-2'>{review.review_content} </p>
                                                        </div>
                                                        <div className='w-[6%] relative'>{review.images && <>
                                                            <img src={review.images[0]} alt='Kyrah Star' className='w-full h-full ' />
                                                            <span className='absolute bottom-1  right-1 px-1  bg-[#f8f8f8]'>+{review.images.length - 1}</span>
                                                        </>}</div>
                                                    </div>
                                                    <p className='absolute top-0 right-2'>{review.review_data} </p>
                                                </div >
                                                <hr className='h-2 my-5' />
                                            </>
                                        )
                                    })}
                                    <div className={`w-1/2 lg:w-[20%] mx-auto my-5   border-[1px] border-black px-6 justify-center text-black hover:bg-black hover:text-white flex flex-row py-3`}>
                                        <span className='text-14 font-inter text-center'>
                                            Load More
                                        </span>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div> */}

            {/* <div className='w-[95%] 2xl:w-[70%] mx-auto mb-10 mt-20 lg:mt-10'>
                {
                    AS_SEE_ON_YOU.map((data, index) => {
                        return (
                            <div key={index} className=''>
                                <div className='flex flex-col lg:flex-row'>
                                    <div className='content-center text-center'>
                                        <p className='text-24 lg:mb-2 font-inter'>{data.title}</p>
                                        <p className='text-14 font-inter'>{data.subtitle}</p>
                                        <p className='text-14 font-inter'>{data.tag}</p>
                                    </div>
                                    <div className="flex flex-row gap-6 justify-between mx-auto w-[80%]  px-3  ">
                                        <Slider {...settings2} className='w-full flex flex-row mx-2 gap-5'>
                                            {data.images?.map((image, index) => {
                                                return (
                                                    <div key={index} className={` relative`}>
                                                        <img src={image} alt='Kyrah Star' className=' object-cover duration-300 lg:h-[338px] lg:w-[338px] ' />
                                                        <p className='absolute pt-[50%] opacity-0 pl-[35%] top-0 text-white hover:bg-black hover:bg-opacity-75 duration-300 hover:opacity-100 h-full w-full'>Shop Now</p>
                                                    </div>
                                                )
                                            })}
                                        </Slider>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>

            <div className='w-[95%] 2xl:w-[70%] mx-auto mt-10'>
                <div className='text-30 lg:text-36 font-inter '>
                    Recently View
                </div>
                <div className={`mt-2 pr-5 mx-w-[100%] transition-all duration-300 ease-in-out `}>
                    <Slider {...settings3} className='mt-4 w-full '>
                        {PopularRings.map((data, index) => {
                            return (
                                <div key={index} className={`cursor-pointer relative`} onMouseEnter={() => setHoverIndex(index)} onMouseLeave={() => setHoverIndex()}>
                                    <div className='absolute left-2 top-1.5'>
                                        <p className='text-10 px-3 py-1 bg-[#183E40] text-white rounded-full'>Signature Collections</p>
                                    </div>
                                    <img src={hoverIndex === index ? data.attributes.hover_link : data.mappings.core.imageUrl} className={` px-1.5`} alt="Kyrah Star" />
                                    <p className=' text-center px-3'>{data.mappings.core.name}</p>
                                    <p className=' text-center px-3'>${data.mappings.core.price}</p>
                                </div>
                            )
                        })}
                    </Slider>
                </div>
            </div> */}
        </div>
    )
}

export default PurchaseReview
