import React from 'react';
import { Navigate, Route } from 'react-router-dom';

const ReverseProtectedRoute = ({ element, ...rest }) => {
    const user = localStorage.getItem('user-info'); // Check if user is logged in

    // If the user is logged in, redirect them to the homepage or another page
    if (user) {
        return <Navigate to="/" />;
    }

    // If not logged in, return the given element (e.g., login or register page)
    return element;
};


const ProtectedRoute = ({ element, ...rest }) => {
    const user = localStorage.getItem('user-info'); // Check if user is logged in

    if (!user) {
        // If not authenticated, redirect to the login page
        return <Navigate to="/login" />;
    }

    // If authenticated, render the route
    return element;
};


export { ProtectedRoute }
export default ReverseProtectedRoute;
