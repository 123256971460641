import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ClipLoader } from 'react-spinners';
import GemAndShapeViewer from '../components/GemAndShapeViewer';
import { Images } from '../components/Image';
import SliderView from '../components/Slider';
import { axiosInstance } from '../redux/api';
import { addHomePageData, setHomeLoading } from '../redux/slices/pageContent.slice';
import { decryptData } from '../components/decrypt';

const HomePage = () => {
    const [hoverBanner, setHoverBanner] = useState('Home')
    const data = useSelector((state) => state.data)
    const dispatch = useDispatch()

    // async function fetchToken() {
    //     const response = await axiosInstance.post('token');
    //     const token = localStorage.getItem('token')
    //     token && fetchBanner()
    //     localStorage.setItem('token', response.data.data)
    // };
    const fetchBanner = async () => {
        try {
            dispatch(setHomeLoading())
            let response = await axiosInstance.post('/banner');
            const isDecryption = process.env.REACT_APP_IS_DECRYPT
            dispatch(addHomePageData({ data: isDecryption == 'true' ? decryptData(response.data).data : response.data.data.data }))
        } catch (e) {
            // if (e.response.data.message === 'Unauthorized!') {
            //     fetchToken()
            // }
            console.log(e.response.data.message)
        }
    };

    useEffect(() => {
        fetchBanner()
    }, [])

    return (
        data.home.loading ? <p className=' text-center h-[100vh] mt-20'><ClipLoader /></p> :
            <div className=' mx-auto mb-20'>
                {/* <Header /> */}
                {/* <Banner data={HomePage_Banner} /> */}
                {data.home?.data?.Banner?.length > 0 && <div className='relative h-[650px] w-full transition-all ease-in-out duration-700'  >
                    {
                        data.home.data.Banner?.map((data, index) => {
                            return (
                                <div key={index} className={`text-bannerTextColor w-full transition-all ease-in-out duration-1000 ${data.BANNER_NAME === hoverBanner ? 'opacity-100' : 'opacity-0 absolute top-0'}`} >
                                    <div className='duration-1000 ease-in-out'>
                                        <img src={data.IMG_URL} className={`w-[100%]
                                       h-[500px] lg:h-[650px]
                                         object-cover`} alt="Kyrah Star" />
                                    </div>

                                    <div className={`${data.IS_LEFT_ALIGN ? 'text-black lg:text-bannerTextColor lg:absolute lg:w-[65%] 2xl:w-[50%] items-center   lg:left-0 justify-center flex  lg:top-[50%] -translate-y-1/2' : 'lg:absolute lg:w-[53%] 2xl:w-[50%] items-center   lg:right-0 justify-center flex  lg:top-[50%] -translate-y-1/2'}`}>
                                        <div className='flex lg:pb-[20%] flex-col w-[90%] sm:w-[80%] md:w-[75%] text-center lg:text-left sm:text-center  mt-40 lg:mt-0 '>
                                            <h1 className=' text-32 sm:text-42 lg:text-bannerHeading lg:leading-none font-inter xl:font-bannerHeading'>
                                                {data.HEADER}
                                            </h1>

                                            <span className=' lg:text-bannerDescription sm:text-20 text-16 font-inter lg:mt-3 lg:mb-5 sm:my-2 mb-2 font-bannerDescription'>
                                                {data.DESCR}
                                            </span>
                                            <div>
                                                <button className='w-[30%] md:w-[30%] md:mx-auto lg:mx-0  text-center border-[1px] lg:border-2 lg:border-bannerTextColor border-black hover:bg-[#818f92] sm:p-3 lg:px-1 sm:py-3  font-bannerButtonText font-inter'>Shop Now</button>

                                            </div>
                                        </div>
                                    </div>
                                    <div className={`absolute bottom-0 hidden lg:flex w-full`}>
                                        <div className='w-[50%] border border-bannerTextColor py-[1%] backdrop-blur-md bg-black bg-opacity-25' onMouseEnter={() => setHoverBanner('Engagement')} onMouseLeave={() => setHoverBanner('Home')} >
                                            <p className='text-bannerButtonText font-inter text-center'>Shop Lab Grown Diamond</p>
                                            <img alt='brillient earth' src={Images.EngagementRing} className='mx-auto' />
                                        </div>
                                        <div className='w-[50%] border border-bannerTextColor py-[1%] backdrop-blur-md bg-black bg-opacity-25' onMouseEnter={() => setHoverBanner('Wedding')} onMouseLeave={() => setHoverBanner('Home')}>
                                            <p className='text-bannerButtonText font-inter text-center'>Shop Lab Grown Diamond</p>
                                            <img alt='brillient earth' src={Images.WeddingRing} className='mx-auto' />
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>}
                {data.home.data?.Category?.length > 0 && <div className="mx-auto mt-10  md:mt-16 w-[95%] 2xl:w-[90%] hidden">
                    <p className="text-28 sm:text-32 md:text-sectionHeading text-center  font-sectionHeading w-full  font-inter ">
                        Explore <span className='uppercase'> Kyrahstar</span>
                    </p>
                    <p className="text-28 sm:text-32 md:text-sectionSubHeading text-center italic font-sectionHeading w-full mb-5 md:mb-10 font-inter">
                        Indulge in what we offer.
                    </p>
                    <SliderView data={data.home.data?.Category} title={'CAT_NAME'} />
                </div>}

                {data.home.data?.Shape?.length > 0 && <div className='mx-auto md:my-16 my-10'>
                    <h3 className='text-32 md:text-sectionHeading text-center w-full font-inter'>
                        Shop Diamonds by Shape
                    </h3>
                    <GemAndShapeViewer data={data.home.data?.Shape} />
                    {/* <div className="overflow-x-auto lg:overflow-x-hidden scrollbar-hide">
                    <div className="flex flex-row justify-start md:justify-start lg:justify-center justify-start md:gap-9 gap-9  px-5 xl:mx-auto xl:w-[90%] w-full mt-6 whitespace-nowrap">
                        {Shape_menu?.map((item, index) => (
                            <div key={index} className="cursor-pointer hover:text-hover flex flex-col items-center inline-block flex-shrink-0">
                                <img src={item.image} className="xl:h-20xl:w-20 md:h-16 md:w-16  h-16 w-16" alt="Kyrah Star" />
                                <div className="md:mt-4 sm:mt-1 text-center">
                                    <span className="font-inter md:text-14 sm:text-12">
                                        {item.name}
                                    </span>
                                </div>
                            </div>
                        ))}
                    </div> */}
                </div>}
                {data.home.data?.Offer?.[0]?.IMG_URL && <div className='mx-auto my-16 w-[95%] 2xl:w-[90%] hidden'>
                    <img src={data.home.data?.Offer?.[0]?.IMG_URL} className='w-full' alt="Kyrah Star" />

                    {/* <div className='mt-2'>
                            <span className='cursor-pointer text-14 lg:text-16 font-inter text-[#2c2c2c] hover:text-hover underline'>
                                Terms Apply*
                            </span>
                        </div>

                        <div className={`w-[50%] sm:w-[20%] mt-3 border border-1 border-black px-6 justify-center text-black hover:bg-black hover:text-white flex flex-row py-3`}>
                            <span className='text-13 sm:text-14 font-inter text-center'>
                                Shop Now
                            </span>
                        </div> */}
                </div>
                }

                {
                    data.home.data?.Style?.length > 0 && <div className="mx-auto my-10 md:my-20 w-[95%] 2xl:w-[90%] hidden">
                        {/* <h1 className="text-30 sm:text-32 md:text-36 text-center md:text-start font-medium w-full mb-10 font-inter">
                            Popular Engagement Ring Styles
                        </h1> */}
                        <p className="text-28 sm:text-32 md:text-sectionHeading text-center  font-sectionHeading w-full  font-inter ">
                            Explore <span className='uppercase'> Kyrahstar</span>
                        </p>
                        <p className="text-28 sm:text-32 md:text-sectionSubHeading text-center italic font-sectionHeading w-full mb-5 md:mb-10 font-inter">
                            Indulge in what we offer.
                        </p>
                        <SliderView data={data.home.data?.Style} title={'RT_NAME'} />
                    </div>
                }

                <div className='hidden'>
                    <img src={Images.Advertisement}
                        alt='Kyrah Star' className='w-full' />
                </div>

                {/* <div className='mx-auto mt-12 xl:gap-12 md:gap-8 lg:gap-10 gap-5  flex sm:flex-row flex-col  sm:p-2 p-2 w-[95%] sm:w-[97%] lg:w-[90%] 2xl:w-[70%] '>
                    <div className='md:w-2/4 md:h-2/4 w-full '>
                        <img src="https://image.brilliantearth.com/cdn-cgi/image/width=720,height=545,quality=100,format=auto/https://cdn.builder.io/api/v1/image/assets%2F9f2a69003c86470ea05deb9ecb9887be%2F7388a4b5621543889f2ae11207bbe51c" className='w-full' alt="Kyrah Star" />
                    </div>

                    <div className='md:w-2/4 w-full flex flex-col pr-5 justify-center '>
                        <h1 className='text-20 font-inter text-[#2c2c2c] font-normal '>
                            SIGNATURE COLLECTION
                        </h1>

                        <h2 className='font-inter mt-2 font-semibold text-black text-28'>
                            The Camellia
                        </h2>
                        <span className='text-14 font-inter text-start my-3'>
                            Inspired by the beauty of their namesake flower, Camellia designs feature textural details, unique petal motifs, and delicate diamond leaves.
                        </span>

                        <div className='flex flex-row justify-between w-full'>
                            <div className={`w-[49%] lg:mt-3 mt-3 border border-1 border-black  justify-center text-black hover:bg-black hover:text-white flex flex-row py-3`}>
                                <span className='sm:text-12 md:text-14 text-14 font-inter text-center'>
                                    Shop Now
                                </span>
                            </div>
                            <div className={`w-[49%] lg:mt-3 mt-3 border border-1 border-black truncate  justify-center text-black hover:bg-black hover:text-white flex flex-row py-3`}>
                                <span className='sm:text-12 md:text-14 text-14 font-inter text-center truncate px-2'>
                                    Explore More Collections
                                </span>
                            </div>
                        </div>
                    </div>
                </div> */}

                {data.home.data?.Marketing?.length > 0 && <div className="mx-auto md:mt-20 mt-10  gap-10 2xl:gap-20 flex flex-wrap flex-col md:flex-row w-[95%] 2xl:w-[90%] justify-center ">
                    {data.home.data?.Marketing?.map((item, index) => {
                        return (
                            <div className="lg:w-[46.9%] 2xl:h-[90vh]  lg:h-[70%] w-[99%] " key={index}>
                                <div className='w-full h-[77%]'>
                                    <img src={item.IMG_URL} alt="brillient earth" className="object-cover w-full h-[100%] " />
                                </div>
                                <div className="flex flex-col mt-6 ">
                                    <span className="font-normal font-inter text-27 sm:text-32 md:text-[33.23px] text-black">
                                        {item?.HEADER}
                                    </span>
                                    <span className="font-inter mt-2 font-normal text-18 ">
                                        {item?.DESCR}
                                    </span>
                                    <div
                                        className={`w-[100%] sm:w-[25%] lg:w-[50%] xl:w-[30%] mt-5 border border-1 border-black px-6 justify-center text-black cursor-pointer hover:bg-black hover:text-white flex flex-row py-3`}
                                    >
                                        <span className="text-[18px] font-inter text-center">Learn More</span>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>}

                <div className='relative h-[40vh] lg:h-[60vh] hidden'>
                    <img src={Images.FooterStepPoster} alt='brillient earth' className='w-full mt-24 h-[40vh] lg:h-[60vh]' />
                    <div className='absolute top-5 sm:top-10 2xl:top-28 lg:w-[60%] 2xl:w-[50%] w-[100%] flex flex-col items-center backdrop-blur-md bg-white bg-opacity-25'>
                        <h1 className='font-inter lg:text-[50px] text-40 font-medium lg:mt-10'>Build Your Own Ring</h1>
                        <div className='flex gap-10 lg:my-5  w-full item-center justify-center'>
                            <img src={Images.EngagementRing} alt='brillient earth ' />
                            <div
                                className={`my-5 pt-4 border border-1 border-black lg:px-10 px-5 justify-center text-black cursor-pointer hover:bg-black hover:text-white `}
                            >
                                <span className="lg:text-18 text-16 font-inter text-center">Contact us today</span>
                            </div>
                        </div>
                        <div className='flex gap-5 lg:mb-10'>
                            <div>
                                <h3 className='lg:text-40 sm:text-30 text-26 font-semibold italic font-inter text-center'>Step 1</h3>
                                <p className='lg:text-30 sm:text-24 text-18 font-medium font-inter text-centernd'>Choose Ring</p>
                            </div>
                            <div>
                                <h3 className='lg:text-40 sm:text-30 text-26 font-semibold italic font-inter text-center'>Step 2</h3>
                                <p className='lg:text-30 sm:text-24 text-18 font-medium font-inter' text-center>Choose Diamond</p>
                            </div>
                            <div>
                                <h3 className='lg:text-40 sm:text-30 text-26 font-semibold italic font-inter text-center'>Step 3</h3>
                                <p className='lg:text-30 sm:text-24 text-18 font-medium font-inter text-center'>Complete Ring</p>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className='mx-auto mt-16 w-[95%] sm:w-[97%] lg:w-[90%] 2xl:w-[70%] overflow-hidden'>
                    <h1 className='xl:text-36 lg:text-36 md:text-36 sm:text-30 text-30 lg:mx-5 md:mx-3 sm:mx-2 mx-3text-36 md:text-center text-center lg:text-start w-full font-inter'>
                        Our Most Coveted Bridal Designs
                    </h1>

                    <div className='overflow-x-auto scrollbar-hide flex lg:gap-5 md:gap-3 sm:gap-2 gap-3 lg:px-5 md:px-3 sm:px-2  justify-between mx-auto w-full mt-6'>
                        {
                            Bridal_Category?.map((item, index) => {
                                return (
                                    <div key={index} className="cursor-pointer text-[#2c2c2c] hover:text-hover flex-shrink-0 sm:w-96 w-80 md:w-[18.5%]">
                                        <img src={item.image} className='' alt="Kyrah Star" />
                                        <div className="mt-4 text-start">
                                            <span className='font-inter-400 font-medium xl:text-17 lg:text-16'>
                                                {item.name}
                                            </span>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div> */}

                {/* MENU_CONTAINER */}
                {/* <div className='mx-auto mt-16 xl:gap-8 md:gap-5 gap-5  flex  sm:flex-row flex-col  justify-between w-[95%] 2xl:w-[70%] '>
                    {
                        menu?.map((item, index) => {
                            return (
                                <div key={index} className='w-full'>
                                    <img src={item.image}
                                        alt='namew'
                                        className='w-full'
                                    />
                                    <div className='flex flex-col mt-6'>
                                        <span className='font-medium font-inter text-26 text-black'>
                                            {item?.title}
                                        </span>
                                        <span className='font-inter mt-2 font-normal text-14'>
                                            {item?.content}
                                        </span>
                                        <div className={`w-[40%] mt-5 border border-1 border-black px-6 justify-center text-black hover:bg-black hover:text-white flex flex-row py-3`}>
                                            <span className='text-14 font-inter text-center'>
                                                Shop Now
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }

                </div> */}

                {/* APPOINTMENTS_CONTAINER */}
                {/* <div className='mx-auto mt-16 flex   flex-row justify-between w-[95%] 2xl:w-[70%] '>
                    {
                        Appointments?.map((item, index) => {
                            return (
                                <div key={index} className='w-full'>
                                    <img src={item.image}
                                        alt='namew'
                                        className='w-full'
                                    />
                                    <div className='flex flex-col mt-6'>
                                        <span className='font-medium font-inter text-26 text-black'>
                                            {item?.title}
                                        </span>
                                        <span className='font-inter mt-2 font-normal text-14'>
                                            {item?.content}
                                        </span>
                                        <div className='flex flex-col md:flex-row gap-5'>
                                            <div className={`xl:w-[20%]  sm:w-[80%] w-[100%] mt-5 border border-1 border-black px-4 justify-center  text-black hover:bg-black hover:text-white flex flex-row py-3`}>
                                                <span className='text-14 font-inter text-center truncate'>
                                                    Visit a Showroom
                                                </span>
                                            </div>

                                            <div className={`xl:w-[20%] sm:w-[80%]  w-[100%] md:mt-5 border border-1 border-black px-4 justify-center  text-black hover:bg-black hover:text-white flex flex-row py-3`}>
                                                <span className='text-14 font-inter text-center truncate'>
                                                    Book a Virtual Appointment
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }

                </div> */}
                {/* FOOTER */}
                {/* <Footer /> */}
            </div>

    )
}

export default HomePage