import React, { useState } from 'react'
import { CiSearch } from 'react-icons/ci'
import { FaChevronDown, FaChevronUp } from 'react-icons/fa'
import { RxCross1 } from 'react-icons/rx'
import { useLocation } from 'react-router-dom'
import { Diamond_Shape, Diamond_Style, PopularRings, Wedding_Ring_Matching } from '../../helpers/jsonData'

const Matching = () => {
    const { pathname } = useLocation()
    const [isMetalDropdown, setIsMetalDropdown] = useState(false);
    const [isShapeDropdown, setIsShapeDropdown] = useState(false);
    const [isStyleDropdown, setIsStyleDropdown] = useState(false);
    const [search, setSearch] = useState('')
    const [filterData, setFilterData] = useState({ metal: [], shape: [], style: [] })

    const toggleMetalDropdown = () => {
        setIsMetalDropdown(true);
    };
    const toggleShapeDropdown = () => {
        setIsShapeDropdown(true);
    };
    const toggleStyleDropdown = () => {
        setIsStyleDropdown(true);
    };
    const changeFilter = (e, type) => {
        const { value } = e.target
        console.log(value, type)
        setFilterData(filterData[type].includes(value) ? { ...filterData, [type]: filterData[type].filter((data) => data !== value) } : { ...filterData, [type]: [...filterData[type], value] });
    }
    const searchProduct = () => {
        console.log(search)
    }
    return (
        <div>
            <div className="mx-auto pt-3 container max-w-screen-lg text-start">
                <span className="text-12 text-lightBlack font-inter capitalize">
                    {` Home / Wedding Rings / ${pathname.split('/')[2]}`}
                </span>
            </div>
            <div className={`w-[100%] h-[20%] bg-[#F7F7F8]  bg-[url('https://css.brilliantearth.com/static/img/channel/wedding-rings/matching-wedding-rings/fmmwr-hero-cache3.jpg')] bg-[1024px,auto] md:bg-[1000px,auto] lg:bg-[1248px,auto]  bg-no-repeat bg-right md:bg-center`}>
                {Wedding_Ring_Matching.map((item, index) => {
                    return (<div key={index} className={`w-[80%] md:w-[45%] pb-[35%] mx-[20%] md:mx-[10%] md:pb-0 mt-10 text-center  `}>
                        <h1 className='text-48 font-inter capitalize px-10 md:px-5 leading-[50px]'>{item.title}</h1>
                        <p className='text-16 px-20 my-5 hidden md:block'>{item.subtitle}</p>
                    </div>)
                })}
            </div>
            <div className="flex mt-10 items-center w-[100%] md:w-[95%] lg:w-[90%] 2xl:w-[70%] container h-20 relative mx-auto py-6 border border-[#e2e2e2]">
                {/* Step 1 */}
                <div className='border-separate flex flex-row justify-center w-[50%] px-7 text-center items-center align-top text-16'>
                    <div className='flex flex-row items-center'>
                        <span className='text-25 font-inter font-normal'>
                            1.
                        </span>
                        <span className='text-14 ml-5 font-inter font-normal'>
                            Choose Your Engagement Ring
                        </span>
                    </div>
                    <div>
                        <img
                            src='https://css.brilliantearth.com/static/img/channel/wedding-rings/matching-wedding-rings/diamond-rings-1-y23.svg'
                            alt='Settings'
                            className='w-20 h-20 mx-auto'
                        />
                    </div>
                </div>
                <hr className='w-[1px] border-t h-20 bg-[#e2e2e2]' />

                {/* Step 2 */}
                <div className='border-separate flex flex-row justify-center w-[50%] px-7 text-center items-center align-top text-16'>
                    <div className='flex flex-row items-center'>
                        <span className='text-25 font-inter font-normal'>
                            2.
                        </span>
                        <div className='flex flex-col'>
                            <span className='text-14 ml-5 font-inter font-normal'>
                                Choose Your Matching Wedding Ring
                            </span>
                        </div>
                        <div>
                            <img
                                src='https://css.brilliantearth.com/static/img/channel/wedding-rings/matching-wedding-rings/diamond-rings-2-y23.svg'
                                alt='Settings'
                                className='w-20 h-20 mx-auto'
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className='flex flex-row justify-between cursor-pointer items-center font-inter border-[#e2e2e2] md:w-[95%] lg:w-[90%] 2xl:w-[70%] w-[95%] mx-auto mt-2 px-5 py-3'>
                <div className='flex gap-5'>
                    <div onMouseEnter={toggleMetalDropdown} onMouseLeave={() => setIsMetalDropdown(false)} className='flex relative'>
                        <span className='font-inter text-14 '>
                            Metal
                        </span>
                        {isMetalDropdown ? <FaChevronUp className='ml-1 mt-1' size={14} /> : <FaChevronDown className='ml-1 mt-1' size={14} />}
                        {isMetalDropdown && (
                            <div className='absolute left-0 w-[500%] bg-white border-t-0 border top-5 px-2 pt-5 border-[#e2e2e2] shadow-lg z-10'>
                                <span className='py-1 hover:bg-gray-100 cursor-pointer flex text-14'><input type='checkbox' className='mx-2' checked={filterData.metal.length === 0} /> Default View</span>
                                <span className='py-2 grid grid-cols-2 text-14'>
                                    {['Platinum', 'White Gold', 'Yellow Gold', 'Rose Gold'].map((metal, index) => {
                                        return (
                                            <div key={index} className='py-1 hover:bg-gray-100 cursor-pointer flex '><input type='checkbox' className='mx-2' onChange={(e) => changeFilter(e, 'metal')} value={metal} checked={filterData?.metal.includes(metal)} /> {metal}</div>
                                        )
                                    })}
                                </span>
                            </div>
                        )}
                    </div>
                    <div onMouseEnter={toggleShapeDropdown} onMouseLeave={() => setIsShapeDropdown(false)} className='flex relative'>
                        <span className='font-inter text-14 '>
                            Diamond Shape
                        </span>
                        {isShapeDropdown ? <FaChevronUp className='ml-1 mt-1' size={14} /> : <FaChevronDown className='ml-1 mt-1' size={14} />}
                        {isShapeDropdown && (
                            <div className='absolute left-0 w-[400%] bg-white border-t-0 border top-5 px-2 pt-5 border-[#e2e2e2] shadow-lg z-10'>
                                <span className='py-1 cursor-pointer flex text-14'><input type='checkbox' className='mx-2' checked={filterData.shape.length === 0} /> Default View</span>
                                <span className='py-2 grid grid-cols-5  text-14'>
                                    {Diamond_Shape.map((shape, index) => {
                                        return (
                                            <div key={index} className='py-1 cursor-pointer flex text-center'><input type='checkbox' className='mx-2' onChange={(e) => changeFilter(e, 'shape')} value={shape.name} checked={filterData?.shape.includes(shape.name)} /> <div>
                                                <img src={shape.image} alt='Diamond Shape' className='w-10 h-10' />
                                                <span>{shape.name}</span>
                                            </div></div>
                                        )
                                    })}
                                </span>
                            </div>
                        )}
                    </div>
                    <div onMouseEnter={toggleStyleDropdown} onMouseLeave={() => setIsStyleDropdown(false)} className='flex relative'>
                        <span className='font-inter text-14 '>
                            Style
                        </span>

                        {isStyleDropdown ? <FaChevronUp className='ml-1 mt-1' size={14} /> : <FaChevronDown className='ml-1 mt-1' size={14} />}
                        {isStyleDropdown && (
                            <div className='absolute left-0 w-[700%] bg-white border-t-0 border top-5 px-2 pt-5 border-[#e2e2e2] shadow-lg z-10'>
                                <span className='py-1 cursor-pointer flex text-14'><input type='checkbox' className='mx-2' checked={filterData.style.length === 0} /> Default View</span>
                                <span className='py-2 grid grid-cols-3  text-14'>
                                    {Diamond_Style.map((style, index) => {
                                        return (
                                            <div key={index} className='py-1 cursor-pointer flex text-center'><input type='checkbox' className='mx-2' onChange={(e) => changeFilter(e, 'style')} value={style.name} checked={filterData?.style.includes(style.name)} /> <div>
                                                <img src={style.image} alt='Diamond Shape' className='w-14 h-14 mx-4' />
                                                <span>{style.name}</span>
                                            </div></div>
                                        )
                                    })}
                                </span>
                            </div>
                        )}
                    </div>
                </div>
                <div className='flex border-b w-[35%] md:w-[30%] lg:w-[22%] xl:w-[17%] '>
                    <input type='text' placeholder={`Find My Engagement Ring`} className='text-14 w-[100%] focus:outline-none pb-1' value={search} onChange={(e) => { setSearch(e.target.value) }} /> <button onClick={searchProduct}><CiSearch className='text-18 ml-1 ' /></button>
                </div>
            </div>
            <hr className='mt-2' />
            <div className='flex py-3 justify-end bg-[#f3f3f3]' >
                <p className='mr-[45%]'>Choose Your Engagement Ring</p>
                <RxCross1 className='mt-1.5 cursor-pointer' />
            </div>
            <div className='mx-auto mt-10 container w-[95%] 2xl:w-[70%] '>
                <p className='text-center'>350 Items</p>
                <div className="flex flex-row flex-wrap  justify-between mx-auto w-full mt-6">
                    {PopularRings?.map((item, index) => {
                        return (
                            <div key={index} className={`relative w-[48%] lg:w-[30%] xl:w-[23%] outline-none text-[#2c2c2c] mb-10`} >
                                <img
                                    src={item.mappings.core.thumbnailImageUrl}
                                    className='cursor-pointer  px-2  mx-auto'
                                    alt="Kyrah Star" />
                                <div className="text-center mt-4 w-[100%]">
                                    <p className='font-inter font-[350] tracking-wider text-14 cursor-default'>
                                        {item.mappings.core.flag}
                                    </p>
                                    <p className='font-inter mb-1 font-medium tracking-wider cursor-pointer hover:text-hover text-14 '>
                                        {item.mappings.core.name}
                                    </p>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export default Matching
