import React, { useEffect, useState } from 'react';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import Slider from 'react-slick';
import SliderProduct from './SliderProduct';

const SliderView
    = ({ data, title, slides, ...props }) => {
        const [slidesToShow, setSlidesToShow] = useState();
        useEffect(() => {
            const handleResize = () => {
                if (window.innerWidth >= 1024) {
                    setSlidesToShow(4.5);
                } else if (window.innerWidth >= 768) {
                    setSlidesToShow(2.5);
                }
                else {
                    setSlidesToShow(1.5);
                }
            };
            window.addEventListener('resize', handleResize);
            handleResize();
            return () => window.removeEventListener('resize', handleResize);
        }, []);


        const CustomPrevArrow = (props) => {
            const { currentSlide, ...rest } = props;
            return (
                currentSlide !== 0 && <button button {...rest} className="slick-arrow rounded-full bg-white p-3   absolute  left-[3%] top-[50%] translate-y-[-50%] z-10 shadow-xl" >
                    <FaChevronLeft size={16} color="black" />
                </button >
            )
        }
        const CustomNextArrow = (props) => {
            const { currentSlide, slideCount, ...rest } = props
            return (
                currentSlide !== slideCount - slidesToShow && <button {...rest} className="slick-arrow rounded-full  p-3 bg-white  absolute  right-[3%] top-[50%] translate-y-[-50%] z-10 shadow-xl">
                    <FaChevronRight size={16} color="black" />
                </button>
            );
        }
        // console.log(Math.round(slidesToShow))
        const settings = {
            dots: false,
            infinite: false,
            speed: 500,
            slidesToShow: slidesToShow,
            slidesToScroll: 2,
            prevArrow: <CustomPrevArrow />,
            nextArrow: <CustomNextArrow />
        };
        return (
            Math.round(slidesToShow - 1) < data?.length ?
                <Slider {...settings} {...props} >
                    {data?.map((data, index) => {
                        return (
                            <SliderProduct image={data.IMG_URL} title={data[title]} key={index} />
                        )
                    })}
                </Slider> :
                <div className='flex justify-center lg:gap-10 2xl:gap-20 gap-5'>
                    {data?.map((data, index) => {
                        return (
                            <div key={index}>
                                <div className='hover:text-hover ' >
                                    <img src={data.IMG_URL} alt='Kyrah Star' className='  aspect-auto' />
                                    <p className=' font-inter text-22 md:text-18 mt-3'>{data[title]}</p>
                                </div>
                                {/* <p className='pr-1 mt-1'>{subtitle}</p> */}
                            </div>
                        )
                    })}
                </div>
        )
    }

export default SliderView

