import React, { useState } from 'react';
import { AiFillFacebook, AiFillLinkedin, AiFillTikTok, AiFillYoutube, AiOutlineInstagram } from "react-icons/ai";
import { BsTwitterX } from "react-icons/bs";
import { FaPinterestP } from "react-icons/fa";
import { HiOutlineChevronRight } from 'react-icons/hi';
import { IoCallOutline, IoLocationOutline, IoMailOutline, IoPersonSharp } from 'react-icons/io5';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const Footer = () => {
    const currency = useSelector(state => state.navigation)
    const [email, setEmail] = useState('');
    const navigate = useNavigate()

    const handleChange = (e) => {
        setEmail(e.target.value);
    };

    return (
        <div className='w-full bg-footerBackground text-center  lg:text-left overflow-hidden'>
            <div className=' mx-auto flex w-[95%]  lg:w-[90%] flex-col '>
                <div className='flex  flex-col lg:gap-20 lg:flex-row text-footerText justify-center items-center '>

                    <div className=''>
                        <h2 className='font-inter font-footerMenuHeading text-footerMenuHeading mt-5 lg:mt-10   hover:underline cursor-pointer'>
                            CONTACT US
                        </h2>

                        <ul className="mt-2 font-inter font-footerMenu text-footerMenu">
                            <li className=" hover:underline  pt-2  cursor-pointer flex gap-2 items-center"><span className='text-20'><IoCallOutline /></span>+91 70167 46250</li>
                            <li className=" hover:underline  pt-2  cursor-pointer flex gap-2 items-center"><span className='text-20'><IoMailOutline /></span>sales@kyrahstar.com</li>
                            <li className=" hover:underline  pt-2 flex gap-2 items-center"><span className='text-20'><IoPersonSharp /></span>Soham Sanjay Patel</li>
                            {/* <li className=" hover:underline  pt-2  cursor-pointer flex gap-2 items-center"><span className='text-20'><IoLocationOutline /></span>Rise On Plaza,Surat</li> */}
                            {/* <li className=" hover:underline  pt-1.5  cursor-pointer">Email Us</li>
                            <li className=" hover:underline  pt-1.5  cursor-pointer">1.800.691.0952</li>
                            <li className=" hover:underline  pt-1.5  cursor-pointer">Affiliates</li> */}
                        </ul>
                    </div>
                    <div className=''>
                        <h2 className='font-inter font-footerMenuHeading w-full text-footerMenuHeading mt-10   hover:underline cursor-pointer uppercase' onClick={() => navigate('/register')}>
                            Sign Up for Email
                        </h2>
                        <div className='mt-2 text-footerMenu'>
                            <span className='font-inter  pt-1.5'>
                                Send me Kyrah Star news, updates and offers.
                            </span>
                        </div>

                        <div className="flex mt-2 items-center relative" >
                            <input
                                type="email"
                                value={email}
                                onChange={handleChange}
                                placeholder="Your Email Address"
                                className="flex-grow px-4 border py-[11.8px] border-gray-300 overflow-hidden font-inter text-13 text-black focus:outline-none"
                            />
                            {/* <button
                                type="submit"
                                className="bg-white text-white px-3 py-[10px]"
                            > */}
                            <HiOutlineChevronRight color='black' size={25} className='absolute right-0 cursor-pointer' />
                            {/* </button> */}
                        </div>
                        <div className='mt-3 flex flex-row w-[60%] mx-auto gap-4 items-center justify-around px-1 hidden'>
                            {/* <AiFillTikTok className=' cursor-pointer' size={25} /> */}
                            <AiFillYoutube className=' cursor-pointer' size={25} />
                            <AiOutlineInstagram className=' cursor-pointer' size={25} />
                            <AiFillFacebook className=' cursor-pointer' size={25} />
                            {/* <FaPinterestP className=' cursor-pointer' size={25} /> */}
                            <BsTwitterX className=' cursor-pointer' size={20} />
                            {/* <AiFillLinkedin className=' cursor-pointer' size={25} /> */}
                        </div>
                    </div>
                </div>


                <div className='flex  flex-col md:flex-row'>
                    {/* <div className=''>
                        <h2 className='font-inter text-footerMenuHeading mt-5 md:mt-10 font-light  hover:underline cursor-pointer'>
                            EDUCATION
                        </h2>

                        <ul className="mt-4">
                            <li className=" hover:underline text-13 pt-1.5 font-inter cursor-pointer">Blog</li>
                            <li className=" hover:underline text-13 pt-1.5 font-inter cursor-pointer">Diamond Buying Guide</li>
                            <li className=" hover:underline text-13 pt-1.5 font-inter cursor-pointer">Lab Grown Diamonds</li>
                            <li className=" hover:underline text-13 pt-1.5 font-inter cursor-pointer">Moissanite vs. Diamonds</li>
                            <li className=" hover:underline text-13 pt-1.5 font-inter cursor-pointer">Ring Size Chart</li>
                            <li className=" hover:underline text-13 pt-1.5 font-inter cursor-pointer">Careers</li>
                            <li className=" hover:underline text-13 pt-1.5 font-inter cursor-pointer">Investor Relations</li>
                        </ul>
                    </div>

                    <div className='w-[100%] md:w-[33%]'>
                        <h2 className='font-inter text-16 mt-5 md:mt-10 font-light  hover:underline cursor-pointer'>
                            CUSTOMER SERVICE
                        </h2>

                        <ul className="mt-4">
                            <li className=" hover:underline text-13 pt-1.5 font-inter cursor-pointer">FAQs</li>
                            <li className=" hover:underline text-13 pt-1.5 font-inter cursor-pointer">Jewelry Financing</li>
                            <li className=" hover:underline text-13 pt-1.5 font-inter cursor-pointer">Lifetime Diamond Upgrade</li>
                            <li className=" hover:underline text-13 pt-1.5 font-inter cursor-pointer">Promo Codes & Offers</li>
                            <li className=" hover:underline text-13 pt-1.5 font-inter cursor-pointer">Refer a Friend</li>
                            <li className=" hover:underline text-13 pt-1.5 font-inter cursor-pointer">Accessibility</li>
                            <li className=" hover:underline text-13 pt-1.5 font-inter cursor-pointer">Accessibility Info</li>
                        </ul>
                    </div> */}

                    {/* <div className='w-[100%] md:w-[33%]'>
                        <h2 className='font-inter w-full text-16 mt-16 font-light  hover:underline cursor-pointer uppercase'>
                            Sign Up for Email
                        </h2>
                        <div className='mt-4'>
                            <span className='font-inter text-13 pt-1.5'>
                                Send me Kyrah Star news, updates and offers.
                            </span>
                        </div>

                        <div className="flex mt-2 items-center" >
                            <input
                                type="email"
                                value={email}
                                onChange={handleChange}
                                placeholder="Your Email Address"
                                className="flex-grow px-4 border py-[11.8px] border-gray-300 overflow-hidden font-inter text-13 text-black focus:outline-none"
                            />
                            <button
                                type="submit"
                                className="bg-cellBackground text-white px-3 py-[9.8px]"
                            >
                                <HiOutlineChevronRight color='white' size={25} />
                            </button>
                        </div>
                        <div className='mt-5 flex flex-row gap-4 items-center justify-center'>
                            <AiFillTikTok className=' cursor-pointer' size={25} />
                            <AiFillYoutube className=' cursor-pointer' size={25} />
                            <AiOutlineInstagram className=' cursor-pointer' size={25} />
                            <AiFillFacebook className=' cursor-pointer' size={25} />
                            <FaPinterestP className=' cursor-pointer' size={25} />
                            <BsTwitterX className=' cursor-pointer' size={25} />
                            <AiFillLinkedin className=' cursor-pointer' size={25} />
                        </div>
                    </div> */}
                </div>
                <div className="text-footerText text-sm py-4">
                    <div className="container mx-auto flex flex-col ">
                        <div className='flex text-footerMenu font-footerMenu flex-row justify-center flex-wrap gap-3 w-full items-center uppercase'>
                            <div className=' text-footerText lg:pt-1.5 font-inter'>
                                &copy;Kyrahstar
                            </div>
                            <span className=''>|</span>
                            <a href="/about/terms-&-condition" className=" hover:underline   lg:pt-1.5 font-inter cursor-pointer">Terms & Conditions</a>
                            <span className=''>|</span>
                            <a href="/about/privacy-policy" className=" hover:underline   lg:pt-1.5 font-inter cursor-pointer">Privacy Policy</a>
                            {/* <span className=''>|</span>
                            <a href="/" className=" hover:underline   pt-1.5 font-inter cursor-pointer">Interest-Based Ads</a>
                            <span className=''>|</span>
                            <a href="/" className=" hover:underline   pt-1.5 font-inter cursor-pointer">Do Not Share My Personal Information (California Residents)</a>
                            <span className=''>|</span>
                            <a href="/" className=" hover:underline   pt-1.5 font-inter cursor-pointer">CA Transparency Act</a>
                            <span className=''>|</span>
                            <a href="/" className=" hover:underline   pt-1.5 font-inter cursor-pointer">CPRA</a>
                            <span className=''>|</span>
                            <a href="/" className=" hover:underline   pt-1.5 font-inter cursor-pointer">Site Map</a> */}
                        </div>
                        {/* <div className="flex flex-row justify-center items-center gap-3 flex-wrap mt-1.5 text-footerMenu">
                            {currency?.navigationMenu?.data?.CURRENCY?.map((data, index) => {
                                return (<div key={index}><p className='hover:underline   pt-1.5 font-inter cursor-pointer'>{data.Items} </p> {index + 1 < currency?.navigationMenu?.data?.CURRENCY?.length && <span className='text-12' >|</span>}</div>)
                            })}
                        </div> */}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer;