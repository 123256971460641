import React, { useState } from 'react'
import { RotatingLines } from 'react-loader-spinner'
import { useNavigate } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'
import { axiosInstance } from '../../redux/api'
import { showErrorToast, showSuccessToast } from '../../components/ToastNotification'

const ResetPassword = () => {
    const navigate = useNavigate()
    const [email, setEmail] = useState('')
    const [emailError, setEmailError] = useState('')
    const [isLoading, setIsLoading] = useState(false)

    const submitHandler = async (e) => {
        e.preventDefault()
        if (email.trim() === '') {
            setEmailError('Email is Required')
        }
        if (email) {
            const payload = {
                "PHONE": '',
                "EMAIL": email.trim(),
            };

            try {
                setIsLoading(true);
                // const response = await fetch(`${process.env.REACT_APP_PUBLIC_BASE_URL}auth/forgot-password`, {
                //     method: 'POST',
                //     headers: {
                //         'Content-Type': 'application/json',
                //     },
                //     body: JSON.stringify(payload),
                // });
                const response = await axiosInstance.post('/auth/forgot-password', payload)
                // const data = await response.json();
                if (response.data.success === true) {
                    console.log(response.data.message, 're')
                    showSuccessToast(response.data.message)
                    setIsLoading(false);
                    setTimeout(() => {
                        navigate(`/login`)
                    }, 1000)
                } else {
                    showErrorToast(response.data.message)
                    setIsLoading(false);
                }
                // Handle successful response.data, e.g., show a success message or navigate
                // console.log(response.data);
            } catch (error) {
                console.log(error.response.data.message, 'error')
                showErrorToast(error.response.data.message)
                setIsLoading(false);
                // Handle errors, e.g., show an error message
                // console.error('There was a problem with the fetch operation:', error);
            }
        }
    }
    return (
        <div className=' text-start lg:w-[55%] mx-auto px-5'>
            <ToastContainer />
            <div className=' md:pt-3 pt-2  text-start '>
                <span className='text-12 text-lightBlack font-inter '>
                    <span onClick={() => navigate("/")} className="cursor-pointer hover:underline">Home</span> /  <span onClick={() => navigate("/login")} className="cursor-pointer hover:underline">Account</span> <span>/ Password Reset</span>
                </span>
            </div>
            <div className='my-5'>
                <h1 className='text-36 font-semibold font-inter'>Forgot Password</h1>
                {/* <p className='text-20 mb-3'>Account Assistance</p> */}
                {/* <p>Enter the email address you used to create your Kyrah Star account.</p> */}
                <ul className='list-disc mx-5'>
                    <li>Your New Password will be send on your email</li>
                    {/* <li>If you do not receive an email, please contact us for assistance.</li> */}
                </ul>
                <form className='mt-5' onSubmit={submitHandler}>
                    <label>
                        Email :
                    </label>
                    <input type='text' placeholder='Email Address' className='border-b outline-none  w-[40%] ml-2 py-1.5'
                        value={email}
                        onChange={(e) => {
                            let inputValue = e.target.value;
                            const regex = /\S+@\S+\.\S+/;
                            setEmail(inputValue); // Only update the value if valid
                            if (inputValue === '') {
                                setEmailError('Email is required.');
                            } else if (!regex.test(inputValue)) {
                                setEmailError('Invalid email format');
                            } else {
                                setEmailError('');// Only update the value if valid
                            }
                        }} /><br />
                    {emailError && <p className='text-red-500 text-left text-14'>{emailError}</p>}
                    {/* <button className='bg-footerBackground cursor-pointer text-white w-[10%] mt-3 py-1.5 rounded-xl'> Submit</button> */}
                    <div className=''>
                        {
                            isLoading ?
                                <button
                                    className='flex flex-row justify-center bg-footerBackground cursor-pointer text-white w-[10%] mt-3 py-1.5 rounded-full'
                                >
                                    <RotatingLines
                                        visible={true}
                                        height="20"
                                        width="20"
                                        strokeWidth="5"
                                        strokeColor='white'
                                        animationDuration="0.75"
                                        ariaLabel="rotating-lines-loading"
                                        wrapperStyle={{}}
                                        wrapperClass=""
                                    />
                                </button>
                                :
                                <button
                                    className='bg-footerBackground cursor-pointer text-white w-[15%] mt-3 py-1.5 rounded-full'
                                >
                                    Submit
                                </button>
                        }
                    </div>
                </form>
            </div>
        </div >
    )
}

export default ResetPassword
