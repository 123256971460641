
import CryptoJS from 'crypto-js';


const secretKey = CryptoJS.enc.Hex.parse(`${process.env.REACT_APP_DECRYPT_KEY}`);
// console.log(process.env.REACT_APP_DECRYPT_KEY, 'secretkey')
export function decryptData(encryptedData) {

    const iv = CryptoJS.enc.Hex.parse(encryptedData.iv); // Parse IV as hex
    const encryptedBytes = CryptoJS.enc.Hex.parse(encryptedData.data); // Parse the encrypted data

    // console.log(iv, 'iv');
    // console.log(encryptedBytes, 'encryptedBytes');

    const bytes = CryptoJS.AES.decrypt(
        { ciphertext: encryptedBytes },
        secretKey,
        {
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        }
    );
    const decrypted = bytes.toString(CryptoJS.enc.Utf8);
    // console.log('Decrypted string:', decrypted); // Log decrypted string for debugging
    try {
        return JSON.parse(decrypted); // Parse JSON if decryption succeeds
    } catch (error) {
        console.error("Error during decryption or parsing:", error);
        throw error; // Re-throw for logging in getSize function
    }

}