import React from 'react'

const PrivacyPolicy = () => {
    return (
        <div className='px-10 py-5 w-[80%] mx-auto'>
            <h1 className='text-center text-[28px] font-semibold'>Privacy Policy</h1>
            <div className='mt-5 flex flex-col gap-2 text-[18px]'>
                <p>This privacy policy provides you with the information on how KyrahStar (hereafter referred to as "the Store", “we”) collects, uses and protects your information through your use of the website at https://www.kyrahstar.com/</p>
                <p>Here at KyrahStar.com, we take your privacy seriously and are committed to protecting it. Using this site, you can be assured that any personally identifiable information we may collect and record through this website will be used only per this privacy statement.</p>
                <p>This policy may be updated from time to time by the platform. To keep up to date with any changes, check our website and the privacy policy periodically.</p>
            </div>
            <div>
                <h3 className='text-[24px] font-semibold mt-3'>Type of information we collect</h3>
                <div className='mt-2'>
                    <h3 className='text-[20px] font-semibold '>Personal Information:</h3>
                    <p>When you sell or buy diamonds from our platform or contact us electronically, we may collect certain information from you, such as first name, last name, email address, contact number, username, password, and demographic information such as address, state, province, city, postcode (collectively referred as “Personal Information”)</p>
                </div>
                <div className='mt-2'>
                    <h3 className='text-[20px] font-semibold '>Payment Information:</h3>
                    <p>It includes information about payments made or attempted to make through our website via credit card to use our services. These include, but are not limited to name, email address, billing or shipping address, contact number, card number, CVV/CVC number, transaction details, etc.</p>
                </div>
                <div className='mt-2'>
                    <h3 className='text-[20px] font-semibold '>Other Information:</h3>
                    <p>We may also collect other information that may include but are not limited to:</p>
                    <p>From you - Information you voluntarily share with us about your diamond and its characteristics (weight, colour, shape, clarity, certificate details, etc.), personal interests, gender, product and service preferences, and any other information not related to your identity.</p>
                    <p>From your activity - We automatically collect certain information when you use the platform, including but not limited to IP address, pages viewed, date and time, language and browser type, operating system, referring pages, URLs, duration of visits to specific pages, information related to customer survey and/or offers, etc. (collectively, “statistical information”).</p>
                </div>
            </div>
            <div>
                <h3 className='text-[24px] font-semibold mt-3'>How we use and share information</h3>
                <div className='mt-2'>
                    <h3 className='text-[20px] font-semibold '>Personal Information:</h3>
                    <p>To provide better service to you, we may use personal data, specifically for the following purposes.</p>
                    <p>To update and enhance our internal records.</p>
                    <p>To provide, maintain and improve our products and services.</p>
                    <p>To manage your account and requests.</p>
                </div>
                <div className='mt-2'>
                    <h3 className='text-[20px] font-semibold '>Payment Information:</h3>
                    <p>Unless required by law, payment details are never disclosed to anyone. The website stores no credit card information when an online order is placed, as all credit card information is directly submitted to our payment gateways.</p>
                </div>
                <div className='mt-2'>
                    <h3 className='text-[20px] font-semibold '>Other Information:</h3>
                    <p>Additional information is collected for selling or marketing our services, as well as managing and identifying problems to provide you with a better service. Your information may also be used for other purposes such as identifying trends, measuring the effectiveness of our promotional campaigns, data analysis, and evaluating and improving our products, services, marketing and customer experience.
                        Statistical information is collected and managed by third parties like click-tracking software, analytics, etc., so the data will be accessible to them as well. We only share statistical information on an aggregate basis, without disclosing any personal details.</p>
                </div>
            </div>
            <div>
                <div className='mt-2'>
                    <h3 className='text-[20px] font-semibold '>Your Rights:</h3>
                    <p>You have the right to request details or access to your personal information, subject to the payment of a small fee, that we hold about you under the Data Protection Act 1998. To exercise this right, you must submit a written request through email using our ‘contact us’ information and provide proof of your identity and address, and describe which personal data you wish to access, including any reference or account number where applicable.</p>
                    <p>If you believe that any information that we hold about you is incorrect or incomplete, please write to us or email us as soon as possible, at the below address. We will promptly correct any information found to be incorrect.</p>
                </div>
                <div className='mt-2'>
                    <h3 className='text-[20px] font-semibold '>Deletion Request Rights of Your Information:</h3>
                    <p>You can ask us to delete your personal information by sending a request email using our mailing address below, email address listed in our contact us details, or at contact@KyrahStar.com</p>
                    <p>Your request to remove your information will be processed typically within 7-10 business days after receiving your request.</p>
                    <p>We never sell, transfer, exchange, distribute or lease your personal information to any third- parties without your consent or unless we are required to do so by law. When you indicate you are interested in receiving promotional information from third parties, we may send you information we think you will find interesting.</p>
                </div>
                <div className='mt-2'>
                    <h3 className='text-[20px] font-semibold '>Data Security:</h3>
                    <p>We are committed to protecting your information. To prevent unauthorized access, misuse, loss or disclosure of the information we collect online, we have put in place appropriate physical, electronic and managerial procedures. Unfortunately, the transmission of information over the internet is not completely secure. While we do our best to ensure the security of your personal data, we cannot guarantee it.</p>
                </div>
                <div className='mt-2'>
                    <h3 className='text-[20px] font-semibold '>Controlling your personal information:</h3>
                    <p>The website allows you to restrict the collection or use of your personal information, or stop receiving marketing communications at any time in the following ways:</p>
                </div>
            </div>
            <div>
                <p>When you fill out a form on the website, you can check or uncheck relevant boxes to make changes to your marketing preferences or by clicking the opt-out link in any marketing communication you receive.</p>
                <p>The information you provided to us for direct marketing purposes may be changed by contacting us using our contact information.</p>
                <h4 className='text-[20px] font-semibold mt-2'>External Links</h4>
                <p>Our website may contain links to other third-party websites of interest. We have no control over the content and privacy practices of any of our advertisers, business partners, sponsors, or any other websites we may link to. Therefore, we cannot be responsible for the protection and privacy of any information that you provide whilst visiting any third-party websites as such sites are not governed by this privacy statement. When you leave our site or visit any other website, we encourage you to check the terms of use and privacy policies of that site.</p>
            </div>
        </div>
    )
}

export default PrivacyPolicy
