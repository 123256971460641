
import React, { useState } from 'react'
import { FaCaretLeft, FaCaretRight, FaChevronDown, FaChevronLeft, FaChevronRight, FaChevronUp } from 'react-icons/fa'
import { GoHeart } from 'react-icons/go'
import { useParams } from 'react-router-dom'
import Slider from 'react-slick'
import RangeSelector from '../../components/RangeSelector'
import { Colored_Gemstone, Filter_Gemstone_Color, FIlter_Gemstone_Shape, Gemstones_Heading, PopularRings, Shape_menu, Type_Of_Gemstone_Filters } from '../../helpers/jsonData'

const DifferentGemstones = () => {
    const param = useParams()
    const [filterIndex, setFilterIndex] = useState(1);
    const [isDropdown, setIsDropdown] = useState(false);
    const [isProductCountDropdown, setIsProductCountDropdown] = useState(false);

    const CustomPrevArrow = (props) => (
        <button
            {...props}
            className="slick-arrow absolute  left-[-20px] top-[40%] translate-y-[-50%] z-5"
        >
            <FaChevronLeft className='size-3 lg:size-5' color="black" />
        </button>
    );
    const CustomNextArrow = (props) => (
        <button
            {...props}
            className="slick-arrow  absolute  right-[-18px] top-[40%] translate-y-[-50%] z-5"
        >
            <FaChevronRight className='size-3 lg:size-5' color="black" />
        </button>
    );
    const CustomPrevArrow1 = (props) => {
        const { currentSlide, ...rest } = props;
        return (
            currentSlide !== 0 && <button button {...rest} className="slick-arrow     absolute  left-[-10px] top-[45%] translate-y-[-50%] z-10 shadow-xl" >
                <FaChevronLeft size={16} color="black" />
            </button >
        )
    }
    const CustomNextArrow1 = (props) => {
        const { currentSlide, slideCount, slidesToShow, ...rest } = props
        console.log(props)
        return (
            currentSlide !== slideCount - slidesToShow && <button {...rest} className="slick-arrow    absolute  right-[-10px] top-[45%] translate-y-[-50%] z-10 shadow-xl">
                <FaChevronRight size={16} color="black" />
            </button>
        );
    }
    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 10,
        slidesToScroll: 5,
        prevArrow: <CustomPrevArrow />,
        nextArrow: <CustomNextArrow slidesToShow={12} />,
    };
    const settings2 = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 6,
        slidesToScroll: 5,
        prevArrow: <CustomPrevArrow1 />,
        nextArrow: <CustomNextArrow1 slidesToShow={6} />,
    };

    const toggleDropdown = () => {
        setIsDropdown(true);
    };
    const toggleProductsCountDropdown = () => {
        setIsProductCountDropdown(true);
    };

    return (
        <div>
            <div className='mx-auto pt-3 container max-w-screen-lg text-start'>
                <span className='text-12 text-lightBlack font-inter capitalize'>
                    {`Home / Gemstones/ ${param?.type}`}
                </span>
            </div>

            <div className='mx-auto container mt-10'>
                <h1 className='text-36 text-center w-full font-inter capitalize'>
                    {Gemstones_Heading[param.type]?.title}
                </h1>

                <div className='mt-2 tect-16 text-center font-normal w-[40%] mx-auto font-inter'>
                    {Gemstones_Heading[param.type]?.subtitle}
                </div>
            </div>

            <div className="flex mt-10 items-center w-[100%] md:w-[95%] lg:w-[90%] 2xl:w-[70%] container h-20 relative mx-auto py-6 border border-[#e2e2e2]">
                {/* Steps */}
                <div className='border-separate w-[18%] text-center table-cell align-top text-16 hidden xl:block'>
                    <span className='text-15 font-inter font-normal '>
                        Design Your Ring
                    </span>
                </div>
                <hr className='w-[1px] border-t h-20 bg-[#e2e2e2]' />

                {/* Step 1 */}
                <div className='border-separate flex flex-row justify-between w-[33%] px-1 lg:px-3 xl:px-5 text-center items-center align-top text-16'>
                    <div className='flex flex-row items-center'>
                        <span className='text-25 font-inter font-normal'>
                            1.
                        </span>
                        <span className='text-14 ml-5 font-inter font-normal'>
                            Choose Gemstone
                        </span>
                    </div>
                    <div className='hidden md:block'>
                        <img
                            src='https://css.brilliantearth.com/static/img/svg/diamond.svg'
                            alt='Settings'
                            className='w-20 h-20 mx-auto'
                        />
                    </div>
                </div>
                <hr className='w-[1px] border-t h-20 bg-[#e2e2e2]' />

                {/* Step 2 */}
                <div className='border-separate flex flex-row justify-between w-[33%] px-1 lg:px-3 xl:px-5 text-left items-center align-top text-16'>
                    <div className='flex flex-row items-center'>
                        <span className='text-25 font-inter font-normal'>
                            2.
                        </span>
                        <div className='flex flex-col'>
                            <span className='text-14 ml-5 font-inter font-normal'>
                                Choose setting
                            </span>
                            <span className='text-12 underline ml-1.5 font-inter font-normal hidden md:block'>
                                Browse Settings
                            </span>
                        </div>
                    </div>
                    <div className='hidden md:block'>
                        <img
                            src='https://css.brilliantearth.com/static/img/svg/setting.svg'
                            alt='Settings'
                            className='w-20 h-20 mx-auto'
                        />

                    </div>
                </div>
                <hr className='w-[1px] border-t h-20 bg-[#e2e2e2]' />

                {/* Step 3 */}
                <div className='border-separate flex flex-row justify-between w-[33%] px-1 lg:px-3 xl:px-5 text-center items-center align-top text-16'>
                    <div className='flex flex-row items-center'>
                        <span className='text-25 font-inter font-normal'>
                            3.
                        </span>
                        <div className='flex flex-col'>
                            <span className='text-14 ml-5 font-inter font-normal'>
                                Complete Ring
                            </span>
                            <span className='text-12 ml-2 font-inter font-normal hidden md:block'>
                                Select Ring Size
                            </span>
                        </div>
                    </div>
                    <div className='hidden md:block'>
                        <img
                            src='https://css.brilliantearth.com/static/img/svg/ring.svg'
                            alt='Settings'
                            className='w-20 h-20 mx-auto'
                        />
                    </div>
                </div>
            </div>

            <div>
                <div className="flex justify-center 2xl:w-[60%] xl:w-[70%] lg:w-[80%] md:w-[85%] sm:w-[90%] w-[95%] mx-auto mt-10">
                    {Type_Of_Gemstone_Filters.map((option, index) => {
                        return (
                            <label
                                key={index}
                                className="flex items-center gap-2 mx-auto"
                                onClick={() => setFilterIndex(index + 1)}
                            >
                                {option.map((data, index) => {
                                    return (
                                        <span key={index} className="cursor-pointer hover:text-[#183E40] ">
                                            {data}
                                        </span>
                                    );
                                })}
                            </label>
                        );
                    })}
                    <span className="ml-24">
                        <span className="flex cursor-pointer">
                            <button onClick={() => setFilterIndex((prev) => prev - 1)} disabled={filterIndex === 1}>
                                <FaCaretLeft size={22} className={`${filterIndex === 1 ? 'text-[#e8e8e8]' : ''}`} />
                            </button>
                            <button onClick={() => setFilterIndex((prev) => prev + 1)} disabled={filterIndex === Type_Of_Gemstone_Filters.length}>
                                <FaCaretRight size={22} className={`${filterIndex === Type_Of_Gemstone_Filters.length ? 'text-[#e8e8e8]' : ''}`} />
                            </button>
                        </span>
                    </span>
                </div>
                <hr className="mt-3 2xl:w-[60%] xl:w-[70%] lg:w-[80%] md:w-[85%] sm:w-[90%] w-[95%] mx-auto " />
            </div>

            {filterIndex === 1 ?
                <div className="2xl:w-[60%] xl:w-[70%] lg:w-[80%] md:w-[85%] sm:w-[90%] w-[95%]  mx-auto flex lg:gap-10 gap-5 text-13">
                    <div className="flex flex-row justify-between mx-auto w-full mt-6">
                        <Slider {...settings} className='w-[90%] mx-auto flex flex-row items-center mx-2'>
                            {FIlter_Gemstone_Shape?.map((item, index) => {
                                return (
                                    <div key={index} className={`cursor-pointer px-1.5 outline-none text-[#2c2c2c] hover:text-hover `}>
                                        <img src={item.image} className='mx-auto w-12' alt="Kyrah Star" />
                                        <div className="mt-1 text-center ">
                                            <span className='font-inter font-normal text-10 truncate'>
                                                {item.name}
                                            </span>
                                        </div>
                                    </div>
                                )
                            })}
                        </Slider>
                    </div>
                </div>
                : <div className='mx-auto mt-5 2xl:w-[60%] xl:w-[70%] lg:w-[80%] md:w-[85%] sm:w-[90%] w-[95%]'>
                    <p className='text-14 mt-5'>Gemstone Shape</p>
                    <div className='flex justify-between gap-1' >
                        {Shape_menu?.map((data, index) => {
                            return (
                                <div className='w-full cursor-pointer' key={index}>
                                    <div key={index} className='w-full hover:text-hover  '>
                                        <img src={data.image} alt='Kyrah Star' className='w-10 mx-auto' />
                                        <p className=' text-11 mt-1 text-center'>{data.name}</p>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    <div className='flex justify-between gap-5'>
                        <div className='w-[50%]'>
                            <p className='text-14 mt-5'>Gemstone Color</p>
                            <div className=' w-[95%]' >
                                <Slider {...settings2} className='w-full'>
                                    {Filter_Gemstone_Color?.map((item, index) => {
                                        return (
                                            <div key={index} className={`cursor-pointer mt-4 outline-none text-[#2c2c2c] hover:text-hover `}>
                                                <img src={item.image} className='mx-auto w-6' alt="Kyrah Star" />
                                                <div className="mt-1 text-center">
                                                    <span className='font-inter font-normal text-11'>
                                                        {item.name}
                                                    </span>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </Slider>
                            </div>

                            <p className="text-14 mt-5">Origin</p>
                            <div className="flex flex-wrap">
                                {['Show All', "Natural", "Lab"]?.map((data, index) => {
                                    return (
                                        <div key={index} className={`px-10 text-12 md:text-14 lg:text-16 py-1 border-2  hover:border-[#2c2c2c] border-solid  mr-2 mt-1 `} >
                                            <div key={index} className="hover:text-hover  rounded-full">
                                                <p className=" text-center">{data}</p>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                        <div className="w-[50%]">
                            <div className="mt-8 text-[#2c2c2c]" >
                                <p className="text-14 mt-5">Gemstone Price</p>
                                <div className=' lg:w-[100%] w-[95%]'>
                                    <RangeSelector min={210} max={6000} step={1} />
                                </div>
                                {/* <RangeSelector min={210} max={6000} step={1} /> */}
                            </div>
                            <p className="text-14 mt-7">Dimentions</p>
                            <div className="flex flex-wrap">
                                {['Under 6mm', "6-6.9mm", "7-7.9mm", '8-9.9mm', '10mm+']?.map((data, index) => {
                                    return (
                                        <div key={index} className={`px-1.5 text-12 md:text-14 lg:text-16 py-1 border-2  hover:border-[#2c2c2c] border-solid  mr-2 mt-1 `} >
                                            <div key={index} className="hover:text-hover  rounded-full">
                                                <p className=" text-center">{data}</p>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>}
            <div className=' relative flex flex-row justify-between cursor-pointer items-center border-[#e2e2e2] 2xl:w-[60%] xl:w-[70%] lg:w-[80%] md:w-[85%] sm:w-[90%] w-[95%] mx-auto mt-5  py-3'>
                <p className='ml-[30%] lg:ml-[38%] text-[#2c2c2c]'>60 of 21,257 Colored Gemstones</p>
                <div onMouseEnter={toggleDropdown} onMouseLeave={() => setIsDropdown(false)} className='flex'>
                    <span className='font-inter text-14'>
                        Sort:  Best Sellers
                    </span>

                    {isDropdown ? <FaChevronUp className='ml-1 mt-1 size-3 md:size-4 ' /> : <FaChevronDown className='ml-1 mt-1 size-3 md:size-4 ' />}
                    {isDropdown && (
                        <div className='absolute right-0  w-[15%] bg-white border-t-0 border top-8 border-[#e2e2e2] shadow-lg z-10'>
                            <ul className='py-2'>
                                <li className='px-4 py-1 hover:bg-gray-100 cursor-pointer '>Best Sellers</li>
                                <li className='px-4 py-1 hover:bg-gray-100 cursor-pointer'>Newest</li>
                                <li className='px-4 py-1 hover:bg-gray-100 cursor-pointer'>Price: Low to High</li>
                                <li className='px-4 py-1 hover:bg-gray-100 cursor-pointer'>Price: High to Low</li>
                            </ul>
                        </div>
                    )}
                </div>
            </div>
            <div className=' relative flex flex-row justify-end cursor-pointer items-center border-[#e2e2e2] 2xl:w-[60%] xl:w-[70%] lg:w-[80%] md:w-[85%] sm:w-[90%] w-[95%] mx-auto mt-5  py-3'>
                <span className='font-inter text-14 mr-1'>Show:</span>
                <div onMouseEnter={toggleProductsCountDropdown} onMouseLeave={() => setIsProductCountDropdown(false)} className='flex'>
                    <span className='font-inter text-14'>60</span>
                    {isProductCountDropdown ? <FaChevronUp className='ml-1 size-3 md:size-4 ' /> : <FaChevronDown className='ml-1 size-3 md:size-4 ' />}
                    {isProductCountDropdown && (
                        <div className='absolute right-0  w-[5%] bg-white border-t-0 border top-8 border-[#e2e2e2] shadow-lg z-10'>
                            <ul className='py-2'>
                                <li className='px-4 py-1 hover:bg-gray-100 cursor-pointer '>20</li>
                                <li className='px-4 py-1 hover:bg-gray-100 cursor-pointer'>60</li>
                                <li className='px-4 py-1 hover:bg-gray-100 cursor-pointer'>100</li>
                            </ul>
                        </div>
                    )}
                </div>
            </div>

            <div className='mx-auto mt-10 container w-[80%] 2xl:w-[70%] '>
                <div className="flex flex-row flex-wrap  justify-between mx-auto w-full mt-6">
                    {Colored_Gemstone?.map((item, index) => {
                        return (
                            <div key={index} className={`relative w-[48%] lg:w-[30%] xl:w-[23%] outline-none text-[#2c2c2c] mb-10`} >
                                <div className='absolute right-3 top-1.5'>
                                    <GoHeart />
                                </div>
                                <img
                                    src={item.imageUrl}
                                    className='cursor-pointer  px-2  mx-auto'
                                    alt="Kyrah Star" />
                                <div className=" flex justigy-between gap-1  mt-4 w-[100%]">
                                    <span className='font-inter mb-1 font-medium tracking-wider cursor-pointer hover:text-hover text-12'>
                                        {item.name}
                                    </span>
                                    <span className='font-inter font-[350] tracking-wider text-13 cursor-default'>
                                        ${item.price}
                                    </span>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div >
    )
}

export default DifferentGemstones
