import React from 'react';
import { useNavigate } from 'react-router-dom';
import Banner from '../../components/Banner';
import ProductSlider from '../../components/ProductSlider';
import { Appointments, Bridal_Category, Engagement_Banner, menu, PopularRings, Shape_menu, Shope_Category } from '../../helpers/jsonData';
import GemAndShapeViewer from '../../components/GemAndShapeViewer';

const EngagementRings = () => {
    const navigate = useNavigate()

    return (
        <div className='overflow-hidden w-[100%]'>
            {/* <div className=' md:pt-3 pt-2 md:pl-8 pl-5 text-start'>
                <span className='text-12 text-lightBlack font-inter'>
                    <span onClick={() => navigate("/")} className="cursor-pointer hover:underline">Home</span> / Engagement Rings
                </span>
            </div> */}

            <Banner data={Engagement_Banner} />

            <div className='mt-10 '>
                <h3 className='xl:text-36 lg:text-36 md:text-36 sm:text-30 text-30 text-center w-[100%] font-inter'>
                    Shop Diamonds by Shape
                </h3>
                <GemAndShapeViewer data={Shape_menu} />
                {/* <div className="overflow-x-auto lg:overflow-x-hidden scrollbar-hide">
                    <div className="flex flex-row justify-start md:justify-start lg:justify-center justify-start md:gap-9 gap-9  px-5 xl:mx-auto xl:w-[90%] w-full mt-6 whitespace-nowrap">
                        {Shape_menu?.map((item, index) => (
                            <div key={index} className="cursor-pointer hover:text-hover flex flex-col items-center inline-block flex-shrink-0">
                                <img src={item.image} className="xl:h-20xl:w-20 md:h-16 md:w-16  h-16 w-16" alt="Kyrah Star" />
                                <div className="md:mt-4 sm:mt-1 text-center">
                                    <span className="font-inter md:text-14 sm:text-12">
                                        {item.name}
                                    </span>
                                </div>
                            </div>
                        ))}
                    </div>
                </div> */}


            </div>
            <div className='mx-auto mt-12  box-border 2xl:w-[79%]'>
                <h1 className='xl:text-36 lg:text-36 md:text-36 sm:text-30 text-30 lg:mx-5 md:mx-3 sm:mx-2 mx-3text-36 md:text-center text-center lg:text-start w-full font-inter'>
                    Design Your Own Engagement Ring
                </h1>
                <div className="">
                    <div className="flex flex-wrap justify-between md:gap-3 lg:gap-5 sm:gap-[2%] gap-[2%] lg:px-5 md:px-3 px-[2%] sm:px-[2%] 2xl:px-0 lg:mx-auto w-full mt-6 ">
                        {Shope_Category?.map((item, index) => (
                            <div key={index} className="cursor-pointer outline-none text-[#2c2c2c] hover:text-hover sm:w-[47%] md:w-[23.5%] w-[46.5%] flex-shrink-0">
                                <img src={item.image} className="w-full h-auto" alt="Kyrah Star" />
                                <div className="sm:mt-1 md:mt-4 mb-1 text-start">
                                    <span className="font-inter-400 font-medium xl:text-17 lg:text-16 md:text-15 sm:text-14">
                                        {item.name}
                                    </span>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>

            </div>

            <div className='mx-auto mt-12 2xl:w-[79%]'>
                <h1 className='xl:text-36 lg:text-36 md:text-36 sm:text-30 text-30 lg:mx-5 md:mx-3 sm:mx-2 mx-3text-36 md:text-center text-center lg:text-start w-full font-inter'>
                    Popular Engagement Rings
                </h1>

                <div className='mt-5'>
                    <ProductSlider data={PopularRings} />
                </div>
            </div>

            {/* Shop Engagement Rings By Style */}
            <div className='mx-auto mt-16 2xl:w-[79%]'>
                <h1 className='xl:text-36 lg:text-36 md:text-36 sm:text-30 text-30 lg:mx-5 md:mx-3 sm:mx-2 mx-3text-36 md:text-center text-center lg:text-start w-full font-inter'>
                    Shop Engagement Ring By Style
                </h1>

                <div className='overflow-x-auto scrollbar-hide flex  lg:gap-5 md:gap-3 gap-3 sm:gap-2 lg:px-5 md:px-3 sm:px-2 px-3 justify-between mx-auto w-full mt-6'>
                    {
                        Bridal_Category?.map((item, index) => {
                            return (
                                <div key={index} className="cursor-pointer text-[#2c2c2c] hover:text-hover flex-shrink-0 sm:w-96 w-80 md:w-[18.5%]">
                                    <img src={item.image} className='' alt="Kyrah Star" />
                                    <div className="mt-4 text-start">
                                        <span className='font-inter font-medium xl:text-17 text-16'>
                                            {item.name}
                                        </span>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>

            <div className='mx-auto mt-12 xl:gap-12 md:gap-8 lg:gap-10 gap-5  flex sm:flex-row flex-col  sm:p-2 p-2 2xl:w-[79%]'>
                <div className='md:w-2/4 md:h-2/4 w-full '>
                    <img src="https://image.brilliantearth.com/cdn-cgi/image/width=720,height=545,quality=100,format=auto/https://cdn.builder.io/api/v1/image/assets%2F9f2a69003c86470ea05deb9ecb9887be%2F7388a4b5621543889f2ae11207bbe51c" className='w-full' alt="Kyrah Star" />
                </div>

                <div className='md:w-2/4 w-full flex flex-col pr-5 justify-center '>
                    <h1 className='text-20 font-inter text-[#2c2c2c] font-normal '>
                        SIGNATURE COLLECTION
                    </h1>

                    <h2 className='font-inter mt-2 font-semibold text-black text-28'>
                        The Camellia
                    </h2>
                    <span className='text-14 font-inter text-start my-3'>
                        Inspired by the beauty of their namesake flower, Camellia designs feature textural details, unique petal motifs, and delicate diamond leaves.
                    </span>

                    <div className='flex flex-row justify-between w-full'>
                        <div className={`w-[49%] lg:mt-3 mt-3 border border-1 border-black  justify-center text-black hover:bg-black hover:text-white flex flex-row py-3`}>
                            <span className='sm:text-12 md:text-14 text-14 font-inter text-center'>
                                Shop Now
                            </span>
                        </div>
                        <div className={`w-[49%] lg:mt-3 mt-3 border border-1 border-black  justify-center text-black hover:bg-black hover:text-white flex flex-row py-3`}>
                            <span className='sm:text-12 md:text-14 text-14 font-inter px-2 text-center truncate'>
                                Explore More Collections
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            <div className='mx-auto mt-16 2xl:w-[79%]'>
                <h1 className='xl:text-36 lg:text-36 md:text-36 sm:text-30 text-30 lg:mx-5 md:mx-3 sm:mx-2 mx-3text-36 md:text-center text-center lg:text-start w-full font-inter'>
                    Our Most Coveted Bridal Designs
                </h1>

                <div className='overflow-x-auto scrollbar-hide flex lg:gap-5 md:gap-3 sm:gap-2 gap-3 lg:px-5 md:px-3 sm:px-2 px-3 justify-between mx-auto w-full mt-6'>
                    {
                        Bridal_Category?.map((item, index) => {
                            return (
                                <div key={index} className="cursor-pointer text-[#2c2c2c] hover:text-hover flex-shrink-0 sm:w-96 w-80 md:w-[18.5%]">
                                    <img src={item.image} className='' alt="Kyrah Star" />
                                    <div className="mt-4 text-start">
                                        <span className='font-inter-400 font-medium xl:text-17 lg:text-16'>
                                            {item.name}
                                        </span>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>

            {/* MENU_CONTAINER */}
            <div className='mx-auto mt-16 xl:gap-8 md:gap-5 gap-5 lg:px-5 px-3 flex w-full sm:flex-row flex-col  justify-between 2xl:w-[79%]'>
                {
                    menu?.map((item, index) => {
                        return (
                            <div key={index} className='w-full'>
                                <img src={item.image}
                                    alt='namew'
                                    className='w-full'
                                />
                                <div className='flex flex-col mt-6'>
                                    <span className='font-medium font-inter text-26 text-black'>
                                        {item?.title}
                                    </span>
                                    <span className='font-inter mt-2 font-normal text-14'>
                                        {item?.content}
                                    </span>
                                    <div className={`w-[40%] mt-5 border border-1 border-black px-6 justify-center text-black hover:bg-black hover:text-white flex flex-row py-3`}>
                                        <span className='text-14 font-inter text-center'>
                                            Shop Now
                                        </span>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }

            </div>

            {/* APPOINTMENTS_CONTAINER */}
            <div className='mx-auto mt-16 mb-10 flex lg:px-5 md:px-3 sm:px-2 px-3 w-full flex-row justify-between 2xl:w-[79%]'>
                {
                    Appointments?.map((item, index) => {
                        return (
                            <div key={index} className='w-full'>
                                <img src={item.image}
                                    alt='namew'
                                    className='w-full'
                                />
                                <div className='flex flex-col mt-6'>
                                    <span className='font-medium font-inter text-26 text-black'>
                                        {item?.title}
                                    </span>
                                    <span className='font-inter mt-2 font-normal text-14'>
                                        {item?.content}
                                    </span>
                                    <div className='flex flex-col md:flex-row gap-5'>
                                        <div className={`xl:w-[20%]  sm:w-[80%] w-[100%] mt-5 border border-1 border-black px-4 justify-center  text-black hover:bg-black hover:text-white flex flex-row py-3`}>
                                            <span className='text-14 font-inter text-center truncate'>
                                                Visit a Showroom
                                            </span>
                                        </div>

                                        <div className={`xl:w-[20%] sm:w-[80%]  w-[100%] md:mt-5 border border-1 border-black px-4 justify-center  text-black hover:bg-black hover:text-white flex flex-row py-3`}>
                                            <span className='text-14 font-inter text-center truncate'>
                                                Book a Virtual Appointment
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }

            </div>
            {/* FOOTER */}
            {/* <Footer /> */}
        </div>
    )
}

export default EngagementRings