import React from 'react'

const SliderProduct = ({ image, title, subtitle }) => {
    return (
        <div className='flex flex-col items-center'>
            <div className='hover:text-hover w-full'>
                <div className='relative w-full aspect-[7/9] overflow-hidden'>
                    <img
                        src={image}
                        alt='Kyrah Star'
                        className='absolute inset-0 w-full h-full object-cover px-1.5'
                    />
                </div>
                <p className=' font-inter text-18  mt-3 px-1.5'>{title}</p>
            </div>
            {/* <p className='py-1 mt-1 text-center'>{subtitle}</p> */}
        </div>
    )
}

export default SliderProduct