import { createSlice } from "@reduxjs/toolkit";

export const filterData = createSlice({
    name: 'filter',
    initialState: {
        filtersData: {}
    },
    reducers: {
        addFilters: (state, action) => {
            const { data } = action.payload
            state.filtersData.data = data;
        },
    }
}
)

export const { addFilters } = filterData.actions
export default filterData.reducer