import React, { useEffect, useState } from 'react'
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import Slider from 'react-slick';
import ProductComponent from './Product';
import { PopularRings } from '../helpers/jsonData';

const ProductSlider = ({ data }) => {
    const [slidesToShow, setSlidesToShow] = useState();
    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth >= 1024) {
                setSlidesToShow(4.5);
            } else if (window.innerWidth >= 768) {
                setSlidesToShow(3.5);
            }
            else {
                setSlidesToShow(1.5);
            }
        };
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const CustomPrevArrow = (props) => {
        const { currentSlide, ...rest } = props;
        return (
            currentSlide !== 0 && <button button {...rest} className="slick-arrow rounded-full bg-white p-3   absolute  left-[3%] top-[50%] translate-y-[-50%] z-10 shadow-xl" >
                <FaChevronLeft size={16} color="black" />
            </button >
        )
    }
    const CustomNextArrow = (props) => {
        const { currentSlide, slideCount, ...rest } = props
        return (
            currentSlide !== slideCount - slidesToShow && <button {...rest} className="slick-arrow rounded-full  p-3 bg-white  absolute  right-[3%] top-[50%] translate-y-[-50%] z-10 shadow-xl">
                <FaChevronRight size={16} color="black" />
            </button>
        );
    }

    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: slidesToShow,
        slidesToScroll: 1.5,
        prevArrow: <CustomPrevArrow />,
        nextArrow: <CustomNextArrow />,
    };
    return (
        <div>
            <div className="flex flex-row gap-6 justify-between mx-auto w-full overflow-hidden">
                <Slider {...settings} className="w-full flex flex-row mx-2 gap-5">
                    {PopularRings?.map((item, index) => {
                        return (
                            <div
                                key={index}
                                className={`px-1.5 outline-none text-[#2c2c2c]`}
                            >
                                <ProductComponent
                                    item={item}
                                    hoverIndex={index}
                                    productData={PopularRings}
                                />
                            </div>
                        );
                    })}
                </Slider>
            </div>
        </div>
    )
}

export default ProductSlider
