import { useEffect, useState } from "react";
import { Images } from "./Image";
import { isValidImageUrl } from "../utils/isValidImage";

const ProductComponent = ({ item, hoverIndex, productData, viewProductDetail, variant }) => {

    const [isHovered, setIsHovered] = useState(false);
    const [hoveredVariantIndex, setHoveredVariantIndex] = useState(null);
    const [selectedVariant, setSelectedVariant] = useState(item?.Variants?.find(v => v.IS_DEFAULT === true) || null);
    const [hoveredVariants, setHoveredVariants] = useState(
        Array(productData?.length).fill(null)
    );

    const metalOrder = ["14K White Gold", "14K Rose Gold", "14K Yellow Gold", '18K White Gold', '18K Yellow Gold', 'Platinum'];

    const sortedVariants = [...(item?.Variants || [])].sort((a, b) => {
        const indexA = metalOrder.indexOf(a?.METAL) !== -1 ? metalOrder.indexOf(a.METAL) : Infinity;
        const indexB = metalOrder.indexOf(b?.METAL) !== -1 ? metalOrder.indexOf(b.METAL) : Infinity;
        return indexA - indexB;
    });

    const currentVariant = hoveredVariantIndex !== null ? hoveredVariants[hoverIndex] : selectedVariant;

    const handleMouseEnter = (index) => {
        setHoveredVariantIndex(index);
        const newHoveredVariants = [...hoveredVariants];
        newHoveredVariants[hoverIndex] = sortedVariants[index];
        setHoveredVariants(newHoveredVariants);
    };

    const handleMouseLeave = () => {
        setHoveredVariantIndex(null);
        const defaultVariant = sortedVariants.find(v => v.IS_DEFAULT === 1) || sortedVariants[0];
        setHoveredVariants((prev) => {
            const newHovered = [...prev];
            newHovered[hoverIndex] = defaultVariant;
            return newHovered;
        });
    };

    const handleSelectVariant = (variant) => {
        setSelectedVariant(variant);
        setHoveredVariantIndex(null);
    };

    const handleNavigate = (variant) => {
        viewProductDetail(hoverIndex, variant.VARIANT_ID, item.SKU_NAME, variant.SHP_SEL);
    };

    useEffect(() => {
        setHoveredVariantIndex(null);
        setIsHovered(false);
    }, [productData]);


    return (
        <div className="flex flex-col items-center">
            <div className="sm:h-[300px] sm:w-[300px] h-[150px] w-[150px]">
                <img
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}
                    onClick={() => handleNavigate(currentVariant)}
                    src={isHovered && currentVariant ? currentVariant.HOVER_IMG_URL ? `${currentVariant.HOVER_IMG_URL}?version=${new Date().getTime()}` : Images.notFound : currentVariant?.IMG_URL ? `${currentVariant?.IMG_URL}?version=${new Date().getTime()}` : Images.notFound}
                    className="h-[97%] w-[100%] cursor-pointer object-cover border-[1px] border-productImageBorder rounded-xl mb-2"
                    alt="Kyrah Star"
                />
            </div>
            <div className="flex flex-row justify-center gap-1 sm:gap-1.5 items-center ">
                {sortedVariants.map((variant, index) => {
                    const color = variant?.METAL?.toLowerCase();
                    const isSelected = selectedVariant?.VARIANT_ID === variant.VARIANT_ID;

                    return (
                        <div
                            key={index}
                            onMouseEnter={() => handleMouseEnter(index)}
                            onMouseLeave={handleMouseLeave}
                            onClick={() => {
                                handleSelectVariant(variant);
                                handleNavigate(variant);
                            }}
                            className={`rounded-full flex flex-wrap items-center justify-center h-6 w-6 sm:h-8  sm:w-8 cursor-pointer
                ${isSelected ? "border-[2px] border-footerBackground hover:border-footerBackground" : "border-productImageBorder border-[2px]"} 
                   hover:border-[2px] hover:border-[#eae0e0]`}
                        >
                            {color === "18k white gold" && <div className="bg-silver-gradient h-5 w-5 sm:h-7 sm:w-7 sm:pt-0.5 pr-0.5 text-footerBackground rounded-full text-10 sm:text-11  flex items-center justify-center">18K</div>}
                            {color === "14k white gold" && <div className="bg-silver-gradient h-5 w-5 sm:h-7 sm:w-7 sm:pt-0.5 pr-0.5 text-footerBackground rounded-full text-10 sm:text-11  flex items-center justify-center">14K</div>}
                            {color === "18k yellow gold" && <div className="bg-gold-gradient h-5 w-5 sm:h-7 sm:w-7 sm:pt-0.5 pr-0.5 text-footerBackground rounded-full text-10 sm:text-11  flex items-center justify-center">18K</div>}
                            {color === "14k yellow gold" && <div className="bg-gold-gradient h-5 w-5 sm:h-7 sm:w-7 sm:pt-0.5 pr-0.5 text-footerBackground rounded-full text-10 sm:text-11  flex items-center justify-center">14K</div>}
                            {color === "14k rose gold" && <div className="bg-rose-gradient h-5 w-5 sm:h-7 sm:w-7 sm:pt-0.5 pr-0.5 text-footerBackground rounded-full text-10 sm:text-11  flex items-center justify-center">14K</div>}
                            {color === "platinum" && <div className="bg-platinum-gradient h-5 w-5 sm:h-7 sm:w-7 sm:pt-0.5 pl-0.5 text-footerBackground rounded-full text-10 sm:text-11  flex items-center justify-center">PT</div>}
                        </div>
                    );
                })}
            </div>

            <div className="sm:mt-4 mt-2 text-center flex flex-col">
                <span
                    onClick={() => handleNavigate(currentVariant)}
                    className="font-inter mb-1 font-productHeading tracking-wider cursor-pointer hover:text-footerBackground text-14 sm:text-17 xl:text-productHeading"
                >
                    {currentVariant?.SKU_NAME}
                </span>
                <span
                    onClick={() => handleNavigate(currentVariant)}
                    className="font-inter  tracking-wider sm:text-16 text-14 xl:text-productHeading cursor-pointer"
                >
                    {currentVariant?.VARIANT_PRICE?.toLocaleString()}{currentVariant?.CURR_SYMBOL}
                </span>
            </div>
        </div>
    );
};

export default ProductComponent;
