import React, { Fragment } from 'react';
import Banner from '../../components/Banner';
import FlexView from '../../components/FlexView';
import GemAndShapeViewer from '../../components/GemAndShapeViewer';
import Review from '../../components/Review';
import SliderView from '../../components/Slider';
import { Gem_Category, Gem_Shape, Gemstone_Education, Gemstone_Engagement_Rings, Gemstone_Review, GEMSTONEBANNER, Rare_Gemstones, SHOP_GEM_BY_COLOR, Shop_Gem_Stone_Collections } from '../../helpers/jsonData';


const Gemstone = () => {
    return (
        <div>
            <div className=' md:pt-3 pt-2 md:pl-8 pl-5 text-start'>
                <span className='text-12 text-lightBlack font-inter'>
                    Home / Gemstones
                </span>
            </div>

            {/* Banner Section */}
            <Banner data={GEMSTONEBANNER} className={'text-black'} />


            {/* Gemstons section */}
            <div className='mt-20'>
                <h3 className='text-30 text-center w-full font-inter'>
                    Shop by Gemstone
                </h3>
                {/* <GemAndShapeViewer data={Gem_Shape} /> */}
                <div className='flex justify-between mx-auto gap-10  lg:gap-5 w-[95%] 2xl:w-[70%] mt-5 overflow-x-auto lg:overflow-x-hidden scrollbar-hide' >
                    {Gem_Shape?.map((item, index) => (
                        <div className="cursor-pointer hover:text-hover w-[10%] lg:w-[7%] flex-shrink-0" key={index}>
                            <img src={item.IMG_URL} className='w-16 h-16 mx-auto' alt="Kyrah Star" />
                            <div className="mt-4 text-center">
                                <span className='font-inter lg:text-14 text-15' >
                                    {item.S_NAME}
                                </span>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            {/* Shop by color section */}
            <div className=" mx-auto w-[95%] 2xl:w-[70%] mt-16 ">
                <h3 className='text-30 font-inter mb-5'>Shop By Color</h3>
                <SliderView data={SHOP_GEM_BY_COLOR} />
            </div>

            {/* Collection section */}
            <div className=" mx-auto w-[95%] 2xl:w-[70%] p-14 mt-16 bg-[url('https://cdn.builder.io/api/v1/image/assets%2F9f2a69003c86470ea05deb9ecb9887be%2F5492ce3ecfb94011a052569fce6058b6')] bg-cover">
                {
                    Shop_Gem_Stone_Collections.map((data, index) => {
                        return (
                            <Fragment key={index}>
                                <div className='text-center text-white'>
                                    <p className='font-bold text-32 my-2'>{data.title}</p>
                                    <p className='lg:w-[45%] w-full mx-auto'>{data.subtitle}</p>
                                </div>
                                <div className={`w-1/2 lg:w-1/3  mx-auto mt-6 px-6 justify-center  flex flex-row p-3 hover:bg-black hover:text-white bg-white text-black-900 ' `}>
                                    <span className={`text-13 font-inter text-center  hover:text-white truncate `}>
                                        {data.buttons[0]}
                                    </span>
                                </div>
                            </Fragment>
                        )
                    })
                }
            </div>

            {/* Gemstone Engagement Rings */}
            <div className=" mx-auto w-[95%] 2xl:w-[70%] mt-16 ">
                <h3 className='text-30 font-inter mb-5'>Gemstone Engagement Rings</h3>
                <FlexView data={Gemstone_Engagement_Rings} />
            </div>

            <div className=' mx-auto w-[95%] 2xl:w-[70%] mt-16'>
                {Rare_Gemstones.map((data, index) => {
                    return (
                        <div key={index} className='flex justify-between flex-col lg:flex-row gap-3'>
                            <img src={data.image} alt="Kyrah Star" className='lg:w-[50%] w-full' />
                            <div className='lg:pl-14 lg:pt-[10%] w-[80%] mx-auto'>
                                <p className='text-30 my-2 font-inter'>{data.title}</p>
                                <p className='text-14 font-inter'>{data.subtitle}</p>
                                <div key={index} className={`md:w-1/2 w-full  mt-6 px-6 justify-center border-[1px] border-black border-solid flex flex-row p-3 hover:bg-black hover:text-white bg-white text-black-900 ' `}>
                                    <span className={`text-14 font-inter text-center  hover:text-white `}>
                                        {data.button}
                                    </span>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>

            <div className=' mx-auto w-[95%] 2xl:w-[70%] mt-16 flex flex-col md:flex-row justify-between gap-3'>
                {Gem_Category.map((data, index) => {
                    return (
                        <div key={index} className=''>
                            <img src={data.image} alt="Kyrah Star" className='w-full' />
                            <div >
                                <p className='text-34 my-1'>{data.title}</p>
                                <p>{data.subtitle}</p>
                                <div key={index} className={`lg:w-1/2 mt-3 px-6 justify-center border-[1px] border-black border-solid flex flex-row p-3 hover:bg-black hover:text-white bg-white text-black-900 ' `}>
                                    <span className={`text-13 font-inter text-center  hover:text-white `}>
                                        {data.button}
                                    </span>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>

            {/* Gemstone Review Section */}
            <div className=" mx-auto w-[95%] 2xl:w-[70%] mt-16 " id='a'>
                <Review data={Gemstone_Review} title={'Gemstones'} />
            </div>

            <div className=" mx-auto w-[95%] 2xl:w-[70%] mt-16 ">
                <h3 className='text-30 w-full font-inter mb-5'>Gemstone Education</h3>
                <SliderView data={Gemstone_Education} />
            </div>

            <div className=" mx-auto w-[80%] mt-32 2xl:w-[60%] ">
                <h3 className='text-30 w-full text-center font-EB mb-5'>Ethically-Sourced Gemstones</h3>
                <p className='mt-5 text-[#a0a0a0]-500'>Gemstone rings are an excellent alternative to more traditional <span className='underline'>diamond</span> styles. <span className='underline'>Morganite,</span> <span className='underline'>emerald,</span> ruby, aquamarine, moissanite, sapphire, and amethyst are all fabulous gemstones that are the perfect choice for someone looking for a look with a pop of color. Gemstone engagement rings are a special way to celebrate your unique, anything-but-colorless love story – or simply as a colorful gift for yourself.</p>
                <p className='mt-5 text-[#a0a0a0]-500'>Once you have selected the perfect semi precious gemstone, it is time to find a setting to match it. Our curated selection of designs ranges from nature-inspired to ultra-modern. Plain bands give way to luxe diamond-accented styles, while other gemstone ring settings are unique enough to catch the eye. Choose a setting for your stone that will complement and enhance its beauty and color, creating the perfect semi precious gemstone ring.</p>
            </div>

        </div >
    )
}

export default Gemstone
