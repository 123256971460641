import { createSlice } from "@reduxjs/toolkit";

export const navigationMenu = createSlice({
    name: 'navigation',
    initialState: {
        navigationMenu: {}
    },
    reducers: {
        addMenuData: (state, action) => {
            const { data } = action.payload
            state.navigationMenu.data = data;
        },
    }
}
)

export const { addMenuData } = navigationMenu.actions
export default navigationMenu.reducer