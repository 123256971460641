import { createSlice } from "@reduxjs/toolkit";

export const selectedDiamond = createSlice({
    name: 'diamond',
    initialState: {
        diamondDetail: {}
    },
    reducers: {
        addSelectedDiamond: (state, action) => {
            console.log(action.payload)
            state.diamondDetail = action.payload[0];
        },
        removeSelectedDiamond: (state, action) => {
            state.diamondDetail = {};
        }
    }
}
)

export const { addSelectedDiamond, removeSelectedDiamond } = selectedDiamond.actions
export default selectedDiamond.reducer