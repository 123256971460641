import React, { useEffect, useState } from 'react';
import { MdOutlineKeyboardArrowDown } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { countryCodes } from '../../helpers/jsonData';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { RotatingLines } from 'react-loader-spinner';
import { showErrorToast, showSuccessToast } from '../../components/ToastNotification';
import { axiosInstance } from '../../redux/api';

const Register = () => {

    const navigate = useNavigate();

    // States for form fields
    const [mobile, setMobile] = useState('');
    const [email, setEmail] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [gender, setGender] = useState(''); // Default to male
    const [countryCode, setCountryCode] = useState('+91'); // Default to India's code
    const [searchTerm, setSearchTerm] = useState('');
    const [searchTermCode, setSearchTermCode] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [isOpenCode, setIsOpenCode] = useState(false);
    const defaultCountry = countryCodes?.find(country => country?.name === 'India');
    const [selectedCountry, setSelectedCountry] = useState(defaultCountry); // Default to the first country
    const [filteredCountries, setFilteredCountries] = useState([]);
    const [filteredCountriesCode, setFilteredCountriesCode] = useState([]);
    // States for individual field errors

    const [mobileError, setMobileError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [firstNameError, setFirstNameError] = useState('');
    const [lastNameError, setLastNameError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [confirmPasswordError, setConfirmPasswordError] = useState('');
    const [genderError, setGenderError] = useState('');
    const [countryError, setCountryError] = useState('');

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])


    const toggleDropdown = () => {
        setIsOpenCode(!isOpenCode)
        setIsOpen(false)
    }

    const handleSelect = (country) => {
        setSelectedCountry(country);
        setSearchTermCode('')
        setCountryCode(country.code);
        setIsOpenCode(false);
    };


    useEffect(() => {
        const debounceTimeout = setTimeout(() => {
            let filtered = countryCodes;
            if (searchTerm) {
                filtered = filtered.filter(item =>
                    item.name.toLowerCase().includes(searchTerm.toLowerCase())
                );
            }



            // Use a Map to group items by their code
            const codeMap = new Map();

            filtered.forEach(item => {
                if (!codeMap.has(item.code)) {
                    codeMap.set(item.code, item); // Store the first item with this code
                } else {
                    // If this code already exists, keep the existing one
                    // This keeps the first item found with this code
                    // You could also decide to keep a specific item based on some logic
                }
            });

            // Convert the Map back to an array
            const uniqueFiltered = Array.from(codeMap.values());

            setFilteredCountries(uniqueFiltered);
        }, 300); // Wait for 300ms after user stops typing

        return () => clearTimeout(debounceTimeout); // Cleanup timeout on each render
    }, [searchTerm]);

    useEffect(() => {
        const debounceTimeout = setTimeout(() => {
            let filtered = countryCodes;

            if (searchTermCode) {
                filtered = countryCodes.filter(item =>
                    item.code.includes(searchTermCode) || item.name.toLowerCase().includes(searchTermCode.toLowerCase())
                );
            }

            setFilteredCountriesCode(filtered);
        }, 300); // Wait for 300ms after user stops typing

        return () => clearTimeout(debounceTimeout); // Cleanup timeout on each render
    }, [searchTermCode]);

    const handleToggleDropdown = () => {
        setIsOpen(!isOpen);
        setIsOpenCode(false)
    };

    const handleSelectCountry = (country) => {
        setSelectedCountry(country);
        setIsOpen(false); // Close dropdown after selection
        setSearchTerm('')
        setCountryError('')
    };

    // Function to validate email
    const validateEmail = (email) => {
        const re = /\S+@\S+\.\S+/;
        return re.test(email);
    };

    // Individual handleChange functions

    const handleMobileChange = (e) => {
        setMobile(e.target.value);
        if (e.target.value.trim() === '') {
            setMobileError('Mobile number is required');
        } else {
            setMobileError('');
        }
    };

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
        if (e.target.value.trim() === '') {
            setEmailError('Email is required');
        } else if (!validateEmail(e.target.value)) {
            setEmailError('Invalid email format');
        } else {
            setEmailError('');
        }
    };

    const handleFirstNameChange = (e) => {
        setFirstName(e.target.value);
        if (e.target.value.trim() === '') {
            setFirstNameError('First name is required');
        } else {
            setFirstNameError('');
        }
    };

    const handleLastNameChange = (e) => {
        setLastName(e.target.value);
        if (e.target.value.trim() === '') {
            setLastNameError('Last name is required');
        } else {
            setLastNameError('');
        }
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
        if (e.target.value.trim() === '') {
            setPasswordError('Password is required');
        } else {
            setPasswordError('');
        }
    };

    const handleConfirmPasswordChange = (e) => {
        setConfirmPassword(e.target.value);
        if (e.target.value.trim() === '') {
            setConfirmPasswordError('Confirm password is required');
        } else if (e.target.value !== password) {
            setConfirmPasswordError('Passwords do not match');
        } else {
            setConfirmPasswordError('');
        }
    };

    const handleGenderChange = (e) => {
        const value = e.target.value;
        setGender(value);
        if (value) {
            setGenderError(''); // Clear the error if a gender is selected
        } else {
            setGenderError('Gender is required');
        }
    };

    const isDisabled = !mobile || !email || !firstName || !lastName || !password || !confirmPassword || !gender || !selectedCountry || mobileError || emailError || firstNameError || lastNameError || passwordError || confirmPasswordError || genderError || countryError

    // Handle form submission
    const handleSubmit = async () => {
        if (isDisabled) {
            return;
        }

        setIsLoading(true);
        // Prepare the request body
        const payload = {
            PHONE: mobile,
            PASSWORD: password,
            CON_CODE: countryCode,
            EMAIL: email,
            FIRST_NAME: firstName,
            LAST_NAME: lastName,
            GENDER: gender,
            COUNTRY: selectedCountry.name
        };

        try {
            // const response = await fetch(`${process.env.REACT_APP_PUBLIC_BASE_URL}auth/register`, {
            //     method: 'POST',
            //     headers: {
            //         'Content-Type': 'application/json',
            //     },
            //     body: JSON.stringify(payload),
            // });
            const response = await axiosInstance.post('auth/register', payload)
            // const data = await response.json();

            if (response.data.success === true) {
                setIsLoading(false);
                showSuccessToast(response.data.message)
                navigate(`/login`)
            } else {
                setIsLoading(false);
                showErrorToast(response.data.message)
            }
            // Handle successful response, e.g., show a success message or navigate
            console.log(response.data);
        } catch (error) {
            setIsLoading(false);
            // Handle errors, e.g., show an error message
            console.error('There was a problem with the fetch operation:', error);
        } finally {
            setIsLoading(false);
        }
    };



    console.log(isDisabled, "isDia");

    return (
        <>
            <ToastContainer />
            <div className='mx-auto container mt-2'>
                <div className='text-start'>
                    <span className='text-12 text-lightBlack font-inter'>
                        <span onClick={() => navigate("/")} className="cursor-pointer hover:underline">Home</span> / Customer Service / Account
                    </span>
                </div>

                <div className='mt-5'>
                    <div className='w-full mx-auto flex justify-center flex-row'>
                        <div className='w-full mt-10'>
                            <h1 className='font-inter font-semibold text-25 text-center'>Signup with Kyrahstar</h1>
                            <p className='font-inter font-normal text-14 text-center mt-2'>
                                Unlock Best prices and become an insider for our exclusive launches & offers.
                            </p>
                        </div>
                    </div>

                    <div className='flex flex-col justify-center items-center mt-10'>

                        {/* Mobile Number and Email */}
                        <div className='flex flex-row justify-center items-center gap-5 w-full'>
                            {/* Country Code dropdown */}
                            <div className='flex flex-col w-[30%] relative'>
                                <div className='border border-[#ccc] rounded-lg p-3 text-15 w-full outline-none my-2 flex flex-row'>
                                    <div onClick={toggleDropdown} className='flex items-center cursor-pointer outline-none'>
                                        {/* <img src={selectedCountry.svg} alt={`Flag of ${selectedCountry.name}`} className="w-8 h-6 mr-2" /> */}
                                        <span className='text-15'>{countryCode}</span>
                                        <span className={`transform ml-2 transition-transform ${isOpenCode ? 'rotate-180' : 'rotate-0'}`}>
                                            <MdOutlineKeyboardArrowDown size={20} />
                                        </span>
                                    </div>

                                    {isOpenCode && (
                                        <div className='absolute z-10 top-[51px] right-0 border border-[#ccc] h-[420px] overflow-auto rounded-lg mt-1 bg-white w-full'>
                                            {/* Search Input */}
                                            <input
                                                type="text"
                                                placeholder="Search..."
                                                value={searchTermCode}
                                                onChange={(e) => setSearchTermCode(e.target.value)}
                                                className="p-2 border-b border-[#ccc] w-full outline-none"
                                            />
                                            {/* Filtered Countries List */}
                                            {filteredCountriesCode.length > 0 ? (
                                                filteredCountriesCode.map((country) => {
                                                    return (
                                                        <div
                                                            key={country.id}
                                                            className='flex items-center p-3 cursor-pointer hover:bg-gray-100'
                                                            onClick={() => handleSelect(country)}
                                                        >
                                                            <img src={country.svg} alt={`Flag of ${country.name}`} className="w-8 h-6 mr-2" />
                                                            <span>{country.code}</span>
                                                        </div>
                                                    )
                                                })
                                            ) : (
                                                <div className="p-3 text-gray-500">No countries found</div>
                                            )}
                                        </div>
                                    )}

                                    <div className='flex flex-col w-full'>
                                        <input
                                            type="number"
                                            placeholder="Mobile Number"
                                            value={mobile}
                                            onChange={handleMobileChange}
                                            className="px-2 w-full outline-none"
                                        />
                                    </div>
                                </div>
                                {mobileError && <p className="text-red-500 text-14 font-inter">{mobileError}</p>}
                            </div>
                            <div className='flex flex-col w-[30%]'>
                                <input
                                    type='email'
                                    placeholder='Email'
                                    value={email}
                                    onChange={handleEmailChange}
                                    className='border border-[#ccc] rounded-lg p-3 text-15 w-full outline-none my-2'
                                />
                                {emailError && <p className="text-red-500 text-14 font-inter">{emailError}</p>}
                            </div>
                        </div>

                        {/* First Name and Last Name */}
                        <div className='flex flex-row justify-center items-center gap-5 w-full'>
                            <div className='flex flex-col w-[30%]'>
                                <input
                                    type='text'
                                    placeholder='First Name'
                                    value={firstName}
                                    onChange={handleFirstNameChange}
                                    className='border border-[#ccc] rounded-lg p-3 text-15 outline-none w-full my-2'
                                />
                                {firstNameError && <p className=" text-red-500 text-14 font-inter">{firstNameError}</p>}
                            </div>

                            <div className='flex flex-col w-[30%]'>
                                <input
                                    type='text'
                                    placeholder='Last Name'
                                    value={lastName}
                                    onChange={handleLastNameChange}
                                    className='border border-[#ccc] rounded-lg p-3 text-15 w-full outline-none my-2'
                                />
                                {lastNameError && <p className=" text-red-500 text-14 font-inter">{lastNameError}</p>}
                            </div>
                        </div>

                        {/* Password and Confirm Password */}
                        <div className='flex flex-row justify-center items-center gap-5 w-full'>
                            <div className='flex flex-col w-[30%]'>
                                <input
                                    type='password'
                                    placeholder='Password'
                                    value={password}
                                    onChange={handlePasswordChange}
                                    className='border border-[#ccc] rounded-lg p-3 text-15 outline-none w-full my-2'
                                />
                                {passwordError && <p className=" text-red-500 text-14 font-inter">{passwordError}</p>}
                            </div>

                            <div className='flex flex-col w-[30%]'>
                                <input
                                    type='password'
                                    placeholder='Confirm Password'
                                    value={confirmPassword}
                                    onChange={handleConfirmPasswordChange}
                                    className='border border-[#ccc] rounded-lg p-3 text-15 w-full outline-none my-2'
                                />
                                {confirmPasswordError && <p className=" text-red-500 text-14 font-inter">{confirmPasswordError}</p>}
                            </div>

                        </div>

                        <div className='flex flex-row w-full justify-center items-center gap-5 mt-2'>
                            {/* Gender Radio buttons */}
                            <div className='flex flex-row justify-start items-center gap-5 w-[30%]'>
                                <div className='flex flex-row items-center'>
                                    <input
                                        type='radio'
                                        value='Male'
                                        checked={gender.toLowerCase() === 'male'}
                                        onChange={handleGenderChange}
                                        name='gender'
                                        className='mr-1 w-5 h-5'
                                    /> Male
                                </div>
                                <div className='flex flex-row items-center'>
                                    <input
                                        type='radio'
                                        value='Female'
                                        checked={gender.toLowerCase() === 'female'}
                                        onChange={handleGenderChange}
                                        name='gender'
                                        className='mr-1 w-5 h-5'
                                    /> Female
                                </div>
                                <div className='flex flex-row items-center'>
                                    <input
                                        type='radio'
                                        value='Other'
                                        checked={gender.toLowerCase() === 'other'}
                                        onChange={handleGenderChange}
                                        name='gender'
                                        className='mr-1 w-5 h-5'
                                    /> Other
                                </div>
                                {genderError && <p className=" text-red-500 text-14 font-inter">{genderError}</p>}

                            </div>

                            <div className='relative w-[30%]'>
                                {/* Selected country display */}
                                <div className='border border-[#ccc] rounded-lg p-3 flex items-center justify-between cursor-pointer' onClick={handleToggleDropdown}>
                                    <div className='flex items-center'>
                                        <img src={selectedCountry.svg} alt={`Flag of ${selectedCountry.name}`} className="w-8 h-6 mr-2" />
                                        <span className='text-15'>{selectedCountry.name}</span>
                                    </div>
                                    <span className={`transform transition-transform ${isOpen ? 'rotate-180' : 'rotate-0'}`}>
                                        <MdOutlineKeyboardArrowDown size={20} />
                                    </span>
                                </div>
                                {countryError && <p className=" text-red-500 text-14 font-inter">{countryError}</p>}


                                {/* Dropdown options */}
                                {isOpen && (
                                    <div className='absolute z-10 border border-[#ccc] h-72 top-11 overflow-auto rounded-lg mt-1 bg-white w-full'>

                                        {/* Search Input */}
                                        <input
                                            type="text"
                                            placeholder="Search..."
                                            value={searchTerm}
                                            onChange={(e) => setSearchTerm(e.target.value)}
                                            className="p-2 border-b border-[#ccc] w-full outline-none"
                                        />
                                        {filteredCountries.map((country) => (
                                            <div
                                                key={country.id}
                                                className='flex items-center p-3 cursor-pointer hover:bg-gray-100'
                                                onClick={() => handleSelectCountry(country)}
                                            >
                                                <img src={country.svg} alt={`Flag of ${country.name}`} className="w-8 h-6 mr-2" />
                                                <span>{country.name}</span>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                        </div>

                        {/* Submit Button */}
                        {
                            isLoading ?
                                <button
                                    className={`bg-footerBackground flex flex-row justify-center cursor-pointer text-white w-[50%] rounded-full p-3 mt-8`}
                                >
                                    <RotatingLines
                                        visible={true}
                                        height="20"
                                        width="20"
                                        strokeWidth="5"
                                        strokeColor='white'
                                        animationDuration="0.75"
                                        ariaLabel="rotating-lines-loading"
                                        wrapperStyle={{}}
                                        wrapperClass=""
                                    />
                                </button>
                                :
                                <button
                                    className={`${isDisabled ? 'bg-gray-200 cursor-not-allowed text-black' : 'bg-footerBackground cursor-pointer text-white'} w-[50%] rounded-full p-3 mt-8`}
                                    onClick={handleSubmit}
                                    disabled={isDisabled}
                                >
                                    Create Account
                                </button>
                        }
                        {/* <button
                            className={`${isDisabled ? 'bg-gray-200 cursor-not-allowed text-black' : 'bg-footerBackground cursor-pointer text-white'} w-[50%] rounded-lg p-3 mt-8`}
                            onClick={handleSubmit}
                            disabled={isDisabled}
                        >
                            Create Account
                        </button> */}
                    </div>
                    <div className='cursor-pointer underline hover:text-footerBackground flex justify-center mt-5' onClick={() => navigate('/login')}> Already have account signIn?</div>

                </div>
            </div>
        </>
    );
};

export default Register;
