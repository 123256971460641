import React, { useState, useEffect } from 'react';

function DependentDropdowns() {
    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);
    const [pincodes, setPincodes] = useState([]);

    const [selectedCountry, setSelectedCountry] = useState('');
    const [selectedState, setSelectedState] = useState('');
    const [selectedCity, setSelectedCity] = useState('');
    const [selectedPincode, setSelectedPincode] = useState('');

    const [countyDropdown, setCountyDropdown] = useState(false);
    const [stateDropdown, setStateDropdown] = useState(false);
    const [cityDropdown, setCityDropdown] = useState(false);
    const [pincodeDropdown, setpincodeDropdown] = useState(false);

    const auth_token = "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7InVzZXJfZW1haWwiOiJhbHBpdHBldGhhbmlAZ21haWwuY29tIiwiYXBpX3Rva2VuIjoiTFZhWGQ4ekJrUXNpd1lFdDVVLVdPaUdCWVNwcUdrWFE5WmVkLWNYRGlBYUxVeG91UHNNM2gtUkdieUNqM0lpTE1QZyJ9LCJleHAiOjE3MzIzMzk5NDR9.Q6wfsnkPY5Nx9QyTtXllh6TPHXpyTJoCBjZAViUwavc";
    const headers = new Headers();
    headers.append('Authorization', auth_token);
    headers.append("Accept", "application/json");

    const optionObject = { headers: headers };

    const fetchData = async (url, options) => {
        try {
            const response = await fetch(url, options);
            const responseJSON = await response.json();
            return responseJSON;
        } catch (error) {
            console.log(error);
        }
    };

    // Fetch countries when component mounts
    useEffect(() => {
        const fetchCountries = async () => {
            try {
                const response = await fetchData('https://www.universal-tutorial.com/api/countries', optionObject);
                setCountries(response);
            } catch (error) {
                console.error('Error fetching countries:', error);
            }
        };
        fetchCountries();
    }, []);

    // UseEffect for debouncing fetching states when selectedCountry changes
    useEffect(() => {
        if (selectedCountry) {
            const timer = setTimeout(async () => {
                try {
                    const response = await fetchData(`https://www.universal-tutorial.com/api/states/${selectedCountry}`, optionObject);
                    setStates(response);
                } catch (error) {
                    console.error('Error fetching states:', error);
                }
            }, 500); // Delay of 500ms

            // Clean up the timer if selectedCountry changes before the timeout
            return () => clearTimeout(timer);
        }
    }, [selectedCountry]);

    // UseEffect for debouncing fetching cities when selectedState changes
    useEffect(() => {
        if (selectedState) {
            const timer = setTimeout(async () => {
                try {
                    const response = await fetchData(`https://www.universal-tutorial.com/api/cities/${selectedState}`, optionObject);
                    setCities(response);
                } catch (error) {
                    console.error('Error fetching cities:', error);
                }
            }, 500); // Delay of 500ms

            return () => clearTimeout(timer);
        }
    }, [selectedState]);

    // UseEffect for debouncing fetching pincodes when selectedCity changes
    useEffect(() => {
        if (selectedCity) {
            const timer = setTimeout(async () => {
                try {
                    const response = await fetchData(`https://api.example.com/pincodes?city=${selectedCity}`);
                    setPincodes(response);
                } catch (error) {
                    console.error('Error fetching pincodes:', error);
                }
            }, 500); // Delay of 500ms

            return () => clearTimeout(timer);
        }
    }, [selectedCity]);

    // Handlers for input changes (for triggering debounced fetch)
    const handleCountryChange = (e) => setSelectedCountry(e.target.value);
    const handleStateChange = (e) => setSelectedState(e.target.value);
    const handleCityChange = (e) => setSelectedCity(e.target.value);
    const handlePincodeChange = (e) => setSelectedPincode(e.target.value);
    return (
        <div className="max-w-3xl mx-auto p-6">
            {/* Country dropdown */}
            <div className="mb-4">
                {/* <label htmlFor="country" className="block text-sm font-medium text-gray-700">Select Country</label>
                <select
                    id="country"
                    name="country"
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                    onChange={(e) => setSelectedCountry(e.target.value)}
                    value={selectedCountry}
                >
                    <option value="">Select Country</option>
                    {countries?.map((country) => (
                        <option key={country.country_name} value={country.country_name}>{country.country_name}</option>
                    ))}
                </select> */}

                <label class=" font-inter ">Select Country:</label>
                <div class="relative mt-1" onClick={() => setCountyDropdown(!countyDropdown)} >
                    <input
                        type="text"
                        value={selectedCountry}
                        className="relative  border border-[#c0cece] font-poppins  outline-none w-full px-1.5 md:px-3 py-1 md:py-2  rounded-lg"
                        placeholder="Select Country"
                        onChange={handleCountryChange}
                        onBlur={() => setTimeout(() => setCountyDropdown(false), 200)} // Close dropdown on blur with a delay
                    />

                    {countyDropdown && (
                        <>
                            <div class="absolute z-10 bg-white border border-[#c0cece] rounded-lg w-full mt-0  ">
                                <ul class="max-h-[50vh] overflow-auto">
                                    {countries.map(item => (
                                        <li
                                            key={item.Items}
                                            className="cursor-pointer px-3 py-1 hover:bg-gray-100 rounded-lg"
                                            onClick={() => {
                                                setSelectedCountry(item.country_name);
                                                setCountyDropdown(false)
                                                // document.getElementById('metal-input').value = item.Items; // Update input
                                            }}
                                        >
                                            {item.country_name}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            {/* <div className='absolute z-[]'>
                                <option value="" className='text-12 bg:white text-black rounded-lg'>Select Shape</option>
                                {dropdown[1].map(item => <option value={`${item.Items}`} className='text-12 bg:white text-black'>{item.Items}</option>)}
                            </div> */}
                        </>
                    )}
                </div>
            </div>

            {/* State dropdown */}
            <div className="mb-4">
                {/* <label htmlFor="state" className="block text-sm font-medium text-gray-700">Select State</label>
                <select
                    id="state"
                    name="state"
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                    onChange={(e) => setSelectedState(e.target.value)}
                    value={selectedState}
                    disabled={!selectedCountry}
                >
                    <option value="">Select State</option>
                    {states?.map((state) => (
                        <option key={state.state_name} value={state.state_name}>{state.state_name}</option>
                    ))}
                </select> */}

                <label class=" font-inter ">Select State:</label>
                <div class="relative mt-1" onClick={() => setStateDropdown(!stateDropdown)} >
                    <input
                        type="text"
                        value={selectedState}
                        className="relative  border border-[#c0cece] font-poppins  outline-none w-full px-1.5 md:px-3 py-1 md:py-2  rounded-lg"
                        placeholder="Select State"
                        onChange={handleStateChange}
                        onBlur={() => setTimeout(() => setStateDropdown(false), 200)} // Close dropdown on blur with a delay
                    />

                    {stateDropdown && (
                        <>
                            <div class="absolute z-10 bg-white border border-[#c0cece] rounded-lg w-full mt-0  ">
                                <ul class="max-h-[50vh] overflow-auto">
                                    {states?.length > 0 ? states.map(item => (
                                        <li
                                            key={item.Items}
                                            className="cursor-pointer px-3 py-1 hover:bg-gray-100 rounded-lg"
                                            onClick={() => {
                                                setSelectedState(item.state_name);
                                                setStateDropdown(false)
                                                // document.getElementById('metal-input').value = item.Items; // Update input
                                            }}
                                        >
                                            {item.state_name}
                                        </li>
                                    )) : <li className='cursor-pointer px-3 py-1 rounded-lg'>No State Found for {selectedCountry}</li>}
                                </ul>
                            </div>
                            {/* <div className='absolute z-[]'>
                                <option value="" className='text-12 bg:white text-black rounded-lg'>Select Shape</option>
                                {dropdown[1].map(item => <option value={`${item.Items}`} className='text-12 bg:white text-black'>{item.Items}</option>)}
                            </div> */}
                        </>
                    )}
                </div>
            </div>

            {/* City dropdown */}
            <div className="mb-4">
                {/* <label htmlFor="city" className="block text-sm font-medium text-gray-700">Select City</label>
                <select
                    id="city"
                    name="city"
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                    onChange={(e) => setSelectedCity(e.target.value)}
                    value={selectedCity}
                    disabled={!selectedState}
                >
                    <option value="">Select City</option>
                    {cities?.map((city) => (
                        <option key={city.city_name} value={city.city_name}>{city.city_name}</option>
                    ))}
                </select> */}

                <label class=" font-inter ">Select City:</label>
                <div class="relative mt-1" onClick={() => setCityDropdown(!cityDropdown)} >
                    <input
                        type="text"
                        value={selectedCity}
                        className="relative  border border-[#c0cece] font-poppins  outline-none w-full px-1.5 md:px-3 py-1 md:py-2  rounded-lg"
                        placeholder="Select City"
                        onChange={handleCityChange}
                        onBlur={() => setTimeout(() => setCityDropdown(false), 200)} // Close dropdown on blur with a delay
                    />

                    {cityDropdown && (
                        <>
                            <div class="absolute z-10 bg-white border border-[#c0cece] rounded-lg w-full mt-0  ">
                                <ul class="max-h-[50vh] overflow-auto">
                                    {cities?.length > 0 ? cities.map(item => (
                                        <li
                                            key={item.Items}
                                            className="cursor-pointer px-3 py-1 hover:bg-gray-100 rounded-lg"
                                            onClick={() => {
                                                setSelectedCity(item.city_name);
                                                setCityDropdown(false)
                                                // document.getElementById('metal-input').value = item.Items; // Update input
                                            }}
                                        >
                                            {item.city_name}
                                        </li>
                                    )) : <li className='cursor-pointer px-3 py-1 rounded-lg'>No City Found for {selectedState}</li>}
                                </ul>
                            </div>
                            {/* <div className='absolute z-[]'>
                                <option value="" className='text-12 bg:white text-black rounded-lg'>Select Shape</option>
                                {dropdown[1].map(item => <option value={`${item.Items}`} className='text-12 bg:white text-black'>{item.Items}</option>)}
                            </div> */}
                        </>
                    )}
                </div>
            </div>

            {/* Pincode dropdown */}
            <div className="mb-4">
                {/* <label htmlFor="pincode" className="block text-sm font-medium text-gray-700">Select Pincode</label>
                <select
                    id="pincode"
                    name="pincode"
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                    onChange={(e) => setSelectedPincode(e.target.value)}
                    value={selectedPincode}
                    disabled={!selectedCity}
                >
                    <option value="">Select Pincode</option>
                    {pincodes?.map((pincode) => (
                        <option key={pincode.pincode} value={pincode.pincode}>{pincode.pincode}</option>
                    ))}
                </select> */}

                <label class=" font-inter ">Select Pin Code:</label>
                <div class="relative mt-1" onClick={() => setpincodeDropdown(!pincodeDropdown)} >
                    <input
                        type="text"
                        value={selectedPincode}
                        className="relative  border border-[#c0cece] font-poppins  outline-none w-full px-1.5 md:px-3 py-1 md:py-2  rounded-lg"
                        placeholder="Select Pin Code"
                        onChange={handlePincodeChange}
                        onBlur={() => setTimeout(() => setpincodeDropdown(false), 200)} // Close dropdown on blur with a delay
                    />

                    {pincodeDropdown && (
                        <>
                            <div class="absolute z-10 bg-white border border-[#c0cece] rounded-lg w-full mt-0  ">
                                <ul class="max-h-[50vh] overflow-auto">
                                    {pincodes?.length > 0 ? pincodes.map(item => (
                                        <li
                                            key={item.Items}
                                            className="cursor-pointer px-3 py-1 hover:bg-gray-100 rounded-lg"
                                            onClick={() => {
                                                setSelectedPincode(item.pincode);
                                                setpincodeDropdown(false)
                                                // document.getElementById('metal-input').value = item.Items; // Update input
                                            }}
                                        >
                                            {item.pincode}
                                        </li>
                                    )) : <li className='cursor-pointer px-3 py-1 rounded-lg'>No Pin Code Found for {selectedState}</li>}
                                </ul>
                            </div>
                            {/* <div className='absolute z-[]'>
                                <option value="" className='text-12 bg:white text-black rounded-lg'>Select Shape</option>
                                {dropdown[1].map(item => <option value={`${item.Items}`} className='text-12 bg:white text-black'>{item.Items}</option>)}
                            </div> */}
                        </>
                    )}
                </div>
            </div>
        </div >
    );
}

export default DependentDropdowns;
