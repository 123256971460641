import { createSlice } from "@reduxjs/toolkit";

export const sizeData = createSlice({
    name: 'size',
    initialState: {
        size: {},
        selectedSize: ''
    },
    reducers: {
        addSizeData: (state, action) => {
            const { data } = action.payload
            state.size.data = data;
        },
        addSelectedSize: (state, action) => {
            state.selectedSize = action.payload;
        }
    }
}
)

export const { addSizeData, addSelectedSize } = sizeData.actions
export default sizeData.reducer