import { BlobServiceClient } from "@azure/storage-blob";
import { containerName, sasToken, upload_azure_url } from "./azureInfo";


const addImageInAzure = async ({ image, blobName, directoryName }) => {
    try {
        // Create a BlobServiceClient
        const blobServiceClient = new BlobServiceClient(
            `${upload_azure_url}?${sasToken}`
        );

        // Get a reference to the container
        const containerClient = blobServiceClient.getContainerClient(containerName);

        const fetchImageAsBlob = async (url) => {
            const response = await fetch(url);
            if (!response.ok) {
                throw new Error(`Failed to fetch image from URL: ${url}`);
            }
            return await response.blob();
        };

        // Get a block blob client
        const name = `${directoryName}/${blobName}`;
        const blockBlobClient = containerClient.getBlockBlobClient(name);

        const imageBlob = typeof image === 'string' ? await fetchImageAsBlob(image) : image;
        // Define the blob options  
        const options = {
            blobHTTPHeaders: {
                blobContentType: imageBlob.type, // Set Content-Type
            },
        };
        // Upload the file
        const response = await blockBlobClient.upload(imageBlob, imageBlob.size, options);

        if (response.errorCode === undefined) {
            // setLoader(false)
            return true
        }

        // setLoader(false)
        return false

    } catch (error) {
        // setLoader(false)
        console.log(error, 'Error to upload image in azure')
    }

}

export default addImageInAzure;