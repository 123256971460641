import React from 'react'

const Button = ({ btn1, btn2 }) => {
    return (
        <div className='flex flex-col  md:flex-row gap-4 '>
            <div className={`w-[100%] sm:w-[100%] md:w-[50%] md:mx-auto lg:mx-0 bg-[#183E40] text-center  text-white hover:bg-[#818f92] p-3 lg:px-1 py-3`}>
                <span className='text-13 font-inter text-center'>
                    {btn1}
                </span>
            </div>

            {btn2 && <div className={`w-[100%] md:w-[50%] border border-1 border-black text-center  text-black hover:bg-black hover:text-white  p-2`}>
                <span className='text-13 font-inter text-center'>
                    {btn2}
                </span>
            </div>}
        </div>
    )
}

export default Button