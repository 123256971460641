import React, { Fragment, useState } from 'react'
import { FaChevronDown, FaChevronUp } from 'react-icons/fa'
import { useLocation } from 'react-router-dom'
import Banner from '../../components/Banner'
import Offer from '../../components/Offer'
import Review from '../../components/Review'
import { Design_Your_Own_Jewelry, Jewelry_Banner, Jewelry_Collection, Jewelry_Education, Jewelry_Information, Jewelry_Review, Jewelry_With_Meaning, Most_Popolar_Jewelry, OFFER_DATA, Shop_Jewelry_By_Category } from '../../helpers/jsonData'
import FlexView from '../../components/FlexView'

const Jewelry = () => {
    const location = useLocation()
    console.log(location)
    const [isDropdown, setIsDropdown] = useState(false)
    return (
        <div>
            <div className=' md:pt-3 pt-2 md:pl-8 pl-5 text-start'>
                <span className='text-12 text-lightBlack font-inter'>
                    Home / Jewelry
                </span>
            </div>

            <Banner data={Jewelry_Banner} />


            <div className=" mx-auto w-[95%] 2xl:w-[70%] mt-16 ">
                <h3 className='text-30 font-inter mb-5'>Shop Jewelry By Category</h3>
                <FlexView data={Shop_Jewelry_By_Category} />
            </div>

            <div className='w-[95%] 2xl:w-[70%] mx-auto'>
                <Offer data={OFFER_DATA} />
            </div>

            <div className=" mx-auto w-[95%] 2xl:w-[70%]  mt-16 ">
                <h3 className='text-30 font-inter mb-5 '>Most Popular Jewelry</h3>
                <FlexView data={Most_Popolar_Jewelry} className={`flex flex-wrap flex-row gap-[2%] lg:gap-4 justify-center`} imageStyle={true} />
            </div>


            <div className=' mx-auto w-[95%] 2xl:w-[70%] mt-10 flex justify-between flex-col md:flex-row flex-wrap gap-3'>
                {Design_Your_Own_Jewelry.map((data, index) => {
                    return (
                        <div key={index} className='md:w-[49%] w-full my-5'>
                            <img src={data.image} alt="Kyrah Star" className='w-full' />
                            <div >
                                <p className='text-34 my-1'>{data.title}</p>
                                <p>{data.subtitle}</p>
                                {data.buttons.map((button, index) => {
                                    return (
                                        <div key={index} className={`lg:w-1/2 w-full mt-3 px-6 relative text-center border-[1px] border-black border-solid  p-3 hover:bg-black hover:text-white bg-white text-black-900 ' ${isDropdown && button.type === 'dropdown' ? 'bg-black text-white' : ''} `} onClick={button.type === 'dropdown' ? () => setIsDropdown(!isDropdown) : undefined}>
                                            <span className={`text-13 font-inter   hover:text-white   ${button.type === 'dropdown' ? 'flex justify-end md:gap-[28%] lg:gap-[13%] gap-[30%] sm:gap-[38%]' : ''}`} >
                                                <span className='truncate'>{button.text}</span>
                                                {button.type === 'dropdown' ? isDropdown ?
                                                    <FaChevronUp className='ml-1   lg:ml-1' />
                                                    :
                                                    <FaChevronDown className='ml-1  lg:ml-1' /> : ''}
                                                {button.type === 'dropdown' ? isDropdown && (
                                                    <div className='absolute text-black right-0 mt-2 w-full bg-white text-left border top-9 border-[#e2e2e2] shadow-lg z-10'>
                                                        <ul className='py-2'>
                                                            <li className='px-4 py-2 hover:bg-gray-100 cursor-pointer'>Naturel Diamond necklace</li>
                                                            <li className='px-4 py-2 hover:bg-gray-100 cursor-pointer'>Lab Diamond necklace</li>
                                                        </ul>
                                                    </div>
                                                ) : ''}
                                            </span>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    )
                })}
            </div>

            <div className=" mx-auto w-[95%] 2xl:w-[70%] mt-16 ">
                <h3 className='text-30 font-inter mb-5'>Jewelry with Meaning</h3>
                <FlexView data={Jewelry_With_Meaning} />
            </div>


            <div className=" mx-auto w-[95%] 2xl:w-[70%] mt-16 ">
                <h3 className='text-30 font-inter mb-5'>Jewelry Collections</h3>
                <FlexView data={Jewelry_Collection} divStyle={true} />
            </div>

            <div className=" mx-auto w-[95%] 2xl:w-[70%] p-10 sm:p-14 mt-16 bg-[url('https://image.brilliantearth.com/cdn-cgi/image/width=1440,height=400,quality=100,format=auto/https://www.brilliantearth.com/bee2-ui/img/mission/sustainability/SustainabilityHeroDesktop.jpg')] bg-cover">
                {
                    Jewelry_Information.map((data, index) => {
                        return (
                            <Fragment key={index}>
                                <div className='text-center text-white'>
                                    <p className='font-bold text-30 my-2'>{data.title}</p>
                                    <p className='w-full sm:w-[50%] mx-auto'>{data.subtitle}</p>
                                </div>
                                <div className={`md:w-1/5 w-1/3 mx-auto mt-6 px-6 justify-center  flex flex-row p-3 hover:bg-black hover:text-white bg-white text-black-900 ' `}>
                                    <span className={`text-13 font-inter text-center  hover:text-white truncate`}>
                                        {data.buttons[0]}
                                    </span>
                                </div>
                            </Fragment>
                        )
                    })
                }
            </div>

            <div className=" mx-auto w-[95%] 2xl:w-[70%] mt-16 " id='a'>
                <Review data={Jewelry_Review} title={'Jewelry'} />
            </div>

            <div className=" mx-auto w-[95%] 2xl:w-[70%] mt-20 " id='a'>
                <h3 className='text-30 w-full font-inter mb-5'>Jewelry Education</h3>
                <FlexView data={Jewelry_Education} />
            </div>

            <div className=" mx-auto w-[80%] 2xl:w-[60%] mt-32 ">
                <h3 className='text-30 w-full text-center font-EB mb-5'>Ethically-Sourced Gemstones</h3>
                <p className='mt-5 text-[#a0a0a0]-500'>Gemstone rings are an excellent alternative to more traditional <span className='underline'>diamond</span> styles. <span className='underline'>Morganite,</span> <span className='underline'>emerald,</span> ruby, aquamarine, moissanite, sapphire, and amethyst are all fabulous gemstones that are the perfect choice for someone looking for a look with a pop of color. Gemstone engagement rings are a special way to celebrate your unique, anything-but-colorless love story – or simply as a colorful gift for yourself.</p>
                <p className='mt-5 text-[#a0a0a0]-500'>Once you have selected the perfect semi precious gemstone, it is time to find a setting to match it. Our curated selection of designs ranges from nature-inspired to ultra-modern. Plain bands give way to luxe diamond-accented styles, while other gemstone ring settings are unique enough to catch the eye. Choose a setting for your stone that will complement and enhance its beauty and color, creating the perfect semi precious gemstone ring.</p>
            </div>

        </div>
    )
}

export default Jewelry
