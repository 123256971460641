import React, { useEffect, useRef, useState } from 'react';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { MdClose } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import { decryptData } from '../../components/decrypt';
import { Images } from '../../components/Image';
import ToastNotification, { showErrorToast, showSuccessToast } from '../../components/ToastNotification';
import { Shape_Images } from '../../helpers/jsonData';
import { axiosInstance } from '../../redux/api';
import { addCartListCount } from '../../redux/slices/cartList.slice';
import { addTableData, error, setTableDataLoading } from '../../redux/slices/tableData.slice';
import { encryptURL } from '../../utils/encrypt';

const tableHeading = ['Shape', 'Price', 'Carat', 'Cut', 'Color', 'Clarity', 'Compare']

const ShowDiamondsTable = ({ filterData, setFilterData, state, setOpen, skuno, variantId, Shape, shapeData, shape, getDiamond, setGetDiamond, getShapeDiamond, setGetShapeDiamond }) => {

    const [isDropdown, setIsDropdown] = useState(false);
    const [expandedRowId, setExpandedRowId] = useState([]);
    const [additionalDetailId, setAdditionalDwetailId] = useState([]);
    const [dropdownIndex, setDropdownIndex] = useState([]);
    const [compareProducts, setCompareProducts] = useState([]);
    const [sortBy, setSortBy] = useState('')
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const data = useSelector((state) => state.tableData)
    const [tableData, setTableData] = useState(data.tableData?.data);

    const [totalDataCount, setTotalDataCount] = useState(0)
    const [page, setPage] = useState(1);
    const tableRef = useRef(null);
    const [pageTracked, setPageTracked] = useState(false);
    const shape_code = Shape ? `-${Shape}` : ""

    const { pathname } = useLocation()
    const userInfo = JSON.parse(localStorage.getItem('user-info'))
    // const currency = JSON.parse(localStorage.getItem('user-info'))?.CURRENCY ?? 'INR'
    const currency = userInfo?.CURRENCY ?? 'INR'

    const fetchTableData = async () => {
        try {
            dispatch(setTableDataLoading())
            const payload = { ...filterData, CURR: currency }
            const response = await axiosInstance.post('data/diamond', payload)
            const isDecryption = process.env.REACT_APP_IS_DECRYPT
            dispatch(addTableData(isDecryption == 'true' ? decryptData(response.data).data : response.data.data.data))
            // dispatch(addTableData(response.data.data))
            if (response) {
                setPageTracked(false)
            }
            if (pageTracked) {
                setTableData((prevData) => [
                    ...prevData,
                    ...(isDecryption === 'true'
                        ? Array.isArray(decryptData(response.data).data.data)
                            ? decryptData(response.data).data.data
                            : []
                        : Array.isArray(response.data.data.data.data)
                            ? response.data.data.data.data
                            : [])
                ]);
                // setTableData((prevData) => [...prevData, ...response.data.data.data]);
            } else {
                setTableData(isDecryption == 'true' ? decryptData(response.data).data.data : response.data.data.data.data);
                setTotalDataCount(isDecryption == 'true' ? decryptData(response.data).data.totalRecords : response.data.data.data.totalRecords)
                // setTableData(response.data.data.data);
                // setTotalDataCount(response.data.data.totalRecords)
            }
        } catch (e) {
            dispatch(error())
            console.log(e)
        }
    }

    useEffect(() => {
        if (shapeData || (getDiamond && getShapeDiamond)) {
            fetchTableData()
            return
        } else if (getDiamond && getShapeDiamond) {
            fetchTableData()
        }
        // if (getDiamond) {
        //     fetchTableData()
        //     setGetDiamond(false)
        // }
    }, [filterData, shape])

    // useEffect(() => {
    //     setFilterData({
    //         ...filterData, 'IS_FANCY': pathname.split('/')[2] === 'colored-diamonds' || pathname.split('/')[2] === 'lab-colored-diamonds' ? 'yes' : 'no', 'IS_LABGROWN': pathname.split('/')[2] === 'search-natural-diamonds' ? 'no' : 'yes', "S_CODE": Shape_Code[pathname.split('/')[2].charAt(0).toUpperCase() + pathname.split("/")[2]?.slice(1)] || ''
    //     })
    // }, [pathname])

    useEffect(() => {
        if (page > 1) {
            setFilterData((prevFilterData) => ({
                ...prevFilterData,
                PAGE_NUMBER: page,  // Update PAGE_NUMBER when page changes
            }));
        }
    }, [page]);

    const handleRowClick = (id) => {
        const index = expandedRowId.findIndex((data) => data === id)
        setExpandedRowId(expandedRowId[index] === id ? expandedRowId.filter((data) => data !== id) : [...expandedRowId, id]);
        setAdditionalDwetailId(additionalDetailId.filter((data) => data !== id))
    };
    const handleAdditionalDetailClick = (id) => {
        const index = additionalDetailId.findIndex((data) => data === id)
        setAdditionalDwetailId(additionalDetailId[index] === id ? additionalDetailId.filter((data) => data !== id) : [...additionalDetailId, id]);
    };
    const handleDropdown = (id) => {
        const index = dropdownIndex.findIndex((data) => data === id)
        setDropdownIndex(dropdownIndex[index] === id ? dropdownIndex.filter((data) => data !== id) : [...dropdownIndex, id]);
    }
    const handleCompareProduct = (e, id) => {
        e.stopPropagation()
        const index = compareProducts.findIndex((data) => data === id)
        setCompareProducts(compareProducts[index] === id ? compareProducts.filter((data) => data !== id) : [...compareProducts, id]);
    }
    const sortData = ({ heading }) => {
        setSortBy(heading);
        let sortedData;
        if (['Price', 'Carat'].includes(heading)) {
            if (heading === sortBy) {
                sortedData = [...tableData].sort((a, b) => b[heading] - a[heading]);
                setSortBy('')
            } else {
                sortedData = [...tableData].sort((a, b) => a[heading] - b[heading]);
            }
        } else {
            if (heading === sortBy) {
                sortedData = [...tableData].sort((a, b) => b?.[heading].localeCompare(a?.[heading]));
                setSortBy('')
            } else {
                sortedData = [...tableData].sort((a, b) => a?.[heading].localeCompare(b?.[heading]));
            }
        }
        setTableData(sortedData);
    }
    const compateProducts = () => {
        setTableData(tableData.filter(
            (data) => compareProducts.includes(data.StockID)
        ))
    }

    const toggleDropdown = () => {
        setIsDropdown(true);
    };

    useEffect(() => {

        if (pageTracked === true) {
            setPage((prevPage) => prevPage + 1);
        }
    }, [pageTracked])

    useEffect(() => {
        const handleScroll = () => {
            const container = tableRef.current;
            if (container) {
                const { scrollTop, scrollHeight, clientHeight } = container;
                // Check if the user has scrolled to the bottom
                if (scrollTop + clientHeight >= scrollHeight - 1) {
                    setPageTracked(true)
                } else {
                }
            }
        };

        const container = tableRef.current;
        if (container) {
            container.addEventListener('scroll', handleScroll);
        }

        return () => {
            if (container) {
                container.removeEventListener('scroll', handleScroll);
            }
        };
    }, []);

    const changeSortBy = (value) => {
        setSortBy(value)
        setFilterData({ ...filterData, ORDER_BY: value, PAGE_NUMBER: 1 })
        // setPageTracked(false)
        // setPage(1)
        setIsDropdown(false)
    }

    const showDrawer = () => {
        setOpen(true);
    };

    const addToCart = async (data) => {
        console.log(data, 'data')
        if (userInfo?.USERID) {
            // const shape = shapes.Shape.find(data => data.Items === diamondDetails?.[0]?.Shape)
            let payload
            payload = {
                "USERID": userInfo?.USERID,
                "SKUNO": '',
                "PRD_VARIANT_ID": 0,
                "S_CODE": '',
                "STOCK_ID": data?.StockID,
                "IS_ONLY_DIAMOND": true,
                "IS_REMOVE": false,
                "QUANTITY": 1,
            }

            try {
                const response = await axiosInstance.post('cart/add', payload);
                // const jsonResponse = await axiosInstance.post('wishlist/add', payload);
                // const isDecryption = process.env.REACT_APP_IS_DECRYPT
                // const response = Decryption == 'true' ? decryptData(jsonResponse.data) : jsonResponse.data.data
                if (response.data.success) {
                    showSuccessToast(response.data.message)
                    dispatch(addCartListCount(response?.data?.data?.[0]?.[0]?.CART_COUNT))
                    navigate('/shop/cart',)
                }
            } catch (e) {
                showErrorToast(e.response.data.message)
                // setLoading(false)
            }
        } else {
            navigate('/login')
        }
    }

    return (
        <div className='w-[100%]'>
            <ToastNotification />
            <div className=" flex justify-between lg:justify-end relative">
                <div className='lg:hidden'>
                    <button onClick={showDrawer} className='bg-footerBackground rounded-full flex gap-1 flex-row justify-around items-center text-12 md:text-14   text-white font-inter px-3 py-1 md:px-4 md:py-2 lg:px-5 '>
                        Filters
                        <img
                            src='https://image.brilliantearth.com/static/img/icon/svg/filters-white.svg'
                            alt='filter'
                            className='w-5 md:w-6'
                        />
                    </button>
                </div>
                <div onMouseEnter={toggleDropdown} onMouseLeave={() => setIsDropdown(false)} className='border-footerBackground border rounded-full flex flex-row justify-between cursor-pointer items-center text-12 md:text-14 px-3 py-1 md:px-4 md:py-2 lg:px-5 '>
                    <span className='font-poppins5'>
                        Sort By: {filterData.ORDER_BY === 'PRICE_ASC' ? 'Price (Low to High)' : filterData.ORDER_BY === 'PRICE_DESC' ? 'Price (High to Low)' : filterData.ORDER_BY === 'CARAT_ASC' ? 'Carat (Low to High)' : filterData.ORDER_BY === 'CARAT_DESC' ? 'Carat (High to Low)' : 'Most Popular'}
                    </span>

                    {
                        isDropdown ?
                            <FaChevronUp className='ml-4' />
                            :
                            <FaChevronDown className='ml-4' />
                    }

                    {isDropdown && (
                        <div className='absolute right-0 mt-2 text-12 md:text-14  py-[1px]  md:py-2  bg-white border top-5 md:top-7  border-footerBackground rounded-2xl shadow-lg z-[6]'>
                            <ul className='py-[2px]  ' >
                                {[{ data: 'Price (Low to High)', value: 'PRICE_ASC' }, { data: 'Price (HIgh to Low)', value: 'PRICE_DESC' }, { data: 'Carat (Low to High)', value: 'CARAT_ASC' }, { data: 'Carat (High to Low)', value: 'CARAT_DESC' }].map((value, index) => <li key={index} className='pl-[13px] pr-20 md:pl-4 md:pr-[100px] py-[5px] hover:bg-gray-100 cursor-pointer' onClick={() => changeSortBy(value.value)}>{value.data}</li>)}
                            </ul>
                        </div>
                    )}
                </div>

                {/* <div className='lg:mr-5 border px-3 cursor-pointer' onClick={() => setFilterData(defaultFilterData)}>Reset Filter</div> */}
            </div>
            <div className=" mx-auto mt-3 flex justify-between w-[100%]">
                {/* <div onClick={() => setTableData(data.tableData?.data)} className='mx-auto border w-[100%] py-1 text-14 font-medium text-center cursor-pointer'>All Product ({totalDataCount})</div> */}
                <div onClick={() => setTableData(data?.tableData?.data)} className='mx-auto border w-[100%] py-1 text-tableHeahing font-medium text-center cursor-pointer'>All Product ({data?.tableData?.data?.length})</div>
                <div onClick={() => compateProducts()} className='mx-auto border w-[100%] py-1 text-tableHeahing font-medium text-center cursor-pointer'>Compare ({compareProducts.length})</div>
            </div>
            <div className=" mx-auto max-h-[800px]  overflow-auto scrollbar-hide border border-collaps" ref={tableRef}>
                <table className=" table-fixed w-full border">
                    <thead className="w-full  ">
                        <tr className="text-13 md:text-14 lg:text-tableHeahing bg-[#e8e8e8] text-[#111111]  sticky top-[-1px] z-[5]  ">
                            {tableHeading.map((heading, index) => {
                                return <th key={index} className='py-1'><span onClick={() => index !== tableHeading.length - 1 && sortData({ heading })} className='cursor-pointer flex items-center justify-center'>{heading} <div className=" ml-1">{index !== tableHeading.length - 1 ? sortBy === heading ? <FaChevronUp size={13} className='mr-2 hidden lg:block' /> : <FaChevronDown size={13} className='mr-2 hidden lg:block' /> : ''}</div></span></th>
                            })}
                        </tr>
                    </thead>
                    <tbody className="text-center relative h-auto">
                        {/* data.tableData?.loading ? <div className=''> <ClipLoader className='absolute top-[10%] left-[50%] mt-[10%]' /></div> : */}
                        {data.tableData?.loading && !pageTracked ? <div className='absolute flex flex-row justify-center w-full'> <ClipLoader className='my-[3%]' /></div> :
                            tableData?.map((row, index) => {
                                const isLastElement = index === tableData.length - 1;
                                return (
                                    <React.Fragment key={index}>
                                        <tr
                                            className={`text-11 md:text-tableText border-b text-[#2c2c2c]   hover:bg-[#e8e8e8] hover:text-black cursor-pointer ${expandedRowId?.includes(row?.StockID) ? 'bg-[#e8e8e8] hover:bg-[#e8e8e8] ' : ''} `}
                                            onClick={() => handleRowClick(row?.StockID)}
                                        >
                                            <td className="flex justify-center lg:justify-start  lg:ml-[15%]  my-3">
                                                <img
                                                    src={Shape_Images[row?.Shape]}
                                                    className="w-6 h-6 mr-2 hover:text-white"
                                                    alt="kyrah star"
                                                />
                                                <span className='hidden lg:block capitalize'>{row?.Shape}</span>
                                            </td>
                                            <td>{row?.Price?.toLocaleString()}{row?.CURR_SYMBOL}</td>
                                            <td>{row?.Carat}</td>
                                            <td>{row?.Cut}</td>
                                            <td>{row?.Color}</td>
                                            <td><div className='flex justify-end items-center'>{row?.Clarity} <div className="  ml-[15%] md:ml-[20%] lg:ml-[26%] sm:pr-2">
                                                {expandedRowId.includes(row?.StockID) ? <FaChevronUp /> : <FaChevronDown />}
                                            </div></div></td>
                                            <td onClick={(e) => handleCompareProduct(e, row?.StockID)} ><input type='checkbox' className='cursor-pointer' onChange={() => console.log('click')} checked={compareProducts?.includes(row?.StockID)} /></td>
                                        </tr>
                                        {expandedRowId.includes(row?.StockID) ?
                                            <div className='w-[700%]  border-b'>
                                                {console.log(row)}
                                                <div className='flex lg:flex-row flex-col text-left relative'>
                                                    <MdClose size={20} className='absolute right-1 cursor-pointer hidden lg:block' onClick={() => handleRowClick(row?.StockID)} />
                                                    {/* <img src={row?.VideoURL} className='lg:w-[25%] lg:h-[25%] w-[40%] h-[40%] mx-auto border my-3' alt='Kyrah Star' /> */}
                                                    <img
                                                        src={row?.VideoURL ?? Images.notFound}
                                                        className='lg:w-[25%] lg:h-[25%] w-[40%] h-[40%] mx-auto border my-3 object-cover'
                                                        alt='Kyrah Star'
                                                    />
                                                    {/* <video src={row?.VideoURL} autoPlay="true" /> */}
                                                    <div className='w-full'>
                                                        <div className='flex w-full '>
                                                            <div className='flex flex-col lg:flex-row ml-2 text-16  w-full'>
                                                                <div className='w-[100%] lg:w-[75%]'>
                                                                    <p className=' text-moreTableText sm:text-16 mb-1 lg:mb-0 lg:text-16  cursor-pointer flex items-center gap-2'><p className='w-[50%]'>{row?.Report} {row?.Carat} Carat {row?.Shape} {row?.Origin} </p> <p className='md:text-16 text-moreTableText lg:text-16 text-[#2c2c2c] '>Price : {row?.Price?.toLocaleString()}{row?.CURR_SYMBOL}</p></p>
                                                                    <div className='flex gap-2 py-1 lg:py-2 border-b '>
                                                                        <p className='text-12 sm:text-14 lg:text-moreTableText mb-1  w-[50%]'>Color : {row?.Color}</p>
                                                                        <p className='text-12 sm:text-14 lg:text-moreTableText mb-1 '>Clarity : {row?.Clarity}</p>
                                                                    </div>
                                                                    <div className='flex gap-2 py-1 lg:py-2 border-b'>
                                                                        <p className='text-12 sm:text-14 lg:text-moreTableText mb-1  w-[50%]'>Cut : {row?.Cut}</p>
                                                                        <p className='text-12 sm:text-14 lg:text-moreTableText mb-1 '>Polish : {row['Pol']}</p>
                                                                    </div>
                                                                    <div className='flex gap-2 py-1 lg:py-2 border-b'>
                                                                        <p className='text-12 sm:text-14 lg:text-moreTableText mb-1  w-[50%]'>Symmetry : {row['Sym']}</p>
                                                                        <p className='text-12 sm:text-14 lg:text-moreTableText mb-1 '>Fluor : {row['Floro']}</p>
                                                                    </div>
                                                                    <div className='flex gap-2 py-1 lg:py-2 border-b'>
                                                                        <p className='text-12 sm:text-14 lg:text-moreTableText mb-1  w-[50%]'>Measurements : {row['Measurement']}</p>
                                                                        <p className='text-12 sm:text-14 lg:text-moreTableText mb-1 '>L:W : {row['LW']}</p>
                                                                    </div>
                                                                    <div className='flex gap-2 py-1 lg:py-2 border-b'>
                                                                        <p className='text-12 sm:text-14 lg:text-moreTableText mb-1  w-[50%]'>Table : {row['Tab']}%</p>
                                                                        <p className='text-12 sm:text-14 lg:text-moreTableText mb-1 '>Depth : {row['Dep']}%</p>
                                                                    </div>
                                                                    <div className='flex gap-2 py-1 lg:py-2 border-b'>
                                                                        <p className='text-12 sm:text-14 lg:text-moreTableText mb-1  w-[50%]'>Culet : {row['Culet']}</p>
                                                                        <p className='text-12 sm:text-14 lg:text-moreTableText mb-1 '>Girdle : {row['Girdle']}</p>
                                                                    </div>
                                                                </div>
                                                                <div className='w-[100%] lg:w-[25%]  lg:mt-5 mt-2 '>
                                                                    <div
                                                                        className={`my-1 rounded-full relative w-[50%] lg:w-full mx-auto text-center border-[1px] border-black border-solid ${dropdownIndex.includes(row?.StockID) ? 'bg-black text-white' : ''}     text-black-900 ' `}
                                                                        onClick={() => handleDropdown(row?.StockID)}
                                                                    >
                                                                        {
                                                                            (skuno && variantId?.length < 3) ?
                                                                                <Link to={skuno ? `/rings/vd/${encryptURL(row?.StockID)}/${skuno}-${variantId}${shape_code}` : `/rings/vd/${encryptURL(row?.StockID)}`} className="w-full text-center cursor-pointer ">
                                                                                    <p className={`text-moreTableText rounded-full font-inter flex text-center justify-center hover:bg-black hover:text-white p-1.5 lg:p-3`}>
                                                                                        {/* <span>Select Diamond</span> */}
                                                                                        Select Diamond
                                                                                    </p>
                                                                                </Link>
                                                                                :
                                                                                <span className={`text-13 font-inter flex  p-1.5 lg:p-3  ${state ? 'justify-center' : 'justify-between'}`}>
                                                                                    <div className='cursor-pointer w-full flex justify-between items-center'>
                                                                                        <span>Select Diamond</span>
                                                                                        {!state && <div className="">{dropdownIndex.includes(row?.StockID) ? <FaChevronUp /> : <FaChevronDown />}</div>}
                                                                                    </div>
                                                                                    {dropdownIndex.includes(row?.StockID) && !state && (
                                                                                        <div className="absolute text-black rounded-2xl right-0 mt-2 w-full bg-white text-left  top-5 lg:top-8 px-1 border-[1px] border-footerBackground shadow-lg z-10">
                                                                                            <ul className="py-2">
                                                                                                {
                                                                                                    (skuno && variantId?.length < 3 && variantId) ?
                                                                                                        <>

                                                                                                        </>
                                                                                                        :
                                                                                                        <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer hidden"
                                                                                                            onClick={() => navigate(`/engagement-rings/${row?.Shape.toLowerCase()}/${encryptURL(row.StockID)}`)}
                                                                                                        // onClick={() => navigate(skuno ? `/rings/vd/${row.StockID}/${skuno}-${variantId}${shape_code}` : `/rings/vd/${row.StockID}`)}
                                                                                                        >
                                                                                                            Add to Ring
                                                                                                        </li>
                                                                                                }
                                                                                                <div
                                                                                                    // to={skuno ? `/rings/vd/${row.StockID}/${skuno}-${variantId}${shape_code}` : `/rings/vd/${row.StockID}`}
                                                                                                    // to={'/shop/cart'}
                                                                                                    // onClick={() => addToCart(row)}
                                                                                                    onClick={() => navigate(skuno ? `/rings/vd/${encryptURL(row.StockID)}/${skuno}-${variantId}${shape_code}` : `/rings/vd/${encryptURL(row.StockID)}`)}
                                                                                                >
                                                                                                    <li className=" px-4 py-2 hover:bg-gray-100 cursor-pointer">
                                                                                                        Add to Bag
                                                                                                    </li>
                                                                                                </div>
                                                                                            </ul>
                                                                                        </div>
                                                                                    )}
                                                                                </span>
                                                                        }
                                                                        {/* <span className={`text-13 font-inter flex   ${state ? 'justify-center' : 'justify-between'}`}>
                                                                            <span>Select Diamond</span>
                                                                            {!state && <div className="mt-1">{dropdownIndex.includes(row.StockID) ? <FaChevronUp /> : <FaChevronDown />}</div>}
                                                                            {dropdownIndex.includes(row.StockID) && !state && (
                                                                                <div className="absolute text-black right-0 mt-2 w-full bg-white text-left border top-9 border-[#e2e2e2] shadow-lg z-10">
                                                                                    <ul className="py-2">
                                                                                        {
                                                                                            (skuno && variantId) ?
                                                                                                <>

                                                                                                </>
                                                                                                :
                                                                                                <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer" onClick={() => navigate(`/engagement-rings/${row?.Shape.toLowerCase()}/${row.StockID}`)}>
                                                                                                    Add to Ring
                                                                                                </li>
                                                                                        }
                                                                                        <Link to={skuno ? `/rings/vd/${row.StockID}/${skuno}-${variantId}${shape_code}` : `/rings/vd/${row.StockID}`} className="px-4 py-2 hover:bg-gray-100 cursor-pointer">
                                                                                            Add to Bag
                                                                                        </Link>
                                                                                    </ul>
                                                                                </div>
                                                                            )}
                                                                        </span> */}
                                                                    </div>

                                                                    <div className='text-center my-1 lg:my-2 lg:mb-5'>
                                                                        <Link to={skuno ? `/rings/vd/${encryptURL(row.StockID)}/${skuno}-${variantId}${shape_code}` : `/rings/vd/${encryptURL(row.StockID)}`} state={row.StockID} className='text-13 underline'>
                                                                            View Diamond Details
                                                                            {/* <a href='/lab-diamonds-search/view-detail' className='text-13 underline'>View Diamond Details</a> */}
                                                                        </Link>
                                                                    </div>
                                                                    {/* <div className='lg:flex gap-2 flex-wrap hidden'>
                                                                        {['Quick Ship', '100% Renewable', 'Sustainable'].map((data, index) => {
                                                                            return (<div key={index} className=' text-10 text-footerBackground'>
                                                                                <span className='px-2 py-[2px] rounded-full bg-[#b4cfcf]'>{data}</span>
                                                                            </div>)
                                                                        })}
                                                                    </div> */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {additionalDetailId.includes(row.StockID) ?
                                                            <div className='grid  grid-cols-[auto_auto_auto_auto_auto] text-[#6c6c6c] '>
                                                                <div className='w-auto text-additionalTableText p-2'><p className='capitalize'>L:W : </p><p className='capitalize'>{row['LW']}</p></div>
                                                                <div className='w-auto text-additionalTableText p-2'><p className='capitalize'>Fluor : </p><p className='capitalize'>{row['Floro']}</p></div>
                                                                <div className='w-auto text-additionalTableText p-2'><p className='capitalize'>Symmetry : </p><p className='capitalize'>{row['Sym']}</p></div>
                                                                <div className='w-auto text-additionalTableText p-2'><p className='capitalize'>Table : </p><p className='capitalize'>{row['Tab'].toFixed(1)} %</p></div>
                                                                <div className='w-auto text-additionalTableText p-2'><p className='capitalize'>Measurements : </p><p className='capitalize'>{row['Measurement']}</p></div>
                                                                <div className='w-auto text-additionalTableText p-2'><p className='capitalize'>Culet : </p><p className='capitalize'>{row['Culet']}</p></div>
                                                                <div className='w-auto text-additionalTableText p-2'><p className='capitalize'>Polish : </p><p className='capitalize'>{row['Pol']}</p></div>
                                                                <div className='w-auto text-additionalTableText p-2'><p className='capitalize'>Girdle : </p><p className='capitalize'>{row['Girdle']}</p></div>
                                                                <div className='w-auto text-additionalTableText p-2'><p className='capitalize'>Depth : </p><p className='capitalize'>{row['Dep'].toFixed(1)} %</p></div>
                                                                <div className='w-auto text-additionalTableText p-2'><p className='capitalize'>Report : </p><p className='capitalize'>{row['Report']}</p></div>
                                                            </div> : null
                                                        }
                                                    </div>
                                                </div>
                                                {/* <div className='lg:hidden flex gap-2 flex-wrap flex-start my-2'>
                                                    {['Quick Ship', '100% Renewable', 'Sustainable'].map((data, index) => {
                                                        return (<div key={index} className=' text-10 text-footerBackground'>
                                                            <span className='px-2 py-[2px] rounded-full bg-[#b4cfcf]'>{data}</span>
                                                        </div>)
                                                    })}
                                                </div> */}
                                            </div> : ''}
                                    </React.Fragment>
                                )
                            })}

                        {data.tableData?.loading && <div className='absolute flex flex-row justify-center w-full'> <ClipLoader className='my-[3%]' /></div>}
                    </tbody>
                </table>
            </div >
        </div >
    );
}

export default ShowDiamondsTable



