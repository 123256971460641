// import PropTypes from 'prop-types';
// import React, { useCallback, useEffect, useRef, useState } from 'react';

// const RangeSelector = ({
//     min = 0,
//     max = 100,
//     step = 1,
//     onChange = null,
//     stepLabels = null,
//     label = '',
// }) => {
//     const [range, setRange] = useState({ min, max });
//     const [inputValue, setInputValue] = useState(range);
//     const [dragging, setDragging] = useState(null);
//     const trackRef = useRef(null);
//     const animationFrameId = useRef(null); // To keep track of the animation frame

//     // Effect to initialize range values from props
//     useEffect(() => {
//         setRange({ min, max });
//     }, [min, max]);

//     const handleMouseMove = useCallback((e) => {
//         if (dragging) {
//             // Cancel the previous frame if any
//             if (animationFrameId.current) {
//                 cancelAnimationFrame(animationFrameId.current);
//             }
//             animationFrameId.current = requestAnimationFrame(() => {
//                 const trackRect = trackRef.current.getBoundingClientRect();
//                 const trackWidth = trackRect.width;
//                 const minOffset = e.clientX - trackRect.left;
//                 const maxOffset = Math.min(trackWidth, Math.max(0, minOffset));
//                 const value = (maxOffset / trackWidth) * (max - min) + min;

//                 // Round value to the nearest step
//                 const roundedValue = Math.round(value / step) * step;

//                 setRange((prev) => {
//                     if (dragging === 'min') {
//                         return {
//                             ...prev,
//                             min: Math.min(roundedValue, prev.max - step),
//                         };
//                     } else if (dragging === 'max') {
//                         return {
//                             ...prev,
//                             max: Math.max(roundedValue, prev.min + step),
//                         };
//                     }
//                     return prev;
//                 });
//             });
//         }
//     }, [dragging, min, max, step]);

//     useEffect(() => {
//         const handleMouseUp = () => {
//             setDragging(null);
//             if (animationFrameId.current) {
//                 cancelAnimationFrame(animationFrameId.current);
//             }
//         };

//         document.addEventListener('mousemove', handleMouseMove);
//         document.addEventListener('mouseup', handleMouseUp);

//         return () => {
//             document.removeEventListener('mousemove', handleMouseMove);
//             document.removeEventListener('mouseup', handleMouseUp);
//             if (animationFrameId.current) {
//                 cancelAnimationFrame(animationFrameId.current);
//             }
//         };
//     }, [handleMouseMove]);

//     useEffect(() => {
//         if (onChange) onChange(range);
//     }, [range]);

//     useEffect(() => {
//         setInputValue(range);
//     }, [range]);

//     const handleMouseDown = (type) => (e) => {
//         e.preventDefault();
//         setDragging(type);
//     };

//     const trackWidth = ((range.max - range.min) / (max - min)) * 100;
//     const minPosition = ((range.min - min) / (max - min)) * 100;
//     const maxPosition = ((range.max - min) / (max - min)) * 100;

//     const changeHandler = (e) => {
//         e.preventDefault();
//         setRange(inputValue);
//     };

//     return (
//         <>
//             <div className="relative w-[100%] h-4 px-2 relative">
//                 {stepLabels && <ol>{stepLabels.map((data, index) =>
//                     !(index + 1 >= stepLabels.length) && <li key={index} className={`h-1 border-r border-white bg-gray-300 border-solid border-[1px] absolute bottom-[6px] z-10`} style={{ left: `${stepLabels.length === 5 ? ++index * 20 : stepLabels.length === 10 ? ++index * 10 : stepLabels.length === 9 ? ++index * 11.11 : stepLabels.length === 7 ? ++index * 14.28 : stepLabels.length === 3 ? ++index * 33.33 : 33.33}%` }}></li>
//                 )}
//                 </ol>}
//                 <div ref={trackRef} className="absolute w-[100%] h-1 bg-gray-300 rounded-full top-[35%]" />
//                 <div className="absolute bg-rangeColor h-1 rounded-full top-[35%]" style={{ left: `${minPosition}%`, width: `${trackWidth}%` }} />
//                 <div className="absolute w-4 h-4 bg-rangeColor rounded-full cursor-pointer z-20" style={{ left: `${minPosition}%` }} onMouseDown={handleMouseDown('min')} onMouseUp={changeHandler} />
//                 <div className="absolute w-4 h-4 bg-rangeColor rounded-full cursor-pointer z-20" style={{ left: `${maxPosition - 1}%` }} onMouseDown={handleMouseDown('max')} onMouseUp={changeHandler} />
//             </div>
//             {stepLabels ?
//                 <div className="flex mx-auto w-[100%] mt-1 text-12">
//                     {stepLabels.map((data, index) => (
//                         <div key={index} className={`font-inter text-center w-[100%] text-[#2c2c2c] opacity-[0.8]`}  >{data}</div>
//                     ))}
//                 </div> :
//                 <div className='flex justify-between gap-40 w-[105%] mt-1 '>
//                     <div className='border border-black-600 '>
//                         <form onSubmit={changeHandler} className='flex'>
//                             <input type='text' value={label === '$' ? `$${inputValue.min}` : label === '%' ? `${inputValue.min.toFixed(2)} %` : inputValue.min.toFixed(2)} className='focus:outline-none w-[90%] text-left' onChange={(e) => setInputValue({ ...inputValue, min: e.target.value })} />
//                         </form>
//                     </div>
//                     <form onSubmit={changeHandler} className='flex text-right border'>
//                         <input type='text' value={label === '$' ? `$${inputValue.max}` : label === '%' ? `${inputValue.max.toFixed(2)} %` : inputValue.max.toFixed(2)} className='focus:outline-none w-[90%] text-right' onChange={(e) => setInputValue({ ...inputValue, max: e.target.value })} />
//                     </form>
//                 </div>
//             }
//         </>
//     );
// };

// RangeSelector.propTypes = {
//     min: PropTypes.number.isRequired,
//     max: PropTypes.number.isRequired,
//     step: PropTypes.number.isRequired,
//     onChange: PropTypes.func,
//     stepLabels: PropTypes.arrayOf(PropTypes.string),
// };

// export default RangeSelector;

// import React, { useState, useRef, useEffect, useCallback } from 'react';
// import PropTypes from 'prop-types';

// const RangeSelector = ({
//     min = 0,
//     max = 100,
//     step = 1,
//     onChange = null,
//     stepLabels = null,
//     label = '',
// }) => {
//     const [range, setRange] = useState({ min, max });
//     const [tempRange, setTempRange] = useState({ min, max });
//     const [dragging, setDragging] = useState(null);
//     const trackRef = useRef(null);
//     const animationFrameId = useRef(null);

//     useEffect(() => {
//         setRange({ min, max });
//         setTempRange({ min, max });
//     }, [min, max]);

//     const handleMouseMove = useCallback((e) => {
//         if (dragging) {
//             if (animationFrameId.current) {
//                 cancelAnimationFrame(animationFrameId.current);
//             }
//             animationFrameId.current = requestAnimationFrame(() => {
//                 const trackRect = trackRef.current.getBoundingClientRect();
//                 const trackWidth = trackRect.width;
//                 const minOffset = e.clientX - trackRect.left;
//                 const maxOffset = Math.min(trackWidth, Math.max(0, minOffset));
//                 const value = (maxOffset / trackWidth) * (max - min) + min;
//                 const roundedValue = Math.round(value / step) * step;

//                 setTempRange((prev) => {
//                     const newRange = { ...prev };
//                     if (dragging === 'min') {
//                         newRange.min = Math.min(roundedValue, newRange.max - step);
//                     } else if (dragging === 'max') {
//                         newRange.max = Math.max(roundedValue, newRange.min + step);
//                     }
//                     return newRange;
//                 });
//             });
//         }
//     }, [dragging, min, max, step]);

//     useEffect(() => {
//         const handleMouseUp = () => {
//             if (dragging) {
//                 setRange(tempRange); // Set the confirmed range
//                 if (onChange) onChange(tempRange); // Notify parent component
//             }
//             setDragging(null);
//             if (animationFrameId.current) {
//                 cancelAnimationFrame(animationFrameId.current);
//             }
//         };

//         document.addEventListener('mousemove', handleMouseMove);
//         document.addEventListener('mouseup', handleMouseUp);

//         return () => {
//             document.removeEventListener('mousemove', handleMouseMove);
//             document.removeEventListener('mouseup', handleMouseUp);
//             if (animationFrameId.current) {
//                 cancelAnimationFrame(animationFrameId.current);
//             }
//         };
//     }, [handleMouseMove, dragging, onChange, tempRange]);

//     const handleMouseDown = (type) => (e) => {
//         e.preventDefault();
//         setDragging(type);
//     };

//     // Calculate visual positions
//     const trackWidth = ((tempRange.max - tempRange.min) / (max - min)) * 100;
//     const minPosition = ((tempRange.min - min) / (max - min)) * 100;
//     const maxPosition = ((tempRange.max - min) / (max - min)) * 100;

//     return (
//         <>
//             <div className="relative w-full h-4 px-2">
//                 {stepLabels && (
//                     <ol>
//                         {stepLabels.map((data, index) => (
//                             !(index + 1 >= stepLabels.length) && (
//                                 <li
//                                     key={index}
//                                     className="h-1 border-r border-white bg-gray-300 border-solid border-[1px] absolute bottom-[6px] z-10"
//                                     style={{
//                                         left: `${(index + 1) * (100 / stepLabels.length)}%`,
//                                     }}
//                                 />
//                             )
//                         ))}
//                     </ol>
//                 )}
//                 <div
//                     ref={trackRef}
//                     className="absolute w-full h-1 bg-gray-300 rounded-full top-[35%]"
//                 />
//                 <div
//                     className="absolute bg-rangeColor h-1 rounded-full top-[35%]"
//                     style={{ left: `${minPosition}%`, width: `${trackWidth}%` }}
//                 />
//                 <div
//                     className="absolute w-4 h-4 bg-rangeColor rounded-full cursor-pointer z-20"
//                     style={{ left: `${minPosition}%` }}
//                     onMouseDown={handleMouseDown('min')}
//                 />
//                 <div
//                     className="absolute w-4 h-4 bg-rangeColor rounded-full cursor-pointer z-20"
//                     style={{ left: `${maxPosition}%` }}
//                     onMouseDown={handleMouseDown('max')}
//                 />
//             </div>
//             {stepLabels ? (
//                 <div className="flex mx-auto w-full mt-1 text-12">
//                     {stepLabels.map((data, index) => (
//                         <div
//                             key={index}
//                             className="font-inter text-center w-full text-[#2c2c2c] opacity-[0.8]"
//                         >
//                             {data}
//                         </div>
//                     ))}
//                 </div>
//             ) : (
//                 <div className='flex justify-between gap-40 w-full mt-1'>
//                     <input
//                         type='text'
//                         value={label === '$' ? `$${range.min}` : label === '%' ? `${range.min.toFixed(2)} %` : range.min.toFixed(2)}
//                         className='focus:outline-none w-[90%] text-left border'
//                         onChange={(e) => setTempRange({ ...tempRange, min: parseFloat(e.target.value) })}
//                     />
//                     <input
//                         type='text'
//                         value={label === '$' ? `$${range.max}` : label === '%' ? `${range.max.toFixed(2)} %` : range.max.toFixed(2)}
//                         className='focus:outline-none w-[90%] text-right border'
//                         onChange={(e) => setTempRange({ ...tempRange, max: parseFloat(e.target.value) })}
//                     />
//                 </div>
//             )}
//         </>
//     );
// };

// RangeSelector.propTypes = {
//     min: PropTypes.number.isRequired,
//     max: PropTypes.number.isRequired,
//     step: PropTypes.number.isRequired,
//     onChange: PropTypes.func,
//     stepLabels: PropTypes.arrayOf(PropTypes.string),
// };

// export default RangeSelector;


import React, { useState, useRef, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
const RangeSelector = ({
    min = 0,
    max = 100,
    step = 1,
    onChange = null,
    stepLabels = null,
    label = '',
    input = {}
}) => {
    const [tempRange, setTempRange] = useState({ min, max });
    const [dragging, setDragging] = useState(null);
    const trackRef = useRef(null);
    const animationFrameId = useRef(null);
    const [inputValue, setInputValue] = useState({ min, max });


    // Sync range with external input changes
    useEffect(() => {
        setTempRange({ min, max });
        setInputValue({ min, max });
    }, [min, max]);

    // Calculate visual positions
    const trackWidth = ((tempRange.max - tempRange.min) / (max - min)) * 100;
    const minPosition = ((tempRange.min - min) / (max - min)) * 100;
    const maxPosition = ((tempRange.max - min) / (max - min)) * 100;

    // Handle dragging logic (both mouse and touch)
    const handleMove = useCallback((e) => {
        if (dragging) {
            if (animationFrameId.current) {
                cancelAnimationFrame(animationFrameId.current);
            }
            animationFrameId.current = requestAnimationFrame(() => {
                const trackRect = trackRef.current.getBoundingClientRect();
                const trackWidth = trackRect.width;
                const offset = e?.clientX ? e?.clientX || min : e?.touches?.[0]?.clientX || min; // Handle mouse or touch
                const position = offset - trackRect.left;
                const value = Math.max(min, Math.min(max, (position / trackWidth) * (max - min) + min));
                const roundedValue = Math.round(value / step) * step;

                setTempRange((prev) => {
                    const newRange = { ...prev };
                    if (dragging === 'min') {
                        newRange.min = Math.min(roundedValue, newRange.max - step);
                    } else if (dragging === 'max') {
                        newRange.max = Math.max(roundedValue, newRange.min + step);
                    }
                    return newRange;
                });
                setInputValue(tempRange);
            });
        }
    }, [dragging, min, max, step, tempRange]);

    // Set input range when dragging ends
    const handleMouseUp = () => {
        if (dragging) {
            setInputValue(tempRange); // Set confirmed range
            if (onChange) onChange(tempRange); // Notify parent component
        }
        setDragging(null);
        if (animationFrameId.current) {
            cancelAnimationFrame(animationFrameId.current);
        }
    };

    const handleTouchEnd = () => {
        handleMouseUp();
    };

    // Event listeners
    const handleMouseDown = (type) => (e) => {
        e.preventDefault();
        setDragging(type);
    };

    const handleTouchStart = (type) => (e) => {
        e.preventDefault();
        setDragging(type);
    };
    useEffect(() => {

        document.addEventListener('mousemove', handleMove);
        document.addEventListener('mouseup', handleMouseUp);
        document.addEventListener('touchmove', handleMove, { passive: false });
        document.addEventListener('touchend', handleTouchEnd);

        return () => {
            document.removeEventListener('mousemove', handleMove);
            document.removeEventListener('mouseup', handleMouseUp);
            document.removeEventListener('touchmove', handleMove);
            document.removeEventListener('touchend', handleTouchEnd);
            if (animationFrameId.current) {
                cancelAnimationFrame(animationFrameId.current);
            }
        };
    }, [handleMove, dragging, onChange, tempRange]);

    // Handle input value changes
    const handleSubmit = (e) => {
        e.preventDefault();
        if (isNaN(tempRange.min) || tempRange.min < min || tempRange.max > max) {
            setInputValue({ ...inputValue, min: min, max: max });
        } else {
            setInputValue({
                ...inputValue,
                min: label === '$' ? parseFloat(inputValue.min) : parseFloat(inputValue.min).toFixed(2),
                max: label === '$' ? parseFloat(inputValue.max) : parseFloat(inputValue.max).toFixed(2)
            });
            setTempRange({
                ...inputValue,
                min: label === '$' ? parseFloat(inputValue.min) : parseFloat(inputValue.min).toFixed(2),
                max: label === '$' ? parseFloat(inputValue.max) : parseFloat(inputValue.max).toFixed(2)
            });
            if (onChange) onChange(inputValue);
        }
    };

    const changeHandler = (e, type) => {
        setInputValue({ ...inputValue, [type]: label === '$' ? e.target.value.slice(1) : e.target.value });
    };

    return (
        <>
            <div className="relative w-full h-4 px-2">
                {stepLabels && (
                    <ol>
                        {stepLabels.map((data, index) => (
                            !(index + 1 >= stepLabels.length) && (
                                <li
                                    key={index}
                                    className="h-2 border-r border-white bg-gray-300 border-solid border-[1px] absolute bottom-[6px] lg:bottom-[6px] z-10"
                                    style={{
                                        left: `${(index + 1) * (100 / stepLabels.length)}%`,
                                    }}
                                />
                            )
                        ))}
                    </ol>
                )}
                <div
                    ref={trackRef}
                    className="absolute w-full h-1 bg-gray-300 rounded-full top-[35%]"
                />
                <div
                    className="absolute bg-rangeColor h-1 rounded-full top-[35%]"
                    style={{ left: `${minPosition}%`, width: `${trackWidth}%` }}
                />
                <div
                    className="absolute w-4 h-4 bg-rangeColor rounded-full cursor-pointer z-10"
                    style={{ left: `${minPosition}%` }}
                    onMouseDown={handleMouseDown('min')}
                    onTouchStart={handleTouchStart('min')}
                />
                <div
                    className="absolute w-4 h-4 bg-rangeColor rounded-full cursor-pointer z-10"
                    style={{ left: `${maxPosition - 2}%` }}
                    onMouseDown={handleMouseDown('max')}
                    onTouchStart={handleTouchStart('max')}
                />
            </div>

            {stepLabels ? (
                <div className="flex mx-auto w-full mt-1 text-13 ">
                    {stepLabels.map((data, index) => (
                        <div
                            key={index}
                            className="font-inter text-center w-full text-[#2c2c2c] opacity-[0.8]"
                        >
                            {data}
                        </div>
                    ))}
                </div>
            ) : (
                <div className='flex justify-between gap-4 w-full mt-1'>
                    <div className=''>
                        <form onBlur={handleSubmit} onSubmit={handleSubmit} className='flex'>
                            <input
                                type='text'
                                value={label
                                    ? `${label}${inputValue?.min?.toLocaleString()}`
                                    : (typeof inputValue.min === 'number'
                                        ? inputValue.min.toFixed(2)
                                        : inputValue.min)
                                }
                                className='focus:outline-none w-[90%] text-left border border-[#cccccc] rounded-lg px-2'
                                onChange={(e) => changeHandler(e, 'min')}
                            />
                        </form>
                    </div>
                    <form onBlur={handleSubmit} onSubmit={handleSubmit} className='flex justify-end text-right'>
                        <input
                            type='text'
                            value={label
                                ? `${label}${inputValue?.max?.toLocaleString()}`
                                : (typeof inputValue.max === 'number'
                                    ? inputValue.max.toFixed(2)
                                    : inputValue.max)
                            }
                            className='focus:outline-none w-[90%] text-right border border-[#cccccc] rounded-lg px-2'
                            onChange={(e) => changeHandler(e, 'max')}
                        />
                    </form>
                </div>
            )}
        </>
    );
};
RangeSelector.propTypes = {
    min: PropTypes.number.isRequired,
    max: PropTypes.number.isRequired,
    step: PropTypes.number.isRequired,
    onChange: PropTypes.func,
    stepLabels: PropTypes.arrayOf(PropTypes.string),
};
export default RangeSelector;

// < div className='flex justify-between gap-40 w-[105%] mt-1 '>
//     <div className='border border-black-600'>
//         <form onSubmit={handleSubmit} className='flex'>
//             <input
//                 type='text'
//                 value={label === '$' ? `$${parseFloat(inputValue.min || 0).toFixed(2)}` : parseFloat(inputValue.min || 0).toFixed(2)}
//                 className='focusutline-none w-[90%] text-left'
//                 onChange={e => changeHandler(e, 'min')}
//             />
//         </form>
//     </div>
//     <form onSubmit={handleSubmit} className='flex text-right border'>
//         <input
//             type='text'
//             value={label === '$' ? `$${parseFloat(inputValue.max || 0).toFixed(2)}` : parseFloat(inputValue.max || 0).toFixed(2)}
//             className='focusutline-none w-[90%] text-right'
//             onChange={e => changeHandler(e, 'max')}
//         />
//     </form>
// </ div >