import { DatePicker, Modal, Tooltip } from 'antd'
import dayjs from 'dayjs'
import moment from 'moment/moment'
import React, { useEffect, useRef, useState } from 'react'
import { FaRegEye, FaRegFilePdf } from 'react-icons/fa'
import { FiDownload } from "react-icons/fi"
import { Link } from 'react-router-dom'
import { ClipLoader } from 'react-spinners'
import { decryptData } from '../../components/decrypt'
import { showErrorToast, showSuccessToast } from '../../components/ToastNotification'
import { axiosInstance } from '../../redux/api'
import { azure_url } from '../../utils/azureInfo'
import { encryptURL } from '../../utils/encrypt'

const MyOrders = () => {
    const dateFormatList = ['DD-MM-YYYY', 'DD-MM-YY', 'DD/MM/YYYY', 'DD/MM/YY'];
    const [orderData, setOrderData] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [startDate, setStartDate] = useState(dayjs().subtract(3, 'month')); // Start Date set to 1 month ago 
    const [endDate, setEndDate] = useState(dayjs()); // End Date (Today)
    const [selectedOrderStatus, setSelectedOrderStatus] = useState('Pending')
    const [filteredData, setFilteredData] = useState([]); // Filtered data based on date range
    const [search, setSearch] = useState('');
    const [hoverSKUName, setHoverSKUName] = useState('')
    const [hoverSKUNameIndex, setHoverSKUNameIndex] = useState()
    const [hoverReason, setHoverReason] = useState('')
    const [hoverReasonIndex, setHoverReasonIndex] = useState()
    const [hoverProduct, setHoverProduct] = useState()
    const [cancelOrderModalOpen, setCancelOrderModalOpen] = useState(false)
    const [reason, setReason] = useState('')
    const [cancelOrderData, setCancelOrderData] = useState();
    const [reasonError, setReasonError] = useState('')
    const [modalLoader, setModalLoader] = useState(false);

    const userInfo = JSON.parse(localStorage.getItem('user-info'))
    const textareaRef = useRef(null);


    useEffect(() => {
        if (cancelOrderModalOpen && textareaRef.current) {
            // Using setTimeout to make sure the component has re-rendered before focusing
            setTimeout(() => {
                textareaRef.current.focus();
            }, 0); // 0 ms delay to ensure focus happens after the render
        }
    }, [cancelOrderModalOpen]);

    // Function to group data by ORDNO and create the desired output
    const groupOrders = (data) => {
        // Grouping by ORDNO
        const grouped = data.reduce((acc, curr) => {
            const { ORDNO, INVNO, TAX_PRICE, SHIPPING_PRICE, FINAL_PRICE, CURRENCY } = curr;

            // Create an order object if it doesn't exist
            if (!acc[ORDNO]) {
                acc[ORDNO] = {
                    ORDNO,
                    INVNO,
                    TAX_PRICE,
                    SHIPPING_PRICE,
                    FINAL_PRICE,
                    CURRENCY,
                    PRODUCTS: []
                };
            }

            // Add the current product to the PRODUCTS array of the corresponding order
            const product = {
                SKU_NAME: curr.SKU_NAME,
                PRD_SIZE: curr.PRD_SIZE,
                TOTAL_PRICE: curr.TOTAL_PRICE,
                STOCKID: curr.STOCKID,
                PRD_ID: curr.PRD_ID,
                PRD_VARIANT_ID: curr.PRD_VARIANT_ID,
                IS_PRODUCT: curr.IS_PRODUCT,
                ORDER_STATUS: curr.ORDER_STATUS,
                PAYMENT_STATUS: curr.PAYMENT_STATUS,
                CURRENCY: curr.CURRENCY,
                S_CODE: curr.S_CODE,
                SKUNO: curr.SKUNO,
                REJECT_REASON: curr.REJECT_REASON,
            };

            acc[ORDNO].PRODUCTS.push(product);

            return acc;
        }, {});

        // Convert the grouped object into an array
        return Object.values(grouped);
    };

    const getOrderData = async () => {
        const payload = { USERID: userInfo?.USERID, F_DATE: startDate ? dayjs(startDate).format('YYYY-MM-DD') : '', T_DATE: endDate ? dayjs(endDate).format('YYYY-MM-DD') : '', ORD_TYPE: selectedOrderStatus }
        try {
            setIsLoading(true);
            let response = await axiosInstance.post('/order', payload);

            setIsLoading(false);
            const isDecryption = process.env.REACT_APP_IS_DECRYPT
            setOrderData(isDecryption == 'true' ? groupOrders(decryptData(response.data).data) : groupOrders(response.data.data.data))
            setFilteredData(isDecryption == 'true' ? groupOrders(decryptData(response.data).data) : groupOrders(response.data.data.data))
            // setOrderData(response.data.data)

            // dispatch(addWishlistCount(totalProduct))

            // Handle successful response, e.g., show a success message or navigate
        } catch (error) {
            setIsLoading(false);
            showErrorToast(error.response.data.message)
            // Handle errors, e.g., show an error message
            console.error('There was a problem with the fetch operation:', error);
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        getOrderData()
    }, [selectedOrderStatus])

    const handleStartDateChange = (date) => {
        setStartDate(date);
    };

    // Handle Date Change for End Date
    const handleEndDateChange = (date) => {
        setEndDate(date);
    };

    const filterDataBySearch = (search) => {
        const lowerCaseSearch = search.toLowerCase();
        const filtered = orderData.filter(order => {
            // Search across multiple fields in the order data
            const matchesOrder =
                order.INVNO.toLowerCase().includes(lowerCaseSearch)

            // Also search within products of each order
            const matchesProduct = order?.PRODUCTS?.some(product =>
                product?.SKU_NAME?.toLowerCase()?.includes(lowerCaseSearch) ||
                product?.STOCKID?.toLowerCase()?.includes(lowerCaseSearch) ||
                product?.REPORTNO?.toLowerCase()?.includes(lowerCaseSearch)
            );

            return matchesOrder || matchesProduct;
        });

        setFilteredData(filtered);
    };

    const handleSearch = (e) => {
        setSearch(e.target.value)
        filterDataBySearch(e.target.value)
    }

    const formatDate = (dateString) => {
        // Convert UTC time to the local time zone using moment.js
        return moment.utc(dateString).format('DD/MM/YYYY hh:mm A');
    };

    const handleFilterData = () => {
        getOrderData();
    }
    const handleCancelOrder = (e, data) => {
        setCancelOrderData(data);
        setCancelOrderModalOpen(true)
    }
    const cancelOrder = async () => {
        if (reason === '' || reason.trim().length === 0) {
            return setReasonError('Please Enter Reason.')
        }
        if (reasonError === '') {
            try {
                setModalLoader(true)
                const payload = {
                    "ORDNO": cancelOrderData.ORDNO,
                    "REASON": reason.trim()
                }
                const data = await axiosInstance.post('order/order-cancel', payload)
                console.log(data, 'aa')
                if (data.data.data.message) {
                    showSuccessToast(data.data.data.message)
                    setCancelOrderModalOpen(false)
                    setReason('')
                    getOrderData()
                    setModalLoader(false)
                }
            } catch (err) {
                console.log(err)
                showErrorToast(err.response.data.message)
                setModalLoader(false)
            }
        }
    }

    const handleDownload = (filename) => {
        const pdfUrl = `${azure_url}Invoice/${filename}`;
        // window.open(pdfUrl, '_blank');
        fetch(pdfUrl)
            .then(response => response.blob()) // Get the response as a Blob
            .then(blob => {
                // Create a link element to trigger the download
                const link = document.createElement('a');
                link.href = URL.createObjectURL(blob); // Create a URL for the Blob
                link.download = filename;  // Set the filename for download
                link.click();  // Simulate a click to trigger the download
                URL.revokeObjectURL(link.href);  // Clean up the object URL after use
            })
            .catch(error => {
                console.error("Error downloading the PDF:", error);
                alert("There was an issue downloading the PDF.");
            });
    };

    return (
        <div className=' text-start  2xl:w-[70%] mx-auto px-3 2xl:px-0 mb-10'>
            <div className='flex justify-between font-inter items-center'>
                <p className='text-20 md:text-34 my-3 md:my-5  font-medium'>{userInfo?.FIRST_NAME}'s Orders</p>
                {/* <button
                    className='footertext-footerBackground:bg-footerBackground cursor-pointer footertext-footerBackground:text-white text-12 md:text-16 w-[40%] md:w-[30%] lg:w-[20%]  py-1.5 rounded-full flex justify-center gap-2 items-center border-2 border-footerBackground'
                >
                    Share Wish List <PiShareFatBold />
                </button> */}
            </div>
            <div className=''>
                <div>
                    <div className='flex flex-wrap gap-10 border-b-2 font-inter items-center w-full py-1'>
                        <div className=" flex flex-row items-center justify-between w-full ">
                            <div className="flex flex-wrap gap-1 md:gap-3 w-[100%] md:w-[90%] items-center">
                                {/* Pending Status */}
                                <label
                                    className={`flex items-center gap-1 px-1.5 md:px-3 py-1.5 cursor-pointer rounded-full ${selectedOrderStatus === 'Pending' ? 'text-black bg-[#eeb560] bg-opacity-50' : ''}`}
                                    onClick={() => setSelectedOrderStatus('Pending')}
                                >
                                    <span className="text-14 md:text-15">Pending</span>
                                </label>

                                {/* Accept Status */}
                                <label
                                    className={`flex items-center gap-1 px-1.5 md:px-3 py-1.5 cursor-pointer rounded-full ${selectedOrderStatus === 'Accept' ? 'text-black bg-[#aafdb3] bg-opacity-80' : ''}`}
                                    onClick={() => setSelectedOrderStatus('Accept')}
                                >
                                    <span className="text-14 md:text-15">Accepted</span>
                                </label>

                                {/* Reject Status */}
                                <label
                                    className={`flex items-center gap-1 px-1.5 md:px-3 py-1.5 cursor-pointer rounded-full ${selectedOrderStatus === 'Reject' ? 'text-black bg-[#fdaac6] bg-opacity-50' : ''}`}
                                    onClick={() => setSelectedOrderStatus('Reject')}
                                >
                                    <span className="text-14 md:text-15">Rejected</span>
                                </label>

                                {/* DIspatch Status */}
                                <label
                                    className={`flex items-center gap-1 px-1.5 md:px-3 py-1.5 cursor-pointer rounded-full ${selectedOrderStatus === 'Dispatch' ? 'text-black bg-[#8da2ff] bg-opacity-50' : ''}`}
                                    onClick={() => setSelectedOrderStatus('Dispatch')}
                                >
                                    <span className="text-14 md:text-15">Dispatched</span>
                                </label>

                                {/* All Status */}
                                <label
                                    className={`flex items-center gap-1 px-1.5 md:px-3 py-1.5 cursor-pointer rounded-full ${selectedOrderStatus === 'All' ? 'text-black bg-[#aad8fd] bg-opacity-80' : ''}`}
                                    onClick={() => setSelectedOrderStatus('All')}
                                >
                                    <span className="text-14 md:text-15">All</span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className='flex py-2 flex-col md:flex-row w-full border-b-2 overflow-hidden'>
                        <div className="flex  items-center gap-1.5 md:gap-3 w-full">
                            <div className='flex items-center gap-0.5 md:gap-2 '>
                                <label className="text-12 md:text-14   text-right font-inter text-footerBackground font-semibold">
                                    From:
                                </label>
                                <div className="">
                                    <DatePicker
                                        value={startDate}
                                        format={dateFormatList}
                                        maxDate={endDate}
                                        onChange={handleStartDateChange}
                                    />
                                </div>
                            </div>
                            <div className='flex items-center gap-0.5 md:gap-2'>
                                <label className="text-12 md:text-14   text-right font-inter text-footerBackground font-semibold">
                                    To:
                                </label>
                                <div className="">
                                    <DatePicker
                                        value={endDate}
                                        format={dateFormatList}
                                        minDate={startDate}
                                        onChange={handleEndDateChange}
                                    />
                                </div>
                            </div>
                            <div>
                                <button onClick={handleFilterData} className='bg-footerBackground border border-footerBackground cursor-pointer text-white w-fit px-3 py-0.5 md:py-1 rounded-full text-14 md:text-16' >
                                    Search
                                </button>
                            </div>
                        </div>
                        <div className="relative mx-auto mt-2 md:mt-0 w-[100%] md:w-[50%]">
                            <input
                                type="text"
                                className="w-full border border-gray-400 font-inter outline-none px-1.5 md:px-3 py-1.5  text-12 md:text-14 rounded-3xl"
                                placeholder="Search"
                                value={search}
                                onChange={handleSearch}
                            // onChange={(e) => setSearch(e.target.value)}
                            />
                            {/* <IoSearchSharp className='absolute right-3 top-2 size-5 cursor-pointer' onClick={() => handleSearch()} /> */}
                        </div>
                    </div>
                </div>

                {isLoading ? <div className=' text-center h-[100vh] mt-20'>
                    <ClipLoader size={10}
                        color='white'
                    />
                </div> :
                    <div>
                        {filteredData && filteredData.length > 0 ? (
                            <div className='h-[67vh] md:h-[75vh]  overflow-auto' >
                                <div className="w-full overflow-auto text-center sticky top-0 bg-white z-[2] md:block hidden">
                                    <div className="flex w-full font-medium   border-b-2 py-3 items-center ">
                                        <p className="w-[3%]  text-14 lg:text-16 ">No</p>
                                        <p className="w-[10%] text-14 lg:text-16   ">Order Time</p>
                                        <p className="w-[8%] text-14 lg:text-16   ">Invoice</p>
                                        <div className={`${selectedOrderStatus === 'Pending' ? 'w-[69%]' : selectedOrderStatus === 'Accept' ? 'w-[73%]' : 'w-[79%]'} flex`}>
                                            <p className={`${selectedOrderStatus === 'All' ? 'w-[26%]' : selectedOrderStatus === 'Reject' ? 'w-[34.5%]' : 'w-[42%]'} text-14 lg:text-16  `}>SKU Name</p>
                                            <p className="w-[7.7%]  text-14 lg:text-16 ">Size</p>
                                            <p className={`w-[20.5%] text-14 lg:text-16 `}>Diamond StockID</p>
                                            <p className="w-[6.5%]  text-14 lg:text-16 ">View</p>
                                            <p className={`w-[11.5%]  text-14 lg:text-16  text-right`}>Amount</p>
                                            <p className={`${selectedOrderStatus === 'All' || selectedOrderStatus === 'Reject' ? 'w-[10%]' : 'w-[12%]'}  text-14 lg:text-16 `}>Payment</p>
                                            {(selectedOrderStatus === 'All' || selectedOrderStatus === 'Reject') && <p className="w-[9%]  text-14 lg:text-16  ">Reason</p>}
                                            {selectedOrderStatus === 'All' && <p className="w-[8%]  text-14 lg:text-16  ">Order</p>}
                                        </div>
                                        <p className={`w-[6%]   text-14 lg:text-16  text-center`}>Tax</p>
                                        <p className={`w-[6%] pr-1  text-14 lg:text-16  text-center`}>Shipping</p>
                                        <p className={`w-[6%] pr-1  text-14 lg:text-16  text-center`}>Total</p>
                                        {selectedOrderStatus === 'Pending' && <p className='w-[10%]'></p>}
                                        {selectedOrderStatus === 'Accept' && <p className='w-[3%] text-center text-18 lg:text-20 flex justify-center'><FiDownload /></p>}
                                        {/* {selectedOrderStatus === 'Accept' && <p className='w-[6%] truncate'>Download</p>} */}

                                    </div>
                                </div>
                                <div>
                                    {filteredData.map((item, index) => (
                                        <div key={index} className={`flex relative items-center h-auto py-3 border-b-[1.5px] border-[#776f6f] w-full ${index % 2 !== 0 ? 'bg-headerMenuBackground ' : ''} `}>
                                            <div className="w-[0%] md:w-full  flex-row items-center hidden md:flex">
                                                <div className='w-[3%] text-center '>
                                                    <span className="text-black font-poppins text-14 lg:text-16">
                                                        {item?.ORDNO}
                                                    </span>
                                                </div>
                                                <div className='w-[10%]  text-center flex flex-wrap'>
                                                    <span className="text-black font-mono text-14 lg:text-16">
                                                        {formatDate(item?.ENTRYDATETIME)}
                                                    </span>
                                                </div>
                                                <div className='w-[8%] text-center'>
                                                    <span className="text-black font-poppins text-14 lg:text-16">
                                                        {item?.INVNO.split('/')[0]}
                                                        <div>/{item?.INVNO.split('/')[1]}</div>
                                                    </span>
                                                </div>
                                                <div className={`${selectedOrderStatus === 'Pending' ? 'w-[69%]' : selectedOrderStatus === 'Accept' ? 'w-[73%]' : 'w-[79%]'} `}>
                                                    {item?.PRODUCTS?.map((items, i) => {
                                                        return (
                                                            <div key={i} className={`flex flex-wrap w-[100%] py-1  ${i !== item?.PRODUCTS?.length - 1 ? 'border-b border-[#b6b6b6]' : ''}`}>
                                                                <div className={`${selectedOrderStatus === 'All' ? 'w-[26%]' : selectedOrderStatus === 'Reject' ? 'w-[34.5%]' : 'w-[42%]'} text-center truncate`} >
                                                                    <Tooltip className={`w-[100%]`} placement="bottomLeft" title={i === hoverSKUNameIndex && index === hoverProduct && hoverSKUName}>
                                                                        <span className="text-black font-poppins text-14 lg:text-16" onMouseEnter={() => { setHoverSKUName(items?.SKU_NAME); setHoverSKUNameIndex(i); setHoverProduct(index) }} onMouseLeave={() => { setHoverSKUName(); setHoverSKUNameIndex(); setHoverProduct() }}>
                                                                            {items?.SKU_NAME}
                                                                        </span>
                                                                    </Tooltip>
                                                                </div>
                                                                <div className='w-[7.5%]  text-center '>
                                                                    <span className="text-black font-poppins text-14 lg:text-16">
                                                                        {items?.PRD_SIZE}
                                                                    </span>
                                                                </div>
                                                                <div className='w-[20.5%]   text-center'>
                                                                    <span className="text-black font-poppins text-14 lg:text-16">
                                                                        {items?.STOCKID}
                                                                    </span>
                                                                </div>
                                                                <div className='w-[6.5%]  text-center flex justify-center items-center'>
                                                                    <span className="text-black  font-poppins text-14 lg:text-16">
                                                                        <Link
                                                                            className="text-black  cursor-pointer text-14 lg:text-16"
                                                                            to={items?.IS_PRODUCT
                                                                                ? items?.STOCKID !== '' ? `http://localhost:3000/product-detail/${encryptURL(items?.SKUNO)}-${items?.PRD_VARIANT_ID}-${items?.S_CODE}/${encryptURL(items?.STOCKID)}?size=${items?.PRD_SIZE}`
                                                                                    : `http://localhost:3000/product-detail/${encryptURL(items?.SKUNO)}-${items?.PRD_VARIANT_ID}-${items?.S_CODE}?size=${items?.PRD_SIZE}`
                                                                                : `http://localhost:3000/rings/vd/${encryptURL(items?.STOCKID)}`}
                                                                            target="_blank" // Open in a new tab
                                                                            rel="noopener noreferrer" // Security feature to prevent exploitation of the opened page,
                                                                        >
                                                                            <FaRegEye className='size-4 lg:size-5' />
                                                                        </Link>
                                                                    </span>
                                                                </div>
                                                                <div className='w-[11.5%]   text-right'>
                                                                    <span className="text-black  text-14 lg:text-16">
                                                                        <span className='font-poppins'>{items?.TOTAL_PRICE?.toLocaleString() + ' '}</span><span className='text-17 font-medium'>{items?.CURRENCY}</span>
                                                                    </span>
                                                                </div>
                                                                <div className={`${selectedOrderStatus === 'All' || selectedOrderStatus === 'Reject' ? 'w-[10%]' : 'w-[12%]'}  text-center`} >
                                                                    <span className="text-black font-poppins text-14 lg:text-16">
                                                                        {items?.PAYMENT_STATUS}
                                                                    </span>
                                                                </div>
                                                                {(selectedOrderStatus === 'All' || selectedOrderStatus === 'Reject') && <div className='w-[9%] text-center truncate'>
                                                                    <Tooltip className={`w-[100%]`} placement="bottomLeft" title={i === hoverReasonIndex && index === hoverProduct && hoverReason}>
                                                                        <span className="text-black font-poppins text-14 lg:text-16" onMouseEnter={() => { setHoverReason(items?.REJECT_REASON); setHoverReasonIndex(i); setHoverProduct(index) }} onMouseLeave={() => { setHoverReason(); setHoverReasonIndex(); setHoverProduct() }}>
                                                                            {items?.REJECT_REASON}
                                                                        </span>
                                                                    </Tooltip>
                                                                </div>}
                                                                {selectedOrderStatus === 'All' && <div className='w-[8%] text-center '>
                                                                    <span className="text-black font-poppins text-14 lg:text-16">
                                                                        {items?.ORDER_STATUS}
                                                                    </span>
                                                                </div>}
                                                            </div>)
                                                    })}
                                                </div>
                                                <div className='w-[6%] text-center'>
                                                    <span className="text-black  text-14 lg:text-16">
                                                        <span className='font-poppins'>{item?.TAX_PRICE?.toLocaleString() + ' '}</span><span className='text-17 font-medium'>{item?.CURRENCY}</span>
                                                    </span>
                                                </div>
                                                <div className='w-[6%]  text-center'>
                                                    <span className="text-black  text-14 lg:text-16">
                                                        <span className='font-poppins'>{item?.SHIPPING_PRICE?.toLocaleString() + ' '}</span><span className='text-17 font-medium'>{item?.CURRENCY}</span>
                                                    </span>
                                                </div>
                                                <div className='w-[6%]  text-center'>
                                                    <span className="text-black  text-14 lg:text-16">
                                                        <span className='font-poppins'>{item?.FINAL_PRICE?.toLocaleString() + ' '}</span><span className='text-17 font-medium'>{item?.CURRENCY}</span>
                                                    </span>
                                                </div>
                                                {item?.PRODUCTS?.[0]?.ORDER_STATUS === 'Pending' && selectedOrderStatus === 'Pending' && <div className='text-14 md:text-16 mx-auto w-[10%]  border border-footerBackground cursor-pointer text-center duration-500 py-1.5 transition-all bg-footerBackground rounded-full  text-white leading-5 hover:bg-red-500  hover:bg-opacity-100' onClick={(e) => handleCancelOrder(e, item)}><span>Cancel Order</span></div>}
                                                {selectedOrderStatus === 'Accept' && <div className='w-[3%] text-center '>
                                                    <span className="text-red-500 mt-1.5 flex justify-center items-center " >
                                                        <FaRegFilePdf className='cursor-pointer size-5 lg:size-6 mb-1.5' onClick={() => handleDownload(`${item?.ORDNO}-${item?.INVNO?.replace('/', '-')}.pdf`)} />
                                                    </span>
                                                </div>}
                                            </div>
                                            <div className='w-[100%] text-14 md:hidden font-inter'>
                                                <div className='flex flex-wrap w-[100%]'>
                                                    <div className='w-[40%] flex items-center gap-1'>
                                                        <div >No:</div><div className='text-14'>{item?.ORDNO}</div>
                                                    </div>
                                                    <div className='w-[60%] flex items-center gap-1'>
                                                        <div >Invoice:</div><div className='text-14'>{item?.INVNO}</div>
                                                    </div>
                                                </div>
                                                <div className='flex flex-wrap mt-1 items-center boredr-b'>
                                                    <div className='w-[70%] flex items-center gap-1'>
                                                        <div >Order Time:</div><div className='font-mono text-12 mt-0.5'>{(formatDate(item?.ENTRYDATETIME))}</div>
                                                    </div>
                                                    <div className='w-[30%]'>
                                                        {item?.PRODUCTS?.[0]?.ORDER_STATUS === 'Pending' && selectedOrderStatus === 'Pending' && <div className='text-12  mx-auto w-[90%] border border-footerBackground cursor-pointer text-center duration-500 py-0.5 transition-all bg-footerBackground rounded-full  text-white leading-5 hover:bg-red-500  hover:bg-opacity-100' onClick={(e) => handleCancelOrder(e, item)}>Cancel Order</div>}
                                                        {selectedOrderStatus === 'Accept' && <div className='w-[90%] text-center '>
                                                            <span className="text-black mt-1.5 flex justify-center items-center " >
                                                                <FaRegFilePdf className='cursor-pointer size-5' onClick={() => handleDownload(`${item?.ORDNO}-${item?.INVNO?.replace('/', '-')}.pdf`)} />
                                                            </span>
                                                        </div>}
                                                    </div>
                                                </div>
                                                <div className='flex flex-wrap mt-1 items-center boredr-b'>
                                                    <div className='w-[50%] flex items-center gap-1 '>
                                                        <div >Tax:</div><div className=' mt-0.5'>{item?.TAX_PRICE?.toLocaleString()}{item?.CURRENCY}</div>
                                                    </div>
                                                    <div className='w-[50%] flex items-center gap-1 '>
                                                        <div >Shipping Charge:</div><div className=' mt-0.5'>{item?.SHIPPING_PRICE?.toLocaleString()}{item?.CURRENCY}</div>
                                                    </div>
                                                    <div className='w-[50%] flex items-center gap-1 '>
                                                        <div >Total Price:</div><div className=' mt-0.5'>{item?.FINAL_PRICE?.toLocaleString()}{item?.CURRENCY}</div>
                                                    </div>
                                                </div>
                                                <div>
                                                    {item?.PRODUCTS?.map((items, i) => {
                                                        return (
                                                            <div key={i} className={`flex relative flex-wrap pb-1 pt-3  ${i !== item?.PRODUCTS?.length - 1 ? 'border-b border-[#b6b6b6]' : ''}`}>
                                                                <span className="text-black  font-poppins text-14 md:text-[16px]">
                                                                    <Link
                                                                        className="text-black absolute right-3 bottom-1 cursor-pointer text-14 md:text-[16px]"
                                                                        to={items?.IS_PRODUCT
                                                                            ? items?.STOCKID !== '' && items?.STOCKID !== null ? `http://localhost:3000/product-detail/${encryptURL(items?.SKUNO)}-${items?.PRD_VARIANT_ID}-${items?.S_CODE ?? ''}/${encryptURL(items?.STOCKID)}?size=${items?.PRD_SIZE}`
                                                                                : `http://localhost:3000/product-detail/${encryptURL(items?.SKUNO)}-${items?.PRD_VARIANT_ID}-${items?.S_CODE}?size=${items?.PRD_SIZE}`
                                                                            : `http://localhost:3000/rings/vd/${encryptURL(items?.STOCKID)}`}
                                                                        target="_blank" // Open in a new tab
                                                                        rel="noopener noreferrer" // Security feature to prevent exploitation of the opened page,
                                                                    >
                                                                        <FaRegEye className='size-5' />
                                                                    </Link>
                                                                </span>
                                                                <div className='w-[100%] flex items-center gap-1'>
                                                                    <div>Sku Name: </div>
                                                                    <div className='text-14 '>{items?.SKU_NAME}</div>
                                                                </div>
                                                                <div className='flex w-[100%]'>
                                                                    <div className='w-[40%] flex items-center gap-1'>
                                                                        <div>Size:</div>
                                                                        <div className='text-14 '>{items?.PRD_SIZE}</div>
                                                                    </div>
                                                                    <div className='w-[60%] flex items-center gap-1'>
                                                                        <div>Diamond:</div>
                                                                        <div className='text-14 '>{items?.STOCKID}</div>
                                                                    </div>
                                                                </div>
                                                                <div className='flex w-[100%]'>
                                                                    <div className='w-[40%] flex items-center gap-1'>
                                                                        <div>Amount:</div>
                                                                        <div className='text-14 '>{items?.TOTAL_PRICE?.toLocaleString() + ' ' + items?.CURRENCY}</div>
                                                                    </div>
                                                                    <div className='w-[60%] flex items-center gap-1'>
                                                                        <div>Payment:</div>
                                                                        <div className='text-14 '>{items?.PAYMENT_STATUS}</div>
                                                                    </div>
                                                                </div>
                                                                {(selectedOrderStatus === 'All' || selectedOrderStatus === 'Reject') && <div className='flex w-[100%]'>
                                                                    <div className='w-[80%] flex items-center gap-1 truncate'>
                                                                        <div>Reason:</div>
                                                                        <div className='text-14 '>{items?.REJECT_REASON}</div>
                                                                    </div>
                                                                </div>}
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>)
                            : <div className="w-full text-center py-4">
                                No data available for the selected date range.
                            </div>
                        }

                    </div>
                }
            </div>
            <Modal
                open={cancelOrderModalOpen}
                onCancel={() => { setCancelOrderModalOpen(false); setReason(''); setReasonError('') }}
                closable
                footer={false}
            >
                <div className='font-inter'>
                    <div className='text-18 font-semibold'>Cancel Order</div>
                    <div className='text-16 mt-2'>Are you sure you want to cancel this order?</div>
                    <div className='my-3'>
                        <label>Please enter reason to cancel order :</label>
                        <textarea
                            className="w-full border border-gray-400 font-inter outline-none px-1.5 md:px-3 py-1.5  text-12 md:text-14 rounded-3xl"
                            placeholder="Reason"
                            value={reason}

                            onChange={(e) => { setReason(e.target.value); if (e.target.value === '') { setReasonError('Please Enter Reason.') } else if (e.target.value.trim().length < 5) { setReasonError('Reason must have 5 characters.') } else { setReasonError('') }; }}
                            rows={5}
                            ref={textareaRef}
                        >
                        </textarea>
                        {reasonError.length > 0 && <p className='text-12 text-red-500'>{reasonError}</p>}
                    </div>
                    <div className='flex justify-center gap-3 mt-5'>
                        {modalLoader ?
                            <button className='bg-footerBackground border border-footerBackground cursor-pointer text-white w-fit px-3 py-0.5 md:py-1 rounded-full text-16 ' >
                                <ClipLoader />
                            </button>
                            : <button onClick={() => { cancelOrder() }} className='bg-footerBackground border border-footerBackground cursor-pointer text-white w-fit px-3 py-0.5 md:py-1 rounded-full text-16 ' >
                                Cancel
                            </button>}
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default MyOrders
