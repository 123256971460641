import React from 'react'
import Icons from "../assets/svg/personLogo.svg";
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { showSuccessToast } from './ToastNotification';
import { useDispatch } from 'react-redux';
import { addWishlistCount } from '../redux/slices/wishList.slice';
import { addCartListCount } from '../redux/slices/cartList.slice';


const AccountsSideBar = () => {
    const navigate = useNavigate()
    const { pathname } = useLocation()
    const dispatch = useDispatch()
    return (
        <div className=' pl-3 md:border-r '>
            <div className='w-full flex gap-5 items-center border-b pt-8 pb-5 px-3 '>
                <img src={Icons} alt='Kyrah Star' className='w-[10%] sm:w-[8%] md:w-[15%]' />
                <p className='flex gap-1 text-18'>Hi, <span>{JSON.parse(localStorage.getItem('user-info'))?.FIRST_NAME}</span></p>
            </div>
            <div >
                <p className={`py-2 md:py-3 border-b px-3 hover:cursor-pointer ${pathname === '/accounts' ? 'bg-headerMenuBackground' : ''}`} onClick={() => navigate('/accounts')}>Account Overview</p>
                <p className={`py-2 md:py-3 border-b px-3 hover:cursor-pointer ${pathname === '/accounts/settings' ? 'bg-headerMenuBackground' : ''}`} onClick={() => navigate('/accounts/settings')}>Settings & Preference</p>
                <p className={`py-2 md:py-3 border-b px-3 hover:cursor-pointer`} onClick={() => navigate('/shop/cart')}>Shopping Bag</p>
                <p className={`py-2 md:py-3 border-b px-3 hover:cursor-pointer `} onClick={() => navigate('/shop/wish-list')}>Wish List</p>
                <p className={`py-2 md:py-3 border-b px-3 hover:cursor-pointer ${pathname === '/accounts/my-order' ? 'bg-headerMenuBackground' : ''}`} onClick={() => navigate('/accounts/my-order')}>My Order</p>
                <p className={`py-2 md:py-3 border-b px-3 hover:cursor-pointer hover:bg-headerMenuBackground`} onClick={() => { dispatch(addWishlistCount(0)); dispatch(addCartListCount(0)); showSuccessToast('Log Out Succlessfull !'); localStorage.setItem('currency', "INR"); localStorage.removeItem('user-info'); navigate('/'); }}>Sign Out</p>
            </div>
        </div>
    )
}

export default AccountsSideBar
