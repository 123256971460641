import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the Data Grid
import "ag-grid-community/styles/ag-theme-quartz.css";
import { Drawer } from "antd";
import React, { useEffect, useState } from "react";
import {
    FaChevronDown,
    FaChevronLeft,
    FaChevronRight,
    FaChevronUp,
} from "react-icons/fa";
import { RxCross2 } from "react-icons/rx";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Slider from "react-slick";
import DesignRingComponent from "../../components/DesignRingComponent";
import RangeSelector from "../../components/RangeSelector";
import {
    clarity,
    color,
    cut,
    flour,
    polish,
    Shape_Code,
    Shape_Code1,
    Shape_Code_Images,
    Shape_Images,
    shapeName,
    Table_Data
} from "../../helpers/jsonData";
import { axiosInstance } from "../../redux/api";
import ShowDiamondsTable from "./ShowDiamondsTable";
import { decryptData } from "../../components/decrypt";
import { useDispatch, useSelector } from "react-redux";
import { addshapeData } from "../../redux/slices/shape.slice";
import { decryptURL } from "../../utils/encrypt";

const LabDiamonds = () => {
    const { pathname, state } = useLocation();
    const data = useParams();
    // console.log(data, "data");

    const type = data?.type || ''; // Ensure type is a string, default to empty string if undefined
    const [skuAndVariant, diamondType, stValue] = type.split('&'); // Split by '&' to separate the values
    console.log(diamondType, 'type')
    // console.log(skuAndVariant, "skuAndVariant");
    // console.log(diamondType, "diamondType");
    // console.log(stValue, "stValue"); // Log the st value

    // Check if skuAndVariant is defined before splitting further
    const [skuName, variantId, shape_code] = skuAndVariant ? skuAndVariant.split('-') : [null, null]; // Safely handle undefined

    // console.log(skuAndVariant, "skuName");
    // console.log(variantId, "variantId");
    // console.log(pathname.split('/')[2], 'path')
    const defaultFilters = {
        PAGE_NUMBER: 1,
        // IS_LABGROWN: pathname.split('/')[2] === 'search-natural-diamonds' || pathname.split('/')[2] === 'colored-diamonds' ? 'no' : 'yes',
        IS_LABGROWN: diamondType === 'false' ? 'no' : 'yes',
        S_CODE: Shape_Code[pathname.split('/')[2]?.charAt(0).toUpperCase() + pathname.split("/")[2]?.slice(1)] || '',
        F_CARAT: 0.0,
        T_CARAT: 0.0,
        F_PRICE: 0,
        T_PRICE: 0,
        F_CT_CODE: 0,
        T_CT_CODE: 0,
        F_Q_CODE: 0,
        T_Q_CODE: 0,
        F_C_CODE: 0,
        T_C_CODE: 0,
        F_RATIO: 0.0,
        T_RATIO: 0.0,
        F_FL_CODE: 0,
        T_FL_CODE: 0,
        F_PL_CODE: 0,
        T_PL_CODE: 0,
        F_SY_CODE: 0,
        T_SY_CODE: 0,
        F_DEPTH: 0.0,
        T_DEPTH: 0.0,
        F_TABLE: 0.0,
        T_TABLE: 0.0,
        LAB_CODE: "",
        IS_FANCY: pathname.split('/')[2] === 'colored-diamonds' || pathname.split('/')[2] === 'lab-colored-diamonds' ? 'yes' : 'no',
        FANCY_INTEN: "",
        FANCY_OVER: "",
        FANCY_COLOR: "",
        ORDER_BY: 'PRICE_ASC'
    }
    const [isOriginOpen, setIsOriginOpen] = useState(true);
    const [isShapeOpen, setIsShapeOpen] = useState(true);
    const [isCaratOpen, setIsCaratOpen] = useState(true);
    const [isPriceOpen, setIsPriceOpen] = useState(true);
    const [isColorOpen, setIsColorOpen] = useState(false);
    const [isClarityOpen, setIsClarityOpen] = useState(false);
    const [isCutOpen, setIsCutOpen] = useState(false);
    const [isLWOpen, setIsLWOpen] = useState(false);
    const [isPolishOpen, setIsPolishOpen] = useState(false);
    const [isTableOpen, setIsTableOpen] = useState(false);
    const [isFluorOpen, setIsFluorOpen] = useState(false);
    const [isSymmOpen, setIsSymmOpen] = useState(false);
    const [isDepthOpen, setIsDepthOpen] = useState(false);
    const [isReportOpen, setIsReportOpen] = useState(false);
    const [isFeatureOpen, setIsFeatureOpen] = useState(false);
    const [isDeliveryOpen, setIsDeliveryOpen] = useState(false);
    const [isFancyColor, setIsFancyColor] = useState(false);
    const [filterArray, setFilterArray] = useState(defaultFilters);
    const [shapeFilter, setShapeFilter] = useState(defaultFilters);

    const shapes = useSelector(state => state.shapeData)
    const [shape, setShape] = useState(shapes.shapes.data);
    const [shapeData, setShapeData] = useState('');
    const [shapData, setShapData] = useState();
    const [open, setOpen] = useState(false);

    const { S_CODE, FANCY_COLOR, FANCY_INTEN, FANCY_OVER, F_CARAT, F_CT_CODE, F_C_CODE, F_DEPTH, F_FL_CODE, F_PL_CODE, F_PRICE, F_Q_CODE, F_RATIO, T_CARAT, T_CT_CODE, T_C_CODE, T_DEPTH, T_FL_CODE, T_PL_CODE, T_PRICE, T_Q_CODE, T_RATIO, T_SY_CODE, T_TABLE } = filterArray
    // const currency = JSON.parse(localStorage.getItem('user-info'))?.CURRENCY ?? 'INR'
    const currency = (localStorage.getItem('currency')) ?? 'INR'

    const dispatch = useDispatch()
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const filterChange = (value, filter) => {
        if (
            filter === "S_CODE" ||
            filter === "FANCY_COLOR" ||
            filter === "FANCY_INTEN" ||
            filter === "FANCY_OVER" ||
            filter === "LAB_CODE"
        ) {
            if (filterArray[filter]?.split("-")?.includes(value)) {
                setFilterArray((prev) => ({
                    ...prev,
                    [filter]: prev[filter]
                        .split("-")
                        .filter((data) => data !== value)
                        .join("-"),
                }));
            } else {
                setFilterArray((prev) => ({
                    ...prev,
                    [filter]:
                        prev[filter]?.length > 0 ? prev[filter] + "-" + value : value,
                }));
            }
        } else {
            setFilterArray({ ...filterArray, [filter]: value });
        }
    };

    const filterChangeShape = (value, filter) => {
        if (
            filter === "S_CODE"
        ) {
            if (shapeFilter[filter]?.split("-")?.includes(value)) {
                setShapeFilter((prev) => ({
                    ...prev,
                    [filter]: prev[filter]
                        .split("-")
                        .filter((data) => data !== value)
                        .join("-"),
                }));
            } else {
                setShapeFilter((prev) => ({
                    ...prev,
                    [filter]:
                        prev[filter]?.length > 0 ? prev[filter] + "-" + value : value,
                }));
            }
        } else {
            setShapeFilter({ ...shapeFilter, [filter]: value });
        }
    };

    // Generate Delivery Date array
    function generateDeliveryDateArray(startDate, count) {
        const weekdays = [];
        let currentDate = new Date(startDate);
        while (weekdays.length < count) {
            if (currentDate.getDay() !== 0 && currentDate.getDay() !== 6) {
                weekdays.push(new Date(currentDate));
            }
            currentDate.setDate(currentDate.getDate() + 1);
        }
        return weekdays;
    }

    function formatDate(date) {
        const options = { weekday: "long", month: "long", day: "numeric" };
        return date.toLocaleDateString("en-US", options);
    }

    const today = new Date();
    const threeDaysAhead = new Date(today);
    threeDaysAhead.setDate(today.getDate() + 3);
    const sevenWeekdays = generateDeliveryDateArray(threeDaysAhead, 7);
    const formattedDates = sevenWeekdays.map((date) => formatDate(date));

    const caratChange = (range) => {
        if ((range.min === 0.01 || range.min === '0.01') && (range.max === 30 || range.max === '30.00')) {
            setFilterArray((prev) => ({ ...prev, F_CARAT: 0, T_CARAT: 0 }));
        } else {
            setFilterArray((prev) => ({
                ...prev,
                F_CARAT: parseFloat(range.min).toFixed(2),
                T_CARAT: parseFloat(range.max).toFixed(2),
            }));
        }
    };
    const priceChange = (range) => {
        if ((range.min === 1 || range.min === '1') && (range.max === 9000000 || range.max === '9000000')) {
            setFilterArray((prev) => ({ ...prev, F_PRICE: 0, T_PRICE: 0 }));
        } else {
            setFilterArray((prev) => ({
                ...prev,
                F_PRICE: range.min,
                T_PRICE: range.max,
            }));
        }
    };
    const colorChange = (range) => {
        if (range.min === 1 && range.max === 11) {
            setFilterArray((prev) => ({ ...prev, F_C_CODE: 0, T_C_CODE: 0 }));
        } else {
            setFilterArray((prev) => ({
                ...prev,
                F_C_CODE: range.min,
                T_C_CODE: range.max - 1,
            }));
        }
    };
    const cutChange = (range) => {
        if (range.min === -1 && range.max === 4) {
            setFilterArray((prev) => ({ ...prev, F_CT_CODE: 0, T_CT_CODE: 0 }));
        } else {
            setFilterArray((prev) => ({
                ...prev,
                F_CT_CODE: range.min >= 0 ? range.min + 1 : range.min,
                T_CT_CODE: range.max === 0 ? range.max - 1 : range.max,
            }));
        }
    };
    const clarityChange = (range) => {
        if (range.min === -1 && range.max === 8) {
            setFilterArray((prev) => ({ ...prev, F_Q_CODE: 0, T_Q_CODE: 0 }));
        } else {
            setFilterArray((prev) => ({
                ...prev,
                F_Q_CODE: range.min >= 0 ? range.min + 1 : range.min,
                T_Q_CODE: range.max === 0 ? range.max - 1 : range.max,
            }));
        }
    };
    const lwChange = (range) => {
        console.log(range)
        if ((range.min === 1.00 || range.min === '1') && (range.max === 2.75 || range.max === '2.75')) {
            setFilterArray((prev) => ({ ...prev, F_RATIO: 0, T_RATIO: 0 }));
        } else {
            setFilterArray((prev) => ({
                ...prev,
                F_RATIO: parseFloat(range.min).toFixed(2),
                T_RATIO: parseFloat(range.max).toFixed(2),
            }));
        }
    };
    const polishChange = (range) => {
        if (range.min === 1 && range.max === 4) {
            setFilterArray((prev) => ({ ...prev, F_PL_CODE: 0, T_PL_CODE: 0 }));
        } else {
            setFilterArray((prev) => ({
                ...prev,
                F_PL_CODE: range.min,
                T_PL_CODE: range.max - 1,
            }));
        }
    };
    const tableChange = (range) => {
        if ((range.min === 45 || range.min === '45.00') && (range.max === 85 || range.max === '85.00')) {
            setFilterArray((prev) => ({ ...prev, F_TABLE: 0, T_TABLE: 0 }));
        } else {
            setFilterArray((prev) => ({
                ...prev,
                F_TABLE: parseFloat(range.min).toFixed(2),
                T_TABLE: parseFloat(range.max).toFixed(2),
            }));
        }
    };
    const fluorChange = (range) => {
        if (range.min === 1 && range.max === 8) {
            setFilterArray((prev) => ({ ...prev, F_FL_CODE: 0, T_FL_CODE: 0 }));
        } else {
            setFilterArray((prev) => ({
                ...prev,
                F_FL_CODE: range.min,
                T_FL_CODE: range.max - 1,
            }));
        }
    };
    const symmetryChange = (range) => {
        if (range.min === 1 && range.max === 4) {
            setFilterArray((prev) => ({ ...prev, F_SY_CODE: 0, T_SY_CODE: 0 }));
        } else {
            setFilterArray((prev) => ({
                ...prev,
                F_SY_CODE: range.min,
                T_SY_CODE: range.max - 1,
            }));
        }
    };
    const depthChange = (range) => {
        if ((range.min === 0.6 || range.min === '0.60') && (range.max === 85.9 || range.max === '85.90')) {
            setFilterArray((prev) => ({ ...prev, F_DEPTH: 0, T_DEPTH: 0 }));
        } else {
            setFilterArray((prev) => ({
                ...prev,
                F_DEPTH: parseFloat(range.min).toFixed(2),
                T_DEPTH: parseFloat(range.max).toFixed(2),
            }));
        }
    };

    const fetchShape = async () => {
        const response = await axiosInstance.post("/shape");
        const isDecryption = process.env.REACT_APP_IS_DECRYPT
        dispatch(addshapeData(isDecryption == 'true' ? decryptData(response.data).data : response.data.data.data))
        setShape(isDecryption == 'true' ? decryptData(response.data).data : response.data.data.data);
        // setShape(response.data.data);
    };

    const fetchDiamondShape = async () => {
        try {
            const requestBody = {
                PRD_VARIANT_ID: variantId,
                SKUNO: decryptURL(skuName)
            }
            const response = await axiosInstance.post('data/shape-product', requestBody);
            const isDecryption = process.env.REACT_APP_IS_DECRYPT
            setShapeData(isDecryption == 'true' ? decryptData(response.data).data[0] : response.data.data.data?.[0])
            // setShapeData(response?.data?.data?.[0])
        } catch (e) {
            console.log(e)
        }
    }

    // console.log(diamondType, "diamondType");


    useEffect(() => {
        if (diamondType) {
            fetchDiamondShape()
        }
    }, [diamondType])

    useEffect(() => {
        if (diamondType == undefined && !shape) {
            fetchShape();
        }
    }, [diamondType]);

    const CustomPrevArrow = (props) => {
        const { currentSlide, ...rest } = props;
        return (
            currentSlide !== 0 && (
                <button
                    {...rest}
                    className="text-[#2c2c2c] opacity-[0.5] text-11 slick-arrow absolute  left-[-15px] top-[35%] translate-y-[-50%] z-5"
                >
                    <FaChevronLeft color="black" />
                </button>
            )
        );
    };
    const CustomNextArrow = (props) => {
        const { currentSlide, slideCount, ...rest } = props;
        return (
            currentSlide !== slideCount - 4 && (
                <button
                    {...rest}
                    className="text-[#2c2c2c] opacity-[0.5] text-11 slick-arrow  absolute  right-[-10px] top-[35%] translate-y-[-50%] z-5"
                >
                    <FaChevronRight color="black" />
                </button>
            )
        );
    };

    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 3,
        prevArrow: <CustomPrevArrow />,
        nextArrow: <CustomNextArrow />,
    };

    const [getDiamond, setGetDiamond] = useState(false)
    const [getShapeDiamond, setGetShapeDiamond] = useState(false)

    useEffect(() => {
        const newFilterArray = { ...filterArray }
        const path = pathname.split("/");
        if (path[2] === 'colored-diamonds' || path[2] === 'lab-colored-diamonds') {
            setIsFancyColor(true)
            setIsColorOpen(true)
        }
        // 'IS_LABGROWN': path[2] === 'search-natural-diamonds' || path[2] === 'colored-diamonds' ? 'no' : 'yes',
        setFilterArray({
            ...newFilterArray, 'IS_FANCY': path[2] === 'colored-diamonds' || path[2] === 'lab-colored-diamonds' ? 'yes' : 'no',
            "S_CODE": Shape_Code[path[2]?.charAt(0).toUpperCase() + path[2]?.slice(1)] || '',
            'IS_LABGROWN': path[2] === 'search-natural-diamonds' || path[2] === 'colored-diamonds' || path[2] === 'start-with-a-diamond' || path[2] === 'design-your-own-diamond-ring' ? 'no' : 'yes',
        })
        if (path[2] === 'search-natural-diamonds' || path[2] === 'colored-diamonds' || path[2] === 'search-lab-diamonds' || path[2] === 'lab-colored-diamonds') {
            console.log('a')
            setShapData({})
            setFilterArray({
                ...newFilterArray, 'IS_FANCY': path[2] === 'colored-diamonds' || path[2] === 'lab-colored-diamonds' ? 'yes' : 'no',
                "S_CODE": Shape_Code[path[2]?.charAt(0).toUpperCase() + path[2]?.slice(1)] || '',
                'IS_LABGROWN': diamondType ? diamondType === 'true' ? 'yes' : 'no' : path[2] === 'search-natural-diamonds' || path[2] === 'colored-diamonds' ? 'no' : 'yes',
            })
        }
        console.log(path[2], 'path')
        const paths = ['search-natural-diamonds', 'colored-diamonds', 'search-lab-diamonds', 'lab-colored-diamonds', 'design-your-own-diamond-ring', 'design-your-own-lab-diamond-ring']
        if (shapeData && (!paths.includes(path[2]) && ((path[2] !== 'start-with-a-diamond' || skuName) && (path[2] !== 'start-with-a-lab-grown-diamond' || skuName)))) {
            console.log(shapeData, 'change')
            const ringShapesAvailable = shapeData?.RING_SHAPE_AVAIL || "";

            const formattedString = shapeData?.RING_SHAPE_AVAIL?.split(',')?.join('-'); // Join the array into a string with '-' as the separator

            const ringShapesArray = ringShapesAvailable.split(',').filter(Boolean);

            const diamondData = ringShapesArray?.map(shape => ({
                S_CODE: shape,
                name: Shape_Code1[shape],
                image: Shape_Code_Images[shape]
            }));
            setFilterArray({
                ...newFilterArray,
                S_CODE: shapeFilter?.S_CODE ? shapeFilter?.S_CODE : formattedString || '',
                IS_LABGROWN: diamondType ? diamondType === 'true' ? 'yes' : 'no' : path[2] === 'search-natural-diamonds' || path[2] === 'colored-diamonds' ? 'no' : 'yes'
            });
            setShapData(diamondData)
            setGetShapeDiamond(true)
        } else {
            setGetDiamond(true)
        }
        if (!path[3]) {
            setGetShapeDiamond(true)
        }
    }, [shapeData, data, shapeFilter])


    return (
        <>
            <div className="overflow-hidden mb-10">
                {/* <div className="mx-auto pt-3 pl-5  text-start">
                    <span className="text-12 text-lightBlack font-inter">
                        <span
                            onClick={() => navigate("/")}
                            className="cursor-pointer hover:underline"
                        >
                            Home
                        </span>
                        /
                        {pathname.split("/")[1] === "lab-diamond" ? (
                            "Lab Diamond"
                        ) : (
                            <>
                                <Link to="/diamonds" className="hover:underline">
                                    {" "}
                                    Diamond{" "}
                                </Link>
                                <span>
                                    /{" "}
                                    {pathname.split("/")[2]?.charAt(0).toUpperCase() +
                                        pathname.split("/")[2]?.slice(1)}
                                </span>
                            </>
                        )}
                    </span>
                </div> */}

                {/* Header Section  */}
                {/* {Natural_Diamond_Profile.map((data,index) => {
                    return (
                        data.title === pathname.split("/")[2] && (
                            <div
                                key={data.id}
                                className="w-[90%] md:w-[50%] mx-auto text-center mt-10"
                            >
                                <h1 className="text-30 md:text-36  font-inter ">
                                    {data.description}
                                </h1>
                                <p className="mt-3">{data.content}</p>
                            </div>
                        )
                    );
                })} */}


                {diamondType && <div className={`hidden md:block`}>
                    <DesignRingComponent
                        title={'Diamond Finder'}
                        desc={'Use our diamond search feature to find IGI and GIA-graded, conflict-free loose diamonds of the highest quality.'}
                        settings={diamondType}
                        skuno={skuName}
                        variantId={variantId}
                        Shape={shape_code ? shape_code : ""}
                        diamondInfo={{}}
                    />
                </div>}

                <div className="flex mx-auto overflow-auto justify-center gap-x-5 w-[95%] 2xl:w-[70%] mt-5 ">
                    <div className="w-[35%]  max-h-[90vh] overflow-y-auto scrollbar-hide font-inter mt-8 hidden lg:block">
                        <div className={`flex gap-[3px] items-center border-b py-2 flex-wrap sticky top-0 bg-white z-[15]`}>
                            {/* {shapeFilter.S_CODE ? <div className="flex flex-wrap gap-[3px]">{shapeFilter.S_CODE.split("-").map((data,index) => (
                                <span className="flex gap-[3px] items-center text-14 cursor-pointer" key={index}  onClick={() => filterChangeShape(data, "S_CODE")}>
                                    <span >{shapeName[data]}</span> <RxCross2 className="mt-[2px]" />
                                </span>
                            ))}</div> : null} */}
                            {filterArray.S_CODE ? <div className="flex flex-wrap gap-[3px]">{filterArray.S_CODE.split("-").map((data, index) => (
                                <span key={index} className="flex gap-[3px] items-center text-14 cursor-pointer" onClick={() => filterChange(data, "S_CODE")}>
                                    <span >{shapeName[data]}</span> <RxCross2 className="mt-[2px]" />
                                </span>
                            ))}</div> : null}
                            {filterArray.F_CARAT === 0 && filterArray.T_CARAT === 0 ? null : <div className="text-14 cursor-pointer" onClick={() => caratChange({ min: 0.01, max: 30 })}>{<span className="flex gap-[3px] items-center">{filterArray.F_CARAT + ' - ' + filterArray.T_CARAT + ' Carat'} <RxCross2 className="mt-[2px]" /></span>}</div>}
                            {filterArray.F_PRICE === 0 && filterArray.T_PRICE === 0 ? null : <div className="text-14 cursor-pointer" onClick={() => priceChange({ min: 1, max: 9000000 })}>{<span className="flex gap-[3px] items-center">{'$' + filterArray.F_PRICE + ' - $' + filterArray.T_PRICE + ' Price'} <RxCross2 className="mt-[2px]" /></span>}</div>}
                            {filterArray.F_CT_CODE === 0 && filterArray.T_CT_CODE === 0 ? null : <div className="text-14 cursor-pointer" onClick={() => cutChange({ min: -1, max: 4 })}>{filterArray.F_CT_CODE === filterArray.T_CT_CODE ? <span className="flex gap-[3px] items-center">{cut[filterArray.F_CT_CODE === -1 ? filterArray.F_CT_CODE + 1 : filterArray.F_CT_CODE] + ' Cut'} <RxCross2 className="mt-[2px]" /></span> : <span className="flex gap-[3px] items-center">{cut[filterArray.F_CT_CODE === -1 ? filterArray.F_CT_CODE + 1 : filterArray.F_CT_CODE] + ' - ' + cut[filterArray.T_CT_CODE] + ' Cut'} <RxCross2 className="mt-[2px]" /></span>}</div>}
                            {filterArray.F_C_CODE === 0 && filterArray.T_C_CODE === 0 ? null : <div className="text-14 cursor-pointer" onClick={() => colorChange({ min: 1, max: 11 })}>{filterArray.F_C_CODE === filterArray.T_C_CODE ? <span className="flex gap-[3px] items-center">{color[filterArray.F_C_CODE - 1] + ' Color'} <RxCross2 className="mt-[2px]" /></span> : <span className="flex gap-[3px] items-center">{color[filterArray.F_C_CODE - 1] + ' - ' + color[filterArray.T_C_CODE - 1] + ' Color'} <RxCross2 className="mt-[2px]" /></span>}</div>}
                            {filterArray.IS_FANCY === 'yes' ? <span className="flex flex-wrap"> {
                                filterArray.FANCY_INTEN ? <div className="flex gap-[3px] items-center">{filterArray.FANCY_INTEN.split("-").map((data, index) => (
                                    <span className="flex gap-[3px] items-center text-14 cursor-pointer" key={index} onClick={() => filterChange(data.toUpperCase(), "FANCY_INTEN")}>
                                        <span>{data.toLowerCase().charAt(0).toUpperCase() + data.toLowerCase().slice(1)}</span> <RxCross2 className="mt-[2px]" />
                                    </span>
                                ))}</div> : null
                            }
                                {filterArray.FANCY_OVER ? <div className="flex gap-[3px] items-center">{filterArray.FANCY_OVER.split("-").map((data, index) => (
                                    <span className="flex gap-[3px] items-center text-14 cursor-pointer" key={index} onClick={() => filterChange(data.toUpperCase(), "FANCY_OVER")}>
                                        <span>{data.toLowerCase().charAt(0).toUpperCase() + data.toLowerCase().slice(1)}</span> <RxCross2 className="mt-[2px]" />
                                    </span>
                                ))}</div> : null}
                                {filterArray.FANCY_COLOR ? <div className="flex gap-[3px] items-center">{filterArray.FANCY_COLOR.split("-").map((data, index) => (
                                    <span className="flex gap-[3px] items-center text-14 cursor-pointer" key={index} onClick={() => filterChange(data.toUpperCase(), "FANCY_COLOR")}>
                                        <span>{data.toLowerCase().charAt(0).toUpperCase() + data.toLowerCase().slice(1)}</span> <RxCross2 className="mt-[2px]" />
                                    </span>
                                ))}</div> : null}</span> : null}
                            {filterArray.F_Q_CODE === 0 && filterArray.T_Q_CODE === 0 ? null : <div className="text-14 cursor-pointer" onClick={() => clarityChange({ min: -1, max: 8 })}>{filterArray.F_Q_CODE === filterArray.T_Q_CODE ? <span className="flex gap-[3px] items-center">{clarity[filterArray.F_Q_CODE === -1 ? filterArray.F_Q_CODE + 1 : filterArray.F_Q_CODE] + ' Clarity'} <RxCross2 className="mt-[2px]" /></span> : <span className="flex gap-[3px] items-center">{clarity[filterArray.F_Q_CODE === -1 ? filterArray.F_Q_CODE + 1 : filterArray.F_Q_CODE] + ' - ' + clarity[filterArray.T_Q_CODE] + ' Clarity'} <RxCross2 className="mt-[2px]" /></span>}</div>}
                            {filterArray.F_RATIO === 0 && filterArray.T_RATIO === 0 ? null : <div className="text-14 cursor-pointer" onClick={() => lwChange({ min: 1.00, max: 2.75 })}>{<span className="flex gap-[3px] items-center">{filterArray.F_RATIO + ' - ' + filterArray.T_RATIO + ' Ratio'} <RxCross2 className="mt-[2px]" /></span>}</div>}
                            {filterArray.F_FL_CODE === 0 && filterArray.T_FL_CODE === 0 ? null : <div className="text-14 cursor-pointer" onClick={() => fluorChange({ min: 1, max: 8 })}>{filterArray.F_FL_CODE === filterArray.T_FL_CODE ? <span className="flex gap-[3px] items-center">{flour[filterArray.F_FL_CODE] + ' Cut'} <RxCross2 className="mt-[2px]" /></span> : <span className="flex gap-[3px] items-center">{flour[filterArray.F_FL_CODE - 1] + ' - ' + flour[filterArray.T_FL_CODE - 1] + ' Flourescence'} <RxCross2 className="mt-[2px]" /></span>}</div>}
                            {filterArray.F_PL_CODE === 0 && filterArray.T_PL_CODE === 0 ? null : <div className="text-14 cursor-pointer" onClick={() => polishChange({ min: 1, max: 4 })}>{filterArray.F_PL_CODE === filterArray.T_PL_CODE ? <span className="flex gap-[3px] items-center">{polish[filterArray.F_PL_CODE - 1] + ' Cut'} <RxCross2 className="mt-[2px]" /></span> : <span className="flex gap-[3px] items-center">{polish[filterArray.F_PL_CODE - 1] + ' - ' + polish[filterArray.T_PL_CODE - 1] + ' Polish'} <RxCross2 className="mt-[2px]" /></span>}</div>}
                            {filterArray.F_SY_CODE === 0 && filterArray.T_SY_CODE === 0 ? null : <div className="text-14 cursor-pointer" onClick={() => symmetryChange({ min: 1, max: 4 })}>{filterArray.F_SY_CODE === filterArray.T_SY_CODE ? <span className="flex gap-[3px] items-center">{polish[filterArray.F_SY_CODE - 1] + ' Cut'} <RxCross2 className="mt-[2px]" /></span> : <span className="flex gap-[3px] items-center">{polish[filterArray.F_SY_CODE - 1] + ' - ' + polish[filterArray.T_SY_CODE - 1] + ' Symmetry'} <RxCross2 className="mt-[2px]" /></span>}</div>}
                            {filterArray.F_TABLE === 0 && filterArray.T_TABLE === 0 ? null : <div className="text-14 cursor-pointer" onClick={() => tableChange({ min: 45.00, max: 85.00 })}>{<span className="flex gap-[3px] items-center">{filterArray.F_TABLE + ' - ' + filterArray.T_TABLE + ' Table'} <RxCross2 className="mt-[2px]" /></span>}</div>}
                            {filterArray.F_DEPTH === 0 && filterArray.T_DEPTH === 0 ? null : <div className="text-14 cursor-pointer" onClick={() => depthChange({ min: 0.60, max: 85.90 })}>{<span className="flex gap-[3px] items-center">{filterArray.F_DEPTH + ' - ' + filterArray.T_DEPTH + ' Depth'} <RxCross2 className="mt-[2px]" /></span>}</div>}
                            {filterArray.LAB_CODE ? <div className="flex gap-[3px] items-center">{filterArray.LAB_CODE.split("-").map((data, index) => (
                                <span className="flex gap-[3px] items-center text-14 cursor-pointer" key={index} onClick={() => filterChange(data.toUpperCase(), "LAB_CODE")}>
                                    <span>{data}</span> <RxCross2 className="mt-[2px]" />
                                </span>
                            ))}</div> : null}
                            {(S_CODE || FANCY_COLOR || FANCY_INTEN || FANCY_OVER || F_CARAT || F_CT_CODE || F_C_CODE || F_DEPTH || F_FL_CODE || F_PL_CODE || F_PRICE || F_Q_CODE || F_RATIO || T_CARAT || T_CT_CODE || T_C_CODE || T_DEPTH || T_FL_CODE || T_PL_CODE || T_PRICE || T_Q_CODE || T_RATIO || T_SY_CODE || T_TABLE || filterArray.LAB_CODE) ? <span className={`flex gap-[3px] items-center text-14 cursor-pointer ${shapeFilter.S_CODE === Shape_Code[pathname.split('/')[2]?.charAt(0)?.toUpperCase() + pathname.split("/")[2]?.slice(1)] ? 'hidden' : ''}`} onClick={() => setFilterArray(defaultFilters)}>
                                <span>Reset All</span> <RxCross2 className="mt-[2px]" />
                            </span> : null}
                        </div>
                        <div className={`border-y font-inter `}>
                            <div className="w-[100%] my-3 text-[#2c2c2c]">
                                <p
                                    className="text-filterHeading font-filterHeading  font-inter  cursor-pointer flex items-center justify-between"
                                    onClick={() => setIsOriginOpen(!isOriginOpen)}
                                >
                                    Diamond Origin{" "}
                                    <p className="text-[#2c2c2c] opacity-[0.5] text-16 ">
                                        {isOriginOpen ? <FaChevronUp /> : <FaChevronDown />}
                                    </p>
                                </p>
                                <div
                                    className={`flex transition-all duration-200 ease-in-out ${isOriginOpen
                                        ? "max-h-[500px] opacity-100 mb-4 pointer-events-auto"
                                        : "mb-0 max-h-0 opacity-0 pointer-events-none "
                                        }`}
                                >
                                    {[ "Lab Grown Diamonds"]?.map(
                                        (data, index) => {
                                            return (
                                                <div
                                                    key={index}
                                                    className={`lg:px-4 px-1 py-[6px] border-[1px] border-[#2c2c2c]   border-solid rounded-full mr-2 mt-2 ${filterArray.IS_LABGROWN === "yes" &&
                                                        data === "Lab Grown Diamonds"
                                                        ? "border-footerBackground bg-footerBackground hover:bg-footerBackground hover:text-white text-white"
                                                        : filterArray.IS_LABGROWN === "no" &&
                                                            data === "Natural Diamonds"
                                                            ? "border-footerBackground bg-footerBackground hover:bg-footerBackground hover:text-white text-white"
                                                            : "hover:bg-[#e8e8e8] hover:text-footerBackground hover:border-[#e8e8e8]"
                                                        }`}
                                                    onClick={() =>
                                                        filterChange(
                                                            data === "Lab Grown Diamonds" ? "yes" : "no",
                                                            "IS_LABGROWN"
                                                        )
                                                    }
                                                >
                                                    <div key={index} className="  rounded-full">
                                                        <p className=" text-center text-12">{data}</p>
                                                    </div>
                                                </div>
                                            );
                                        }
                                    )}
                                </div>
                            </div>
                        </div>

                        <div className="font-inter py-3  border-b">
                            <p
                                className="text-filterHeading font-filterHeading font-inter flex justify-between items-center cursor-pointer"
                                onClick={() => setIsShapeOpen(!isShapeOpen)}
                            >
                                Diamond Shape
                                <p className="text-[#2c2c2c] opacity-[0.5] text-16 ">
                                    {isShapeOpen ? <FaChevronUp /> : <FaChevronDown />}
                                </p>
                            </p>
                            <div
                                className={`grid grid-cols-4 transition-all duration-200 ease-in-out ${isShapeOpen
                                    ? "max-h-[500px] opacity-100 mb-4 pointer-events-auto"
                                    : "mb-0 max-h-0 opacity-0 pointer-events-none  "
                                    }`}
                            >

                                {
                                    diamondType === undefined ?
                                        shape?.[0]?.map((data, index) => {
                                            return (
                                                <div className=" " key={index}>
                                                    <div
                                                        className={`hover:text-hover  rounded-full`}
                                                        // onClick={() => changeFilterData(data.S_NAME, "shape")}
                                                        onClick={() => filterChange(data.S_CODE, "S_CODE")}
                                                    >
                                                        <img
                                                            src={Shape_Images[data.S_NAME]}
                                                            alt="kyrah star"
                                                            className={`p-1 bg-[#e8e8e8] rounded-full mx-auto w-10 mt-2 ${filterArray?.S_CODE?.split("-")?.includes(data.S_CODE)
                                                                ? "border-filterHeadingtext border-2 border-solid"
                                                                : ""
                                                                }`}
                                                        />
                                                        <p className=" text-14 text-center">{data.S_NAME}</p>
                                                    </div>
                                                </div>
                                            );
                                        })
                                        :
                                        shapData?.map((data, index) => {
                                            return (
                                                <div className=" " key={index}>
                                                    <div
                                                        className={`hover:text-hover rounded-full cursor-pointer`}
                                                        onClick={() => filterChangeShape(data.S_CODE, "S_CODE")} // Change filter based on S_CODE
                                                    >
                                                        <img
                                                            src={data?.image} // Display the correct image for the shape code
                                                            alt={data.S_CODE} // Display name as alt text
                                                            className={`p-1 bg-[#e8e8e8] rounded-full mx-auto w-10 mt-2 ${shapeFilter?.S_CODE?.split("-")?.includes(data.S_CODE)
                                                                ? "border-filterHeadingtext border-2 border-solid"
                                                                : ""
                                                                }`}
                                                        />
                                                        <p className="text-14 text-center">{data.name}</p>
                                                    </div>
                                                </div>
                                            );
                                        })
                                }
                            </div>
                        </div>

                        <div className="mt-3 border-b font-inter text-[#2c2c2c]">
                            <p
                                className="text-filterHeading font-filterHeading mb-3 flex justify-between items-center cursor-pointer"
                                onClick={() => setIsCaratOpen(!isCaratOpen)}
                            >
                                Carat{" "}
                                <p className="text-[#2c2c2c] opacity-[0.5] text-16 ">
                                    {isCaratOpen ? <FaChevronUp /> : <FaChevronDown />}
                                </p>
                            </p>
                            <div
                                className={` w-[95%] transition-all duration-200 ease-in-out  text-14 ${isCaratOpen
                                    ? "max-h-[500px] opacity-100 mb-4 pointer-events-auto"
                                    : "mb-0 max-h-0 opacity-0 pointer-events-none "
                                    }`}
                            >
                                <RangeSelector
                                    min={0.01}
                                    max={30.00}
                                    step={0.01}
                                    input={{ min: filterArray.F_CARAT, max: filterArray.T_CARAT }}
                                    onChange={caratChange}
                                />
                            </div>
                        </div>

                        <div className="mt-3 border-b font-inter text-[#2c2c2c]">
                            <p
                                className="text-filterHeading font-filterHeading mb-3 flex justify-between items-center cursor-pointer"
                                onClick={() => setIsPriceOpen(!isPriceOpen)}
                            >
                                Diamond Price{" "}
                                <p className="text-[#2c2c2c] opacity-[0.5] text-16 ">
                                    {isPriceOpen ? <FaChevronUp /> : <FaChevronDown />}
                                </p>
                            </p>
                            <div
                                className={` w-[95%] transition-all duration-200 ease-in-out  text-14 ${isPriceOpen ? "max-h-[500px]  mb-4" : "mb-0 max-h-0 opacity-0 "
                                    }`}
                            >
                                <RangeSelector
                                    min={1}
                                    max={9000000}
                                    step={1}
                                    label={currency === 'USD' ? '$' : '₹'}
                                    input={{ min: filterArray.F_PRICE, max: filterArray.T_PRICE }}
                                    onChange={priceChange}
                                />
                            </div>
                        </div>

                        {/* <div className=" border-y font-inter hidden">
                            <div className="w-[100%] my-3 text-[#2c2c2c]">
                                <p
                                    className="text-filterHeading font-filterHeading flex justify-between items-center cursor-pointer"
                                    onClick={() => setIsFilterOpen(!isFilterOpen)}
                                >
                                    What are you looking for?{" "}
                                    <p className="text-[#2c2c2c] opacity-[0.5] text-16 ">
                                        {isFilterOpen ? <FaChevronUp /> : <FaChevronDown />}
                                    </p>
                                </p>
                                <div
                                    className={`  transition-all duration-200 ease-in-out text-14 ${isFilterOpen
                                        ? "max-h-[500px] opacity-100 mb-4 pointer-events-auto"
                                        : "mb-0 max-h-0 opacity-0 pointer-events-none "
                                        }`}
                                >
                                    {" "}
                                    <div className="flex ">
                                        {["Most Sparkle", "Best Balance", "Ultra Ideal"]?.map(
                                            (data, index) => {
                                                return (
                                                    <div
                                                        key={index}
                                                        className={`px-4 px-1 py-2 border-2  hover:border-[#2c2c2c] border-solid rounded-full mr-2 mt-2 ${data === filterData.origin ? "border-[#183E40]" : ""
                                                            }`}
                                                    // onClick={() => changeFilterData(data, "origin")}
                                                    >
                                                        <div
                                                            key={index}
                                                            className="hover:text-hover  rounded-full"
                                                        >
                                                            <p className=" text-center text-12">{data}</p>
                                                        </div>
                                                    </div>
                                                );
                                            }
                                        )}
                                    </div>
                                    <span className="text-12 mt-1">
                                        Super ideal cuts that create maximum shine.
                                    </span>
                                </div>
                            </div>
                        </div> */}

                        <div className="mt-3 border-b font-inter text-[#2c2c2c] cursor-pointer">
                            <p
                                className="text-filterHeading font-filterHeading mb-3 flex justify-between items-center cursor-pointer"
                                onClick={() => setIsCutOpen(!isCutOpen)}
                            >
                                Cut
                                <p className="text-[#2c2c2c] opacity-[0.5] text-16  ">
                                    {isCutOpen ? <FaChevronUp /> : <FaChevronDown />}
                                </p>
                            </p>
                            {/* <FaExclamationCircle size={14} className="mt-1 ml-2 text-[#e8e8e8]" /> */}
                            <div
                                className={` w-[95%] transition-all duration-200 ease-in-out  text-14 ${isCutOpen
                                    ? "max-h-[500px] opacity-100 mb-4 pointer-events-auto"
                                    : "mb-0 max-h-0 opacity-0 pointer-events-none "
                                    }`}
                            >
                                <RangeSelector
                                    min={-1}
                                    max={4}
                                    step={1}
                                    input={{ min: filterArray.F_CT_CODE, max: filterArray.T_CT_CODE }}
                                    stepLabels={["Ideal", "Excellent", "Very Good", "Good", "Fair"]}
                                    onChange={cutChange}
                                />
                            </div>
                        </div>

                        <div className="mt-3 border-b font-inter text-[#2c2c2c]">
                            <div
                                className="flex my-3 justify-between items-center "
                                onClick={() => setIsColorOpen(!isColorOpen)}
                            >
                                <p className="text-filterHeading font-filterHeading  gap-3 flex w-[95%]  cursor-pointer">
                                    Color
                                    <p
                                        className=" items-center flex cursor-pointer"
                                        onClick={(e) => {
                                            isColorOpen && e.stopPropagation();
                                            setFilterArray({
                                                ...filterArray,
                                                IS_FANCY: !isFancyColor ? "yes" : "no",
                                            });
                                            setIsFancyColor(!isFancyColor);
                                        }}
                                    >
                                        <input
                                            type="checkbox"
                                            className="mr-1"
                                            checked={filterArray.IS_FANCY === 'yes' ? true : false}
                                        />
                                        <p className="">Fancy Color</p>
                                    </p>
                                </p>
                                <p className="text-[#2c2c2c] opacity-[0.5] text-16  cursor-pointer">
                                    {isColorOpen ? <FaChevronUp /> : <FaChevronDown />}
                                </p>
                            </div>
                            {/* <FaExclamationCircle size={14} className="mt-1 ml-2 text-[#e8e8e8]" /> */}
                            {filterArray.IS_FANCY === 'yes' && isColorOpen ? (
                                <div
                                    className={`${isColorOpen
                                        ? "max-h-[500px] opacity-100 mb-4 pointer-events-auto"
                                        : "mb-0 max-h-0 opacity-0 pointer-events-none "
                                        }`}
                                >
                                    <div className="my-3">
                                        <p className="text-16  mb-2 w-[70%]">Intensity :</p>
                                        <Slider {...settings} className="w-[92%] mx-auto">
                                            {[
                                                "Blue",
                                                "Deep",
                                                "Faint",
                                                "Green",
                                                "Intense",
                                                "Light",
                                                "No",
                                                "Orange",
                                                "Pink",
                                                "Verylight",
                                                "Vivid",
                                                "Yellow",
                                            ].map((data, index) => {
                                                return (
                                                    <div
                                                        className="text-center  text-14 mr-2"
                                                        key={index}
                                                        onClick={() =>
                                                            filterChange(data.toUpperCase(), "FANCY_INTEN")
                                                        }
                                                    >
                                                        <div
                                                            className={` ${filterArray?.FANCY_INTEN?.split("-")?.includes(
                                                                data.toUpperCase()
                                                            )
                                                                ? "border-[#183E40] border-[1px] border-solid"
                                                                : ""
                                                                }border mr-2 truncate`}
                                                        >
                                                            {data}
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </Slider>
                                    </div>
                                    <div className="">
                                        <p className="text-16 mb-2  w-[70%]">Over Tone :</p>
                                        <Slider {...settings} className="w-[92%] mx-auto">
                                            {[
                                                "Blue",
                                                "Bluish",
                                                "Brown",
                                                "Brownish",
                                                "Brownish Greenish",
                                                "Deep",
                                                "Green",
                                                "Greenish",
                                                "Greyish",
                                                "Greyish Greenish",
                                                "Intense",
                                                "No",
                                                "Orange",
                                                "Orangy",
                                                "Pink",
                                                "Pinkish",
                                                "Purple",
                                                "Purplish",
                                                "Red",
                                                "Reddish",
                                                "Vivid",
                                                "Yellow",
                                                "Yellowish",
                                            ].map((data, index) => {
                                                return (
                                                    <div
                                                        className="text-center text-14  mr-2"
                                                        key={index}
                                                        onClick={() =>
                                                            filterChange(data.toUpperCase(), "FANCY_OVER")
                                                        }
                                                    >
                                                        <div
                                                            className={`border mr-2 truncate ${filterArray?.FANCY_OVER?.split("-")?.includes(
                                                                data.toUpperCase()
                                                            )
                                                                ? "border-[#183E40] border-[1px] border-solid"
                                                                : ""
                                                                }`}
                                                        >
                                                            {data}
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </Slider>
                                    </div>
                                    <div className="">
                                        <p className="text-16  mb-2 w-[70%]">Color :</p>
                                        <Slider {...settings} className="w-[92%] mx-auto">
                                            {[
                                                "Blue",
                                                "Bluish",
                                                "Brown",
                                                "Brownish",
                                                "Green",
                                                "No",
                                                "Orange",
                                                "Pink",
                                                "Purple",
                                                "Red",
                                                "Yellow",
                                            ].map((data, index) => {
                                                return (
                                                    <div
                                                        className="text-center text-14  mr-2"
                                                        key={index}
                                                        onClick={() =>
                                                            filterChange(data.toUpperCase(), "FANCY_COLOR")
                                                        }
                                                    >
                                                        <div
                                                            className={`border mr-2 truncate ${filterArray?.FANCY_COLOR?.split("-")?.includes(
                                                                data.toUpperCase()
                                                            )
                                                                ? "border-[#183E40] border-[1px] border-solid"
                                                                : ""
                                                                }`}
                                                        >
                                                            {data}
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </Slider>
                                    </div>
                                </div>
                            ) : (
                                isColorOpen && (
                                    <div
                                        className={` w-[95%] transition-all duration-200 ease-in-out  text-16 ${isColorOpen
                                            ? "max-h-[500px] opacity-100 mb-4 pointer-events-auto"
                                            : "mb-0 max-h-0 opacity-0 pointer-events-none "
                                            }`}
                                    >
                                        <RangeSelector
                                            min={1}
                                            max={11}
                                            step={1}
                                            stepLabels={[
                                                "D",
                                                "E",
                                                "F",
                                                "G",
                                                "H",
                                                "I",
                                                "J",
                                                "K",
                                                "L",
                                                "M",
                                            ]}
                                            input={{ min: filterArray.F_C_CODE, max: filterArray.T_C_CODE }}
                                            onChange={colorChange}
                                        />
                                    </div>
                                )
                            )}
                        </div>

                        <div className="mt-3 border-b font-inter text-[#2c2c2c]">
                            <p
                                className="text-filterHeading font-filterHeading mb-3 flex items-center justify-between cursor-pointer"
                                onClick={() => setIsClarityOpen(!isClarityOpen)}
                            >
                                Clarity{" "}
                                <p className="text-[#2c2c2c] opacity-[0.5] text-16 ">
                                    {isClarityOpen ? <FaChevronUp /> : <FaChevronDown />}
                                </p>
                            </p>
                            {/* <FaExclamationCircle size={14} className="mt-1 ml-2 text-[#e8e8e8]" /> */}
                            <div
                                className={` w-[95%] transition-all duration-200 ease-in-out  text-14 ${isClarityOpen
                                    ? "max-h-[500px] opacity-100 mb-4 pointer-events-auto"
                                    : "mb-0 max-h-0 opacity-0 pointer-events-none "
                                    }`}
                            >
                                <RangeSelector
                                    min={-1}
                                    max={8}
                                    step={1}
                                    stepLabels={[
                                        "FL",
                                        "IF",
                                        "VVS1",
                                        "VVS2",
                                        "VS1",
                                        "VS1",
                                        "SI1",
                                        "SI2",
                                        "SI3",
                                    ]}
                                    input={{ min: filterArray.F_Q_CODE, max: filterArray.T_Q_CODE }}
                                    onChange={clarityChange}
                                />
                            </div>
                        </div>

                        {/* <div className=" border-y font-inter hidden">
                            <div className="w-[100%] my-3 text-[#2c2c2c]">
                                <p
                                    className="text-filterHeading font-filterHeading flex items-center justify-between cursor-pointer"
                                    onClick={() => setIsCollectionOpen(!isCollectionOpen)}
                                >
                                    Our Diamond Collection{" "}
                                    <p className="text-[#2c2c2c] opacity-[0.5] text-16 ">
                                        {isCollectionOpen ? <FaChevronUp /> : <FaChevronDown />}
                                    </p>
                                </p>
                                <div
                                    className={`  transition-all duration-200 ease-in-out  text-14 ${isCollectionOpen
                                        ? "max-h-[500px] opacity-100 mb-4 pointer-events-auto"
                                        : "mb-0 max-h-0 opacity-0 pointer-events-none "
                                        }`}
                                >
                                    <span className="text-12">
                                        A curated assortment of our most innovative diamonds
                                    </span>
                                    {[
                                        "Carbon Capture",
                                        "100% Renewable Energy ",
                                        "Sustainably Rated ",
                                        "Truly Brilliant",
                                    ].map((data, index) => {
                                        return (
                                            <div key={index}>
                                                <input
                                                    type="checkbox"
                                                    className="mr-1"
                                                    value="IGI"
                                                // onChange={(e) =>
                                                //     changeFilterData(e.target.value, "report")
                                                // }
                                                />
                                                <span className="mr-3">{data}</span>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div> */}

                        <div className="mt-3 border-b font-inter text-[#2c2c2c]">
                            <p
                                className="text-filterHeading font-filterHeading mb-3 flex justify-between items-center cursor-pointer"
                                onClick={() => setIsLWOpen(!isLWOpen)}
                            >
                                L:W Ratio{" "}
                                <p className="text-[#2c2c2c] opacity-[0.5] text-16 ">
                                    {isLWOpen ? <FaChevronUp /> : <FaChevronDown />}
                                </p>
                            </p>
                            {/* <FaExclamationCircle size={14} className="mt-1 ml-2 text-[#e8e8e8]" /> */}
                            <div
                                className={` w-[95%] transition-all duration-200 ease-in-out  text-14 ${isLWOpen
                                    ? "max-h-[500px] opacity-100 mb-4 pointer-events-auto"
                                    : "mb-0 max-h-0 opacity-0 pointer-events-none "
                                    }`}
                            >
                                <RangeSelector
                                    min={1.0}
                                    max={2.75}
                                    step={0.01}
                                    input={{ min: filterArray.F_RATIO, max: filterArray.T_RATIO }}
                                    onChange={lwChange}
                                />
                            </div>
                        </div>
                        <div className="mt-3 text-[#2c2c2c] w-full border-b font-inter">
                            <p
                                className="text-filterHeading font-filterHeading mb-3 flex justify-between items-center cursor-pointer"
                                onClick={() => setIsFluorOpen(!isFluorOpen)}
                            >
                                Fluorescence{" "}
                                <p className="text-[#2c2c2c] opacity-[0.5] text-16 ">
                                    {isFluorOpen ? <FaChevronUp /> : <FaChevronDown />}
                                </p>
                            </p>
                            {/* <FaExclamationCircle size={14} className="mt-1 ml-2 text-[#e8e8e8]" /> */}
                            <div
                                className={` w-[95%] transition-all duration-200 ease-in-out  text-14 ${isFluorOpen
                                    ? "max-h-[500px] opacity-100 mb-4 pointer-events-auto"
                                    : "mb-0 max-h-0 opacity-0 pointer-events-none "
                                    }`}
                            >
                                <RangeSelector
                                    min={1}
                                    max={8}
                                    step={1}
                                    input={{ min: filterArray.F_FL_CODE, max: filterArray.T_FL_CODE }}
                                    stepLabels={[
                                        "None",
                                        "Faint",
                                        "Medium",
                                        "Strong",
                                        "Very Strong",
                                        "Slight",
                                        "Very Slight",
                                    ]}
                                    onChange={fluorChange}
                                />
                            </div>
                        </div>
                        <div className="mt-3 border-b font-inter text-[#2c2c2c]">
                            <p
                                className="text-filterHeading font-filterHeading mb-3 flex justify-between items-center cursor-pointer"
                                onClick={() => setIsPolishOpen(!isPolishOpen)}
                            >
                                Polish{" "}
                                <p className="text-[#2c2c2c] opacity-[0.5] text-16 ">
                                    {isPolishOpen ? <FaChevronUp /> : <FaChevronDown />}
                                </p>
                            </p>
                            {/* <FaExclamationCircle size={14} className="mt-1 ml-2 text-[#e8e8e8]" /> */}
                            <div
                                className={` w-[95%] transition-all duration-200 ease-in-out  text-14 ${isPolishOpen
                                    ? "max-h-[500px] opacity-100 mb-4 pointer-events-auto"
                                    : "mb-0 max-h-0 opacity-0 pointer-events-none "
                                    }`}
                            >
                                <RangeSelector
                                    min={1}
                                    max={4}
                                    step={1}
                                    input={{ min: filterArray.F_PL_CODE, max: filterArray.T_PL_CODE }}
                                    stepLabels={["Excellent", "Very Good", "Good"]}
                                    onChange={polishChange}
                                />
                            </div>
                        </div>
                        <div className="mt-3 text-[#2c2c2c] w-full border-b font-inter">
                            <p
                                className="text-filterHeading font-filterHeading mb-3 flex justify-between items-center cursor-pointer"
                                onClick={() => setIsSymmOpen(!isSymmOpen)}
                            >
                                Symmetry{" "}
                                <p className="text-[#2c2c2c] opacity-[0.5] text-16 ">
                                    {isSymmOpen ? <FaChevronUp /> : <FaChevronDown />}
                                </p>
                            </p>
                            {/* <FaExclamationCircle size={14} className="mt-1 ml-2 text-[#e8e8e8]" /> */}
                            <div
                                className={` w-[95%] transition-all duration-200 ease-in-out  text-14 ${isSymmOpen
                                    ? "max-h-[500px] opacity-100 mb-4 pointer-events-auto"
                                    : "mb-0 max-h-0 opacity-0 pointer-events-none "
                                    }`}
                            >
                                <RangeSelector
                                    min={1}
                                    max={4}
                                    step={1}
                                    input={{ min: filterArray.F_SY_CODE, max: filterArray.T_SY_CODE }}
                                    stepLabels={["Excellent", "Very Good", "Good"]}
                                    onChange={symmetryChange}
                                />
                            </div>
                        </div>
                        <div className="mt-3 border-b font-inter text-[#2c2c2c]">
                            <p
                                className="text-filterHeading font-filterHeading mb-3 flex justify-between items-center cursor-pointer"
                                onClick={() => setIsTableOpen(!isTableOpen)}
                            >
                                Table %{" "}
                                <p className="text-[#2c2c2c] opacity-[0.5] text-16 ">
                                    {isTableOpen ? <FaChevronUp /> : <FaChevronDown />}
                                </p>
                            </p>
                            {/* <FaExclamationCircle size={14} className="mt-1 ml-2 text-[#e8e8e8]" /> */}
                            <div
                                className={` w-[95%] transition-all duration-200 ease-in-out  text-14 ${isTableOpen
                                    ? "max-h-[500px] opacity-100 mb-4 pointer-events-auto"
                                    : "mb-0 max-h-0 opacity-0 pointer-events-none "
                                    }`}
                            >
                                <RangeSelector
                                    min={45.0}
                                    max={85.0}
                                    step={0.01}
                                    input={{ min: filterArray.F_TABLE, max: filterArray.T_TABLE }}
                                    onChange={tableChange}
                                    label="%"
                                />
                            </div>
                        </div>
                        <div className="mt-3 text-[#2c2c2c] border-b font-inter">
                            <p
                                className="text-filterHeading font-filterHeading mb-3 flex justify-between items-center cursor-pointer"
                                onClick={() => setIsDepthOpen(!isDepthOpen)}
                            >
                                Depth %{" "}
                                <p className="text-[#2c2c2c] opacity-[0.5] text-16 ">
                                    {isDepthOpen ? <FaChevronUp /> : <FaChevronDown />}
                                </p>
                            </p>
                            {/* <FaExclamationCircle size={14} className="mt-1 ml-2 text-[#e8e8e8]" /> */}
                            <div
                                className={` w-[95%] transition-all duration-200 ease-in-out  text-14 ${isDepthOpen
                                    ? "max-h-[500px] opacity-100 mb-4 pointer-events-auto"
                                    : "mb-0 max-h-0 opacity-0 pointer-events-none "
                                    }`}
                            >
                                <RangeSelector
                                    min={0.6}
                                    max={85.9}
                                    step={0.01}
                                    input={{ min: filterArray.F_DEPTH, max: filterArray.T_DEPTH }}
                                    onChange={depthChange}
                                    label="%"
                                />
                            </div>
                        </div>

                        <div className=" border-y font-inter">
                            <div className="w-[100%] my-3 text-[#2c2c2c]">
                                <p
                                    className="text-filterHeading font-filterHeading flex justify-between items-center cursor-pointer"
                                    onClick={() => setIsReportOpen(!isReportOpen)}
                                >
                                    Report{" "}
                                    <p className="text-[#2c2c2c] opacity-[0.5] text-16 ">
                                        {isReportOpen ? <FaChevronUp /> : <FaChevronDown />}
                                    </p>
                                </p>
                                <div
                                    className={` transition-all duration-200 ease-in-out  text-14 ${isReportOpen
                                        ? "max-h-[500px] opacity-100 mb-4 pointer-events-auto"
                                        : "mb-0 max-h-0 opacity-0 pointer-events-none "
                                        }`}
                                >
                                    {["IGI", "GIA", "GCAL"].map((data, index) => {
                                        return (
                                            <div
                                                key={index}
                                                className="flex text-[#2c2c2c] cursor-pointer"
                                                onClick={() =>
                                                    filterChange(data.toUpperCase(), "LAB_CODE")
                                                }
                                            >
                                                <input
                                                    type="checkbox"
                                                    className="mr-1"
                                                    value="IGI"
                                                    checked={filterArray?.LAB_CODE?.split("-")?.includes(
                                                        data.toUpperCase()
                                                    )}
                                                />
                                                <p className="mr-3 mt-[3px]">{data}</p>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>

                        <div className=" border-y font-inter hidden">
                            <div className="w-[100%] my-3 text-[#2c2c2c]">
                                <p
                                    className="text-filterHeading font-filterHeading flex justify-between cursor-pointer"
                                    onClick={() => setIsFeatureOpen(!isFeatureOpen)}
                                >
                                    Features{" "}
                                    <p className="text-[#2c2c2c] opacity-[0.5] text-11 ">
                                        {isFeatureOpen ? <FaChevronUp /> : <FaChevronDown />}
                                    </p>
                                </p>
                                <div
                                    className={`  transition-all duration-200 ease-in-out  text-12 ${isFeatureOpen
                                        ? "max-h-[500px] opacity-100 mb-4 pointer-events-auto"
                                        : "mb-0 max-h-0 opacity-0 pointer-events-none "
                                        }`}
                                >
                                    {["Hearts & Arrows"].map((data, index) => {
                                        return (
                                            <div key={index}>
                                                <input
                                                    type="checkbox"
                                                    className="mr-1"
                                                    value="IGI"
                                                // onChange={(e) =>
                                                //     changeFilterData(e.target.value, "report")
                                                // }
                                                />
                                                <span className="mr-3">{data}</span>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>

                        <div className=" border-y font-inter hidden">
                            <div className="w-[100%] my-3 text-[#2c2c2c]">
                                <p
                                    className="text-filterHeading font-filterHeading flex justify-between cursor-pointer"
                                    onClick={() => setIsDeliveryOpen(!isDeliveryOpen)}
                                >
                                    Delivery - Specific Date{" "}
                                    <p className="text-[#2c2c2c] opacity-[0.5] text-11 ">
                                        {isDeliveryOpen ? <FaChevronUp /> : <FaChevronDown />}
                                    </p>
                                </p>
                                <div
                                    className={`  transition-all duration-200 ease-in-out  text-12 ${isDeliveryOpen
                                        ? "max-h-[500px] opacity-100 mb-4 pointer-events-auto"
                                        : "mb-0 max-h-0 opacity-0 pointer-events-none "
                                        }`}
                                >
                                    {formattedDates.map((data, index) => {
                                        return (
                                            <div key={index}>
                                                <input
                                                    type="radio"
                                                    className="mr-1"
                                                    value="IGI"
                                                    name="date"
                                                // onChange={(e) =>
                                                //     changeFilterData(e.target.value, "report")
                                                // }
                                                />
                                                <span className="mr-3">{data}</span>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>

                    <ShowDiamondsTable
                        data={Table_Data}
                        filterData={filterArray}
                        setFilterData={setFilterArray}
                        className={"w-[70%]"}
                        skuno={skuName}
                        variantId={variantId}
                        setOpen={setOpen}
                        Shape={shape_code ? shape_code : ""}
                        shapeData={shapeData}
                        shape={shape}
                        getDiamond={getDiamond}
                        setGetDiamond={setGetDiamond}
                        getShapeDiamond={getShapeDiamond}
                        setGetShapeDiamond={setGetShapeDiamond}
                    />
                </div>
            </div>
            <Drawer
                closable
                // destroyOnClose
                title={<span className='text-16 font-inter font-filterHeading text-black'>Filters</span>}
                placement="left"
                open={open}
                width={420}
                onClose={() => setOpen(false)}
            >
                <div className={`border-y font-inter `}>
                    <div className="w-[100%] my-3 text-[#2c2c2c]">
                        <p
                            className="text-filterHeading font-filterHeading  font-inter  cursor-pointer flex items-center justify-between"
                            onClick={() => setIsOriginOpen(!isOriginOpen)}
                        >
                            Diamond Origin{" "}
                            <p className="text-[#2c2c2c] opacity-[0.5] text-16 ">
                                {isOriginOpen ? <FaChevronUp /> : <FaChevronDown />}
                            </p>
                        </p>
                        <div
                            className={`flex transition-all duration-200 ease-in-out ${isOriginOpen
                                ? "max-h-[500px] opacity-100 mb-4 pointer-events-auto"
                                : "mb-0 max-h-0 opacity-0 pointer-events-none "
                                }`}
                        >
                            {[ "Lab Grown Diamonds"]?.map(
                                (data, index) => {
                                    return (
                                        <div
                                            key={index}
                                            className={`lg:px-4 px-1 py-[6px] border-[1px] border-[#2c2c2c]   border-solid rounded-full mr-2 mt-2 ${filterArray.IS_LABGROWN === "yes" &&
                                                data === "Lab Grown Diamonds"
                                                ? "border-footerBackground bg-footerBackground hover:bg-footerBackground hover:text-white text-white"
                                                : filterArray.IS_LABGROWN === "no" &&
                                                    data === "Natural Diamonds"
                                                    ? "border-footerBackground bg-footerBackground hover:bg-footerBackground hover:text-white text-white"
                                                    : "hover:bg-[#e8e8e8] hover:text-footerBackground hover:border-[#e8e8e8]"
                                                }`}
                                            onClick={() =>
                                                filterChange(
                                                    data === "Lab Grown Diamonds" ? "yes" : "no",
                                                    "IS_LABGROWN"
                                                )
                                            }
                                        >
                                            <div key={index} className="  rounded-full">
                                                <p className=" text-center text-12">{data}</p>
                                            </div>
                                        </div>
                                    );
                                }
                            )}
                        </div>
                    </div>
                </div>

                <div className="font-inter py-3  border-b">
                    <p
                        className="text-filterHeading font-filterHeading font-inter flex justify-between items-center cursor-pointer"
                        onClick={() => setIsShapeOpen(!isShapeOpen)}
                    >
                        Diamond Shape
                        <p className="text-[#2c2c2c] opacity-[0.5] text-16 ">
                            {isShapeOpen ? <FaChevronUp /> : <FaChevronDown />}
                        </p>
                    </p>
                    <div
                        className={`grid grid-cols-4 transition-all duration-200 ease-in-out ${isShapeOpen
                            ? "max-h-[500px] opacity-100 mb-4 pointer-events-auto"
                            : "mb-0 max-h-0 opacity-0 pointer-events-none  "
                            }`}
                    >
                        {
                            diamondType === undefined ?
                                shape?.[0]?.map((data, index) => {
                                    return (
                                        <div className=" " key={index}>
                                            <div
                                                className={`hover:text-hover  rounded-full`}
                                                // onClick={() => changeFilterData(data.S_NAME, "shape")}
                                                onClick={() => filterChange(data.S_CODE, "S_CODE")}
                                            >
                                                <img
                                                    src={Shape_Images[data.S_NAME]}
                                                    alt="kyrah star"
                                                    className={`p-1 bg-[#e8e8e8] rounded-full mx-auto w-10 mt-2 ${filterArray?.S_CODE?.split("-")?.includes(data.S_CODE)
                                                        ? "border-filterHeadingtext border-2 border-solid"
                                                        : ""
                                                        }`}
                                                />
                                                <p className=" text-14 text-center">{data.S_NAME}</p>
                                            </div>
                                        </div>
                                    );
                                })
                                :
                                shapData?.map((data, index) => {
                                    return (
                                        <div className=" " key={index}>
                                            <div
                                                className={`hover:text-hover rounded-full cursor-pointer`}
                                                onClick={() => filterChangeShape(data.S_CODE, "S_CODE")} // Change filter based on S_CODE
                                            >
                                                <img
                                                    src={data?.image} // Display the correct image for the shape code
                                                    alt={data.S_CODE} // Display name as alt text
                                                    className={`p-1 bg-[#e8e8e8] rounded-full mx-auto w-10 mt-2 ${shapeFilter?.S_CODE?.split("-")?.includes(data.S_CODE)
                                                        ? "border-filterHeadingtext border-2 border-solid"
                                                        : ""
                                                        }`}
                                                />
                                                <p className="text-14 text-center">{data.name}</p>
                                            </div>
                                        </div>
                                    );
                                })
                        }
                    </div>
                </div>

                <div className="mt-3 border-b font-inter text-[#2c2c2c]">
                    <p
                        className="text-filterHeading font-filterHeading mb-3 flex justify-between items-center cursor-pointer"
                        onClick={() => setIsCaratOpen(!isCaratOpen)}
                    >
                        Carat{" "}
                        <p className="text-[#2c2c2c] opacity-[0.5] text-16 ">
                            {isCaratOpen ? <FaChevronUp /> : <FaChevronDown />}
                        </p>
                    </p>
                    <div
                        className={` w-[95%] transition-all duration-200 ease-in-out  text-14 ${isCaratOpen
                            ? "max-h-[500px] opacity-100 mb-4 pointer-events-auto"
                            : "mb-0 max-h-0 opacity-0 pointer-events-none "
                            }`}
                    >
                        <RangeSelector
                            min={0.01}
                            max={30.00}
                            step={0.01}
                            input={{ min: filterArray.F_CARAT, max: filterArray.T_CARAT }}
                            onChange={caratChange}
                        />
                    </div>
                </div>

                <div className="mt-3 border-b font-inter text-[#2c2c2c]">
                    <p
                        className="text-filterHeading font-filterHeading mb-3 flex justify-between items-center cursor-pointer"
                        onClick={() => setIsPriceOpen(!isPriceOpen)}
                    >
                        Diamond Price{" "}
                        <p className="text-[#2c2c2c] opacity-[0.5] text-16 ">
                            {isPriceOpen ? <FaChevronUp /> : <FaChevronDown />}
                        </p>
                    </p>
                    <div
                        className={` w-[95%] transition-all duration-200 ease-in-out  text-14 ${isPriceOpen ? "max-h-[500px]  mb-4" : "mb-0 max-h-0 opacity-0 "
                            }`}
                    >
                        <RangeSelector
                            min={1}
                            max={9000000}
                            step={1}
                            label={currency === 'USD' ? '$' : '₹'}
                            input={{ min: filterArray.F_PRICE, max: filterArray.T_PRICE }}
                            onChange={priceChange}
                        />
                    </div>
                </div>

                {/* <div className=" border-y font-inter hidden">
                            <div className="w-[100%] my-3 text-[#2c2c2c]">
                                <p
                                    className="text-filterHeading font-filterHeading flex justify-between items-center cursor-pointer"
                                    onClick={() => setIsFilterOpen(!isFilterOpen)}
                                >
                                    What are you looking for?{" "}
                                    <p className="text-[#2c2c2c] opacity-[0.5] text-16 ">
                                        {isFilterOpen ? <FaChevronUp /> : <FaChevronDown />}
                                    </p>
                                </p>
                                <div
                                    className={`  transition-all duration-200 ease-in-out text-14 ${isFilterOpen
                                        ? "max-h-[500px] opacity-100 mb-4 pointer-events-auto"
                                        : "mb-0 max-h-0 opacity-0 pointer-events-none "
                                        }`}
                                >
                                    {" "}
                                    <div className="flex ">
                                        {["Most Sparkle", "Best Balance", "Ultra Ideal"]?.map(
                                            (data, index) => {
                                                return (
                                                    <div
                                                        key={index}
                                                        className={`px-4 px-1 py-2 border-2  hover:border-[#2c2c2c] border-solid rounded-full mr-2 mt-2 ${data === filterData.origin ? "border-[#183E40]" : ""
                                                            }`}
                                                    // onClick={() => changeFilterData(data, "origin")}
                                                    >
                                                        <div
                                                            key={index}
                                                            className="hover:text-hover  rounded-full"
                                                        >
                                                            <p className=" text-center text-12">{data}</p>
                                                        </div>
                                                    </div>
                                                );
                                            }
                                        )}
                                    </div>
                                    <span className="text-12 mt-1">
                                        Super ideal cuts that create maximum shine.
                                    </span>
                                </div>
                            </div>
                        </div> */}

                <div className="mt-3 border-b font-inter text-[#2c2c2c] cursor-pointer">
                    <p
                        className="text-filterHeading font-filterHeading mb-3 flex justify-between items-center cursor-pointer"
                        onClick={() => setIsCutOpen(!isCutOpen)}
                    >
                        Cut
                        <p className="text-[#2c2c2c] opacity-[0.5] text-16  ">
                            {isCutOpen ? <FaChevronUp /> : <FaChevronDown />}
                        </p>
                    </p>
                    {/* <FaExclamationCircle size={14} className="mt-1 ml-2 text-[#e8e8e8]" /> */}
                    <div
                        className={` w-[95%] transition-all duration-200 ease-in-out  text-14 ${isCutOpen
                            ? "max-h-[500px] opacity-100 mb-4 pointer-events-auto"
                            : "mb-0 max-h-0 opacity-0 pointer-events-none "
                            }`}
                    >
                        <RangeSelector
                            min={-1}
                            max={4}
                            step={1}
                            input={{ min: filterArray.F_CT_CODE, max: filterArray.T_CT_CODE }}
                            stepLabels={["Ideal", "Excellent", "Very Good", "Good", "Fair"]}
                            onChange={cutChange}
                        />
                    </div>
                </div>

                <div className="mt-3 border-b font-inter text-[#2c2c2c]">
                    <div
                        className="flex my-3 justify-between items-center "
                        onClick={() => setIsColorOpen(!isColorOpen)}
                    >
                        <p className="text-filterHeading font-filterHeading  gap-3 flex w-[95%]  cursor-pointer">
                            Color
                            <p
                                className=" items-center flex cursor-pointer"
                                onClick={(e) => {
                                    isColorOpen && e.stopPropagation();
                                    setFilterArray({
                                        ...filterArray,
                                        IS_FANCY: !isFancyColor ? "yes" : "no",
                                    });
                                    setIsFancyColor(!isFancyColor);
                                }}
                            >
                                <input
                                    type="checkbox"
                                    className="mr-1"
                                    checked={filterArray.IS_FANCY === 'yes' ? true : false}
                                />
                                <p className="">Fancy Color</p>
                            </p>
                        </p>
                        <p className="text-[#2c2c2c] opacity-[0.5] text-16  cursor-pointer">
                            {isColorOpen ? <FaChevronUp /> : <FaChevronDown />}
                        </p>
                    </div>
                    {/* <FaExclamationCircle size={14} className="mt-1 ml-2 text-[#e8e8e8]" /> */}
                    {filterArray.IS_FANCY === 'yes' && isColorOpen ? (
                        <div
                            className={`${isColorOpen
                                ? "max-h-[500px] opacity-100 mb-4 pointer-events-auto"
                                : "mb-0 max-h-0 opacity-0 pointer-events-none "
                                }`}
                        >
                            <div className="my-3">
                                <p className="text-16  mb-2 w-[70%]">Intensity :</p>
                                <Slider {...settings} className="w-[92%] mx-auto">
                                    {[
                                        "Blue",
                                        "Deep",
                                        "Faint",
                                        "Green",
                                        "Intense",
                                        "Light",
                                        "No",
                                        "Orange",
                                        "Pink",
                                        "Verylight",
                                        "Vivid",
                                        "Yellow",
                                    ].map((data, index) => {
                                        return (
                                            <div
                                                className="text-center  text-14 mr-2"
                                                key={index}
                                                onClick={() =>
                                                    filterChange(data.toUpperCase(), "FANCY_INTEN")
                                                }
                                            >
                                                <div
                                                    className={` ${filterArray?.FANCY_INTEN?.split("-")?.includes(
                                                        data.toUpperCase()
                                                    )
                                                        ? "border-[#183E40] border-[1px] border-solid"
                                                        : ""
                                                        }border mr-2 truncate`}
                                                >
                                                    {data}
                                                </div>
                                            </div>
                                        );
                                    })}
                                </Slider>
                            </div>
                            <div className="">
                                <p className="text-16 mb-2  w-[70%]">Over Tone :</p>
                                <Slider {...settings} className="w-[92%] mx-auto">
                                    {[
                                        "Blue",
                                        "Bluish",
                                        "Brown",
                                        "Brownish",
                                        "Brownish Greenish",
                                        "Deep",
                                        "Green",
                                        "Greenish",
                                        "Greyish",
                                        "Greyish Greenish",
                                        "Intense",
                                        "No",
                                        "Orange",
                                        "Orangy",
                                        "Pink",
                                        "Pinkish",
                                        "Purple",
                                        "Purplish",
                                        "Red",
                                        "Reddish",
                                        "Vivid",
                                        "Yellow",
                                        "Yellowish",
                                    ].map((data, index) => {
                                        return (
                                            <div
                                                className="text-center text-14  mr-2"
                                                key={index}
                                                onClick={() =>
                                                    filterChange(data.toUpperCase(), "FANCY_OVER")
                                                }
                                            >
                                                <div
                                                    className={`border mr-2 truncate ${filterArray?.FANCY_OVER?.split("-")?.includes(
                                                        data.toUpperCase()
                                                    )
                                                        ? "border-[#183E40] border-[1px] border-solid"
                                                        : ""
                                                        }`}
                                                >
                                                    {data}
                                                </div>
                                            </div>
                                        );
                                    })}
                                </Slider>
                            </div>
                            <div className="">
                                <p className="text-16  mb-2 w-[70%]">Color :</p>
                                <Slider {...settings} className="w-[92%] mx-auto">
                                    {[
                                        "Blue",
                                        "Bluish",
                                        "Brown",
                                        "Brownish",
                                        "Green",
                                        "No",
                                        "Orange",
                                        "Pink",
                                        "Purple",
                                        "Red",
                                        "Yellow",
                                    ].map((data, index) => {
                                        return (
                                            <div
                                                className="text-center text-14  mr-2"
                                                key={index}
                                                onClick={() =>
                                                    filterChange(data.toUpperCase(), "FANCY_COLOR")
                                                }
                                            >
                                                <div
                                                    className={`border mr-2 truncate ${filterArray?.FANCY_COLOR?.split("-")?.includes(
                                                        data.toUpperCase()
                                                    )
                                                        ? "border-[#183E40] border-[1px] border-solid"
                                                        : ""
                                                        }`}
                                                >
                                                    {data}
                                                </div>
                                            </div>
                                        );
                                    })}
                                </Slider>
                            </div>
                        </div>
                    ) : (
                        isColorOpen && (
                            <div
                                className={` w-[95%] transition-all duration-200 ease-in-out  text-16 ${isColorOpen
                                    ? "max-h-[500px] opacity-100 mb-4 pointer-events-auto"
                                    : "mb-0 max-h-0 opacity-0 pointer-events-none "
                                    }`}
                            >
                                <RangeSelector
                                    min={1}
                                    max={11}
                                    step={1}
                                    stepLabels={[
                                        "D",
                                        "E",
                                        "F",
                                        "G",
                                        "H",
                                        "I",
                                        "J",
                                        "K",
                                        "L",
                                        "M",
                                    ]}
                                    input={{ min: filterArray.F_C_CODE, max: filterArray.T_C_CODE }}
                                    onChange={colorChange}
                                />
                            </div>
                        )
                    )}
                </div>

                <div className="mt-3 border-b font-inter text-[#2c2c2c]">
                    <p
                        className="text-filterHeading font-filterHeading mb-3 flex items-center justify-between cursor-pointer"
                        onClick={() => setIsClarityOpen(!isClarityOpen)}
                    >
                        Clarity{" "}
                        <p className="text-[#2c2c2c] opacity-[0.5] text-16 ">
                            {isClarityOpen ? <FaChevronUp /> : <FaChevronDown />}
                        </p>
                    </p>
                    {/* <FaExclamationCircle size={14} className="mt-1 ml-2 text-[#e8e8e8]" /> */}
                    <div
                        className={` w-[95%] transition-all duration-200 ease-in-out  text-14 ${isClarityOpen
                            ? "max-h-[500px] opacity-100 mb-4 pointer-events-auto"
                            : "mb-0 max-h-0 opacity-0 pointer-events-none "
                            }`}
                    >
                        <RangeSelector
                            min={-1}
                            max={8}
                            step={1}
                            stepLabels={[
                                "FL",
                                "IF",
                                "VVS1",
                                "VVS2",
                                "VS1",
                                "VS1",
                                "SI1",
                                "SI2",
                                "SI3",
                            ]}
                            input={{ min: filterArray.F_Q_CODE, max: filterArray.T_Q_CODE }}
                            onChange={clarityChange}
                        />
                    </div>
                </div>

                {/* <div className=" border-y font-inter hidden">
                            <div className="w-[100%] my-3 text-[#2c2c2c]">
                                <p
                                    className="text-filterHeading font-filterHeading flex items-center justify-between cursor-pointer"
                                    onClick={() => setIsCollectionOpen(!isCollectionOpen)}
                                >
                                    Our Diamond Collection{" "}
                                    <p className="text-[#2c2c2c] opacity-[0.5] text-16 ">
                                        {isCollectionOpen ? <FaChevronUp /> : <FaChevronDown />}
                                    </p>
                                </p>
                                <div
                                    className={`  transition-all duration-200 ease-in-out  text-14 ${isCollectionOpen
                                        ? "max-h-[500px] opacity-100 mb-4 pointer-events-auto"
                                        : "mb-0 max-h-0 opacity-0 pointer-events-none "
                                        }`}
                                >
                                    <span className="text-12">
                                        A curated assortment of our most innovative diamonds
                                    </span>
                                    {[
                                        "Carbon Capture",
                                        "100% Renewable Energy ",
                                        "Sustainably Rated ",
                                        "Truly Brilliant",
                                    ].map((data, index) => {
                                        return (
                                            <div key={index}>
                                                <input
                                                    type="checkbox"
                                                    className="mr-1"
                                                    value="IGI"
                                                // onChange={(e) =>
                                                //     changeFilterData(e.target.value, "report")
                                                // }
                                                />
                                                <span className="mr-3">{data}</span>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div> */}

                <div className="mt-3 border-b font-inter text-[#2c2c2c]">
                    <p
                        className="text-filterHeading font-filterHeading mb-3 flex justify-between items-center cursor-pointer"
                        onClick={() => setIsLWOpen(!isLWOpen)}
                    >
                        L:W Ratio{" "}
                        <p className="text-[#2c2c2c] opacity-[0.5] text-16 ">
                            {isLWOpen ? <FaChevronUp /> : <FaChevronDown />}
                        </p>
                    </p>
                    {/* <FaExclamationCircle size={14} className="mt-1 ml-2 text-[#e8e8e8]" /> */}
                    <div
                        className={` w-[95%] transition-all duration-200 ease-in-out  text-14 ${isLWOpen
                            ? "max-h-[500px] opacity-100 mb-4 pointer-events-auto"
                            : "mb-0 max-h-0 opacity-0 pointer-events-none "
                            }`}
                    >
                        <RangeSelector
                            min={1.0}
                            max={2.75}
                            step={0.01}
                            input={{ min: filterArray.F_RATIO, max: filterArray.T_RATIO }}
                            onChange={lwChange}
                        />
                    </div>
                </div>
                <div className="mt-3 text-[#2c2c2c] w-full border-b font-inter">
                    <p
                        className="text-filterHeading font-filterHeading mb-3 flex justify-between items-center cursor-pointer"
                        onClick={() => setIsFluorOpen(!isFluorOpen)}
                    >
                        Fluorescence{" "}
                        <p className="text-[#2c2c2c] opacity-[0.5] text-16 ">
                            {isFluorOpen ? <FaChevronUp /> : <FaChevronDown />}
                        </p>
                    </p>
                    {/* <FaExclamationCircle size={14} className="mt-1 ml-2 text-[#e8e8e8]" /> */}
                    <div
                        className={` w-[95%] transition-all duration-200 ease-in-out  text-14 ${isFluorOpen
                            ? "max-h-[500px] opacity-100 mb-4 pointer-events-auto"
                            : "mb-0 max-h-0 opacity-0 pointer-events-none "
                            }`}
                    >
                        <RangeSelector
                            min={1}
                            max={8}
                            step={1}
                            input={{ min: filterArray.F_FL_CODE, max: filterArray.T_FL_CODE }}
                            stepLabels={[
                                "None",
                                "Faint",
                                "Medium",
                                "Strong",
                                "Very Strong",
                                "Slight",
                                "Very Slight",
                            ]}
                            onChange={fluorChange}
                        />
                    </div>
                </div>
                <div className="mt-3 border-b font-inter text-[#2c2c2c]">
                    <p
                        className="text-filterHeading font-filterHeading mb-3 flex justify-between items-center cursor-pointer"
                        onClick={() => setIsPolishOpen(!isPolishOpen)}
                    >
                        Polish{" "}
                        <p className="text-[#2c2c2c] opacity-[0.5] text-16 ">
                            {isPolishOpen ? <FaChevronUp /> : <FaChevronDown />}
                        </p>
                    </p>
                    {/* <FaExclamationCircle size={14} className="mt-1 ml-2 text-[#e8e8e8]" /> */}
                    <div
                        className={` w-[95%] transition-all duration-200 ease-in-out  text-14 ${isPolishOpen
                            ? "max-h-[500px] opacity-100 mb-4 pointer-events-auto"
                            : "mb-0 max-h-0 opacity-0 pointer-events-none "
                            }`}
                    >
                        <RangeSelector
                            min={1}
                            max={4}
                            step={1}
                            input={{ min: filterArray.F_PL_CODE, max: filterArray.T_PL_CODE }}
                            stepLabels={["Excellent", "Very Good", "Good"]}
                            onChange={polishChange}
                        />
                    </div>
                </div>
                <div className="mt-3 text-[#2c2c2c] w-full border-b font-inter">
                    <p
                        className="text-filterHeading font-filterHeading mb-3 flex justify-between items-center cursor-pointer"
                        onClick={() => setIsSymmOpen(!isSymmOpen)}
                    >
                        Symmetry{" "}
                        <p className="text-[#2c2c2c] opacity-[0.5] text-16 ">
                            {isSymmOpen ? <FaChevronUp /> : <FaChevronDown />}
                        </p>
                    </p>
                    {/* <FaExclamationCircle size={14} className="mt-1 ml-2 text-[#e8e8e8]" /> */}
                    <div
                        className={` w-[95%] transition-all duration-200 ease-in-out  text-14 ${isSymmOpen
                            ? "max-h-[500px] opacity-100 mb-4 pointer-events-auto"
                            : "mb-0 max-h-0 opacity-0 pointer-events-none "
                            }`}
                    >
                        <RangeSelector
                            min={1}
                            max={4}
                            step={1}
                            input={{ min: filterArray.F_SY_CODE, max: filterArray.T_SY_CODE }}
                            stepLabels={["Excellent", "Very Good", "Good"]}
                            onChange={symmetryChange}
                        />
                    </div>
                </div>
                <div className="mt-3 border-b font-inter text-[#2c2c2c]">
                    <p
                        className="text-filterHeading font-filterHeading mb-3 flex justify-between items-center cursor-pointer"
                        onClick={() => setIsTableOpen(!isTableOpen)}
                    >
                        Table %{" "}
                        <p className="text-[#2c2c2c] opacity-[0.5] text-16 ">
                            {isTableOpen ? <FaChevronUp /> : <FaChevronDown />}
                        </p>
                    </p>
                    {/* <FaExclamationCircle size={14} className="mt-1 ml-2 text-[#e8e8e8]" /> */}
                    <div
                        className={` w-[95%] transition-all duration-200 ease-in-out  text-14 ${isTableOpen
                            ? "max-h-[500px] opacity-100 mb-4 pointer-events-auto"
                            : "mb-0 max-h-0 opacity-0 pointer-events-none "
                            }`}
                    >
                        <RangeSelector
                            min={45.0}
                            max={85.0}
                            step={0.01}
                            input={{ min: filterArray.F_TABLE, max: filterArray.T_TABLE }}
                            onChange={tableChange}
                            label="%"
                        />
                    </div>
                </div>
                <div className="mt-3 text-[#2c2c2c] border-b font-inter">
                    <p
                        className="text-filterHeading font-filterHeading mb-3 flex justify-between items-center cursor-pointer"
                        onClick={() => setIsDepthOpen(!isDepthOpen)}
                    >
                        Depth %{" "}
                        <p className="text-[#2c2c2c] opacity-[0.5] text-16 ">
                            {isDepthOpen ? <FaChevronUp /> : <FaChevronDown />}
                        </p>
                    </p>
                    {/* <FaExclamationCircle size={14} className="mt-1 ml-2 text-[#e8e8e8]" /> */}
                    <div
                        className={` w-[95%] transition-all duration-200 ease-in-out  text-14 ${isDepthOpen
                            ? "max-h-[500px] opacity-100 mb-4 pointer-events-auto"
                            : "mb-0 max-h-0 opacity-0 pointer-events-none "
                            }`}
                    >
                        <RangeSelector
                            min={0.6}
                            max={85.9}
                            step={0.01}
                            input={{ min: filterArray.F_DEPTH, max: filterArray.T_DEPTH }}
                            onChange={depthChange}
                            label="%"
                        />
                    </div>
                </div>

                <div className=" border-y font-inter">
                    <div className="w-[100%] my-3 text-[#2c2c2c]">
                        <p
                            className="text-filterHeading font-filterHeading flex justify-between items-center cursor-pointer"
                            onClick={() => setIsReportOpen(!isReportOpen)}
                        >
                            Report{" "}
                            <p className="text-[#2c2c2c] opacity-[0.5] text-16 ">
                                {isReportOpen ? <FaChevronUp /> : <FaChevronDown />}
                            </p>
                        </p>
                        <div
                            className={` transition-all duration-200 ease-in-out  text-14 ${isReportOpen
                                ? "max-h-[500px] opacity-100 mb-4 pointer-events-auto"
                                : "mb-0 max-h-0 opacity-0 pointer-events-none "
                                }`}
                        >
                            {["IGI", "GIA", "GCAL"].map((data, index) => {
                                return (
                                    <div
                                        key={index}
                                        className="flex text-[#2c2c2c] cursor-pointer"
                                        onClick={() =>
                                            filterChange(data.toUpperCase(), "LAB_CODE")
                                        }
                                    >
                                        <input
                                            type="checkbox"
                                            className="mr-1"
                                            value="IGI"
                                            checked={filterArray?.LAB_CODE?.split("-")?.includes(
                                                data.toUpperCase()
                                            )}
                                        />
                                        <p className="mr-3 mt-[3px]">{data}</p>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>

                <div className=" border-y font-inter hidden">
                    <div className="w-[100%] my-3 text-[#2c2c2c]">
                        <p
                            className="text-filterHeading font-filterHeading flex justify-between cursor-pointer"
                            onClick={() => setIsFeatureOpen(!isFeatureOpen)}
                        >
                            Features{" "}
                            <p className="text-[#2c2c2c] opacity-[0.5] text-11 ">
                                {isFeatureOpen ? <FaChevronUp /> : <FaChevronDown />}
                            </p>
                        </p>
                        <div
                            className={`  transition-all duration-200 ease-in-out  text-12 ${isFeatureOpen
                                ? "max-h-[500px] opacity-100 mb-4 pointer-events-auto"
                                : "mb-0 max-h-0 opacity-0 pointer-events-none "
                                }`}
                        >
                            {["Hearts & Arrows"].map((data, index) => {
                                return (
                                    <div key={index}>
                                        <input
                                            type="checkbox"
                                            className="mr-1"
                                            value="IGI"
                                        // onChange={(e) =>
                                        //     changeFilterData(e.target.value, "report")
                                        // }
                                        />
                                        <span className="mr-3">{data}</span>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>

                <div className=" border-y font-inter hidden">
                    <div className="w-[100%] my-3 text-[#2c2c2c]">
                        <p
                            className="text-filterHeading font-filterHeading flex justify-between cursor-pointer"
                            onClick={() => setIsDeliveryOpen(!isDeliveryOpen)}
                        >
                            Delivery - Specific Date{" "}
                            <p className="text-[#2c2c2c] opacity-[0.5] text-11 ">
                                {isDeliveryOpen ? <FaChevronUp /> : <FaChevronDown />}
                            </p>
                        </p>
                        <div
                            className={`  transition-all duration-200 ease-in-out  text-12 ${isDeliveryOpen
                                ? "max-h-[500px] opacity-100 mb-4 pointer-events-auto"
                                : "mb-0 max-h-0 opacity-0 pointer-events-none "
                                }`}
                        >
                            {formattedDates.map((data, index) => {
                                return (
                                    <div key={index}>
                                        <input
                                            type="radio"
                                            className="mr-1"
                                            value="IGI"
                                            name="date"
                                        // onChange={(e) =>
                                        //     changeFilterData(e.target.value, "report")
                                        // }
                                        />
                                        <span className="mr-3">{data}</span>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </Drawer>
        </>
    );
};

export default LabDiamonds;
