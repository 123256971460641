// import React, { useEffect, useState } from 'react';
// import ReactCountryFlag from 'react-country-flag';
// import { useTranslation } from 'react-i18next';
// import { BsBag, BsPersonVideo } from "react-icons/bs";
// import { FaChevronDown, FaChevronRight, FaChevronUp } from 'react-icons/fa';
// import { GoHeart, GoPerson } from 'react-icons/go';
// import { IoChatbubbleOutline, IoReorderThreeOutline } from 'react-icons/io5';
// import { RxCross2 } from 'react-icons/rx';
// import { RotatingLines } from 'react-loader-spinner';
// import { useDispatch, useSelector } from 'react-redux';
// import { Link, useLocation, useNavigate } from 'react-router-dom';
// import { toast } from 'react-toastify';
// import { About_Dropdown_Menu } from '../helpers/jsonData';
// import { axiosInstance } from '../redux/api';
// import { addMenuData } from '../redux/slices/navigationbarMenu.slice';
// import { Images } from './Image';
// import NavigationDropdown from './NavigationDropdown';
// import { MdOutlineLogout } from 'react-icons/md';
// import { addSizeData } from '../redux/slices/sizeData.slice';
// import SizeContext from 'antd/es/config-provider/SizeContext';
// import { showErrorToast, showSuccessToast } from './ToastNotification';
// import { addWishlistCount } from '../redux/slices/wishList.slice';
// import { addCartListCount } from '../redux/slices/cartList.slice';

// const Header = () => {
//     const dispatch = useDispatch()
//     const { navigationMenu } = useSelector((state) => state.navigation)
//     const { size } = useSelector((state) => state.sizeData)
//     const { pathname } = useLocation()
//     const [headerHoverItem, setHeaderHoverItem] = useState()
//     const [isMenuOpen, setIsMenuOpen] = useState(false);
//     const [navbarIndex, setNavbarIndex] = useState(0)
//     const [isShow, setIsShow] = useState(true);
//     const data = useSelector((state) => state.data)
//     const { Offer } = data.home.data
//     const navigate = useNavigate()
//     const { t } = useTranslation();
//     const [selectedCurrency, setSelectedCurrency] = useState(JSON.parse(localStorage?.getItem('user-info'))?.CURRENCY || 'I')
//     const [isDropdown, setIsDropdown] = useState(false);
//     const currency = useSelector(state => state.navigation)
//     // console.log(currency?.navigationMenu?.data?.CURRENCY)

//     const isLogin = localStorage.getItem('user-info') ? true : false
//     const [loginPopup, setLoginPopup] = useState(false)

//     const [email, setEmail] = useState('');
//     const [password, setPassword] = useState('');
//     const [userNameError, setUserNameError] = useState('');
//     const [passwordError, setPasswordError] = useState('');
//     const [isLoading, setIsLoading] = useState(false);

//     const { wishListCount } = useSelector(state => state.wishList)
//     const { cartListCount } = useSelector(state => state.cartList)
//     useEffect(() => {
//         setHeaderHoverItem()
//     }, [pathname])
//     useEffect(() => {
//         localStorage.setItem('currency', selectedCurrency)
//     }, [selectedCurrency])

//     const handleHoverNavbar = (item) => {
//         setHeaderHoverItem(item)
//     }
//     const handleClickNavbar = (e, item) => {
//         e.stopPropagation();
//         setHeaderHoverItem(item)
//         setNavbarIndex(1)
//     }
//     async function fetchToken() {
//         const response = await axiosInstance.post('token');
//         // const token = localStorage.getItem('token')
//         // response.data.data && fetchNavbar()
//         localStorage.setItem('token', response.data.data)
//         window.location.reload()
//     };

//     const fetchNavbar = async () => {
//         try {
//             let response = await axiosInstance.post('/menu');
//             dispatch(addMenuData({ data: response.data.data }))
//         } catch (e) {
//             if (e.response.data.message === 'Unauthorized!') {
//                 fetchToken()
//             }
//         }
//     };

//     const getSize = async () => {
//         try {
//             const response = await axiosInstance.post('size');
//             // setSizeData(response.data.data)
//             dispatch(addSizeData({ data: response.data.data }))
//         } catch (e) {
//             console.log(e)
//         }
//     }


//     useEffect(() => {
//         !localStorage.getItem('token') && fetchToken()
//         Object.keys(navigationMenu).length === 0 && localStorage.getItem('token') && fetchNavbar()
//         Object.keys(size).length === 0 && getSize()
//     }, [])

//     useEffect(() => {
//         window.addEventListener('click', () => setIsMenuOpen(false))
//         return () => window.removeEventListener('click', () => setIsMenuOpen(false))
//     })


//     const loginHandler = async (e) => {
//         e.preventDefault()
//         if (email === '') {
//             setUserNameError('Email is Required')
//         }
//         if (password === '') {
//             setPasswordError('Password is Required')
//         }
//         if (email && password) {
//             const payload = {
//                 "PHONE": '',
//                 "EMAIL": email.trim(),
//                 "CON_CODE": '',
//                 "PASSWORD": password
//             };

//             try {
//                 setIsLoading(true);
//                 // const response = await fetch(`${process.env.REACT_APP_PUBLIC_BASE_URL}auth/login`, {
//                 //     method: 'POST',
//                 //     headers: {
//                 //         'Content-Type': 'application/json',
//                 //     },
//                 //     body: JSON.stringify(payload),
//                 // });
//                 const response = await axiosInstance.post('auth/login', payload)

//                 // const data = await response.json();
//                 if (response.data.success === true) {
//                     showSuccessToast(response.data.message)
//                     setIsLoading(false);
//                     localStorage.setItem('user-info', JSON.stringify(response.data.data))
//                     dispatch(addWishlistCount(response.data.data.WISH_COUNT))
//                     dispatch(addCartListCount(response.data.data.CART_COUNT))
//                     setLoginPopup(false)
//                     setTimeout(() => {
//                         navigate(`/`)
//                         window.location.reload()
//                     }, 1000)
//                 } else {
//                     setIsLoading(false);
//                     showErrorToast(response.data.message)
//                 }
//                 // Handle successful response.data, e.g., show a success message or navigate
//                 // console.log(response.data);
//             } catch (error) {
//                 showErrorToast(error.response.data.message)
//                 setIsLoading(false);
//                 // Handle errors, e.g., show an error message
//                 console.error('There was a problem with the fetch operation:', error);
//             } finally {
//                 setIsLoading(false);
//             }
//         }
//     }
//     const changeCurrency = async (value) => {
//         const data = value.Items === 'USD' ? 'D' : 'I'
//         setSelectedCurrency(value.Items === 'USD' ? 'D' : 'I'); setIsDropdown(false)
//         const user = JSON.parse(localStorage.getItem('user-info'))
//         if (user && data !== selectedCurrency) {
//             const newUser = { ...user, CURRENCY: value.Items === 'USD' ? 'D' : 'I' };
//             localStorage.setItem('user-info', JSON.stringify(newUser))
//             try {
//                 let response = await axiosInstance.post('account/update', newUser);

//                 if (response.data.success === true) {
//                     showSuccessToast(response.data.message)
//                     localStorage.setItem('user-info', JSON.stringify(response.data.data))
//                     window.location.reload()
//                 } else {
//                     showErrorToast(response.data.message)
//                 }
//                 // console.log(response)
//             } catch (error) {
//                 showErrorToast(error.response.data.message)
//             }
//         }
//     }
//     return (
//         <div className=' w-[100%] z-10 '>
//             {Offer && isShow && (
//                 <div className="w-full bg-offerHeaderBackground h-auto relative" onClick={() => navigate('/promo-codes-and-offers')}>
//                     {Offer.map((data, index) => {
//                         return <div key={index} className='text-center text-8 sm:text-offerHeaderText font-inter tracking-wider py-1.5 text-offerHeaderTextColor md:px-0 sm:px-20 px-10' dangerouslySetInnerHTML={{ __html: data.DESCR }}></div>
//                     })}
//                     <RxCross2 className='text-offerHeaderTextColor absolute right-2 top-2 cursor-pointer' onClick={(e) => { e.stopPropagation(); setIsShow(false) }} />
//                 </div>
//             )}

//             <div className='relative'>
//                 <div className='relative  border-headerBorder  mx-auto sm:w-full  border-b '>
//                     <div className=' flex flex-row mx-auto md:py-4 px-5 sm:w-full border-b border-headerBorder   justify-between  md:h-20  gap-4 xl:px-[15%]'>
//                         <div className="md:hidden flex items-center ">
//                             <button onClick={(e) => { e.stopPropagation(); setIsMenuOpen(!isMenuOpen); setNavbarIndex(0) }} >
//                                 {isMenuOpen ? <RxCross2 size={32} className='mt-1' /> : <IoReorderThreeOutline size={32} className='mt-1' />}
//                             </button>
//                         </div>
//                         {/* <div className=" md:flex flex-row w-5/12 mx-auto justify-start pt-2 mt-1 gap-10 hidden"> */}
//                         {/* <svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
//                                 <path d="M8.00033 19.75C6.63201 18.5829 5.3637 17.3033 4.20866 15.9248C2.47533 13.8545 0.416993 10.7714 0.416993 7.83335C0.416241 6.33289 0.860627 4.86593 1.69391 3.61813C2.52719 2.37033 3.71192 1.39776 5.09815 0.823532C6.48438 0.2493 8.0098 0.0992076 9.48137 0.392251C10.9529 0.685294 12.3045 1.4083 13.365 2.46977C14.0711 3.1727 14.6307 4.00868 15.0115 4.92934C15.3924 5.85 15.5868 6.83704 15.5837 7.83335C15.5837 10.7714 13.5253 13.8545 11.792 15.9248C10.6369 17.3033 9.36864 18.5829 8.00033 19.75ZM8.00033 4.58335C7.13837 4.58335 6.31172 4.92576 5.70223 5.53525C5.09274 6.14475 4.75033 6.9714 4.75033 7.83335C4.75033 8.6953 5.09274 9.52196 5.70223 10.1314C6.31172 10.7409 7.13837 11.0834 8.00033 11.0834C8.86228 11.0834 9.68893 10.7409 10.2984 10.1314C10.9079 9.52196 11.2503 8.6953 11.2503 7.83335C11.2503 6.9714 10.9079 6.14475 10.2984 5.53525C9.68893 4.92576 8.86228 4.58335 8.00033 4.58335Z" fill="#82746C" />
//                             </svg> */}

//                         {/* <a className='font-inter hover:text-hoverNavbarMenu text-13 hidden md:block' href="/contact">800.691.0952</a> */}
//                         {/* <a className='font-inter hover:text-hoverNavbarMenu text-13 hidden md:block' href="/stores">Stores</a> */}
//                         {/* <a className='flex flex-row hover:text-hoverNavbarMenu gap-2' href="/virtual-appointment">
//                                 <BsPersonVideo size={22} className='text-navbar hover:text-hoverNavbarMenu ' />
//                             </a> */}
//                         {/* <p className='text-navbarMenuText font-inter'>+91 1234567890</p> */}

//                         {/* </div> */}

//                         <div className="flex flex-row md:justify-end cursor-pointer w-[55%] justify-center" onClick={() => { navigate('/'); window.location.reload() }}>
//                             <img alt='brillient earth' src={Images.Logo} />
//                             <span className='text-logoText mt-1 font-garamond'>KYRAHSTAR</span>

//                             {/* <img src="https://css.brilliantearth.com/static/img/logo-new.svg" className='w-[70%] h-full' alt="Kyrah Star" /> */}
//                         </div>

//                         <div className="w-[10%] md:w-[12%] justify-end md:ml-auto  gap-10 flex items-center font-inter">
//                             {/* <span className='font-inter border-b flex border-[#CECDCD] h-[25px]' >
//                                 <input type='text' placeholder='Search' className=' text-15 pl-2 focus:outline-none' />
//                                 <div className=''>
//                                     <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
//                                         <path d="M14.1008 13.186L9.78935 8.87461C10.4584 8.00967 10.8203 6.95215 10.8203 5.83984C10.8203 4.5084 10.3007 3.25996 9.36103 2.31865C8.42138 1.37734 7.16963 0.859375 5.83984 0.859375C4.51006 0.859375 3.2583 1.379 2.31865 2.31865C1.37734 3.2583 0.859375 4.5084 0.859375 5.83984C0.859375 7.16963 1.379 8.42139 2.31865 9.36103C3.2583 10.3023 4.5084 10.8203 5.83984 10.8203C6.95215 10.8203 8.00801 10.4584 8.87295 9.79102L13.1844 14.1008C13.197 14.1134 13.212 14.1235 13.2285 14.1303C13.2451 14.1372 13.2628 14.1407 13.2807 14.1407C13.2985 14.1407 13.3163 14.1372 13.3328 14.1303C13.3493 14.1235 13.3643 14.1134 13.377 14.1008L14.1008 13.3786C14.1134 13.366 14.1235 13.351 14.1303 13.3344C14.1372 13.3179 14.1407 13.3002 14.1407 13.2823C14.1407 13.2644 14.1372 13.2467 14.1303 13.2302C14.1235 13.2137 14.1134 13.1987 14.1008 13.186ZM8.46953 8.46953C7.76562 9.17178 6.83262 9.55859 5.83984 9.55859C4.84707 9.55859 3.91406 9.17178 3.21016 8.46953C2.50791 7.76563 2.12109 6.83262 2.12109 5.83984C2.12109 4.84707 2.50791 3.9124 3.21016 3.21016C3.91406 2.50791 4.84707 2.12109 5.83984 2.12109C6.83262 2.12109 7.76728 2.50625 8.46953 3.21016C9.17178 3.91406 9.55859 4.84707 9.55859 5.83984C9.55859 6.83262 9.17178 7.76729 8.46953 8.46953Z" fill="#705B4F" />
//                                     </svg>
//                                 </div>
//                             </span> */}
//                             <Link className='font-inter hidden md:block relative  ' to="/shop/wish-list">
//                                 {/* <BsHeart size={16} className='text-iconColor ' /> */}
//                                 <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
//                                     <path d="M18.8301 4.09298C18.5421 3.42619 18.1269 2.82194 17.6076 2.31408C17.088 1.8047 16.4753 1.3999 15.8029 1.12169C15.1057 0.832071 14.3579 0.683824 13.6029 0.685562C12.5438 0.685562 11.5104 0.975601 10.6123 1.52345C10.3975 1.65451 10.1934 1.79845 10 1.95529C9.80664 1.79845 9.60254 1.65451 9.3877 1.52345C8.48965 0.975601 7.45625 0.685562 6.39707 0.685562C5.63438 0.685562 4.89531 0.831656 4.19707 1.12169C3.52246 1.40099 2.91445 1.80275 2.39238 2.31408C1.87245 2.82137 1.45712 3.42576 1.16992 4.09298C0.871289 4.78693 0.71875 5.52384 0.71875 6.28224C0.71875 6.99767 0.864844 7.74318 1.15488 8.50158C1.39766 9.13537 1.7457 9.79279 2.19043 10.4567C2.89512 11.5072 3.86406 12.6029 5.06719 13.7137C7.06094 15.5549 9.03535 16.8268 9.11914 16.8783L9.62832 17.2049C9.85391 17.3488 10.1439 17.3488 10.3695 17.2049L10.8787 16.8783C10.9625 16.8246 12.9348 15.5549 14.9307 13.7137C16.1338 12.6029 17.1027 11.5072 17.8074 10.4567C18.2522 9.79279 18.6023 9.13537 18.843 8.50158C19.133 7.74318 19.2791 6.99767 19.2791 6.28224C19.2813 5.52384 19.1287 4.78693 18.8301 4.09298ZM10 15.5055C10 15.5055 2.35156 10.6049 2.35156 6.28224C2.35156 4.09298 4.1627 2.31837 6.39707 2.31837C7.96758 2.31837 9.32969 3.19494 10 4.47541C10.6703 3.19494 12.0324 2.31837 13.6029 2.31837C15.8373 2.31837 17.6484 4.09298 17.6484 6.28224C17.6484 10.6049 10 15.5055 10 15.5055Z" fill="#705B4F" />
//                                 </svg>
//                                 {wishListCount > 0 && <div className='absolute text-10 font-inter -right-1 -bottom-1 text-[#705B4F]'>{wishListCount}</div>}
//                             </Link>
//                             <Link className='font-inter hidden md:block relative' to="/shop/cart">
//                                 {/* <BsBag size={16} className='text-iconColor ' /> */}
//                                 <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
//                                     <path d="M11.75 5.25V7.125C11.75 7.29076 11.6842 7.44973 11.5669 7.56694C11.4497 7.68415 11.2908 7.75 11.125 7.75H10.5V5.25H5.5V7.75H4.875C4.70924 7.75 4.55027 7.68415 4.43306 7.56694C4.31585 7.44973 4.25 7.29076 4.25 7.125V5.25H1.75V16.5H14.25V5.25H11.75ZM4.25 4C4.25 3.00544 4.64509 2.05161 5.34835 1.34835C6.05161 0.645088 7.00544 0.25 8 0.25C8.99456 0.25 9.94839 0.645088 10.6517 1.34835C11.3549 2.05161 11.75 3.00544 11.75 4H14.875C15.0408 4 15.1997 4.06585 15.3169 4.18306C15.4342 4.30027 15.5 4.45924 15.5 4.625V17.125C15.5 17.2908 15.4342 17.4497 15.3169 17.5669C15.1997 17.6842 15.0408 17.75 14.875 17.75H1.125C0.95924 17.75 0.800269 17.6842 0.683058 17.5669C0.565848 17.4497 0.5 17.2908 0.5 17.125V4.625C0.5 4.45924 0.565848 4.30027 0.683058 4.18306C0.800269 4.06585 0.95924 4 1.125 4H4.25ZM5.5 4H10.5C10.5 3.33696 10.2366 2.70107 9.76777 2.23223C9.29893 1.76339 8.66304 1.5 8 1.5C7.33696 1.5 6.70107 1.76339 6.23223 2.23223C5.76339 2.70107 5.5 3.33696 5.5 4Z" fill="#705B4F" />
//                                 </svg>
//                                 {cartListCount > 0 && <div className='absolute text-10 font-inter -right-2 -bottom-1 text-[#705B4F]'>{cartListCount}</div>}
//                             </Link>
//                             <div
//                                 // onMouseLeave={() => { setLoginPopup(false); setUserNameError(''); setPasswordError(''); setEmail(''); setPassword('') }}
//                                 className='relative'>
//                                 <div
//                                 // onMouseEnter={() => setLoginPopup(true)}
//                                 >
//                                     <div className='font-inter flex gap-1 items-center cursor-pointer' onClick={() => { setLoginPopup(!loginPopup); setUserNameError(''); setPasswordError(''); setEmail(''); setPassword('') }}>
//                                         {/* <BsPerson size={16} className='text-iconColor ' /> */}
//                                         {localStorage?.getItem('user-info') && <span className='text-[#705B4F]'>{JSON.parse(localStorage.getItem('user-info'))?.FIRST_NAME}</span>}
//                                         <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
//                                             <path d="M9 9C10.094 9 11.1432 8.52589 11.9168 7.68198C12.6904 6.83807 13.125 5.69347 13.125 4.5C13.125 3.30653 12.6904 2.16193 11.9168 1.31802C11.1432 0.474106 10.094 0 9 0C7.90598 0 6.85677 0.474106 6.08318 1.31802C5.3096 2.16193 4.875 3.30653 4.875 4.5C4.875 5.69347 5.3096 6.83807 6.08318 7.68198C6.85677 8.52589 7.90598 9 9 9ZM11.75 4.5C11.75 5.29565 11.4603 6.05871 10.9445 6.62132C10.4288 7.18393 9.72935 7.5 9 7.5C8.27065 7.5 7.57118 7.18393 7.05546 6.62132C6.53973 6.05871 6.25 5.29565 6.25 4.5C6.25 3.70435 6.53973 2.94129 7.05546 2.37868C7.57118 1.81607 8.27065 1.5 9 1.5C9.72935 1.5 10.4288 1.81607 10.9445 2.37868C11.4603 2.94129 11.75 3.70435 11.75 4.5ZM17.25 16.5C17.25 18 15.875 18 15.875 18H2.125C2.125 18 0.75 18 0.75 16.5C0.75 15 2.125 10.5 9 10.5C15.875 10.5 17.25 15 17.25 16.5ZM15.875 16.494C15.8736 16.125 15.6632 15.015 14.731 13.998C13.8345 13.02 12.1474 12 9 12C5.85125 12 4.1655 13.02 3.269 13.998C2.33675 15.015 2.12775 16.125 2.125 16.494H15.875Z" fill="#705B4F" />
//                                         </svg>
//                                     </div>
//                                 </div>
//                                 {loginPopup &&
//                                     (!isLogin ? <div className='absolute w-[60vw] sm:w-[50vw] md:w-[30vw] lg:w-[25vw] 2xl:w-[15vw]  right-0 md:-right-20 mt-2 text-12 md:text-14 py-2  px-3 bg-white border top-5 shadow-lg z-10'>
//                                         <div className='flex flex-col justify-center text-center p-5'>
//                                             <p className='text-18 mb-3'>Your Account</p>
//                                             <form onSubmit={loginHandler} className='w-[100%]'>
//                                                 <input type='text' placeholder='Email Address' className='border-b w-full border-black text-black outline-none my-2'
//                                                     value={email}
//                                                     onChange={(e) => {
//                                                         let inputValue = e.target.value;
//                                                         const regex = /\S+@\S+\.\S+/;
//                                                         setEmail(inputValue); // Only update the value if valid
//                                                         if (inputValue === '') {
//                                                             setUserNameError('Email is required.');
//                                                         } else if (!regex.test(inputValue)) {
//                                                             setUserNameError('Invalid email format');
//                                                         } else {
//                                                             setUserNameError('');// Only update the value if valid
//                                                         }
//                                                     }}
//                                                 />
//                                                 {userNameError && <p className="text-red-500 text-12 text-left font-inter">{userNameError}</p>}
//                                                 <input type='password' placeholder='Password' className='border-b w-full border-black text-black outline-none my-2'
//                                                     value={password}
//                                                     onChange={(e) => {
//                                                         let inputValue = e.target.value;
//                                                         setPassword(e.target.value)
//                                                         if (inputValue === '') {
//                                                             setPasswordError('Password is Required.');
//                                                         } else {
//                                                             setPasswordError(''); // Only update the value if valid
//                                                         }
//                                                     }}
//                                                 />
//                                                 {passwordError && <p className="text-red-500 text-12 mb-2 font-inter text-left">{passwordError}</p>}
//                                                 <p className='mb-2 hover:underline hover:cursor-pointer w-fit mx-auto' onClick={() => { navigate('/accounts/password-reset'); setLoginPopup(false) }}>Forgot Your Password?</p>
//                                                 {/* <button className='bg-footerBackground cursor-pointer text-white mt-2 py-1.5' onClick={loginHandler}>Log In</button> */}
//                                                 {
//                                                     isLoading ?
//                                                         <button
//                                                             className={`bg-footerBackground cursor-pointer text-white mt-2 w-full py-1.5 flex justify-center`}
//                                                         >
//                                                             <RotatingLines
//                                                                 visible={true}
//                                                                 height="20"
//                                                                 width="20"
//                                                                 strokeWidth="5"
//                                                                 strokeColor='white'
//                                                                 animationDuration="0.75"
//                                                                 ariaLabel="rotating-lines-loading"
//                                                                 wrapperStyle={{}}
//                                                                 wrapperClass=""
//                                                             />
//                                                         </button>
//                                                         :
//                                                         <button
//                                                             className='bg-footerBackground cursor-pointer w-full text-white mt-2 py-1.5'
//                                                         >
//                                                             Login
//                                                         </button>
//                                                 }
//                                             </form>
//                                             <button className='bg-gray-200 cursor-pointer text-black mt-3 py-1.5' onClick={() => { navigate('register'); setLoginPopup(false) }}>Sign Up</button>
//                                         </div>
//                                     </div> :
//                                         <div className='absolute w-[50vw] sm:w-[40vw] md:w-[20vw] 2xl:w-[15vw]  right-0 md:-right-20 mt-2  py-2  px-3 bg-white border top-5 shadow-lg z-10'>
//                                             <div className='flex flex-col justify-center text-center p-3 md:p-5'>
//                                                 <div className='text-left flex flex-col mb-3 '>
//                                                     <span className='hover:underline hover:cursor-pointer w-fit my-1 text-14 md:text-16' onClick={() => { navigate('/accounts'); setLoginPopup(false) }}>My Account</span>
//                                                     <span className='hover:underline hover:cursor-pointer w-fit my-1 text-14 md:text-16' onClick={() => { navigate('/accounts/order-history'); setLoginPopup(false) }}>Order History</span>
//                                                     <span className='hover:underline hover:cursor-pointer w-fit my-1 text-14 md:text-16' onClick={() => { navigate('/shop/wish-list'); setLoginPopup(false) }}>Wish List</span>
//                                                 </div>
//                                                 <button className='bg-footerBackground cursor-pointer text-white text-14 md:text-16 md:mt-2 py-1.5 ' onClick={() => { dispatch(addWishlistCount(0)); dispatch(addCartListCount(0)); showSuccessToast('Log Out Succlessfull !'); localStorage.removeItem('user-info'); navigate('/login'); window.location.reload() }}>Sign Out</button>
//                                                 {/* <button className='bg-gray-200 cursor-pointer text-black mt-3 py-1.5'>Sign Up</button> */}
//                                             </div>
//                                         </div>)
//                                 }
//                             </div>
//                         </div>
//                         {/* <div className=" flex w-5/12 justify-end mx-auto mt-1 py-1 gap-6 md:hidden mr-3">
//                             <a className='font-inter ' href="/stores">
//                                 <IoCallOutline size={16} className='text-iconColor ' />
//                             </a>
//                             <a className='font-inter' href="/shop/cart">
//                                 <BsBag size={16} className='text-iconColor ' />
//                             </a>
//                         </div> */}
//                         {/* <p className='text-navbarMenuText relative uppercase font-inter'>{selectedCurrency}</p>
//                             {currency && <div className='absolute top-1'>
//                                 {currency?.map((data, index) => {
//                                     return (<li key={index}>{data.CURR_NAME}</li>)
//                                 })}
//                             </div>} */}
//                         <div onMouseLeave={() => setIsDropdown(false)} className=' items-center text-[#705B4F] cursor-pointer relative  text-12 md:text-14 hidden md:flex'>
//                             <div onMouseEnter={() => { setIsDropdown(true); setLoginPopup(false) }} className='flex gap-1 items-center'>
//                                 <span className='font-inter flex items-center gap-1 text-12 lg:text-16'>
//                                     <ReactCountryFlag countryCode={selectedCurrency === 'D' ? 'US' : 'IN'} svg />{selectedCurrency === 'D' ? 'USD' : 'INR'}
//                                 </span>

//                                 {
//                                     isDropdown ?
//                                         <FaChevronUp className='ml-2 lg:ml-1 text-[#705B4F]' />
//                                         :
//                                         <FaChevronDown className='ml-2 lg:ml-1 text-[#705B4F]' />
//                                 }
//                             </div>

//                             {isDropdown && (
//                                 <div className='absolute w-[370%] text-black right-0 mt-2 text-12 md:text-16 py-2  px-3 bg-white border top-5 md:top-7 lg:top-7 shadow-lg z-10'>
//                                     <ul className='py-[2px] px-4 mx-auto'>
//                                         <li className='font-medium my-1.5 mb-2 text-center'>Select Currency</li>
//                                         {currency?.navigationMenu?.data?.CURRENCY?.map((value, index) => <li key={index} className=' hover:bg-gray-100 cursor-pointer flex gap-1 items-center my-1.5' onClick={() => changeCurrency(value)}><p className='flex items-center'> <ReactCountryFlag countryCode={value.Items === 'USD' ? 'US' : 'IN'} svg /></p><p className='ml-1'>{`${value.Items === 'USD' ? 'United State' : 'India'}   (${value.Items})`}</p></li>)}
//                                         {/* <li className='px-[17px] md:px-[19px] lg:px-[21px] py-[5px] hover:bg-gray-100 cursor-pointer' onClick={sortProductData}>Best Sellers</li>
//                                     <li className='px-[17px] md:px-[19px] lg:px-[21px] py-[5px] hover:bg-gray-100 cursor-pointer' onClick={sortProductData}>Newest</li>
//                                     <li className='px-[17px] md:px-[19px] lg:px-[21px] py-[5px] hover:bg-gray-100 cursor-pointer' onClick={sortProductData}>Price: Low to High</li>
//                                     <li className='px-[17px] md:px-[19px] lg:px-[21px] py-[5px] hover:bg-gray-100 cursor-pointer' onClick={sortProductData}>Price: High to Low</li> */}
//                                     </ul>
//                                 </div>
//                             )}
//                         </div>

//                     </div>
//                 </div>
//                 {/* {!isMenuOpen && <div className=' justify-between border border-headerBorder px-2 py- mx-3 mt-2 md:hidden mb-2 flex'><input type='text' placeholder='Search' className='w-[90%] focus:outline-none' /><FiSearch className='mt-1' /></div>} */}
//                 {
//                     !isMenuOpen && <div className='bg-headerMenuBackground hidden md:block ' onMouseLeave={() => setHeaderHoverItem()}>
//                         <div className="flex flex-row mx-auto justify-center lg:gap-16 gap-5  py-5 w-full relative">
//                             <Link to={'/engagement-rings'} className=' font-navbarMenuText font-inter  text-navbarMenu text-13 lg:text-navbarMenuText  tracking-wide  hover:text-hoverNavbarMenu block' onMouseEnter={() => handleHoverNavbar('ENGAGEMENT_RINGS')}>{t("ENGAGEMENT_RINGS")}</Link>
//                             <Link to={'/wedding-rings'} className=' font-navbarMenuText font-inter  text-navbarMenu text-13 lg:text-navbarMenuText tracking-wide  hover:text-hoverNavbarMenu block' onMouseEnter={() => handleHoverNavbar('WEDDING_RINGS')}>{t("WEDDING_RINGS")}</Link>
//                             <Link to={'/diamonds'} className=' font-navbarMenuText font-inter  text-navbarMenu text-13 lg:text-navbarMenuText tracking-wide  hover:text-hoverNavbarMenu block' onMouseEnter={() => handleHoverNavbar('DIAMONDS')}>{t("DIAMONDS")}</Link>
//                             <Link to={'/gemstones'} className=' font-navbarMenuText font-inter  text-navbarMenu text-13 lg:text-navbarMenuText tracking-wide  hover:text-hoverNavbarMenu block' onMouseEnter={() => handleHoverNavbar('GEMSTONES')}>{t("GEMSTONES")}</Link>
//                             <Link to={'/jewelry'} className=' font-navbarMenuText font-inter  text-navbarMenu text-13 lg:text-navbarMenuText tracking-wide  hover:text-hoverNavbarMenu block' onMouseEnter={() => handleHoverNavbar('JEWELRY')}>{t("JEWELRY")}</Link>
//                             {/* <Link to={'/gifts'} className=' font-navbarMenuText font-inter  text-navbarMenu text-13 lg:text-navbarMenuText tracking-wide  hover:text-hoverNavbarMenu block' onMouseEnter={() => handleHoverNavbar('GIFTS')}>{t("GIFTS")}</Link> */}
//                             <Link to={'/about'} className=' font-navbarMenuText font-inter  text-navbarMenu text-13 lg:text-navbarMenuText tracking-wide  hover:text-hoverNavbarMenu block' onMouseEnter={() => handleHoverNavbar('ABOUT')}>{t("ABOUT")}</Link>
//                             {/* <a className='font-inter text-navbar text-13 lg:text-14 tracking-wide font-normal hover:text-hoverNavbarMenu block' href="/engagement-rings" onMouseEnter={() => handleHoverNavbar('engagement')} >{t("ENGAGEMENT_RINGS")}</a>
//                         <a className='font-inter text-navbar text-13 lg:text-14 tracking-wide font-normal hover:text-hoverNavbarMenu block' href="/wedding-rings" onMouseEnter={() => handleHoverNavbar('wedding')}>{t("WEDDING_RINGS")}</a>
//                         <a className='font-inter text-navbar text-13 lg:text-14 tracking-wide font-normal hover:text-hoverNavbarMenu block' href="/diamonds" onMouseEnter={() => handleHoverNavbar('diamonds')}>{t("DIAMONDS")}</a>
//                         <a className='font-inter text-navbar text-13 lg:text-14 tracking-wide font-normal hover:text-hoverNavbarMenu block' href="/gemstones" onMouseEnter={() => handleHoverNavbar('gemstone')}>{t("gemstones")}</a>
//                         <a className='font-inter text-navbar text-13 lg:text-14 tracking-wide font-normal hover:text-hoverNavbarMenu block' href="/jewelry" onMouseEnter={() => handleHoverNavbar('jewelry')}>{t("JEWELRY")}</a>
//                         <a className='font-inter text-navbar text-13 lg:text-14 tracking-wide font-normal hover:text-hoverNavbarMenu block' href="/gifts" onMouseEnter={() => handleHoverNavbar('gifts')}>{t("GIFTS")}</a>
//                         <a className='font-inter text-navbar text-13 lg:text-14 tracking-wide font-normal hover:text-hoverNavbarMenu block' href="/about" onMouseEnter={() => handleHoverNavbar('about')}>{t("ABOUT")}</a> */}
//                         </div>
//                         {/* {headerHoverItem && <div onMouseLeave={() => setHeaderHoverItem()}> <NavigationDropdown data={headerHoverItem === 'ENGAGEMENT_RINGS' ? navigationMenu?.data?.[headerHoverItem] : headerHoverItem === 'wedding' ? Wedding_Rings_Dropdown_Menu : headerHoverItem === 'diamonds' ? Diamond_Dropdown_Menu : headerHoverItem === 'gemstone' ? Gemstone_Dropdown_Menu : headerHoverItem === 'jewelry' ? Jewelry_Dropdown_Menu : headerHoverItem === 'gifts' ? Gifts_Dropdown_Menu : headerHoverItem === 'about' ? About_Dropdown_Menu : ''}
//                         link={headerHoverItem === 'engagement' ? 'engagement-rings' : headerHoverItem === 'wedding' ? 'wedding-rings' : headerHoverItem === 'diamonds' ? 'diamonds' : headerHoverItem === 'gemstone' ? 'gemstones' : headerHoverItem === 'jewelry' ? 'jewelry' : headerHoverItem === 'gifts' ? 'gifts' : headerHoverItem === 'about' ? 'about' : ''}
//                         className='absolute top-[90%] w-full xl:pl-[25%] px-5 z-20 bg-white border-y mt-3 border-lightBorder  shadow-xl' /></div>} */}
//                         {
//                             headerHoverItem && <div onMouseLeave={() => setHeaderHoverItem()}> <NavigationDropdown data={headerHoverItem === 'ABOUT' ? About_Dropdown_Menu : navigationMenu?.data?.[headerHoverItem]}
//                                 link={headerHoverItem === 'ENGAGEMENT_RINGS' ? 'engagement-rings' : headerHoverItem === 'WEDDING_RINGS' ? 'wedding-rings' : headerHoverItem === 'DIAMONDS' ? 'diamonds' : headerHoverItem === 'GEMSTONES' ? 'gemstones' : headerHoverItem === 'JEWELRY' ? 'jewelry' : headerHoverItem === 'GIFTS' ? 'gifts' : headerHoverItem === 'ABOUT' ? 'about' : ''}
//                                 hover={headerHoverItem}
//                                 className='absolute top-[90%] w-full xl:pl-[25%] px-5 z-20 bg-white border-b mt-3 border-headerBorder  shadow-xl' /></div>
//                         }
//                     </div>
//                 }

//                 {/* Mobile Menu */}
//                 {
//                     isMenuOpen && (
//                         navbarIndex === 0 ? <div className="z-10 absolute top-[95%]  left-0 bg-white w-[70%] sm:w-[60%] p-4 h-[100vh] shadow-lg md:hidden ">
//                             {/* <div className='flex justify-between border px-2 py-1'><input type='text' placeholder='Search' className='w-[90%] focus:outline-none' /><FiSearch className='mt-1' /></div> */}
//                             {/* Add your menu links here */}
//                             <div className='uppercase'>
//                                 <div className='flex justify-between' onClick={(e) => handleClickNavbar(e, 'ENGAGEMENT_RINGS')} ><span className='block p-2 hover:text-hoverNavbarMenu' >Engagement Rings</span><FaChevronRight className='mt-3' /></div> <hr className='my-1' />
//                                 <div className='flex justify-between' onClick={(e) => handleClickNavbar(e, 'WEDDING_RINGS')}><span className='block p-2 hover:text-hoverNavbarMenu' >Wedding Rings</span><FaChevronRight className='mt-3' /></div> <hr className='my-1' />
//                                 <div className='flex justify-between' onClick={(e) => handleClickNavbar(e, 'DIAMONDS')}><span className='block p-2 hover:text-hoverNavbarMenu' >Diamonds</span><FaChevronRight className='mt-3' /></div> <hr className='my-1' />
//                                 <div className='flex justify-between' onClick={(e) => handleClickNavbar(e, 'GEMSTONES')}><span className='block p-2 hover:text-hoverNavbarMenu' >Gemstones</span><FaChevronRight className='mt-3' /></div> <hr className='my-1' />
//                                 <div className='flex justify-between' onClick={(e) => handleClickNavbar(e, 'JEWELRY')}><span className='block p-2 hover:text-hoverNavbarMenu' >Jewelry</span><FaChevronRight className='mt-3' /></div> <hr className='my-1' />
//                                 {/* <div className='flex justify-between' onClick={() => handleClickNavbar('gifts')}><span className='block p-2 hover:text-hoverNavbarMenu' >Gifts</span><FaChevronRight className='mt-3' /></div> <hr className='my-1' /> */}
//                                 <div className='flex justify-between' onClick={(e) => handleClickNavbar(e, 'ABOUT')}><span className='block p-2 hover:text-hoverNavbarMenu' >About</span><FaChevronRight className='mt-3' /></div> <hr className='my-1' />
//                                 {/* <div className='flex justify-between'><a className='block p-2 hover:text-hoverNavbarMenu' href="/about">Our Story</a><FaChevronRight className='mt-3' /></div> <hr className='my-1' /> */}
//                             </div>


//                             {/* <a className='flex flex-row px-2 py-1 hover:text-hoverNavbarMenu' href="/virtual-appointment">
//                                 <BsPersonVideo size={18} className='text-navbar mr-3' />
//                                 <span className='font-inter  text-15'>Virtual Appointment</span>
//                             </a>
//                             <a className='flex flex-row px-2 py-1 hover:text-hoverNavbarMenu' href="/virtual-appointment">
//                                 <IoChatbubbleOutline size={18} className='text-navbar mr-3' />
//                                 <span className='font-inter  text-15'>Contact Us</span>
//                             </a>
//                             <a className='flex flex-row px-2 py-1 hover:text-hoverNavbarMenu' href="/virtual-appointment">
//                                 <GoPerson size={18} className='text-navbar mr-3' />
//                                 <span className='font-inter  text-15'>Sign In</span>
//                             </a> */}

//                             <div onClick={(e) => { e.stopPropagation(); setIsDropdown(!isDropdown) }} className='px-2 flex items-center  cursor-pointer relative  text-12 md:text-14 '>
//                                 <div className='flex gap-1 items-center'>
//                                     <span className='font-inter flex items-center pl-1 text-15 gap-3'>
//                                         <ReactCountryFlag countryCode={selectedCurrency === 'D' ? 'US' : 'IN'} svg /><p className='mt-1'>{selectedCurrency === 'D' ? 'USD' : 'INR'}</p>
//                                     </span>

//                                     {
//                                         isDropdown ?
//                                             <FaChevronUp className='ml-2 lg:ml-1 ' />
//                                             :
//                                             <FaChevronDown className='ml-2 lg:ml-1 ' />
//                                     }
//                                 </div>

//                                 {isDropdown && (
//                                     <div className='absolute w-[100%] text-black left-0 mt-2 text-15 py-2  px-3 bg-white border top-3 shadow-lg z-10'>
//                                         <ul className='py-[2px] px-4 mx-auto'>
//                                             <li className='font-medium my-1.5 mb-2 text-center'>Select Currency</li>
//                                             {currency?.navigationMenu?.data?.CURRENCY?.map((value, index) => <li key={index} className=' hover:bg-gray-100 cursor-pointer flex gap-1 items-center my-1.5' onClick={() => changeCurrency(value)}><p className='flex items-center'> <ReactCountryFlag countryCode={value.Items === 'USD' ? 'US' : 'IN'} svg /></p><p className='ml-1'>{`${value.Items === 'USD' ? 'United State' : 'India'}   (${value.Items})`}</p></li>)}
//                                         </ul>
//                                     </div>
//                                 )}
//                             </div>

//                             {/* {isLogin && <div className='flex flex-row px-2 py-1 items-center ' onClick={() => { navigate('/accounts'); setIsMenuOpen(false) }}>
//                                 <GoPerson size={18} className='text-navbar mr-3' />
//                                 <span className='font-inter  text-15'>My Accounts ({JSON.parse(localStorage.getItem('user-info'))?.FIRST_NAME})</span>
//                             </div>}
//                             <a className='flex flex-row px-2 py-1 items-center' href="/shop/wish-list">
//                                 <GoHeart size={18} className='text-navbar mr-3' />
//                                 <span className='font-inter  text-15'>Wish List</span>
//                             </a>
//                             <a className='flex flex-row px-2 py-1 items-center' href="/">
//                                 <BsBag size={18} className='text-navbar mr-3' />
//                                 <span className='font-inter  text-15'>My Bag</span>
//                             </a> */}

//                             {/* {isLogin ?
//                                 <>

//                                     <div className='flex flex-row px-2 py-1 items-center ' onClick={() => { localStorage.removeItem('user-info'); navigate('/'); window.location.reload() }}>
//                                         <MdOutlineLogout size={18} className='text-navbar mr-3' />
//                                         <span className='font-inter  text-15'>Sign Out</span>
//                                     </div>
//                                 </>
//                                 :
//                                 <div className='flex flex-row px-2 py-1 items-center ' onClick={() => { navigate('/login'); setIsMenuOpen(false) }}>
//                                     <GoPerson size={18} className='text-navbar mr-3' />
//                                     <span className='font-inter  text-15'>Sign In</span>
//                                 </div>
//                             } */}

//                         </div> :
//                             <div className="z-10 absolute top-[95%] h-[100vh]  left-0 bg-white w-[70%] p-4 shadow-lg md:hidden overflow-auto">
//                                 <NavigationDropdown data={headerHoverItem === 'ABOUT' ? About_Dropdown_Menu : navigationMenu?.data?.[headerHoverItem]}
//                                     link={headerHoverItem === 'ENGAGEMENT_RINGS' ? 'engagement-rings' : headerHoverItem === 'WEDDING_RINGS' ? 'wedding-rings' : headerHoverItem === 'DIAMONDS' ? 'diamonds' : headerHoverItem === 'GEMSTONES' ? 'gemstones' : headerHoverItem === 'JEWELRY' ? 'jewelry' : headerHoverItem === 'GIFTS' ? 'gifts' : headerHoverItem === 'ABOUT' ? 'about' : ''}
//                                     sideMenu={true}
//                                     setNavbarIndex={setNavbarIndex}
//                                     headerHoverItem={headerHoverItem}
//                                     isShow={setIsMenuOpen} />
//                             </div>
//                     )
//                 }
//             </div >
//         </div >
//     );
// }

// export default Header;


import React, { useEffect, useState } from 'react';
import ReactCountryFlag from 'react-country-flag';
import { useTranslation } from 'react-i18next';
import { FaChevronDown, FaChevronRight, FaChevronUp } from 'react-icons/fa';
import { IoReorderThreeOutline } from 'react-icons/io5';
import { RxCross2 } from 'react-icons/rx';
import { RotatingLines } from 'react-loader-spinner';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { About_Dropdown_Menu } from '../helpers/jsonData';
import { axiosInstance } from '../redux/api';
import { addCartListCount } from '../redux/slices/cartList.slice';
import { addMenuData } from '../redux/slices/navigationbarMenu.slice';
import { addSizeData } from '../redux/slices/sizeData.slice';
import { addWishlistCount } from '../redux/slices/wishList.slice';
import { decryptData } from './decrypt';
import { Images } from './Image';
import NavigationDropdown from './NavigationDropdown';
import { showErrorToast, showSuccessToast } from './ToastNotification';

const Header = () => {
    const dispatch = useDispatch()
    const { navigationMenu } = useSelector((state) => state.navigation)
    const { size } = useSelector((state) => state.sizeData)
    const { pathname } = useLocation()
    const [headerHoverItem, setHeaderHoverItem] = useState()
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [navbarIndex, setNavbarIndex] = useState(0)
    const [isShow, setIsShow] = useState(true);
    const data = useSelector((state) => state.data)
    const { Offer } = data.home.data
    const navigate = useNavigate()
    const { t } = useTranslation();
    const [isDropdown, setIsDropdown] = useState(false);
    const currency = useSelector(state => state.navigation)
    // console.log(currency?.navigationMenu?.data?.CURRENCY)
    const userInfo = JSON.parse(localStorage.getItem('user-info'))
    const [selectedCurrency, setSelectedCurrency] = useState(userInfo?.CURRENCY ?? 'INR')
    const isLogin = userInfo ? true : false
    const [loginPopup, setLoginPopup] = useState(false)

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [userNameError, setUserNameError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const { wishListCount } = useSelector(state => state.wishList)
    const { cartListCount } = useSelector(state => state.cartList)
    useEffect(() => {
        setHeaderHoverItem()
    }, [pathname])
    useEffect(() => {
        localStorage.setItem('currency', selectedCurrency)
        setSelectedCurrency(userInfo?.CURRENCY ?? 'INR')
    }, [selectedCurrency, isLogin])

    const handleHoverNavbar = (item) => {
        setLoginPopup(false)
        setHeaderHoverItem(item)
    }
    const handleClickNavbar = (e, item) => {
        e.stopPropagation();
        setHeaderHoverItem(item)
        setNavbarIndex(1)
    }
    async function fetchToken() {
        const response = await axiosInstance.post('token');
        // const token = localStorage.getItem('token')
        // response.data.data && fetchNavbar()
        localStorage.setItem('token', response.data.data)
        window.location.reload()
    };

    const fetchNavbar = async () => {
        try {
            let response = await axiosInstance.post('/menu');
            const isDecryption = process.env.REACT_APP_IS_DECRYPT
            dispatch(addMenuData({ data: isDecryption == 'true' ? decryptData(response.data).data : response.data.data.data }))
        } catch (e) {
            if (e.response.data.message === 'Unauthorized!') {
                fetchToken()
            }
        }
    };

    const getSize = async () => {
        try {
            const response = await axiosInstance.post('size');
            // setSizeData(response.data.data)
            const isDecryption = process.env.REACT_APP_IS_DECRYPT
            dispatch(addSizeData({ data: isDecryption == 'true' ? decryptData(response.data).data[0] : response.data.data.data[0] }))
        } catch (e) {
            console.log(e, 'log error')
        }
    }

    useEffect(() => {
        !localStorage.getItem('token') && fetchToken()
        Object.keys(navigationMenu).length === 0 && localStorage.getItem('token') && fetchNavbar()
        Object.keys(size).length === 0 && getSize()
    }, [])

    useEffect(() => {
        window.addEventListener('click', () => setIsMenuOpen(false))
        return () => window.removeEventListener('click', () => setIsMenuOpen(false))
    })

    const [userInfo1, setUserInfo1] = useState({
        ip: '',
        city: '',
        region: '',
        country: '',
        browser: ''
    });

    useEffect(() => {
        // Fetch IP and location info from ipinfo.io
        const getIpAndLocation = async () => {
            try {
                const response = await fetch('http://ip-api.com/json'); // ip-api supports CORS by default
                if (!response.ok) {
                    throw new Error('Failed to fetch location data');
                }

                const data = await response.json();
                setUserInfo1((prevState) => ({
                    ...prevState,
                    ip: data.query,
                    city: data.city,
                    region: data.regionName,
                    country: data.country,
                }));
            } catch (error) {
                console.log('Error fetching IP location:');
                // setUserInfo1((prevState) => ({
                //     ...prevState,
                //     error: 'Failed to retrieve location data',
                // }));
            }
        };

        // Get browser information
        const getBrowserName = () => {
            const userAgent = navigator.userAgent;
            let browserName = "Unknown";

            if (userAgent.indexOf("Chrome") > -1) {
                browserName = "Google Chrome";
            } else if (userAgent.indexOf("Firefox") > -1) {
                browserName = "Mozilla Firefox";
            } else if (userAgent.indexOf("Safari") > -1) {
                browserName = "Apple Safari";
            } else if (userAgent.indexOf("MSIE") > -1 || userAgent.indexOf("Trident") > -1) {
                browserName = "Internet Explorer";
            }

            setUserInfo1((prevState) => ({
                ...prevState,
                browser: browserName
            }));
        };

        getIpAndLocation();
        getBrowserName();
    }, []);

    const loginHandler = async (e) => {
        e.preventDefault()
        if (email === '') {
            setUserNameError('Email is Required')
        }
        if (password === '') {
            setPasswordError('Password is Required')
        }
        if (email && password) {
            const payload = {
                "PHONE": '',
                "EMAIL": email.trim(),
                "CON_CODE": '',
                "PASSWORD": password
            };

            try {
                setIsLoading(true);
                // const response = await fetch(`${process.env.REACT_APP_PUBLIC_BASE_URL}auth/login`, {
                //     method: 'POST',
                //     headers: {
                //         'Content-Type': 'application/json',
                //     },
                //     body: JSON.stringify(payload),
                // });
                const response = await axiosInstance.post('auth/login', payload)
                // const jsonResponse = await axiosInstance.post('auth/login', payload)
                // const isDecryption = process.env.REACT_APP_IS_DECRYPT
                // const response = Decryption == 'true' ? decryptData(jsonResponse.data) : jsonResponse.data.data

                if (response.data.success === true) {
                    showSuccessToast(response.data.message)
                    setIsLoading(false);
                    localStorage.setItem('user-info', JSON.stringify(response.data.data))
                    localStorage.setItem('currency', response.data.data.CURRENCY)
                    dispatch(addWishlistCount(response.data.data?.WISH_COUNT))
                    dispatch(addCartListCount(response.data.data?.CART_COUNT))
                    setLoginPopup(false)
                    // navigate(`/`)
                    navigate(-1)
                    // window.location.reload();
                    // setTimeout(() => {
                    //     window.location.reload()
                    // }, 1000)
                    const pay = {
                        "LOGIN_ID": response.data.data.USERID,
                        "LOGIN_IP": userInfo1.ip,
                        "DEVICE_NAME": userInfo1.browser,
                        "LOCATION": `${userInfo1.city}, ${userInfo1.region}, ${userInfo1.country}`
                    }
                    await axiosInstance.post('login/add/history', pay)
                } else {
                    setIsLoading(false);
                    showErrorToast(response.data.message)
                }
                // Handle successful response.data, e.g., show a success message or navigate
                // console.log(response.data);
            } catch (error) {
                showErrorToast(error.response.data.message)
                setIsLoading(false);
                // Handle errors, e.g., show an error message
                console.error('There was a problem with the fetch operation:', error);
            } finally {
                setIsLoading(false);
            }
        }
    }

    const changeCurrency = async (value) => {
        const data = value.Items
        setSelectedCurrency(value.Items); setIsDropdown(false)
        const user = JSON.parse(localStorage.getItem('user-info'))
        localStorage.setItem('currency', value.Items)
        if (user && data !== selectedCurrency) {
            const newUser = { ...user, CURRENCY: value.Items };
            localStorage.setItem('user-info', JSON.stringify(newUser))
            try {
                let response = await axiosInstance.post('account/update', newUser);

                if (response.data.success === true) {
                    showSuccessToast(response.data.message)
                    localStorage.setItem('user-info', JSON.stringify(response.data.data))
                    window.location.reload()
                } else {
                    showErrorToast(response.data.message)
                }
                // console.log(response)
            } catch (error) {
                showErrorToast(error.response.data.message)
            }
        } else {
            window.location.reload()
        }
    }
    // useEffect(() => {
    //     if (wishListCount === 0 || cartListCount === 0 || cartListCount === undefined || wishListCount === undefined) {
    //         dispatch(addWishlistCount(userInfo?.WISH_COUNT))
    //         dispatch(addCartListCount(userInfo?.CART_COUNT))
    //     }
    // }, [userInfo])
    return (
        <div className=' w-[100%] z-10 '>
            {/* {Offer && isShow && (
                <div className="w-full bg-offerHeaderBackground h-auto relative" onClick={() => navigate('/promo-codes-and-offers')}>
                    {Offer.map((data, index) => {
                        return <div key={index} className='text-center text-8 sm:text-offerHeaderText font-inter tracking-wider py-1.5 text-offerHeaderTextColor md:px-0 sm:px-20 px-10' dangerouslySetInnerHTML={{ __html: data.DESCR }}></div>
                    })}
                    <RxCross2 className='text-offerHeaderTextColor absolute right-2 top-2 cursor-pointer' onClick={(e) => { e.stopPropagation(); setIsShow(false) }} />
                </div>
            )} */}

            <div className='relative'>
                <div className='relative  border-headerBorder  mx-auto sm:w-full  border-b '>
                    <div className=' flex flex-row mx-auto md:py-4 px-2 sm:w-full border-b border-headerBorder   justify-between  md:h-20  gap-4 xl:px-[15%]'>
                        <div className="md:hidden flex items-center ">
                            <button onClick={(e) => { e.stopPropagation(); setIsMenuOpen(!isMenuOpen); setNavbarIndex(0) }} >
                                {isMenuOpen ? <RxCross2 size={32} className='mt-1' /> : <IoReorderThreeOutline size={32} className='mt-1' />}
                            </button>
                        </div>
                        {/* <div className=" md:flex flex-row w-5/12 mx-auto justify-start pt-2 mt-1 gap-10 hidden"> */}
                        {/* <svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M8.00033 19.75C6.63201 18.5829 5.3637 17.3033 4.20866 15.9248C2.47533 13.8545 0.416993 10.7714 0.416993 7.83335C0.416241 6.33289 0.860627 4.86593 1.69391 3.61813C2.52719 2.37033 3.71192 1.39776 5.09815 0.823532C6.48438 0.2493 8.0098 0.0992076 9.48137 0.392251C10.9529 0.685294 12.3045 1.4083 13.365 2.46977C14.0711 3.1727 14.6307 4.00868 15.0115 4.92934C15.3924 5.85 15.5868 6.83704 15.5837 7.83335C15.5837 10.7714 13.5253 13.8545 11.792 15.9248C10.6369 17.3033 9.36864 18.5829 8.00033 19.75ZM8.00033 4.58335C7.13837 4.58335 6.31172 4.92576 5.70223 5.53525C5.09274 6.14475 4.75033 6.9714 4.75033 7.83335C4.75033 8.6953 5.09274 9.52196 5.70223 10.1314C6.31172 10.7409 7.13837 11.0834 8.00033 11.0834C8.86228 11.0834 9.68893 10.7409 10.2984 10.1314C10.9079 9.52196 11.2503 8.6953 11.2503 7.83335C11.2503 6.9714 10.9079 6.14475 10.2984 5.53525C9.68893 4.92576 8.86228 4.58335 8.00033 4.58335Z" fill="#82746C" />
                            </svg> */}

                        {/* <a className='font-inter hover:text-hoverNavbarMenu text-13 hidden md:block' href="/contact">800.691.0952</a> */}
                        {/* <a className='font-inter hover:text-hoverNavbarMenu text-13 hidden md:block' href="/stores">Stores</a> */}
                        {/* <a className='flex flex-row hover:text-hoverNavbarMenu gap-2' href="/virtual-appointment">
                                <BsPersonVideo size={22} className='text-navbar hover:text-hoverNavbarMenu ' />
                            </a> */}
                        {/* <p className='text-navbarMenuText font-inter'>+91 1234567890</p> */}

                        {/* </div> */}

                        <div className="flex flex-row md:justify-end  items-center w-[65%] justify-center" onClick={() => { navigate('/') }}>
                            <img alt='brillient earth' src={Images.Logo} className='cursor-pointer' />
                            <span className='text-logoText mt-1 font-garamond cursor-pointer'>KYRAHSTAR</span>

                            {/* <img src="https://css.brilliantearth.com/static/img/logo-new.svg" className='w-[70%] h-full' alt="Kyrah Star" /> */}
                        </div>

                        <div className="w-[15%] md:w-[12%] justify-end md:ml-auto  gap-10 flex items-center font-inter">
                            {/* <span className='font-inter border-b flex border-[#CECDCD] h-[25px]' >
                                <input type='text' placeholder='Search' className=' text-15 pl-2 focus:outline-none' />
                                <div className=''>
                                    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M14.1008 13.186L9.78935 8.87461C10.4584 8.00967 10.8203 6.95215 10.8203 5.83984C10.8203 4.5084 10.3007 3.25996 9.36103 2.31865C8.42138 1.37734 7.16963 0.859375 5.83984 0.859375C4.51006 0.859375 3.2583 1.379 2.31865 2.31865C1.37734 3.2583 0.859375 4.5084 0.859375 5.83984C0.859375 7.16963 1.379 8.42139 2.31865 9.36103C3.2583 10.3023 4.5084 10.8203 5.83984 10.8203C6.95215 10.8203 8.00801 10.4584 8.87295 9.79102L13.1844 14.1008C13.197 14.1134 13.212 14.1235 13.2285 14.1303C13.2451 14.1372 13.2628 14.1407 13.2807 14.1407C13.2985 14.1407 13.3163 14.1372 13.3328 14.1303C13.3493 14.1235 13.3643 14.1134 13.377 14.1008L14.1008 13.3786C14.1134 13.366 14.1235 13.351 14.1303 13.3344C14.1372 13.3179 14.1407 13.3002 14.1407 13.2823C14.1407 13.2644 14.1372 13.2467 14.1303 13.2302C14.1235 13.2137 14.1134 13.1987 14.1008 13.186ZM8.46953 8.46953C7.76562 9.17178 6.83262 9.55859 5.83984 9.55859C4.84707 9.55859 3.91406 9.17178 3.21016 8.46953C2.50791 7.76563 2.12109 6.83262 2.12109 5.83984C2.12109 4.84707 2.50791 3.9124 3.21016 3.21016C3.91406 2.50791 4.84707 2.12109 5.83984 2.12109C6.83262 2.12109 7.76728 2.50625 8.46953 3.21016C9.17178 3.91406 9.55859 4.84707 9.55859 5.83984C9.55859 6.83262 9.17178 7.76729 8.46953 8.46953Z" fill="#705B4F" />
                                    </svg>
                                </div>
                            </span> */}
                            <Link className='font-inter hidden md:block relative  ' to={isLogin ? "/shop/wish-list" : '/login'}>
                                {/* <BsHeart size={16} className='text-iconColor ' /> */}
                                <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M18.8301 4.09298C18.5421 3.42619 18.1269 2.82194 17.6076 2.31408C17.088 1.8047 16.4753 1.3999 15.8029 1.12169C15.1057 0.832071 14.3579 0.683824 13.6029 0.685562C12.5438 0.685562 11.5104 0.975601 10.6123 1.52345C10.3975 1.65451 10.1934 1.79845 10 1.95529C9.80664 1.79845 9.60254 1.65451 9.3877 1.52345C8.48965 0.975601 7.45625 0.685562 6.39707 0.685562C5.63438 0.685562 4.89531 0.831656 4.19707 1.12169C3.52246 1.40099 2.91445 1.80275 2.39238 2.31408C1.87245 2.82137 1.45712 3.42576 1.16992 4.09298C0.871289 4.78693 0.71875 5.52384 0.71875 6.28224C0.71875 6.99767 0.864844 7.74318 1.15488 8.50158C1.39766 9.13537 1.7457 9.79279 2.19043 10.4567C2.89512 11.5072 3.86406 12.6029 5.06719 13.7137C7.06094 15.5549 9.03535 16.8268 9.11914 16.8783L9.62832 17.2049C9.85391 17.3488 10.1439 17.3488 10.3695 17.2049L10.8787 16.8783C10.9625 16.8246 12.9348 15.5549 14.9307 13.7137C16.1338 12.6029 17.1027 11.5072 17.8074 10.4567C18.2522 9.79279 18.6023 9.13537 18.843 8.50158C19.133 7.74318 19.2791 6.99767 19.2791 6.28224C19.2813 5.52384 19.1287 4.78693 18.8301 4.09298ZM10 15.5055C10 15.5055 2.35156 10.6049 2.35156 6.28224C2.35156 4.09298 4.1627 2.31837 6.39707 2.31837C7.96758 2.31837 9.32969 3.19494 10 4.47541C10.6703 3.19494 12.0324 2.31837 13.6029 2.31837C15.8373 2.31837 17.6484 4.09298 17.6484 6.28224C17.6484 10.6049 10 15.5055 10 15.5055Z" fill="#705B4F" />
                                </svg>
                                {wishListCount > 0 && <div className='absolute text-10 font-inter -right-1 -bottom-1 text-[#705B4F]'>{wishListCount}</div>}
                            </Link>
                            <Link className='font-inter hidden md:block relative' to={isLogin ? "/shop/cart" : '/login'}>
                                {/* <BsBag size={16} className='text-iconColor ' /> */}
                                <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M11.75 5.25V7.125C11.75 7.29076 11.6842 7.44973 11.5669 7.56694C11.4497 7.68415 11.2908 7.75 11.125 7.75H10.5V5.25H5.5V7.75H4.875C4.70924 7.75 4.55027 7.68415 4.43306 7.56694C4.31585 7.44973 4.25 7.29076 4.25 7.125V5.25H1.75V16.5H14.25V5.25H11.75ZM4.25 4C4.25 3.00544 4.64509 2.05161 5.34835 1.34835C6.05161 0.645088 7.00544 0.25 8 0.25C8.99456 0.25 9.94839 0.645088 10.6517 1.34835C11.3549 2.05161 11.75 3.00544 11.75 4H14.875C15.0408 4 15.1997 4.06585 15.3169 4.18306C15.4342 4.30027 15.5 4.45924 15.5 4.625V17.125C15.5 17.2908 15.4342 17.4497 15.3169 17.5669C15.1997 17.6842 15.0408 17.75 14.875 17.75H1.125C0.95924 17.75 0.800269 17.6842 0.683058 17.5669C0.565848 17.4497 0.5 17.2908 0.5 17.125V4.625C0.5 4.45924 0.565848 4.30027 0.683058 4.18306C0.800269 4.06585 0.95924 4 1.125 4H4.25ZM5.5 4H10.5C10.5 3.33696 10.2366 2.70107 9.76777 2.23223C9.29893 1.76339 8.66304 1.5 8 1.5C7.33696 1.5 6.70107 1.76339 6.23223 2.23223C5.76339 2.70107 5.5 3.33696 5.5 4Z" fill="#705B4F" />
                                </svg>
                                {cartListCount > 0 && <div className='absolute text-10 font-inter -right-2 -bottom-1 text-[#705B4F]'>{cartListCount}</div>}
                            </Link>
                            <div
                                // onMouseLeave={() => { setLoginPopup(false); setUserNameError(''); setPasswordError(''); setEmail(''); setPassword('') }}
                                className='relative'>
                                <div
                                // onMouseEnter={() => setLoginPopup(true)}
                                >
                                    <div className='font-inter flex gap-1 items-center cursor-pointer' onClick={() => { setLoginPopup(!loginPopup); setUserNameError(''); setPasswordError(''); setEmail(''); setPassword(''); !isLogin && navigate('/login', { replace: true }) }}>
                                        {/* <BsPerson size={16} className='text-iconColor ' /> */}
                                        {localStorage?.getItem('user-info') && <span className='text-[#705B4F]'>{JSON.parse(localStorage.getItem('user-info'))?.FIRST_NAME}</span>}
                                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M9 9C10.094 9 11.1432 8.52589 11.9168 7.68198C12.6904 6.83807 13.125 5.69347 13.125 4.5C13.125 3.30653 12.6904 2.16193 11.9168 1.31802C11.1432 0.474106 10.094 0 9 0C7.90598 0 6.85677 0.474106 6.08318 1.31802C5.3096 2.16193 4.875 3.30653 4.875 4.5C4.875 5.69347 5.3096 6.83807 6.08318 7.68198C6.85677 8.52589 7.90598 9 9 9ZM11.75 4.5C11.75 5.29565 11.4603 6.05871 10.9445 6.62132C10.4288 7.18393 9.72935 7.5 9 7.5C8.27065 7.5 7.57118 7.18393 7.05546 6.62132C6.53973 6.05871 6.25 5.29565 6.25 4.5C6.25 3.70435 6.53973 2.94129 7.05546 2.37868C7.57118 1.81607 8.27065 1.5 9 1.5C9.72935 1.5 10.4288 1.81607 10.9445 2.37868C11.4603 2.94129 11.75 3.70435 11.75 4.5ZM17.25 16.5C17.25 18 15.875 18 15.875 18H2.125C2.125 18 0.75 18 0.75 16.5C0.75 15 2.125 10.5 9 10.5C15.875 10.5 17.25 15 17.25 16.5ZM15.875 16.494C15.8736 16.125 15.6632 15.015 14.731 13.998C13.8345 13.02 12.1474 12 9 12C5.85125 12 4.1655 13.02 3.269 13.998C2.33675 15.015 2.12775 16.125 2.125 16.494H15.875Z" fill="#705B4F" />
                                        </svg>
                                    </div>
                                </div>
                                {loginPopup &&
                                    (!isLogin ? <div className='absolute w-[60vw] sm:w-[50vw] md:w-[30vw] lg:w-[25vw] 2xl:w-[15vw]  right-0 md:-right-20 mt-2 text-12 md:text-14 py-2  px-3 bg-white border top-5 shadow-lg z-40 rounded-2xl border-footerBackground'>
                                        <div className='flex flex-col justify-center text-center p-5'>
                                            <p className='text-18 mb-3'>Your Account</p>
                                            <form onSubmit={loginHandler} className='w-[100%]'>
                                                <input type='text' placeholder='Email Address' className='border-b w-full border-black text-black outline-none my-2'
                                                    value={email}
                                                    onChange={(e) => {
                                                        let inputValue = e.target.value;
                                                        const regex = /\S+@\S+\.\S+/;
                                                        setEmail(inputValue); // Only update the value if valid
                                                        if (inputValue === '') {
                                                            setUserNameError('Email is required.');
                                                        } else if (!regex.test(inputValue)) {
                                                            setUserNameError('Invalid email format');
                                                        } else {
                                                            setUserNameError('');// Only update the value if valid
                                                        }
                                                    }}
                                                />
                                                {userNameError && <p className="text-red-500 text-12 text-left font-inter">{userNameError}</p>}
                                                <input type='password' placeholder='Password' className='border-b w-full border-black text-black outline-none my-2'
                                                    value={password}
                                                    onChange={(e) => {
                                                        let inputValue = e.target.value;
                                                        setPassword(e.target.value)
                                                        if (inputValue === '') {
                                                            setPasswordError('Password is Required.');
                                                        } else {
                                                            setPasswordError(''); // Only update the value if valid
                                                        }
                                                    }}
                                                />
                                                {passwordError && <p className="text-red-500 text-12 mb-2 font-inter text-left">{passwordError}</p>}
                                                <p className='mb-2 hover:underline hover:cursor-pointer w-fit mx-auto' onClick={() => { navigate('/accounts/password-reset'); setLoginPopup(false) }}>Forgot Your Password?</p>
                                                {/* <button className='bg-footerBackground cursor-pointer text-white mt-2 py-1.5' onClick={loginHandler}>Log In</button> */}
                                                {
                                                    isLoading ?
                                                        <button
                                                            className={`bg-footerBackground cursor-pointer text-white mt-2 w-full py-1.5 flex justify-center rounded-full`}
                                                        >
                                                            <RotatingLines
                                                                visible={true}
                                                                height="20"
                                                                width="20"
                                                                strokeWidth="5"
                                                                strokeColor='white'
                                                                animationDuration="0.75"
                                                                ariaLabel="rotating-lines-loading"
                                                                wrapperStyle={{}}
                                                                wrapperClass=""
                                                            />
                                                        </button>
                                                        :
                                                        <button
                                                            className='bg-footerBackground cursor-pointer w-full text-white mt-2 py-1.5 rounded-full'
                                                        >
                                                            Login
                                                        </button>
                                                }
                                            </form>
                                            <button className='bg-gray-200 cursor-pointer text-black mt-3 py-1.5 rounded-full' onClick={() => { navigate('register'); setLoginPopup(false) }}>Sign Up</button>
                                        </div>
                                    </div> :
                                        <div className='absolute w-[50vw] sm:w-[40vw] md:w-[20vw] 2xl:w-[15vw]  right-0 md:-right-20 mt-2  py-2  px-3 bg-white border top-5 shadow-lg z-10 rounded-2xl border-footerBackground'>
                                            <div className='flex flex-col justify-center text-center p-3 md:p-5'>
                                                <div className='text-left flex flex-col mb-3 '>
                                                    <span className='hover:underline hover:cursor-pointer w-fit my-1 text-14 md:text-16' onClick={() => { navigate('/accounts'); setLoginPopup(false) }}>My Account</span>
                                                    <span className='hover:underline hover:cursor-pointer w-fit my-1 text-14 md:text-16' onClick={() => { navigate('/accounts/my-order'); setLoginPopup(false) }}>My Order</span>
                                                    <span className='hover:underline hover:cursor-pointer w-fit my-1 text-14 md:text-16' onClick={() => { navigate('/shop/wish-list'); setLoginPopup(false) }}>Wish List</span>
                                                </div>
                                                <button className='bg-footerBackground cursor-pointer text-white text-14 md:text-16 md:mt-2 py-1.5 rounded-full' onClick={() => { dispatch(addWishlistCount(0)); dispatch(addCartListCount(0)); showSuccessToast('Log Out Succlessfull !'); localStorage.setItem('currency', 'INR'); localStorage.removeItem('user-info'); setLoginPopup(false); setSelectedCurrency('INR'); navigate('/') }}>Sign Out</button>
                                                {/* <button className='bg-gray-200 cursor-pointer text-black mt-3 py-1.5'>Sign Up</button> */}
                                            </div>
                                        </div>)
                                }
                            </div>
                        </div>
                        {/* <div className=" flex w-5/12 justify-end mx-auto mt-1 py-1 gap-6 md:hidden mr-3">
                            <a className='font-inter ' href="/stores">
                                <IoCallOutline size={16} className='text-iconColor ' />
                            </a>
                            <a className='font-inter' href="/shop/cart">
                                <BsBag size={16} className='text-iconColor ' />
                            </a>
                        </div> */}
                        {/* <p className='text-navbarMenuText relative uppercase font-inter'>{selectedCurrency}</p>
                            {currency && <div className='absolute top-1'>
                                {currency?.map((data, index) => {
                                    return (<li key={index}>{data.CURR_NAME}</li>)
                                })}
                            </div>} */}
                        <div onMouseLeave={() => setIsDropdown(false)} className=' items-center text-[#705B4F] cursor-pointer relative  text-12 md:text-14 hidden md:flex'>
                            <div onMouseEnter={() => { setIsDropdown(true); setLoginPopup(false) }} className='flex gap-1 items-center'>
                                <span className='font-inter flex items-center gap-1 text-12 lg:text-16'>
                                    <ReactCountryFlag countryCode={selectedCurrency === 'USD' ? 'US' : 'IN'} svg />{selectedCurrency}
                                </span>

                                {
                                    isDropdown ?
                                        <FaChevronUp className='ml-2 lg:ml-1 text-[#705B4F]' />
                                        :
                                        <FaChevronDown className='ml-2 lg:ml-1 text-[#705B4F]' />
                                }
                            </div>

                            {isDropdown && (
                                <div className='absolute rounded-2xl w-[370%] text-black right-0 mt-2 text-12 md:text-16 py-2  px-3 bg-white border border-footerBackground top-5 md:top-7 lg:top-7 shadow-lg z-10'>
                                    <ul className='py-[2px] px-4 mx-auto'>
                                        <li className='font-medium my-1.5 mb-2 text-center'>Select Currency</li>
                                        {currency?.navigationMenu?.data?.CURRENCY?.map((value, index) => <li key={index} className=' hover:bg-gray-100 cursor-pointer flex gap-1 items-center my-1.5' onClick={() => changeCurrency(value)}><p className='flex items-center'> <ReactCountryFlag countryCode={value.Items === 'USD' ? 'US' : 'IN'} svg /></p><p className='ml-1'>{`${value.Items === 'USD' ? 'United State' : 'India'}   (${value.Items})`}</p></li>)}
                                        {/* <li className='px-[17px] md:px-[19px] lg:px-[21px] py-[5px] hover:bg-gray-100 cursor-pointer' onClick={sortProductData}>Best Sellers</li>
                                    <li className='px-[17px] md:px-[19px] lg:px-[21px] py-[5px] hover:bg-gray-100 cursor-pointer' onClick={sortProductData}>Newest</li>
                                    <li className='px-[17px] md:px-[19px] lg:px-[21px] py-[5px] hover:bg-gray-100 cursor-pointer' onClick={sortProductData}>Price: Low to High</li>
                                    <li className='px-[17px] md:px-[19px] lg:px-[21px] py-[5px] hover:bg-gray-100 cursor-pointer' onClick={sortProductData}>Price: High to Low</li> */}
                                    </ul>
                                </div>
                            )}
                        </div>

                    </div>
                </div>
                {/* {!isMenuOpen && <div className=' justify-between border border-headerBorder px-2 py- mx-3 mt-2 md:hidden mb-2 flex'><input type='text' placeholder='Search' className='w-[90%] focus:outline-none' /><FiSearch className='mt-1' /></div>} */}
                {
                    !isMenuOpen && <div className='bg-headerMenuBackground hidden md:block ' onMouseLeave={() => setHeaderHoverItem()}>
                        <div className="flex flex-row mx-auto justify-center lg:gap-16 gap-5  py-5 w-full relative">
                            {/* <Link to={'/engagement-rings'} className=' font-navbarMenuText font-inter  text-navbarMenu text-13 lg:text-navbarMenuText  tracking-wide  hover:text-hoverNavbarMenu block' onMouseEnter={() => handleHoverNavbar('ENGAGEMENT_RINGS')}>{t("ENGAGEMENT_RINGS")}</Link>
                            <Link to={'/wedding-rings'} className=' font-navbarMenuText font-inter  text-navbarMenu text-13 lg:text-navbarMenuText tracking-wide  hover:text-hoverNavbarMenu block' onMouseEnter={() => handleHoverNavbar('WEDDING_RINGS')}>{t("WEDDING_RINGS")}</Link>*/}
                            <Link to={'/diamonds/search-lab-diamonds'} className=' font-navbarMenuText font-inter  text-navbarMenu text-13 lg:text-navbarMenuText tracking-wide  hover:text-hoverNavbarMenu block' onMouseEnter={() => handleHoverNavbar('DIAMONDS')}>{t("DIAMONDS")}</Link>
                            {/* <Link to={'/gemstones'} className=' font-navbarMenuText font-inter  text-navbarMenu text-13 lg:text-navbarMenuText tracking-wide  hover:text-hoverNavbarMenu block' onMouseEnter={() => handleHoverNavbar('GEMSTONES')}>{t("GEMSTONES")}</Link> */}
                            {/*<Link to={'/jewelry'} className=' font-navbarMenuText font-inter  text-navbarMenu text-13 lg:text-navbarMenuText tracking-wide  hover:text-hoverNavbarMenu block' onMouseEnter={() => handleHoverNavbar('JEWELRY')}>{t("JEWELRY")}</Link>*/}
                            {/* <Link to={'/gifts'} className=' font-navbarMenuText font-inter  text-navbarMenu text-13 lg:text-navbarMenuText tracking-wide  hover:text-hoverNavbarMenu block' onMouseEnter={() => handleHoverNavbar('GIFTS')}>{t("GIFTS")}</Link> */}
                            <Link to={'/about/terms-&-condition'} className=' font-navbarMenuText font-inter  text-navbarMenu text-13 lg:text-navbarMenuText tracking-wide  hover:text-hoverNavbarMenu block' onMouseEnter={() => handleHoverNavbar('ABOUT')}>{t("ABOUT")}</Link>
                            {/* <a className='font-inter text-navbar text-13 lg:text-14 tracking-wide font-normal hover:text-hoverNavbarMenu block' href="/engagement-rings" onMouseEnter={() => handleHoverNavbar('engagement')} >{t("ENGAGEMENT_RINGS")}</a>
                        <a className='font-inter text-navbar text-13 lg:text-14 tracking-wide font-normal hover:text-hoverNavbarMenu block' href="/wedding-rings" onMouseEnter={() => handleHoverNavbar('wedding')}>{t("WEDDING_RINGS")}</a>
                        <a className='font-inter text-navbar text-13 lg:text-14 tracking-wide font-normal hover:text-hoverNavbarMenu block' href="/diamonds" onMouseEnter={() => handleHoverNavbar('diamonds')}>{t("DIAMONDS")}</a>
                        <a className='font-inter text-navbar text-13 lg:text-14 tracking-wide font-normal hover:text-hoverNavbarMenu block' href="/gemstones" onMouseEnter={() => handleHoverNavbar('gemstone')}>{t("gemstones")}</a>
                        <a className='font-inter text-navbar text-13 lg:text-14 tracking-wide font-normal hover:text-hoverNavbarMenu block' href="/jewelry" onMouseEnter={() => handleHoverNavbar('jewelry')}>{t("JEWELRY")}</a>
                        <a className='font-inter text-navbar text-13 lg:text-14 tracking-wide font-normal hover:text-hoverNavbarMenu block' href="/gifts" onMouseEnter={() => handleHoverNavbar('gifts')}>{t("GIFTS")}</a>
                        <a className='font-inter text-navbar text-13 lg:text-14 tracking-wide font-normal hover:text-hoverNavbarMenu block' href="/about" onMouseEnter={() => handleHoverNavbar('about')}>{t("ABOUT")}</a> */}
                        </div>
                        {/* {headerHoverItem && <div onMouseLeave={() => setHeaderHoverItem()}> <NavigationDropdown data={headerHoverItem === 'ENGAGEMENT_RINGS' ? navigationMenu?.data?.[headerHoverItem] : headerHoverItem === 'wedding' ? Wedding_Rings_Dropdown_Menu : headerHoverItem === 'diamonds' ? Diamond_Dropdown_Menu : headerHoverItem === 'gemstone' ? Gemstone_Dropdown_Menu : headerHoverItem === 'jewelry' ? Jewelry_Dropdown_Menu : headerHoverItem === 'gifts' ? Gifts_Dropdown_Menu : headerHoverItem === 'about' ? About_Dropdown_Menu : ''}
                        link={headerHoverItem === 'engagement' ? 'engagement-rings' : headerHoverItem === 'wedding' ? 'wedding-rings' : headerHoverItem === 'diamonds' ? 'diamonds' : headerHoverItem === 'gemstone' ? 'gemstones' : headerHoverItem === 'jewelry' ? 'jewelry' : headerHoverItem === 'gifts' ? 'gifts' : headerHoverItem === 'about' ? 'about' : ''}
                        className='absolute top-[90%] w-full xl:pl-[25%] px-5 z-20 bg-white border-y mt-3 border-lightBorder  shadow-xl' /></div>} */}
                        {
                            headerHoverItem && <div onMouseLeave={() => setHeaderHoverItem()}> <NavigationDropdown data={headerHoverItem === 'ABOUT' ? About_Dropdown_Menu : navigationMenu?.data?.[headerHoverItem]}
                                link={headerHoverItem === 'ENGAGEMENT_RINGS' ? 'engagement-rings' : headerHoverItem === 'WEDDING_RINGS' ? 'wedding-rings' : headerHoverItem === 'DIAMONDS' ? 'diamonds' : headerHoverItem === 'GEMSTONES' ? 'gemstones' : headerHoverItem === 'JEWELRY' ? 'jewelry' : headerHoverItem === 'GIFTS' ? 'gifts' : headerHoverItem === 'ABOUT' ? 'about' : ''}
                                hover={headerHoverItem}
                                className='absolute top-[90%] w-full  z-20 bg-white border-b mt-3 border-headerBorder  shadow-xl' /></div>
                        }
                    </div>
                }

                {/* Mobile Menu */}
                {
                    isMenuOpen && (
                        navbarIndex === 0 ? <div className="z-10 absolute top-[95%]  left-0 bg-white w-[70%] sm:w-[60%] p-4 h-[100vh] shadow-lg md:hidden ">
                            {/* <div className='flex justify-between border px-2 py-1'><input type='text' placeholder='Search' className='w-[90%] focus:outline-none' /><FiSearch className='mt-1' /></div> */}
                            {/* Add your menu links here */}
                            <div className='uppercase'>
                                {/* <div className='flex justify-between' onClick={(e) => handleClickNavbar(e, 'ENGAGEMENT_RINGS')} ><span className='block p-2 hover:text-hoverNavbarMenu' >Engagement Rings</span><FaChevronRight className='mt-3' /></div> <hr className='my-1' /> */}
                                {/* <div className='flex justify-between' onClick={(e) => handleClickNavbar(e, 'WEDDING_RINGS')}><span className='block p-2 hover:text-hoverNavbarMenu' >Wedding Rings</span><FaChevronRight className='mt-3' /></div> <hr className='my-1' /> */}
                                <div className='flex justify-between' onClick={(e) => handleClickNavbar(e, 'DIAMONDS')}><span className='block p-2 hover:text-hoverNavbarMenu' >Diamonds</span><FaChevronRight className='mt-3' /></div> <hr className='my-1' />
                                {/* <div className='flex justify-between' onClick={(e) => handleClickNavbar(e, 'GEMSTONES')}><span className='block p-2 hover:text-hoverNavbarMenu' >Gemstones</span><FaChevronRight className='mt-3' /></div> <hr className='my-1' /> */}
                                {/* <div className='flex justify-between' onClick={(e) => handleClickNavbar(e, 'JEWELRY')}><span className='block p-2 hover:text-hoverNavbarMenu' >Jewelry</span><FaChevronRight className='mt-3' /></div> <hr className='my-1' /> */}
                                {/* <div className='flex justify-between' onClick={() => handleClickNavbar('gifts')}><span className='block p-2 hover:text-hoverNavbarMenu' >Gifts</span><FaChevronRight className='mt-3' /></div> <hr className='my-1' /> */}
                                <div className='flex justify-between' onClick={(e) => handleClickNavbar(e, 'ABOUT')}><span className='block p-2 hover:text-hoverNavbarMenu' >About</span><FaChevronRight className='mt-3' /></div> <hr className='my-1' />
                                {/* <div className='flex justify-between'><a className='block p-2 hover:text-hoverNavbarMenu' href="/about">Our Story</a><FaChevronRight className='mt-3' /></div> <hr className='my-1' /> */}
                            </div>


                            {/* <a className='flex flex-row px-2 py-1 hover:text-hoverNavbarMenu' href="/virtual-appointment">
                                <BsPersonVideo size={18} className='text-navbar mr-3' />
                                <span className='font-inter  text-15'>Virtual Appointment</span>
                            </a>
                            <a className='flex flex-row px-2 py-1 hover:text-hoverNavbarMenu' href="/virtual-appointment">
                                <IoChatbubbleOutline size={18} className='text-navbar mr-3' />
                                <span className='font-inter  text-15'>Contact Us</span>
                            </a>
                            <a className='flex flex-row px-2 py-1 hover:text-hoverNavbarMenu' href="/virtual-appointment">
                                <GoPerson size={18} className='text-navbar mr-3' />
                                <span className='font-inter  text-15'>Sign In</span>
                            </a> */}

                            <div onClick={(e) => { e.stopPropagation(); setIsDropdown(!isDropdown) }} className='px-2 flex items-center  cursor-pointer relative  text-12 md:text-14 '>
                                <div className='flex gap-1 items-center'>
                                    <span className='font-inter flex items-center pl-1 text-15 gap-3'>
                                        <ReactCountryFlag countryCode={selectedCurrency === 'USD' ? 'US' : 'IN'} svg /><p className='mt-1'>{selectedCurrency}</p>
                                    </span>

                                    {
                                        isDropdown ?
                                            <FaChevronUp className='ml-2 lg:ml-1 ' />
                                            :
                                            <FaChevronDown className='ml-2 lg:ml-1 ' />
                                    }
                                </div>

                                {isDropdown && (
                                    <div className='absolute w-[100%] text-black left-0 mt-2 text-15 py-2  px-3 bg-white border top-3 shadow-lg z-10'>
                                        <ul className='py-[2px] px-4 mx-auto'>
                                            <li className='font-medium my-1.5 mb-2 text-center'>Select Currency</li>
                                            {currency?.navigationMenu?.data?.CURRENCY?.map((value, index) => <li key={index} className=' hover:bg-gray-100 cursor-pointer flex gap-1 items-center my-1.5' onClick={() => changeCurrency(value)}><p className='flex items-center'> <ReactCountryFlag countryCode={value.Items === 'USD' ? 'US' : 'IN'} svg /></p><p className='ml-1'>{`${value.Items === 'USD' ? 'United State' : 'India'}   (${value.Items})`}</p></li>)}
                                        </ul>
                                    </div>
                                )}
                            </div>

                            {/* {isLogin && <div className='flex flex-row px-2 py-1 items-center ' onClick={() => { navigate('/accounts'); setIsMenuOpen(false) }}>
                                <GoPerson size={18} className='text-navbar mr-3' />
                                <span className='font-inter  text-15'>My Accounts ({JSON.parse(localStorage.getItem('user-info'))?.FIRST_NAME})</span>
                            </div>}
                            <a className='flex flex-row px-2 py-1 items-center' href="/shop/wish-list">
                                <GoHeart size={18} className='text-navbar mr-3' />
                                <span className='font-inter  text-15'>Wish List</span>
                            </a>
                            <a className='flex flex-row px-2 py-1 items-center' href="/">
                                <BsBag size={18} className='text-navbar mr-3' />
                                <span className='font-inter  text-15'>My Bag</span>
                            </a> */}

                            {/* {isLogin ?
                                <>

                                    <div className='flex flex-row px-2 py-1 items-center ' onClick={() => { localStorage.removeItem('user-info'); navigate('/'); window.location.reload() }}>
                                        <MdOutlineLogout size={18} className='text-navbar mr-3' />
                                        <span className='font-inter  text-15'>Sign Out</span>
                                    </div>
                                </>
                                :
                                <div className='flex flex-row px-2 py-1 items-center ' onClick={() => { navigate('/login'); setIsMenuOpen(false) }}>
                                    <GoPerson size={18} className='text-navbar mr-3' />
                                    <span className='font-inter  text-15'>Sign In</span>
                                </div>
                            } */}

                        </div> :
                            <div className="z-10 absolute top-[95%] h-[100vh]  left-0 bg-white w-[70%] p-4 shadow-lg md:hidden overflow-auto">
                                <NavigationDropdown data={headerHoverItem === 'ABOUT' ? About_Dropdown_Menu : navigationMenu?.data?.[headerHoverItem]}
                                    link={headerHoverItem === 'ENGAGEMENT_RINGS' ? 'engagement-rings' : headerHoverItem === 'WEDDING_RINGS' ? 'wedding-rings' : headerHoverItem === 'DIAMONDS' ? 'diamonds' : headerHoverItem === 'GEMSTONES' ? 'gemstones' : headerHoverItem === 'JEWELRY' ? 'jewelry' : headerHoverItem === 'GIFTS' ? 'gifts' : headerHoverItem === 'ABOUT' ? 'about' : ''}
                                    sideMenu={true}
                                    setNavbarIndex={setNavbarIndex}
                                    headerHoverItem={headerHoverItem}
                                    isShow={setIsMenuOpen} />
                            </div>
                    )
                }
            </div>
        </div>
    );
}

export default Header;