import React from 'react'

const RefundPolicy = () => {
    return (
        <div className='px-10 py-5 w-[80%] mx-auto'>
            REFUND POLICY
            <p>
                All refunds shall be made by means of an electronic bank transfer to the customer's bank account within 10 days of receipt of the items. If the mode of payment is through electronic debit / credit card / net banking, the refund will be processed by reversing the previous transaction.
                The cancellation of orders paid through digital wallets will not be refunded to your bank account. It will only be credited to kyrahstar Wallet and can be used for future purchases.
                In the case of refunds under the 7 days return policy, the refund shall be subject to quality review by our professional quality control department and verification of product records such as insurance certificates, original invoices, and product certificates.
            </p>
        </div>
    )
}

export default RefundPolicy
