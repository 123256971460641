
export const Images = {
    Logo: require("../assets/pngImages/Logo.png"),
    // EngagementRing: require("../assets/pngImages/EngagementRing.png"),
    // WeddingRing: require("../assets/pngImages/WeddingRing.png"),
    EngagementRing: require("../assets/pngImages/diamond1.jpg"),
    WeddingRing: require("../assets/pngImages/diamond1.jpg"),
    Advertisement: require('../assets/pngImages/Addvertisement.png'),
    FooterStepPoster: require('../assets/pngImages/FooterStepPoster.png'),
    personLogo: require('../assets/svg/personLogo.svg'),
    notFound: require('../assets/pngImages/NotFound.png'),
    PageNotFound: require('../assets/pngImages/404.png'),
}