import { createSlice } from "@reduxjs/toolkit";

export const tableDataSLice = createSlice({
    name: 'tableData',
    initialState: {
        tableData: {
            loading: false,
            data: [],
            diamondDetail: []
        }
    },
    reducers: {
        setTableDataLoading: (state) => {
            state.tableData.loading = true
        },
        addTableData: (state, action) => {
            const { data } = action.payload
            state.tableData.data = data;
            state.tableData.loading = false;
        },
        addDiamondDetails: (state, action) => {
            const { data } = action.payload
            state.tableData.diamondDetail = data;
            state.tableData.loading = false;
        },
        error: (state) => {
            state.tableData.loading = false;
        }
    }
}
)

export const { setTableDataLoading, addTableData, addDiamondDetails, error } = tableDataSLice.actions
export default tableDataSLice.reducer