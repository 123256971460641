import React, { useEffect, useState } from 'react'
import { RxCross2 } from 'react-icons/rx'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Slider from 'react-slick/lib/slider'
import { ClipLoader } from 'react-spinners'
import { decryptData } from '../../components/decrypt'
import ToastNotification, { showErrorToast, showSuccessToast } from '../../components/ToastNotification'
import { axiosInstance } from '../../redux/api'
import { addCartListCount } from '../../redux/slices/cartList.slice'
import { addSelectedSize } from '../../redux/slices/sizeData.slice'
import { encryptURL } from '../../utils/encrypt'
import { BiSolidEdit } from "react-icons/bi";

const ShoppingCart = () => {
    const [cartData, setCartData] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const userInfo = JSON.parse(localStorage.getItem('user-info'))

    const getCartData = async () => {
        const payload = { USERID: userInfo?.USERID, CURR: userInfo?.CURRENCY }
        try {
            setIsLoading(true);
            let response = await axiosInstance.post('/cart', payload);

            setIsLoading(false);
            const isDecryption = process.env.REACT_APP_IS_DECRYPT
            setCartData(isDecryption == 'true' ? decryptData(response.data).data : response.data.data.data)

            // setCartData(response.data.data)

            // dispatch(addWishlistCount(totalProduct))
        } catch (error) {
            setIsLoading(false);
            showErrorToast(error.response.data.message)
            // Handle errors, e.g., show an error message
            console.error('There was a problem with the fetch operation:', error);
        } finally {
            setIsLoading(false);
        }
    }
    useEffect(() => {
        getCartData()
    }, [])

    const [subtotal, setSubTotal] = useState()

    useEffect(() => {
        if (cartData?.length > 0) {
            const productPrices = cartData[0]?.map((item) => item?.PRICE).filter(price => price !== undefined)
            const productPrices1 = cartData[0]?.map((item) => item?.Price).filter(price => price !== undefined)
            const diamondPrices = cartData[1]?.map((item) => item?.Price).filter(price => price !== undefined)

            const finalPrices = productPrices?.concat(productPrices1, diamondPrices).filter(price => price !== undefined)
            setSubTotal(finalPrices?.reduce((acc, price) => acc += price, 0))
        }
    })


    const removeFromCart = async (data) => {
        if (userInfo?.USERID) {
            // const shape = shapes.Shape.find(data => data.Items === diamondDetails?.[0]?.Shape)
            let payload = {
                "USERID": userInfo?.USERID,
                "SKUNO": data?.SKUNO ? data?.SKUNO : '',
                "PRD_VARIANT_ID": data?.PRD_VARIANT_ID ? data?.PRD_VARIANT_ID : 0,
                "S_CODE": data?.S_CODE ? data?.S_CODE : '',
                "STOCK_ID": data?.STOCKID ?? data?.StockID,
                "IS_ONLY_DIAMOND": data?.SKUNO ? false : true,
                "IS_REMOVE": true,
                "QUANTITY": 1,
            };

            try {
                const response = await axiosInstance.post('cart/add', payload);
                // const jsonResponse = await axiosInstance.post('wishlist/add', payload);
                // const isDecryption = process.env.REACT_APP_IS_DECRYPT
                // const response = Decryption == 'true' ? decryptData(jsonResponse.data) : jsonResponse.data.data
                if (response.data.success) {
                    showSuccessToast(response.data.message)
                    dispatch(addCartListCount(response?.data?.data?.[0]?.[0]?.CART_COUNT))
                    getCartData()
                }
            } catch (e) {
                showErrorToast(e.response.data.message)
                // setLoading(false)
            }
        } else {
            navigate('/login')
        }
    }
    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToScroll: 1,
    };
    const extractStockIds = (data) => {
        let stockIds = [];

        data?.forEach(group => {
            group?.forEach(item => {
                if (item?.STOCKID) {
                    stockIds?.push(item?.STOCKID); // For "STOCKID"
                } else if (item.StockID) {
                    stockIds?.push(item.StockID); // For "StockID"
                }
            });
        });

        return stockIds;
    };

    const [checkOutLoader, setCheckOutLoader] = useState(false)
    const handleOrderCheckOut = async () => {
        if ((userInfo.ADDRESS === '' || userInfo.ADDRESS === null) && (userInfo.PINCODE === '' || userInfo.PINCODE === null)) {
            showErrorToast(`Please Add Address and Pin Code`)
            return
        }
        if (userInfo.ADDRESS === '' || userInfo.ADDRESS === null) {
            showErrorToast(`Please Add Address`)
            return
        }
        if (userInfo.PINCODE === '' || userInfo.PINCODE === null) {
            showErrorToast(`Please Add Pin Code`)
            return
        }
        const response = extractStockIds(cartData)

        const payload = {
            USERID: userInfo?.USERID,
            CURR: userInfo?.CURRENCY,
            STOCKID: response.join(','),
        }
        try {
            setCheckOutLoader(true)
            const data = await axiosInstance.post('order/order-save', payload)
            if (data.data.data.message === "Order Save successfully!") {
                showSuccessToast(data.data.data.message)
                navigate('/accounts/my-order')
                dispatch(addCartListCount(0))
                localStorage.setItem('user-info', JSON.stringify({ ...userInfo, CART_COUNT: 0 }))
            }
            setCheckOutLoader(false)

        } catch (err) {
            console.log(err)
            showErrorToast(err.response.data.message)
            setCheckOutLoader(false)
        }
    }

    return (
        <div className='text-start w-[90%] md:w-[95%] lg:w-[80%] 2xl:w-[60%] mx-auto mb-10'>
            <ToastNotification />
            <h1 className='text-20 md:text-34 my-5  font-medium'>
                Shopping Bag
            </h1>

            {isLoading ? <div className=' text-center h-[100vh] mt-20'>
                <ClipLoader />
            </div> :
                <div className='font-inter flex flex-col md:flex-row gap-10'>
                    <div className='w-[100%] md:w-[65%] overflow-auto h-[70vh]'>
                        {(cartData?.[0]?.length > 0 || cartData?.[1]?.length > 0) ? <div className='font-inter'>
                            {cartData?.[0]?.length > 0 && <>
                                <div className='flex flex-wrap gap-3 md:gap-5 mt-5'>
                                    {cartData?.[0]?.map((item, index) => {
                                        return (
                                            <div key={index} className={`relative w-[100%] border-b-2 gap-5 md:gap-10 flex py-3`}
                                            >
                                                <RxCross2 className='absolute top-0 cursor-pointer right-3' onClick={() => { removeFromCart(item) }} />
                                                <div className='relative w-[50%] md:w-[35%] flex  overflow-hidden object-cover cursor-pointer'
                                                // onClick={() => { dispatch(addSelectedSize(item?.PRD_SIZE)); navigate(`/product-detail/${item?.SKUNO}-${item?.PRD_VARIANT_ID}${item?.S_CODE}/${item?.Shape.toLowerCase()}/${item?.STOCKID}/`) }}
                                                >

                                                    {item?.ImgURL ? <Slider {...settings} className="w-full h-fit">
                                                        <img src={item?.IMG_URL} alt='Kyrah Star' className='w-full h-fit' onClick={(e) => { e.stopPropagation(); dispatch(addSelectedSize(item?.PRD_SIZE)); navigate(`/product-detail/${encryptURL(item?.SKUNO)}-${item?.PRD_VARIANT_ID}-${item?.S_CODE}/${item?.Shape?.toLowerCase()}/${encryptURL(item?.STOCKID)}`) }} />
                                                        <img src={item?.ImgURL} alt='Kyrah Star' className='w-full h-fit' onClick={(e) => { e.stopPropagation(); navigate(`/rings/vd/${encryptURL(item?.STOCKID)}/${encryptURL(item?.SKUNO)}-${item?.PRD_VARIANT_ID}-${item?.S_CODE}`) }} />
                                                    </Slider> :
                                                        <img src={item?.IMG_URL} alt='Kyrah Star' className='w-full h-fit' onClick={(e) => { e.stopPropagation(); dispatch(addSelectedSize(item?.PRD_SIZE)); navigate(`/product-detail/${encryptURL(item?.SKUNO)}-${item?.PRD_VARIANT_ID}-${item?.S_CODE}`) }} />
                                                    }
                                                    {/* <GoHeartFill className={`size-4 md:size-5 cursor-pointer text-footerBackground absolute top-3 right-2`}
                                                    // onClick={(e) => { e.stopPropagation(); removeFromWishList(item) }} 

                                                    /> */}
                                                </div>
                                                <div className=' text-12 md:text-14  mt-1 w-[50%] md:w-[64%]'>
                                                    <div className={`flex justify-between w-[90%] ${item?.ImgURL ? 'border-b' : ''} py-3`}>
                                                        <div >
                                                            {/* {console.log(item.PRD_SIZE, 'size')} */}
                                                            <span onClick={() => { dispatch(addSelectedSize(item?.PRD_SIZE)); item?.STOCKID ? navigate(`/product-detail/${encryptURL(item?.SKUNO)}-${item?.PRD_VARIANT_ID}-${item?.S_CODE}/${item?.Shape?.toLowerCase()}/${encryptURL(item?.STOCKID)}`) : navigate(`/product-detail/${encryptURL(item?.SKUNO)}-${item?.PRD_VARIANT_ID}-${item?.S_CODE}`) }} className='cursor-pointer hover:text-footerBackground'>
                                                                {item?.SKU_NAME && <p >{item?.SKU_NAME}</p>}
                                                                {item?.AVG_WIDTH && <p >{item?.AVG_WIDTH} mm Ring</p>}
                                                            </span>
                                                            {item?.PRD_SIZE && <p className=''>Size : {item?.PRD_SIZE}</p>}
                                                            {item?.SKUNO && <p className='text-gray-500'>{item?.SKUNO}-{item?.PRD_VARIANT_ID}</p>}

                                                            {/* {item?.Carat && <p>{item?.Carat + ' Carat ' + item?.Shape + ' Diamond'}</p>} */}
                                                        </div>
                                                        {item?.PRICE && <p>{item?.PRICE?.toLocaleString()}{item?.CURR_SYMBOL}</p>}
                                                    </div>
                                                    {item?.STOCKID && <div className='flex justify-between w-[90%]  py-3'>
                                                        <div >
                                                            {item?.Carat && <p onClick={() => navigate(`/rings/vd/${encryptURL(item?.STOCKID)}/${encryptURL(item?.SKUNO)}-${item?.PRD_VARIANT_ID}-${item?.S_CODE}`)} className='cursor-pointer hover:text-footerBackground'>{item?.Carat} Carat {item?.Shape} {item?.Diamond_Type}</p>}
                                                            {(item?.Cut || item?.Color) && <p className='text-gray-500'>{item?.Cut ?? 'Ideal'} Cut, {item?.Color} Color, {item?.Clarity} Clarity</p>}
                                                            {item?.STOCKID && <p className='text-gray-500'>{item?.STOCKID}</p>}

                                                            {/* {item?.Carat && <p>{item?.Carat + ' Carat ' + item?.Shape + ' Diamond'}</p>} */}
                                                        </div>
                                                        {item?.PRICE && <p>{item?.Price?.toLocaleString()}{item?.CURR_SYMBOL}</p>}
                                                    </div>}
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </>
                            }
                            {cartData?.[1]?.length > 0 && <>
                                <div className='flex flex-wrap gap-3 md:gap-5 mt-5'>
                                    {cartData?.[1]?.map((item, index) => {
                                        return (
                                            <div key={index} className='w-[100%] border-b-2 gap-5 md:gap-10 flex relative py-3'
                                            >
                                                <RxCross2 className='absolute top-0 cursor-pointer right-3' onClick={() => { removeFromCart(item) }} />
                                                <div className='relative w-[50%] md:w-[35%] flex  overflow-hidden object-cover cursor-pointer'
                                                    onClick={() => navigate(`/rings/vd/${encryptURL(item?.StockID)}`)}
                                                >
                                                    <img src={item?.ImgURL} alt='Kyrah Star' className='w-full h-fit' />
                                                    {/* <GoHeartFill className={`size-4 md:size-5 cursor-pointer text-footerBackground absolute top-3 right-2`}
                                                    // onClick={(e) => { e.stopPropagation(); removeFromWishList(item) }} 

                                                    /> */}
                                                </div>
                                                <div className=' text-12 md:text-14  mt-1 w-[50%] md:w-[64%]'>
                                                    {item?.StockID && <div className='flex justify-between w-[90%]  py-3'>
                                                        <div >
                                                            {item?.Carat && <p onClick={() => navigate(`/rings/vd/${encryptURL(item?.StockID)}`)} className='cursor-pointer hover:text-footerBackground'>{item?.Carat} Carat {item?.Shape} {item?.Diamond_Type}</p>}
                                                            {(item?.Cut || item?.Color) && <p className='text-gray-500'>{item?.Cut ?? 'Ideal'} Cut, {item?.Color} Color, {item?.Clarity} Clarity</p>}
                                                            {item?.StockID && <p className='text-gray-500'>{item?.StockID}</p>}

                                                            {/* {item?.Carat && <p>{item?.Carat + ' Carat ' + item?.Shape + ' Diamond'}</p>} */}
                                                        </div>
                                                        {item?.Price && <p>{item?.Price?.toLocaleString()}{item?.CURR_SYMBOL}</p>}
                                                    </div>}
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </>}
                        </div>
                            : <div className='text-17 font-inter'>Your Cart is Empty. Please Explore our Products!</div>}
                    </div>
                    <div className='w-[100%] md:w-[33%] font-inter'>
                        <div>
                            <div className='border px-3 pt-5 pb-3 mb-5 relative'>
                                <BiSolidEdit className='absolute right-5 size-6 top-5 cursor-pointer' onClick={() => navigate('/accounts')} />
                                <p className='text-18 text-center'>Shipping Details</p>
                                <div className=' py-3 flex flex-col gap-1'>
                                    {userInfo.COMPANY_NAME ? <div className='flex gap-2 text-14'>
                                        <p className='w-[33%] text-gray-500 text-right'>Company : </p>
                                        <p className='w-[67%]'>{userInfo.COMPANY_NAME}</p>
                                    </div> :
                                        <div className='flex gap-2 text-14'>
                                            <p className='w-[33%] text-gray-500 text-right'>Name : </p>
                                            <p className='w-[67%]'>{userInfo.FIRST_NAME + ' ' + userInfo.LAST_NAME}</p>
                                        </div>
                                    }
                                    {userInfo.CONTACT_PERSON && <div className='flex gap-2 text-14'>
                                        <p className='w-[33%] text-gray-500 text-right'>Contact Person : </p>
                                        <p className='w-[67%]'>{userInfo.CONTACT_PERSON}</p>
                                    </div>}
                                    {userInfo.PHONE && <div className='flex gap-2 text-14'>
                                        <p className='w-[33%] text-gray-500 text-right'>Contact No : </p>
                                        <p className='w-[67%]'>{userInfo.CON_CODE + ' ' + userInfo.PHONE}</p>
                                    </div>}
                                    {userInfo.GSTNO && <div className='flex gap-2 text-14'>
                                        <p className='w-[33%] text-gray-500 text-right'>GST No : </p>
                                        <p className='w-[67%]'>{userInfo.GSTNO}</p>
                                    </div>}
                                    {userInfo.ADDRESS && <div className='flex gap-2 text-14'>
                                        <p className='w-[33%] text-gray-500 text-right'>Address :</p>
                                        <p className='w-[67%]'>{userInfo.ADDRESS + ', ' + userInfo.CITY + ' - ' + userInfo.PINCODE + ', ' + userInfo.STATE + ', ' + userInfo.COUNTRY}</p>
                                    </div>}
                                </div>
                            </div>
                        </div>
                        {(cartData?.[1]?.length > 0 || cartData?.[0]?.length > 0) && <div className='font-inter'>
                            <div className='border px-3 py-5'>
                                <p className='text-18 text-center'>Order Summary</p>
                                <div className=' py-3 '>
                                    <div className='flex justify-between text-15 border-b py-2'>
                                        <p>Subtotal</p>
                                        <p>{subtotal?.toLocaleString()}{cartData?.[0]?.[0]?.CURR_SYMBOL || cartData?.[1]?.[0]?.CURR_SYMBOL}</p>
                                    </div>
                                    <div className='flex justify-between text-15 border-b py-2'>
                                        <p>Tax ({cartData?.[2][0]?.TAX_PER}%)</p>
                                        {/* <p>{subtotal?.toLocaleString()}{cartData?.[0]?.[0]?.CURR_SYMBOL || cartData?.[1]?.[0]?.CURR_SYMBOL}</p> */}
                                        <p>{((subtotal * cartData?.[2][0]?.TAX_PER / 100).toFixed(0)).toLocaleString()}{cartData?.[0]?.[0]?.CURR_SYMBOL || cartData?.[1]?.[0]?.CURR_SYMBOL}</p>
                                    </div>
                                    <div className={`flex justify-between text-15 border-b py-2 ${cartData?.[2][0]?.SHIPPING_CHARGE === 0 ? 'bg-headerMenuBackground ' : ''}`}>
                                        <p>Shipping Charge</p>
                                        <p>{cartData?.[2][0]?.SHIPPING_CHARGE === 0 ? 'FREE' : cartData?.[2][0]?.SHIPPING_CHARGE + cartData?.[0]?.[0]?.CURR_SYMBOL || cartData?.[1]?.[0]?.CURR_SYMBOL}</p>
                                    </div>
                                    {/* <div className='flex justify-between text-14'>
                                <p>Shipping</p>
                                <p>Free</p>
                            </div> */}
                                </div>
                                <div className='flex font-semibold justify-between text-16'>
                                    <p>Total</p>
                                    <p>{(
                                        (subtotal + (subtotal * cartData?.[2][0]?.TAX_PER / 100) + cartData?.[2][0]?.SHIPPING_CHARGE)
                                            .toFixed(0) * 1  // Ensuring the result is a number
                                    ).toLocaleString()}{cartData?.[0]?.[0]?.CURR_SYMBOL || cartData?.[1]?.[0]?.CURR_SYMBOL}</p>
                                </div>
                                <div className='flex justify-center mt-5 ' >
                                    {checkOutLoader
                                        ? <button className='w-[50%] py-1.5 text-15 mx-auto text-white bg-footerBackground rounded-full'><ClipLoader size={15} color='white' /></button>
                                        : <button className='w-[50%] py-1.5 text-15 mx-auto text-white bg-footerBackground rounded-full' onClick={() => handleOrderCheckOut()}>CHECK OUT</button>}
                                </div>
                            </div>
                        </div>}
                    </div>
                </div>}
        </div >
    )
}

export default ShoppingCart
