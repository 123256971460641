import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { axiosInstance } from '../redux/api';
import { removeSelectedDiamond } from '../redux/slices/selectedDiamond.slice';
import { decryptData } from './decrypt';
import { decryptURL } from '../utils/encrypt';

const DesignRingComponent = ({ title, desc, settings, skuno, variantId, stockId, Shape, ringInfo, diamondInfo }) => {
    const [productInfo, setProductInfo] = useState()
    // const { tableData } = useSelector(state => state.tableData)
    const [diamondDetails, setDiamondDetails] = useState(diamondInfo)
    // const dispatch = useDispatch()
    const { pathname } = useLocation()
    // const [active, setActive] = useState(pathname.split('/')[1])

    const shape_code = Shape ? `-${Shape}` : ""

    // const currency = JSON.parse(localStorage.getItem('user-info'))?.CURRENCY ?? 'INR'
    const currency = (localStorage.getItem('currency')) ?? 'INR'

    const { selectedSize } = useSelector(state => state?.sizeData)
    // console.log(selectedSize, 'selectedSIzr')

    useEffect(() => {
        ringInfo && setProductInfo({ ...ringInfo })
    }, [ringInfo])
    useEffect(() => {
        Object.keys?.(diamondInfo)?.length > 1 && setDiamondDetails({ ...diamondInfo })
    }, [diamondInfo])


    const fetchProductInfo = async () => {
        try {
            console.log(skuno, stockId, 'aaa')
            const requestBody = {
                PRD_VARIANT_ID: variantId || '',
                SKUNO: decryptURL(skuno) || '',
                S_CODE: Shape || '',
                CURR: currency,
                StockID: stockId ? decryptURL(stockId) : '',
                SIZE: selectedSize
            }
            const response = await axiosInstance.post('data/product/info', requestBody);
            console.log('Fetching product info', response)
            const isDecryption = process.env.REACT_APP_IS_DECRYPT
            setProductInfo(isDecryption == 'true' ? decryptData(response.data).data : response.data.data.data)
            // setProductInfo(response?.data?.data)
        } catch (e) {
            console.log(e)
        }
    }

    useEffect(() => {
        if (skuno && variantId?.length < 3) {
            !ringInfo && fetchProductInfo()
        }
    }, [skuno, variantId, Shape])

    const fetchDiamondDetails = async () => {
        try {
            const requestBody = {
                PRD_VARIANT_ID: variantId ? variantId : '',
                SKUNO: skuno ? decryptURL(skuno) : '',
                S_CODE: Shape ? Shape : '',
                CURR: currency,
                StockID: decryptURL(stockId) ?? '',
                SIZE: selectedSize ? selectedSize : ''
            }
            console.log(requestBody, 'get')
            const response = await axiosInstance.post('data/product/info', requestBody);
            const isDecryption = process.env.REACT_APP_IS_DECRYPT
            setDiamondDetails(isDecryption == 'true' ? decryptData(response.data).data : response.data.data.data)
            // setProductInfo(response?.data?.data)
        } catch (e) {
            console.log(e)
        }
    }

    useEffect(() => {
        if (stockId && Object.keys?.(diamondInfo)?.length === 0) {
            Object.keys?.(diamondInfo)?.length === 0 && fetchDiamondDetails()
        }
    }, [])


    return (
        <>
            <div className="flex mt-3 items-center w-[100%] md:w-[95%] lg:w-[90%] 2xl:w-[70%]  relative mx-auto  border border-designRingBorder rounded-full">
                {/* Step 1 */}

                {/* Step 2 */}

                <div className={`h-24 border-separate flex flex-row justify-between w-[33%] px-2 lg:px-3 xl:px-5 text-left  items-center align-top  border-r border-designRingBorder  ${(pathname.split('/')[1] === 'engagement-rings' || pathname.split('/')[1] === 'product-detail') && pathname.split('/')[2] !== 'start-with-a-diamond' ? 'bg-designRingBackground rounded-l-full' : ''}`}>
                    <div className={`flex flex-row items-center  py-3`}>
                        <span className='text-28 xl:text-50  font-inter font-semibold mr-2 xl:mr-5'>
                            1
                        </span>
                        <div>
                            {
                                productInfo ?
                                    <>
                                        <span className='text-18  font-inter text-designRing md:hidden'>
                                            {productInfo ? "Setting" : "Choose a Setting"}
                                        </span>
                                        <span className='text-13 lg:text-15  font-inter font-normal hidden md:block'>
                                            <Link to={Object.keys(diamondDetails).length > 1 ? `/product-detail/${skuno}-${variantId}${shape_code}/${diamondDetails?.Shape?.toLowerCase()}/${stockId}/` : `/product-detail/${skuno}-${variantId}${shape_code}`}> {`${productInfo?.SKU_NAME} ${productInfo?.AVG_WIDTH}mm  ${productInfo?.PRICE?.toLocaleString() ?? 0}${productInfo?.CURR_SYMBOL}`}</Link>
                                        </span>
                                        <div className='text-13  font-inter   font-normal'>
                                            {/* <a href={Object.keys(diamondDetails).length > 1 ? `/product-detail/${skuno}-${variantId}${shape_code}/${diamondDetails?.Shape.toLowerCase()}/${stockId}/` : `/product-detail/${skuno}-${variantId}${shape_code}`} className='underline'>{productInfo ? "View" : "Browse Settings"}</a>
                                            <a href={Object.keys(diamondDetails).length > 1 ? `/engagement-rings/${diamondDetails?.Shape.toLowerCase()}/${stockId}` : `/engagement-rings/settings`} className='underline ml-3'>{productInfo && "Change"}</a> */}
                                            <Link to={Object.keys(diamondDetails).length > 1 ? `/product-detail/${skuno}-${variantId}${shape_code}/${diamondDetails?.Shape?.toLowerCase()}/${stockId}/` : `/product-detail/${skuno}-${variantId}${shape_code}`} className='underline'>{productInfo ? "View" : "Browse Settings"}</Link>
                                            <Link to={Object.keys(diamondDetails).length > 1 ? `/engagement-rings/${diamondDetails?.Shape?.toLowerCase()}/${stockId}` : `/engagement-rings/settings`} className='underline ml-3'>{productInfo && "Change"}</Link>
                                        </div>
                                    </>
                                    :
                                    <span className=' font-inter text-designRing'>
                                        <Link to={Object.keys(diamondDetails).length > 1 ? `/engagement-rings/${diamondDetails?.Shape?.toLowerCase()}/${stockId}` : `/engagement-rings/settings`} className=''>
                                            <span className='text-15 xl:text-20'> Choose a</span>
                                            <div className=' font-designRingHeading text-17 xl:text-designRingHeading  uppercase'>
                                                Setting
                                            </div>
                                        </Link>
                                    </span>
                            }
                        </div>
                    </div>
                    <div className='hidden md:block'>
                        <img
                            src={productInfo?.IMG_URL ? productInfo?.IMG_URL : `https://css.brilliantearth.com/static/img/svg/setting.svg`}
                            alt='Kyrah Star'
                            className='w-16 h-16 mx-auto'
                        />
                    </div>
                </div>
                {/* <hr className='w-[1px] border-t h-20 bg-designRingBorder' /> */}

                <div className={`h-24 border-separate  flex flex-row justify-between w-[33%]  px-2 lg:px-3 xl:px-5 text-center items-center align-top border-r border-designRingBorder ${pathname.split('/')[1] === 'diamonds' || pathname.split('/')[2] === 'vd' || pathname.split('/')[2] === 'start-with-a-diamond' ? 'bg-designRingBackground ' : ''}`}>
                    <div className='flex flex-row items-center py-3 '>
                        <span className='text-28 xl:text-50 font-inter font-semibold mr-2 xl:mr-5'>
                            2
                        </span>
                        <div className='flex flex-col text-left '>
                            {
                                Object.keys(diamondDetails).length > 1 ?
                                    <div className='mt-1'>
                                        <span className='text-18  font-inter text-designRing md:hidden'>
                                            {diamondDetails ? "Diamond" : "Choose a Setting"}
                                        </span>
                                        <Link to={productInfo ? `/rings/vd/${stockId}/${skuno}-${variantId}${shape_code}` : `/rings/vd/${stockId}`}>
                                            <span className='text-15   font-inter font-normal hidden md:block'>{diamondDetails ? `${diamondDetails?.Carat} Carat ${diamondDetails?.Shape} - ${diamondDetails?.Price?.toLocaleString()}${diamondDetails.CURR_SYMBOL}` : "Choose Diamond"}</span>
                                        </Link>
                                        <span className='text-13 md:text-14  font-inter font-normal'>
                                            {/* <a href={productInfo ? `/rings/vd/${stockId}/${skuno}-${variantId}${shape_code}` : `/rings/vd/${stockId}`} className='underline mr-1'>
                                                View
                                            </a>
                                            <a href={productInfo ? `/engagement-rings/start-with-a-diamond/${skuno}-${variantId}${shape_code}&${settings}` : `/engagement-rings/start-with-a-diamond`} className='underline ml-2' onClick={() => removeSelectedDiamond()}>
                                                Change
                                            </a> */}
                                            <Link to={productInfo ? `/rings/vd/${stockId}/${skuno}-${variantId}${shape_code}` : `/rings/vd/${stockId}`} className='underline mr-1'>
                                                View
                                            </Link>
                                            <Link to={productInfo ? `/engagement-rings/start-with-a-diamond/${skuno}-${variantId}${shape_code}&${settings ? settings : true}` : `/engagement-rings/start-with-a-diamond`} className='underline ml-2' onClick={() => removeSelectedDiamond()}>
                                                Change
                                            </Link>
                                        </span>
                                    </div>
                                    :
                                    <span className=' font-inter text-designRing'>
                                        <Link to={productInfo ? `/engagement-rings/start-with-a-diamond/${skuno}-${variantId}${shape_code}&${settings ? settings : true}` : `/engagement-rings/start-with-a-diamond`} className=''>
                                            <span className='text-15 xl:text-20'> Choose a</span>
                                            <div className='font-designRingHeading text-17 xl:text-designRingHeading uppercase '>
                                                Diamond
                                            </div>
                                        </Link>
                                    </span>
                            }
                            {/* <div className='mt-1'>
                                            <span className='text-13  font-inter font-normal leading-5'>{diamondDetails ? `${diamondDetails?.Carat} Carat ${diamondDetails?.Shape} - ${diamondDetails?.Price}` : "Choose Diamond"}</span><br />
                                            <span className='text-14 md:text-13  font-inter font-normal'>
                                                <a href={`/rings/vd/${stockId}/${skuno}-${variantId}`} className='underline mr-1'>
                                                    View
                                                </a>
                                                <a href='/lab-diamonds-search/view-detail' className='underline ml-3'>
                                                    Change
                                                </a>
                                            </span>
                                        </div> */}
                        </div>
                    </div>
                    <div className='hidden md:block'>
                        <img
                            src={Object.keys(diamondDetails).length > 1 && diamondDetails?.ImgURL ? diamondDetails?.ImgURL : `https://css.brilliantearth.com/static/img/svg/diamond.svg`}
                            alt='Kyrah Star'
                            className='w-16 h-16 mx-auto'
                        />
                    </div>
                </div>

                {/* <hr className='w-[1px] border-t h-20 bg-designRingBorder' /> */}

                {/* Step 4 */}
                <div className={`h-24 border-separate flex flex-row justify-between w-[33%] px-2 lg:px-3 xl:px-5 text-center items-center align-top text-16 ${pathname.split('/')[1] === 'purchase-review' ? 'bg-designRingBackground rounded-r-full w-[34%]' : ''}`}>
                    <div className='flex flex-row items-center'>
                        <span className='text-28 xl:text-50 font-inter font-semibold mr-2 xl:mr-5 '>
                            3
                        </span>
                        <div className='flex flex-col text-left'>
                            <span className=' font-inter font-normal '>
                                <span className='text-15 xl:text-20'>Complete</span>
                                <div className='font-designRingHeading text-17 xl:text-designRingHeading  uppercase '> Ring</div>
                            </span>
                            {/* <span className='text-12 ml-2 font-inter font-normal hidden md:block'>
                                Select Ring Size
                            </span> */}
                        </div>
                    </div>
                    <div className='hidden md:block'>
                        <img
                            src='https://css.brilliantearth.com/static/img/svg/ring.svg'
                            alt='Kyrah Star'
                            className='w-20 h-20 mx-auto'
                        />
                    </div>
                </div>
            </div>
            {/* {title && <div
                className="w-[100%] mx-auto text-center mt-3 "
            >
                <h1 className="text-50  font-inter ">
                    {title}
                </h1>
                <p className='text-20 font-inter'>{desc}</p>
            </div>} */}
        </>
    )
}

export default DesignRingComponent