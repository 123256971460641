import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ToastNotification = () => {
    return <ToastContainer position="top-right" hideProgressBar={false} />;
};

export const showSuccessToast = (message) => {
    toast.success(message, { autoClose: 500 });
};
export const showErrorToast = (message) => {
    toast.error(message, { autoClose: 2000 });
};

export default ToastNotification;