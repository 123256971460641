import CryptoJS from 'crypto-js';

// Secret key, ensure it's the right size for AES
const secretKey = CryptoJS.enc.Hex.parse(`${process.env.REACT_APP_DECRYPT_KEY}`);  // Example: '0123456789abcdef0123456789abcdef'

// Custom function to replace Base64 characters
const customBase64Encode = (input) => {
    let base64 = input.toString(CryptoJS.enc.Base64);
    // Replace specific characters
    base64 = base64.replace(/\+/g, '^')  // Replace + with ^
        .replace(/=/g, '!')  // Replace = with #
        .replace(/\//g, '@');  // Replace / with @
    return base64;
};

function encryptURL(data) {
    // Convert the data to a JSON string
    const jsonData = JSON.stringify(data);

    // Encrypt the data using AES
    const encrypted = CryptoJS.AES.encrypt(jsonData, secretKey, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
    });

    // Extract the ciphertext from the encrypted object
    const ciphertext = encrypted.ciphertext;

    // Apply custom Base64 encoding with the desired replacements
    const encryptedData = customBase64Encode(ciphertext);
    return encryptedData;
}

// Custom function to reverse the Base64 character replacements
const customBase64Decode = (input) => {
    // Reverse the replacements: ^ -> +, # -> =, @ -> /
    let base64 = input.replace(/\^/g, '+')  // Replace ^ back to +
        .replace(/!/g, '=')  // Replace # back to =
        .replace(/@/g, '/');  // Replace @ back to /
    // Add padding if necessary
    while (base64.length % 4) {
        base64 += '#';  // Use # as padding if necessary
    }
    return CryptoJS.enc.Base64.parse(base64);
};

function decryptURL(encryptedData) {
    // Decode the custom Base64 string back to regular Base64
    const originalBase64 = customBase64Decode(encryptedData);

    // Decrypt the data using AES
    const bytes = CryptoJS.AES.decrypt(
        { ciphertext: originalBase64 },
        secretKey,
        {
            mode: CryptoJS.mode.ECB,
            padding: CryptoJS.pad.Pkcs7
        }
    );

    const decrypted = bytes.toString(CryptoJS.enc.Utf8);
    try {
        return JSON.parse(decrypted);  // Parse the decrypted JSON
    } catch (error) {
        console.error("Error during decryption or parsing:", error);
        throw error;
    }
}

export { encryptURL, decryptURL };
