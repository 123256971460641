import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import AccountsSideBar from '../../components/AccountsSideBar'
import { RotatingLines } from 'react-loader-spinner'
import { axiosInstance } from '../../redux/api'
import { toast, ToastContainer } from 'react-toastify'
import { showErrorToast, showSuccessToast } from '../../components/ToastNotification'

const Settings = () => {
    const navigate = useNavigate()
    const [userInfo, setUserInfo] = useState(JSON.parse(localStorage.getItem('user-info')))
    const [email, setEmail] = useState(userInfo?.EMAIL)
    const [emailError, setEmailError] = useState('')
    const [isEmailUpdate, setIsEmailUpdate] = useState(false)
    const [phone, setPhone] = useState(userInfo?.PHONE)
    const [phoneError, setPhoneError] = useState('')
    const [isPhoneUpdate, setIsPhoneUpdate] = useState(false)
    const [editEmail, setEditEmail] = useState(true)
    const [editPhone, setEditPhone] = useState(true)


    const [currentPassword, setCurrentPassword] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [currentError, setCurrentError] = useState('')
    const [newError, setNewError] = useState('')
    const [confirmError, setConfirmError] = useState('')
    const [isUpdatePass, setIsUpdatePass] = useState(false)

    const logOut = () => {
        localStorage.removeItem('user-info')
        setTimeout(() => {
            navigate('/login')
        }, 1000)
    }
    const updateEmail = async () => {
        setEditEmail(false)
        if (email === '') {
            setEmailError('Email is Required')
        }
        if (email && !editEmail) {
            try {
                setIsEmailUpdate(true)
                const payload = {
                    "USERID": userInfo.USERID,
                    "EMAIL": userInfo.EMAIL,
                    "PHONE": "",
                    "NEW_PHONE": "",
                    "NEW_EMAIL": email
                }
                let response = await axiosInstance.post('setting/update/email-phone', payload);
                console.log(response.data, 'em')
                if (response.data.success === true) {
                    setIsEmailUpdate(false)
                    showSuccessToast(response.data.message)
                    logOut()
                } else {
                    setIsEmailUpdate(false)
                    showErrorToast(response.data.message)
                }
                setEditEmail(true)
            } catch (error) {
                showErrorToast(error.response.data.message)
                setEditEmail(true)
                setIsEmailUpdate(false)
            }
        }
    }


    const updatePhone = async () => {
        setEditPhone(false)
        if (phone === '') {
            setPhoneError('Phone Number is Required')
        }
        if (phone && !editPhone) {
            try {
                setIsPhoneUpdate(true)
                const payload = {
                    "USERID": userInfo.USERID,
                    "EMAIL": '',
                    "PHONE": userInfo.PHONE,
                    "NEW_PHONE": phone,
                    "NEW_EMAIL": ''
                }
                let response = await axiosInstance.post('setting/update/email-phone', payload);
                console.log(response, 're')
                if (response.data.success === true) {
                    showSuccessToast(response.data.message)
                    setIsPhoneUpdate(false)
                    logOut()
                } else {
                    showErrorToast(response.data.message)
                    setIsPhoneUpdate(false)
                }
                setEditPhone(true)
                // console.log(response)
            } catch (error) {
                showErrorToast(error.response.data.message)
                setEditPhone(true)
                setIsPhoneUpdate(false)
                console.log(error)
            }
        }
    }

    const updatePassword = async () => {
        if (currentPassword === '') { setCurrentError('Current Password is Required') }
        if (newPassword === '') { setNewError('New Password is Required') }
        if (confirmPassword === '') { setConfirmError('Confirm Password is Required') }
        if (currentPassword === newPassword) { showErrorToast('Current Password and New Password are same'); return }

        if (currentPassword && newPassword && confirmPassword && confirmError === '') {
            try {
                setIsUpdatePass(true)
                const payload = {
                    "USERID": userInfo.USERID,
                    "EMAIL": userInfo.EMAIL,
                    "PHONE": userInfo.PHONE,
                    "OLD_PASSWORD": currentPassword,
                    "NEW_PASSWORD": newPassword
                }
                let response = await axiosInstance.post('setting/update/password', payload);
                console.log(response, 're')
                if (response.data.success === true) {
                    showSuccessToast(response.data.message)
                    setIsUpdatePass(false)
                    logOut()
                } else {
                    showErrorToast(response.data.message)
                    setIsUpdatePass(false)
                }
                // console.log(response)
            } catch (error) {
                showErrorToast(error.response.data.message)
                setIsUpdatePass(false)
            }
        }
    }
    return (
        <div className=' text-start 2xl:w-[55%] mx-auto mb-5'>
            <ToastContainer />
            <div className='px-5 md:px-0 md:pt-3 pt-2  text-start '>
                <span className='text-12 text-lightBlack font-inter '>
                    <span onClick={() => navigate("/")} className="cursor-pointer hover:underline">Home</span> /  <span onClick={() => navigate("/accounts")} className="cursor-pointer hover:underline">My Account</span> <span>/ settings</span>
                </span>
            </div>
            <div className='flex gap-5 flex-col md:flex-row'>
                <div className='mx-auto w-[80%] md:w-[22%]'>
                    <AccountsSideBar />
                </div>
                <div className='w-[90%] md:w-[78%] mx-auto'>
                    <p className='text-22 py-1 font-semibold border-b'>Settings & Preference</p>
                    <div className='my-3'>
                        <div>
                            <p className='text-18 font-medium'>Update Account Informations</p>
                            <div className='flex flex-row  items-center gap-0 w-[100%] md:w-[55%]'>
                                <label className='w-[18%] md:w-[13%]'>Email : </label>
                                <div className='flex flex-col w-[50%] mr-5'>
                                    <input
                                        type='text'
                                        placeholder='Enter Email'
                                        value={email}
                                        disabled={editEmail}
                                        onChange={(e) => {
                                            let inputValue = e.target.value;
                                            const regex = /\S+@\S+\.\S+/;
                                            setEmail(inputValue); // Only update the value if valid
                                            if (inputValue === '') {
                                                setEmailError('Email is required.');
                                            } else if (!regex.test(inputValue)) {
                                                setEmailError('Invalid email format');
                                            } else {
                                                setEmailError('');// Only update the value if valid
                                            }
                                        }}
                                        className='border-b p-1.5 md:p-3 text-15 w-full outline-none my-2'
                                    />
                                    {emailError && <p className="text-red-500 text-14 font-inter">{emailError}</p>}
                                </div>
                                {
                                    isEmailUpdate ?
                                        <button
                                            className={`bg-footerBackground flex  justify-center cursor-pointer w-[20%] text-white rounded-full py-1.5 px-3`}
                                        >
                                            <RotatingLines
                                                visible={true}
                                                height="20"
                                                width="20"
                                                strokeWidth="5"
                                                strokeColor='white'
                                                animationDuration="0.75"
                                                ariaLabel="rotating-lines-loading"
                                                wrapperStyle={{}}
                                                wrapperClass=""
                                            />
                                        </button>
                                        :
                                        <button
                                            className='bg-footerBackground text-white rounded-full py-1.5 px-3 w-[25%] md:w-[20%]'
                                            onClick={() => { updateEmail(); }}
                                        >
                                            {editEmail ? 'Edit' : 'Change'}
                                        </button>
                                }


                            </div>
                            <div className='flex flex-row  items-center gap-0 w-[100%] md:w-[55%]'>
                                <label className='w-[18%] md:w-[13%]'>Phone : </label>
                                <div className='flex flex-col w-[50%] mr-5'>
                                    <input
                                        type='text'
                                        placeholder='Enter Email'
                                        value={phone}
                                        disabled={editPhone}
                                        onChange={(e) => {
                                            let inputValue = e.target.value;
                                            const regex = /^\d{0,10}$/;
                                            if (inputValue === '') {
                                                setPhoneError('Phone Number is Required.');
                                                setPhone(inputValue); // Only update the value if valid
                                            } else if (!regex.test(inputValue)) {
                                                setPhoneError('You can not add more than ten digits Phone Number');
                                            } else {
                                                setPhoneError('');
                                                setPhone(inputValue); // Only update the value if valid
                                            }
                                        }}
                                        className='border-b p-1.5 md:p-3 text-15 w-full outline-none my-2'
                                    />
                                    {phoneError && <p className="text-red-500 text-14 font-inter">{phoneError}</p>}
                                </div>
                                {
                                    isPhoneUpdate ?
                                        <button
                                            className={`bg-footerBackground flex  justify-center cursor-pointer w-[20%] text-white rounded-full py-1.5 px-3 `}
                                        >
                                            <RotatingLines
                                                visible={true}
                                                height="20"
                                                width="20"
                                                strokeWidth="5"
                                                strokeColor='white'
                                                animationDuration="0.75"
                                                ariaLabel="rotating-lines-loading"
                                                wrapperStyle={{}}
                                                wrapperClass=""
                                            />
                                        </button>
                                        :
                                        <button
                                            className='bg-footerBackground text-white rounded-full md:w-[20%] py-1.5 px-3 w-[25%]'
                                            onClick={() => { updatePhone(); }}
                                        >
                                            {editPhone ? 'Edit' : 'Change'}
                                        </button>
                                }
                            </div>
                        </div>
                    </div>
                    <div>
                        <p className='text-18 font-medium'>Update Account Password</p>
                        <div className='flex flex-row  items-center gap-0 w-[100%] md:w-[55%]'>
                            <label className='w-[40%] md:w-[32%]'>Current Password : </label>
                            <div className='flex flex-col w-[50%] mr-5'>
                                <input
                                    type='password'
                                    placeholder='Current Password'
                                    value={currentPassword}
                                    onChange={(e) => {
                                        let inputValue = e.target.value;
                                        setCurrentPassword(inputValue); // Only update the value if valid
                                        if (inputValue === '') {
                                            setCurrentError('Current Password is required.');
                                        } else {
                                            setCurrentError('');// Only update the value if valid
                                        }
                                    }}
                                    className='border-b p-1.5 md:p-3 text-15 w-full outline-none my-2'
                                />
                                {currentError && <p className="text-red-500 text-14 font-inter">{currentError}</p>}
                            </div>
                        </div>
                        <div className='flex flex-row  items-center gap-0 w-[100%] md:w-[55%]'>
                            <label className='w-[40%] md:w-[32%]'>New Password : </label>
                            <div className='flex flex-col w-[50%] mr-5'>
                                <input
                                    type='password'
                                    placeholder='New Password'
                                    value={newPassword}
                                    onChange={(e) => {
                                        let inputValue = e.target.value;
                                        setNewPassword(inputValue); // Only update the value if valid
                                        if (inputValue === '') {
                                            setNewError('New Password is required.');
                                        } else {
                                            setNewError('');// Only update the value if valid
                                        }
                                    }}
                                    className='border-b p-1.5 md:p-3 text-15 w-full outline-none my-2'
                                />
                                {newError && <p className="text-red-500 text-14 font-inter">{newError}</p>}
                            </div>
                        </div>
                        <div className='flex flex-row  items-center gap-0 w-[100%] md:w-[55%]'>
                            <label className='w-[40%]  md:w-[32%]'>Confirm Password : </label>
                            <div className='flex flex-col w-[50%] mr-5'>
                                <input
                                    type='password'
                                    placeholder='Confirm Password'
                                    value={confirmPassword}
                                    onChange={(e) => {
                                        let inputValue = e.target.value;
                                        setConfirmPassword(inputValue); // Only update the value if valid
                                        if (inputValue === '') {
                                            setConfirmError('Confirm Password is required.');
                                        } else if (newPassword !== inputValue) {
                                            setConfirmError('New Password and Confirm Password are not same.')
                                        } else {
                                            setConfirmError('');// Only update the value if valid
                                        }
                                    }}
                                    className='border-b p-1.5 md:p-3 text-15 w-full outline-none my-2'
                                />
                                {confirmError && <p className="text-red-500 text-14 font-inter">{confirmError}</p>}
                            </div>
                        </div>
                        <div className='mt-2 '>
                            {
                                isUpdatePass ?
                                    <button
                                        className={`bg-footerBackground flex  justify-center  w-[50%] md:w-[25%] text-white rounded-full py-1.5 px-3`}
                                    >
                                        <RotatingLines
                                            visible={true}
                                            height="20"
                                            width="20"
                                            strokeWidth="5"
                                            strokeColor='white'
                                            animationDuration="0.75"
                                            ariaLabel="rotating-lines-loading"
                                            wrapperStyle={{}}
                                            wrapperClass=""
                                        />
                                    </button>
                                    :
                                    <button
                                        className='bg-footerBackground text-white rounded-full py-1.5 px-3  w-[50%] md:w-[25%]'
                                        onClick={() => { updatePassword(); }}
                                    >
                                        Update Password
                                    </button>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default Settings
