import { createSlice } from "@reduxjs/toolkit";

export const shapeData = createSlice({
    name: 'size',
    initialState: {
        shapes: {}
    },
    reducers: {
        addshapeData: (state, action) => {
            state.shapes.data = action.payload;
        },
    }
}
)

export const { addshapeData } = shapeData.actions
export default shapeData.reducer