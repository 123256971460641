import React from 'react'

const CancellationPolicy = () => {
    return (
        <div className='px-10 py-5 w-[80%] mx-auto'>
            CANCELLATION POLICY:
            <p>
                Cancellation or alteration of items would only be approved within 24 hours of ordering. After 24 hours, the cancellation fee of 10% of the final price of the product would be deducted from the amount paid and the difference would be refunded.
                The cancellation of orders paid through digital wallets or online vouchers, or gift cards will not be refunded to your bank account. It will only be credited to kyrahstar Wallet and can be used for future purchases.
                kyrahstar may, at its absolute discretion, ask the Customer to
                pay as an advance at the time of placing the order a percentage of the value of the transaction. The consumer shall pay the balance of the order at the time of delivery of the goods. Failure to pay the balance at the time of delivery of the goods shall be considered as cancellation of the order.
            </p>
            <p className='mt-5'>
                kyrahstar may, at its sole discretion, reserve the right to cancel any particular order. Some conditions that may result in your order being cancelled or rejected are
                <p>
                    (i) small quantities available for purchase;
                </p>
                <p>
                    (ii) incomplete or inaccurate product or pricing details.
                </p>
                <p>
                    and  Additional verifications or details can also be needed before any order is approved.
                </p>
            </p>

        </div>
    )
}

export default CancellationPolicy
