import React, { useState } from 'react'
import { FaCaretLeft, FaCaretRight, FaChevronDown, FaChevronLeft, FaChevronRight, FaChevronUp } from 'react-icons/fa'
import { GoHeart } from 'react-icons/go'
import { useParams } from 'react-router-dom'
import Slider from 'react-slick'
import ProductComponent from '../../components/Product'
import RangeSelector from '../../components/RangeSelector'
import { PopularRings, Type_Of_Wedding_Rings_Filters, Wedding_Ring_Filter, Women_Wedding_Rings_Headings } from '../../helpers/jsonData'

const WomensWeddingRings = () => {
    const param = useParams()
    const [filterIndex, setFilterIndex] = useState(1);
    const [isDropdown, setIsDropdown] = useState(false);

    const CustomPrevArrow = (props) => (
        <button
            {...props}
            className="slick-arrow absolute left-[-20px] top-[40%] translate-y-[-50%] z-5"
        >
            <FaChevronLeft className='size-3 lg:size-5' color="black" />
        </button>
    );
    const CustomNextArrow = (props) => (
        <button
            {...props}
            className="slick-arrow  absolute  right-[-18px] top-[40%] translate-y-[-50%] z-5"
        >
            <FaChevronRight className='size-3 lg:size-5' color="black" />
        </button>
    );

    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 6,
        slidesToScroll: 5,
        prevArrow: <CustomPrevArrow />,
        nextArrow: <CustomNextArrow slidesToShow={6} />,
    };

    const toggleDropdown = () => {
        setIsDropdown(true);
    };

    return (
        <div>
            <div className="mx-auto pt-3 container max-w-screen-lg text-start">
                <span className="text-12 text-lightBlack font-inter capitalize">
                    {` Home / Wedding Rings / Women's ${param.shape ? '/ ' + param.shape.split('-').join(' ') : ""}`}
                </span>
            </div>

            <div className='mx-auto container mt-10'>
                <h1 className='text-36 text-center w-full font-inter capitalize'>
                    {Women_Wedding_Rings_Headings[param.shape ? param.shape : 'womens'].title}
                </h1>

                <div className='mt-2 tect-16 text-center font-normal w-[40%] mx-auto font-inter'>
                    {Women_Wedding_Rings_Headings[param.shape ? param.shape : 'womens'].subtitle}
                </div>
            </div>

            <div>
                <div className="flex justify-center 2xl:w-[60%] xl:w-[70%] lg:w-[80%] md:w-[85%] sm:w-[90%] w-[95%] mx-auto mt-10">
                    {Type_Of_Wedding_Rings_Filters.map((option, index) => {
                        return (
                            <label
                                key={index}
                                className="flex items-center gap-2 mx-auto"
                                onClick={() => setFilterIndex(index + 1)}
                            >
                                {option.map((data, index) => {
                                    return (
                                        <span key={index} className="cursor-pointer hover:text-[#183E40] ">
                                            {data}
                                        </span>
                                    );
                                })}
                            </label>
                        );
                    })}
                    <span className="ml-24">
                        <span className="flex cursor-pointer">
                            <button onClick={() => setFilterIndex((prev) => prev - 1)} disabled={filterIndex === 1}>
                                <FaCaretLeft size={22} className={`${filterIndex === 1 ? 'text-[#e8e8e8]' : ''}`} />
                            </button>
                            <button onClick={() => setFilterIndex((prev) => prev + 1)} disabled={filterIndex === Type_Of_Wedding_Rings_Filters.length}>
                                <FaCaretRight size={22} className={`${filterIndex === Type_Of_Wedding_Rings_Filters.length ? 'text-[#e8e8e8]' : ''}`} />
                            </button>
                        </span>
                    </span>
                </div>
                <hr className="mt-3 2xl:w-[60%] xl:w-[70%] lg:w-[80%] md:w-[85%] sm:w-[90%] w-[95%] mx-auto " />
            </div>

            {filterIndex === 1 ?
                <div className="2xl:w-[60%] xl:w-[70%] lg:w-[80%] md:w-[85%] sm:w-[90%] w-[95%]  mx-auto flex lg:gap-10 gap-5 text-13">
                    <div className="flex flex-row justify-between gap-2 mx-auto w-full mt-6">
                        {Wedding_Ring_Filter?.map((item, index) => {
                            return (
                                <div key={index} className={`cursor-pointer  outline-none text-[#2c2c2c] hover:text-hover `}>
                                    <div className='p-0.5'>
                                        <img src={item.image} className='mx-auto  border-[#c4c4c4] border-2 border-solid rounded-full' alt="Kyrah Star" />
                                    </div>
                                    <div className=" text-center">
                                        <span className='font-inter font-normal text-11'>
                                            {item.name}
                                        </span>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
                : <div className="2xl:w-[60%] xl:w-[70%] lg:w-[80%] md:w-[85%] sm:w-[90%] w-[95%] mx-auto flex lg:gap-10 gap-5 text-13">
                    {/* <div className='flex justify-between gap-5'> */}
                    <div className='w-[50%]'>
                        <p className='text-14 mt-5'>Style</p>
                        <div className=' w-[95%]' >
                            <Slider {...settings} className='w-full'>
                                {Wedding_Ring_Filter?.map((item, index) => {
                                    return (
                                        <div key={index} className={`cursor-pointer mt-4 outline-none text-[#2c2c2c] hover:text-hover `}>
                                            <img src={item.image} className='mx-auto w-12 border-[#c4c4c4] border-2 border-solid rounded-full' alt="Kyrah Star" />
                                            <div className="mt-1 text-center">
                                                <span className='font-inter font-normal text-11'>
                                                    {item.name}
                                                </span>
                                            </div>
                                        </div>
                                    )
                                })}
                            </Slider>
                        </div>

                        <p className="text-14 mt-5 mb-3">Eternity</p>
                        <div className="flex ">
                            {['Eternity', 'Non Eternity']?.map((data, index) => {
                                return (
                                    <div key={index} className={`px-5 py-1 border-2 text-14 hover:border-[#2c2c2c] border-solid  mr-2 mt-1 `} >
                                        <div key={index} className="hover:text-hover  rounded-full">
                                            <p className=" text-center">{data}</p>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>

                        <p className="text-14 mt-5 mb-3">Metal</p>
                        <div className='flex gap-10 '>
                            {['Yellow Gold', 'White Gold', 'platinum', 'Rose Gold'].map((color, index) => {
                                return (
                                    <div className={`w-10`}>
                                        <div
                                            key={index}
                                        >
                                            {
                                                color === 'Yellow Gold' &&
                                                <div className={`bg-silver-gradient cursor-pointer h-6 w-6 rounded-full mx-auto`}></div>
                                            }
                                            {
                                                color === 'White Gold' &&
                                                <div className={`bg-gold-gradient cursor-pointer h-6 w-6 rounded-full mx-auto`}></div>
                                            }
                                            {
                                                color === 'Rose Gold' &&
                                                <div className={`bg-rose-gradient cursor-pointer h-6 w-6 rounded-full mx-auto`}></div>
                                            }
                                            {
                                                color === 'platinum' &&
                                                <div className={`bg-platinum-gradient cursor-pointer h-6 w-6 rounded-full mx-auto`}></div>
                                            }
                                        </div>
                                        <p className='text-14 text-center mt-2'>{color}</p>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    <div>
                        <p className="text-14 mt-5">Gemstone</p>
                        <div className="flex flex-wrap text-14 mt-5">
                            {['Diamond', 'Lab Diamond', 'Sapphire', 'Emerald']?.map((data, index) => {
                                return (
                                    <div key={index} className={`px-5 py-1 border-2 text-14 hover:border-[#2c2c2c] border-solid  mr-2 mt-1 `} >
                                        <div key={index} className="hover:text-hover  rounded-full">
                                            <p className=" text-center">{data}</p>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                        <p className="text-14 mt-14">Width</p>
                        <div className="flex flex-wrap text-14 mt-3">
                            {['Under 2mm', "2-3mm", "Over 3mm"]?.map((data, index) => {
                                return (
                                    <div key={index} className={`px-1.5 py-1 border-2  hover:border-[#2c2c2c] border-solid  mr-2 mt-1 `} >
                                        <div key={index} className="hover:text-hover  rounded-full">
                                            <p className=" text-center">{data}</p>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                        <p className="text-14 mt-5">Price</p>
                        <div className="w-[95%] mt-5" >
                            <RangeSelector min={210} max={6000} step={1} />
                        </div>
                    </div>
                    {/* </div> */}
                </div>}

            <div className=' relative flex flex-row justify-between cursor-pointer items-center border-[#e2e2e2] 2xl:w-[60%] xl:w-[70%] lg:w-[80%] md:w-[85%] sm:w-[90%] w-[95%] mx-auto mt-5 px-5 py-3'>
                <p className='ml-[20%] text-[#2c2c2c]'>60 of 21,257 Colored Gemstones</p>
                <div onMouseEnter={toggleDropdown} onMouseLeave={() => setIsDropdown(false)} className='flex'>
                    <span className='font-inter text-14'>
                        Sort:  Best Sellers
                    </span>

                    {isDropdown ? <FaChevronUp className='ml-1 mt-1' size={14} /> : <FaChevronDown className='ml-1 mt-1' size={14} />}
                    {isDropdown && (
                        <div className='absolute right-0  w-[21%] lg:w-[15%] bg-white border-t-0 border top-8 border-[#e2e2e2] shadow-lg z-10'>
                            <ul className='py-2'>
                                <li className='px-4 py-1 hover:bg-gray-100 cursor-pointer '>Best Sellers</li>
                                <li className='px-4 py-1 hover:bg-gray-100 cursor-pointer'>Newest</li>
                                <li className='px-4 py-1 hover:bg-gray-100 cursor-pointer'>Price: Low to High</li>
                                <li className='px-4 py-1 hover:bg-gray-100 cursor-pointer'>Price: High to Low</li>
                            </ul>
                        </div>
                    )}
                </div>
            </div>

            <div className='mx-auto mt-10 container w-[95%] 2xl:w-[70%] '>
                <div className="flex flex-row flex-wrap  justify-between mx-auto w-full mt-6">
                    {PopularRings?.map((item, index) => {
                        return (
                            <div key={index} className={`relative w-[48%] lg:w-[30%] xl:w-[23%] outline-none text-[#2c2c2c] mb-10`} >
                                <div className='absolute left-2 top-1.5'>
                                    <p className='text-10 px-3 py-1 bg-[#183E40] text-white rounded-full'>Signature Collections</p>
                                </div>
                                <div className='absolute right-2 top-1.5'>
                                    <GoHeart />
                                </div>
                                <ProductComponent
                                    item={item}
                                    hoverIndex={index}
                                    productData={PopularRings}
                                />
                            </div>
                        )
                    })}
                </div>
            </div>
        </div >
    )
}

export default WomensWeddingRings

