import { combineReducers, createStore } from "@reduxjs/toolkit";
import { composeWithDevTools } from 'redux-devtools-extension';
import persistReducer from "redux-persist/es/persistReducer";
import persistStore from "redux-persist/es/persistStore";
import sessionStorage from "redux-persist/lib/storage/session";
import { cartList } from "./slices/cartList.slice";
import { filterData } from "./slices/filters.slice";
import { navigationMenu } from "./slices/navigationbarMenu.slice";
import { dataSlice } from "./slices/pageContent.slice";
import { selectedDiamond } from "./slices/selectedDiamond.slice";
import { sizeData } from "./slices/sizeData.slice";
import { tableDataSLice } from "./slices/tableData.slice";
import { wishList } from "./slices/wishList.slice";
import { shapeData } from "./slices/shape.slice";

// const persistConfig = {
//     key: 'root',
//     storage,
//     // Specify the reducers you want to persist
//     whitelist: ['navigation'],
// }


// export const store = configureStore({
//     reducer: {
//         data: dataSlice.reducer,
//         navigation: navigationMenu.reducer,
//         tableData: tableDataSLice.reducer
//     },
// })

// const rootReducer = combineReducers(
//     {
//         navigation: navigationMenu.reducer,
//         data: dataSlice.reducer,
//         tableData: tableDataSLice.reducer
//     })

// const persistedReducer = persistReducer(persistConfig, rootReducer);
// export const store = createStore(persistedReducer);
// export const persistor = persistStore(store);

// persistConfig setup
const persistConfig = {
    key: 'root',
    storage: sessionStorage,
    whitelist: ['navigation', 'filterData', 'sizeData', 'shapeData', 'selectedDiamond', 'data', 'wishList', 'cartList'],
};

// Combine reducers
const rootReducer = combineReducers({
    navigation: navigationMenu.reducer,
    data: dataSlice.reducer,
    tableData: tableDataSLice.reducer,
    filterData: filterData.reducer,
    sizeData: sizeData.reducer,
    shapeData: shapeData.reducer,
    selectedDiamond: selectedDiamond.reducer,
    wishList: wishList.reducer,
    cartList: cartList.reducer
});

// Persist reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Create store with Redux DevTools support
export const store = createStore(
    persistedReducer,
    composeWithDevTools() // Enable Redux DevTools
);

// Create persistor
export const persistor = persistStore(store);