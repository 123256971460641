import React, { useState } from 'react'
import { FaChevronDown, FaChevronUp } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
import Banner from '../../components/Banner'
import FlexView from '../../components/FlexView'
import GemAndShapeViewer from '../../components/GemAndShapeViewer'
import Review from '../../components/Review'
import { Blockchain_Diamond, buttons, Desgn_Your_Own_Diamond_Rings, Desgn_Your_Own_Diamond_Rings2, Desgn_Your_Own_Diamond_Rings3, Design_Your_Own_Diamond_Jewelry, diamond, Diamond_Banner, Diamond_Education, Diamond_More_info, Diamond_More_info2, Diamond_Review, Diamond_Shape } from '../../helpers/jsonData'

const Diamonds = () => {
    const [isDropdown, setIsDropdown] = useState(false)
    // const BgImage = ['https://cdn.builder.io/api/v1/image/assets%2F9f2a69003c86470ea05deb9ecb9887be%2Ff60908f1ea0a461b95b8ae9a8d9fd9b8', 'https://cdn.builder.io/api/v1/image/assets%2F9f2a69003c86470ea05deb9ecb9887be%2Fc0e786e230634b6081e447720e562a9f']
    const navigate = useNavigate()
    return (
        <div>
            <div className=' md:pt-3 pt-2 md:pl-8 pl-5 text-start'>
                <span className='text-12 text-lightBlack font-inter'>
                    <span onClick={() => navigate("/")} className="cursor-pointer hover:underline">Home</span> / Diamonds
                </span>
            </div>

            <Banner data={Diamond_Banner} className={'lg:text-white'} />

            <div className='mt-10 mb-20'>
                <h3 className='text-30 md:text-36 text-center w-full font-inter'>
                    Shop Diamonds by Shape
                </h3>
                <GemAndShapeViewer data={Diamond_Shape} />
            </div>

            <div className=' mx-auto w-[95%] 2xl:w-[70%] mt-16'>
                {Desgn_Your_Own_Diamond_Rings.map((data, index) => {
                    return (
                        <div key={index} className='flex lg:gap-16 flex-col lg:flex-row'>
                            <img src={data.image} alt="Kyrah Star" className='w-[100%] lg:w-[50%]' />
                            <div className=' mt-2 w-[90%] mx-auto xl:pt-[10%]'>
                                <p className='text-30 md:text-36  my-2'>{data.title}</p>
                                <p className='text-14 md:text-16'>{data.subtitle}</p>
                                <div className='flex flex-col md:flex-row gap-5'>
                                    {data.button.map((button, index) => {
                                        return (
                                            <div key={index} className={`w-[100%] md:w-[50%] mt-3 px-6 justify-center border-[1px] border-black border-solid flex flex-row p-3 hover:bg-black hover:text-white bg-white text-black-900 ' `}>
                                                <span className={`text-13 font-inter text-center  hover:text-white `}>
                                                    {button}
                                                </span>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>

            <div
                className={`mx-auto w-[95%] 2xl:w-[70%] p-14 mt-16 bg-cover bg-[url('https://cdn.builder.io/api/v1/image/assets%2F9f2a69003c86470ea05deb9ecb9887be%2Ff60908f1ea0a461b95b8ae9a8d9fd9b8')]`}
            >
                {
                    Diamond_More_info.map((data, index) => {
                        return (
                            <div key={index} className='mt-20 pb-20'>
                                <div className='text-center text-white'>
                                    <p className='font-inter text-30 my-2'>{data.title}</p>
                                    <p className='w-[60%] mx-auto'>{data.subtitle}</p>
                                </div>
                                <div className={`w-[50%] mx-auto mt-6 px-6 justify-center text-white border-2 border-white border-solid flex flex-row p-3 hover:bg-white hover:text-black text-black-900 ' `}>
                                    <span className={`text-13 font-inter text-center  hover:text-black `}>
                                        {data.buttons[0]}
                                    </span>
                                </div>
                            </div>
                        )
                    })
                }
            </div>

            <div className=' mx-auto w-[95%] 2xl:w-[70%] mt-16'>
                {Desgn_Your_Own_Diamond_Rings2.map((data, index) => {
                    return (
                        <div key={index} className='flex lg:gap-16 flex-col-reverse lg:flex-row'>
                            <div className=' lg:pt-[6%] mt-5 w-[90%] lg:w-[50%] mx-auto'>
                                <p className='text-30 my-2'>{data.title}</p>
                                <p>{data.subtitle}</p>
                                <div className='flex md:gap-5 flex-col md:flex-row'>
                                    {data.button.map((button, index) => {
                                        return (
                                            <div key={index} className={`w-[100%] lg:w-[50%]  mt-5 px-6 justify-center border-[1px] border-black border-solid flex flex-row p-3 hover:bg-black hover:text-white bg-white text-black-900 ' `}>
                                                <span className={`text-13 font-inter text-center  hover:text-white `}>
                                                    {button}
                                                </span>
                                            </div>

                                        )
                                    })}
                                </div>
                            </div>
                            <img src={data.image} alt="Kyrah Star" className='w-[100%] lg:w-[50%]' />
                        </div>
                    )
                })}
            </div>

            <div className=' mx-auto w-[95%] 2xl:w-[70%] mt-16'>
                {/* <img src='https://image.brilliantearth.com/cdn-cgi/image/width=1376,height=600,quality=100,format=auto/https://cdn.builder.io/api/v1/image/assets%2F9f2a69003c86470ea05deb9ecb9887be%2Fd723a15c8c6140d18f4aeef01d8ad2a1' alt='Kyrah Star' className='w-full' /> */}
                <div>
                    {Desgn_Your_Own_Diamond_Rings3.map((data, index) => {
                        return (
                            <div key={index} className=''>
                                <img src={data.image} alt="Kyrah Star" className='w-full' />
                                <div className='w-[80%] lg:w-[50%]'>
                                    <p className='text-30 font-inter my-2'>{data.title}</p>
                                    <p>{data.subtitle}</p>
                                    <div className='flex gap-5'>
                                        {data.button.map((button, index) => {
                                            return (
                                                <div key={index} className={`w-[50%]  mt-6 px-6 justify-center border-[1px] border-black border-solid flex flex-row p-3 hover:bg-black hover:text-white bg-white text-black-900 ' `}>
                                                    <span className={`text-13 font-inter text-center  hover:text-white `}>
                                                        {button}
                                                    </span>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>

            <div className=' mx-auto w-[95%] 2xl:w-[70%] mt-10 flex flex-col md:flex-row justify-between flex-wrap gap-3'>
                {diamond.map((data, index) => {
                    return (
                        <div key={index} className='w-[100%] md:w-[49%] my-5'>
                            <img src={data.image} alt="Kyrah Star" className='w-full' />
                            <div >
                                <p className='text-30 font-inter my-1'>{data.title}</p>
                                <p>{data.subtitle}</p>
                                <div className={` w-full lg:w-[50%] mt-3 px-6 relative text-center border-[1px] border-black border-solid  p-3 hover:bg-black hover:text-white bg-white text-black-900 ' `}>
                                    <span className={`text-13 font-inter   hover:text-white  `} >
                                        {data.buttons[0].text}
                                    </span>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>

            <div className=" mx-auto w-[95%] 2xl:w-[70%] pt-14 pb-14 mt-12 bg-[url('https://cdn.builder.io/api/v1/image/assets%2F9f2a69003c86470ea05deb9ecb9887be%2F010fa69deb2b48c0a7fbc71263f73d27')] bg-cover ">
                {
                    Diamond_More_info2.map((data, index) => {
                        return (
                            <div key={index}>
                                <div className='text-center text-white'>
                                    <p className='font-bold text-30 my-2 font-inter'>{data.title}</p>
                                    <p className='w-[55%] mx-auto'>{data.subtitle}</p>
                                </div>
                                <div className={`lg:w-1/6 w-1/3 mx-auto mt-10 px-6 justify-center text-black bg-white border-[1px] border-white border-solid flex flex-row p-3 hover:bg-black hover:text-white hover:border-black text-black-900 ' `}>
                                    <span className={`text-13 font-inter text-center  hover:text-white `}>
                                        {data.buttons[0]}
                                    </span>
                                </div>
                            </div>
                        )
                    })
                }
            </div>

            <div className=' mx-auto w-[95%] 2xl:w-[70%] mt-16 flex flex-col md:flex-row justify-between flex-wrap gap-3'>
                {Design_Your_Own_Diamond_Jewelry.map((data, index) => {
                    return (
                        <div key={index} className='md:w-[49%] w-[100%] my-10'>
                            <img src={data.image} alt="Kyrah Star" className='w-full' />
                            <div >
                                <p className='text-30 my-1 font-inter'>{data.title}</p>
                                <p>{data.subtitle}</p>
                                {data.buttons.map((button, index) => {
                                    return (
                                        <div key={index} className={` w-full lg:w-[50%] mt-3 px-6 relative text-center border-[1px]  border-black border-solid  p-3 hover:bg-black hover:text-white bg-white text-black-900 ' ${isDropdown && button.type === 'dropdown' ? 'bg-black text-white' : ''} `} onClick={button.type === 'dropdown' ? () => setIsDropdown(!isDropdown) : undefined}>
                                            <span className={`text-13 font-inter   hover:text-white   ${button.type === 'dropdown' ? 'flex justify-end md:gap-14 gap-40 lg:gap-5' : ''}`} >
                                                {button.text}
                                                {button.type === 'dropdown' ? isDropdown ?
                                                    <FaChevronUp className='ml-10 lg:ml-1' />
                                                    :
                                                    <FaChevronDown className='ml-10 lg:ml-1' /> : ''}
                                                {button.type === 'dropdown' ? isDropdown && (
                                                    <div className='absolute text-black right-0 mt-2 w-full bg-white text-left border top-9 border-[#e2e2e2] shadow-lg z-10'>
                                                        <ul className='py-2'>
                                                            <li className='px-4 py-2 hover:bg-gray-100 cursor-pointer'>Naturel Diamond necklace</li>
                                                            <li className='px-4 py-2 hover:bg-gray-100 cursor-pointer'>Lab Diamond necklace</li>
                                                        </ul>
                                                    </div>
                                                ) : ''}
                                            </span>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    )
                })}
            </div>


            <div className=' mx-auto w-[95%] 2xl:w-[70%] mt-16'>
                {Blockchain_Diamond.map((data, index) => {
                    return (
                        <div key={index} className='flex lg:gap-16 flex-col-reverse lg:flex-row'>
                            <div className=' lg:pt-[9%] lg:w-[50%] w-[90%] mx-auto'>
                                <p className='text-30 my-2 font-inter'>{data.title}</p>
                                <p className='font-inter'>{data.subtitle}</p>
                                <div key={index} className={`md:w-[50%] w-full  mt-6 px-6 justify-center border-[1px] border-black border-solid flex flex-row p-3 hover:bg-black hover:text-white bg-white text-black-900 ' `}>
                                    <span className={`text-13 font-inter text-center  hover:text-white `}>
                                        {data.buttons[0]}
                                    </span>
                                </div>
                            </div>
                            <img src={data.image} alt="Kyrah Star" className='lg:w-[50%] w-full' />
                        </div>
                    )
                })}
            </div>

            <div className=" mx-auto w-[95%] 2xl:w-[70%] mt-16 " id='a'>
                <Review data={Diamond_Review} title={'Diamond'} />
            </div>

            <div className=" mx-auto w-[95%] 2xl:w-[70%] mt-20 " id='a'>
                <h3 className='text-30 w-full font-inter mb-5'>Jewelry Education</h3>
                <FlexView data={Diamond_Education} />
            </div>

            <div className='flex flex-col sm:flex-row justify-center md:gap-5 w-[95%] 2xl:w-[70%] mx-auto text-center  '>{buttons.map((button, index) => {
                return (
                    <div key={index} className={`w-[90%] mx-auto sm:w-[50%] flex mt-2 sm:mt-16 px-6 text-15 font-inter justify-center items-center hover:text-white  border-[1px] border-black border-solid  p-3 hover:bg-black  text-black-900 ' `}>
                        {button}
                    </div>
                )
            })}</div>

            <div className=" mx-auto w-[80%] mt-20 ">
                <h3 className='text-30 w-full text-center font-inter mb-5'>Ethically-Sourced Gemstones</h3>
                <p className='mt-5 text-[#a0a0a0]-500 font-inter'>Gemstone rings are an excellent alternative to more traditional <span className='underline'>diamond</span> styles. <span className='underline'>Morganite,</span> <span className='underline'>emerald,</span> ruby, aquamarine, moissanite, sapphire, and amethyst are all fabulous gemstones that are the perfect choice for someone looking for a look with a pop of color. Gemstone engagement rings are a special way to celebrate your unique, anything-but-colorless love story – or simply as a colorful gift for yourself.</p>
                <p className='mt-5 text-[#a0a0a0]-500 font-inter'>Once you have selected the perfect semi precious gemstone, it is time to find a setting to match it. Our curated selection of designs ranges from nature-inspired to ultra-modern. Plain bands give way to luxe diamond-accented styles, while other gemstone ring settings are unique enough to catch the eye. Choose a setting for your stone that will complement and enhance its beauty and color, creating the perfect semi precious gemstone ring.</p>
            </div>

        </div >
    )
}

export default Diamonds