export const URL = "/";
export const ENGAGEMENT_RINGS = "engagement-rings";
export const WEDDING_RINGS = "wedding-rings";
export const GEMSTONES = "gemstones";
export const JEWELRY = "Jewelry";
export const DIAMONDS = "diamonds";
export const GIFTS = "gifts";
export const About = "about";

const ROUTE_URLS = {
  Home: URL,
  EngagementRings: `${URL}/${ENGAGEMENT_RINGS}`,
  WeddingRings: `${URL}/${WEDDING_RINGS}`,
  Gemstones: `${URL}/${GEMSTONES}`,
  Jewelry: `${URL}/${JEWELRY}`,
  Diamonds: `${URL}/${DIAMONDS}`,
  Gifts: `${URL}/${GIFTS}`,
  About: `${URL}/${About}`,
};

export default ROUTE_URLS;