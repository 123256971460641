import TawkMessengerReact from '@tawk.to/tawk-messenger-react';
import { useRef } from 'react';
import { Route, Routes } from 'react-router-dom';
import './App.css';
import Footer from './components/Footer';
import Header from './components/Header';
import ReverseProtectedRoute, { ProtectedRoute } from './components/ProtectedRoute';
import ROUTE_URLS from './config/routes';
import Account from './pages/Account/Account';
import Login from './pages/Account/Login';
import MyOrders from './pages/Account/MyOrders';
import Register from './pages/Account/Register';
import ResetPassword from './pages/Account/ResetPassword';
import Settings from './pages/Account/Settings';
import ShoppingCart from './pages/Account/ShoppingCart';
import WishList from './pages/Account/WishList';
import Diamonds from './pages/Diamonds/Diamonds';
import LabDiamondDetails from './pages/Diamonds/LabDiamondDetails';
import LabDiamonds from './pages/Diamonds/LabDiamonds';
import EngagementRings from './pages/Engagement-Rings/EngagementRings';
import ProductDetail from './pages/Engagement-Rings/ProductDetail';
import ShapeEngagementRings from './pages/Engagement-Rings/ShapeEngagementRings';
import DifferentGemstones from './pages/Gemstone/DifferentGemstones';
import Gemstone from './pages/Gemstone/Gemstone';
import Gifts from './pages/Gifts/Gifts';
import HomePage from './pages/Homepage';
import Jewelry from './pages/Jewelry/Jewelry';
import LeaveReview from './pages/LeaveReview';
import PageNotFound from './pages/PageNotFound';
import Promotions from './pages/Promotions';
import PurchaseReview from './pages/PurchaseReview';
import Matching from './pages/Wedding-Rings/Matching';
import MensWeddingRings from './pages/Wedding-Rings/MensWeddingRings';
import WeddingRings from './pages/Wedding-Rings/WeddingRings';
import WomensWeddingRings from './pages/Wedding-Rings/WomensWeddingRings';
import DependentDropdowns from './components/Dropdowns';
import TermsCondition from './pages/TermCondition';
import PrivacyPolicy from './pages/PrivacyPolicy';
import ShippingPolicy from './pages/ShippingPolicy';
import CancellationPolicy from './pages/CancellationPolicy';
import ReturnPolicy from './pages/ReturnPolicy';
import RefundPolicy from './pages/RefundPolicy';

function App() {

  const tawkMessengerRef = useRef();

  const handleMinimize = () => {
    tawkMessengerRef.current.minimize();
  };

  return (
    <>
      <Header />
      <div className='min-h-[70vh]'>
        <Routes>
          <Route path={ROUTE_URLS.Home} element={<HomePage />} />
          <Route path={ROUTE_URLS.EngagementRings} element={<EngagementRings />} />
          <Route path={`${ROUTE_URLS.EngagementRings}/start-with-a-diamond`} element={<LabDiamonds />} />
          <Route path={`${ROUTE_URLS.EngagementRings}/start-with-a-lab-grown-diamond`} element={<LabDiamonds />} />
          <Route path={`${ROUTE_URLS.EngagementRings}/start-with-a-diamond/:type`} element={<LabDiamonds />} />
          <Route path={`${ROUTE_URLS.EngagementRings}/:shape`} element={<ShapeEngagementRings />} />
          <Route path={`${ROUTE_URLS.EngagementRings}/:shape/:diamond`} element={<ShapeEngagementRings />} />

          <Route path={'/product-detail/:skuname'} element={<ProductDetail />} />
          <Route path={'/product-detail/:skuname/:diamond'} element={<ProductDetail />} />
          <Route path={'/product-detail/:skuname/:shape/:diamond'} element={<ProductDetail />} />

          {/* final product */}
          {/* <Route path={'/final/:skuname'} element={<ProductDetail />} />
          <Route path={'/final/:skuname/:shape'} element={<ProductDetail />} /> */}

          <Route path={ROUTE_URLS.WeddingRings} element={<WeddingRings />} />
          <Route path={`${ROUTE_URLS.WeddingRings}/matching`} element={<Matching />} />
          <Route path={`${ROUTE_URLS.WeddingRings}/:shape`} element={<ShapeEngagementRings />} />
          <Route path={`${ROUTE_URLS.WeddingRings}/womens`} element={<WomensWeddingRings />} />
          <Route path={`${ROUTE_URLS.WeddingRings}/womens/:metal`} element={<ShapeEngagementRings />} />
          {/* <Route path={`${ROUTE_URLS.WeddingRings}/womens/:shape`} element={<WomensWeddingRings />} /> */}
          <Route path={`${ROUTE_URLS.WeddingRings}/mens/`} element={<MensWeddingRings />} />
          <Route path={`${ROUTE_URLS.WeddingRings}/mens/:metal`} element={<ShapeEngagementRings />} />
          {/* <Route path={`${ROUTE_URLS.WeddingRings}/mens/:type`} element={<MensWeddingRings />} /> */}
          <Route path={ROUTE_URLS.Diamonds} element={<Diamonds />} />
          <Route path={`${ROUTE_URLS.Diamonds}/:type`} element={<LabDiamonds />} />
          <Route path={`/lab-diamond`} element={<LabDiamonds />} />
          <Route path={`/lab-diamonds-search/view-detail`} element={<LabDiamondDetails />} />
          <Route path={`/rings/vd/:stockId`} element={<LabDiamondDetails />} />
          <Route path={`/rings/vd/:stockId/:skucode`} element={<LabDiamondDetails />} />
          <Route path={ROUTE_URLS.Gemstones} element={<Gemstone />} />
          <Route path={`${ROUTE_URLS.Gemstones}/:type`} element={<DifferentGemstones />} />
          <Route path={ROUTE_URLS.Jewelry} element={<Jewelry />} />
          <Route path={`review/:data`} element={<LeaveReview />} />

          {/* Jewelry final product */}
          <Route path={ROUTE_URLS.Jewelry} element={<Jewelry />} />
          <Route path={`${ROUTE_URLS.Jewelry}/:type`} element={<ShapeEngagementRings />} />

          <Route path={ROUTE_URLS.Gifts} element={<Gifts />} />
          <Route path={`${ROUTE_URLS.About}/terms-&-condition`} element={<TermsCondition />} />
          <Route path={`${ROUTE_URLS.About}/privacy-policy`} element={<PrivacyPolicy />} />
          <Route path={`${ROUTE_URLS.About}/shipping-policy`} element={<ShippingPolicy />} />
          <Route path={`${ROUTE_URLS.About}/cancellation-policy`} element={<CancellationPolicy />} />
          <Route path={`${ROUTE_URLS.About}/return-policy`} element={<ReturnPolicy />} />
          <Route path={`${ROUTE_URLS.About}/refund-policy`} element={<RefundPolicy />} />
          <Route path={`/terms-condition`} element={<TermsCondition />} />
          <Route path={`/privacy-policy`} element={<PrivacyPolicy />} />
          <Route path={`/shipping-policy`} element={<ShippingPolicy />} />
          <Route path={`/cancellation-policy`} element={<CancellationPolicy />} />
          <Route path={`/return-policy`} element={<ReturnPolicy />} />
          <Route path={`/refund-policy`} element={<RefundPolicy />} />
          {/* <Route path={`/login`} element={<Login />} />
          <Route path={`/register`} element={<Register />} /> */}

          <Route
            path="/login"
            element={<ReverseProtectedRoute element={<Login />} />}
          />
          <Route
            path="/register"
            element={<ReverseProtectedRoute element={<Register />} />}
          />
          <Route path={`/accounts/password-reset`} element={<ResetPassword />} />

          <Route path={`/accounts`} element={<ProtectedRoute element={<Account />} />} />
          <Route path={`/accounts/settings`} element={<ProtectedRoute element={<Settings />} />} />
          <Route path={`/accounts/my-order`} element={<ProtectedRoute element={<MyOrders />} />} />
          <Route path={`/shop/wish-list`} element={<ProtectedRoute element={<WishList />} />} />
          <Route path={`/shop/cart`} element={<ProtectedRoute element={<ShoppingCart />} />} />

          <Route path={`/promo-codes-and-offers`} element={<Promotions />} />
          <Route path={`/purchase-review/:skuname/:diamond`} element={<PurchaseReview />} />
          <Route path={`/dropdown`} element={<DependentDropdowns />} />
          <Route path={`*`} element={<PageNotFound />} />

        </Routes>
      </div>
      <Footer />

      <TawkMessengerReact
        ref={tawkMessengerRef}
        propertyId={process.env.REACT_APP_TAWK_PROPERTY_ID}
        widgetId={process.env.REACT_APP_TAWK_WIDGET_ID}
      />
    </>
  );
}

export default App;
