import React from 'react';
import { FaChevronLeft } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { Shape_Images } from '../helpers/jsonData';

const NavigationDropdown = ({ data, link, hover, sideMenu, ...props }) => {
    const navigate = useNavigate()
    const groupedData = data?.reduce((acc, curr) => {
        let key = curr.DISP;
        key === 'DES' ? key = 'design_your_own_engagement_ring' : key === 'SHP' ? key = 'shop_by_shape' : key === 'STYL' && (hover === 'ENGAGEMENT_RINGS' || props.headerHoverItem === 'ENGAGEMENT_RINGS') ? key = 'Engagement_Rings_style' : key === 'RING' && hover === 'ENGAGEMENT_RINGS' ? key = 'gemstone_engagement_rings' : key === 'WMN_METAL' ? key = 'Women`s_By_Metal' : key === 'MEN_METAL' ? key = 'Men`s_By_Metal' : key === 'NAT' ? key = 'Natural Diamonds' : key === 'NATSHP' ? key = 'nat' : key === 'LAB' ? key = 'Lab Diamonds' : key === 'OWN' && hover !== 'GEMSTONES' ? key = 'Design Your Own' : key === 'COL' ? key = 'Shop By Color' : key === 'OWN' && hover === 'GEMSTONES' ? key = 'Design Youe Own Gemstone Rings' : key === 'RING' && hover === 'GEMSTONES' ? key = 'Present Gemstone Rings' : key === 'JEW' ? key = 'Jewelry' : key === 'STYL' && (hover === 'JEWELRY' || props.headerHoverItem === 'JEWELRY') ? key = 'Shop By Style' : key = curr.DISP
        if (!acc[key]) {
            acc[key] = [];
        } if (key === 'nat') {
            acc.nat.push(curr)
        } else {
            acc[key].push(curr);
        }
        if (key === 'nat') {
            acc['Lab Diamonds'][2] = acc?.nat
        } else {
            delete acc?.nat
        }
        return acc;
    }, {});

    const formatCategoryName = (category) => {
        console.log(category, props.headerHoverItem)
        return category
            .split('_')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    };

    const handleClick = (item, code, disp, index) => {
        console.log(index, 'item')
        if (code === 'SET' && item !== 'Start with a Setting' && item !== 'Start with a Bridal Set') {
            // navigate(`/${link}/${item.toLowerCase().replace(' ', '-')}`)
            window.location.href = `/${link}/${item.toLowerCase().replace(' ', '-')}`;
        } else if (code === 'SET' && item === 'Start with a Setting') {
            // navigate(`/${link}/settings`, { replace: true })
            window.location.href = `/${link}/settings`;
        } else if (code === 'SET' && item === 'Start with a Bridal Set') {
            navigate(`/${link}/sets`)
        } else if (code === 'DIA') {
            navigate(`/${link}/${item.toLowerCase().replaceAll(' ', '-')}`)
        } else if (code === 'GEM' && item === 'Start with a Gemstone') {
            navigate(`/gemstones/shop-all`)
        } else {
            if (disp === 'WOMEN') {

            }
            if (disp === 'WMN_METAL') {
                // navigate(`/${link}/women/${item.replaceAll(' ', '-').toLowerCase()}`)
                window.location.href = `/${link}/womens/${index + 1}`
            } else if (disp === 'MEN_METAL') {
                // navigate(`/${link}/men/${item.replaceAll(' ', '-').toLowerCase()}`)
                window.location.href = `/${link}/mens/${index + 1}`
            } else if (disp === 'WOMEN') {
                // navigate(`/${link}/women/${item.replaceAll(' ', '-').toLowerCase()}`)
                window.location.href = `/${link}/womens/${item.replaceAll(' ', '-').replaceAll('`s', '').toLowerCase()}`
            } else if (disp === 'MEN') {
                // navigate(`/${link}/men/${item.replaceAll(' ', '-').toLowerCase()}`)
                window.location.href = `/${link}/mens/${item.replaceAll(' ', '-').replaceAll('`s', '').toLowerCase()}`
            } else {
                // navigate(`/${link}/${item.replaceAll(' ', '-').replaceAll('`s', '').toLowerCase()}`)
                window.location.href = `/${link}/${item.replaceAll(' ', '-').replaceAll('`s', '').toLowerCase()}`
            }
        }
    }
    // setTimeout(() => {
    //     window.location.reload();
    // }, 100);


    return (
        !sideMenu ? data && <div className='border-b border-lightBorder mx-auto z-[40]' {...props}>
            <div className='  my-5 text-[#333333] '>
                <div
                    // className={`w-[60%] lg:w-[80%] md:w-[90%] xl:w-[80%] 2xl:w-[50%]  mx-auto xl:mx-0  ${link === 'engagement-rings' ? 'grid grid-cols-[40%,40%] gap-x-5  gap-y-5 grid-flow-*' : hover === 'WEDDING_RINGS' ? ' grid-cols-[26%,26%,20%,20%] gap-x-8  gap-y-5' : hover === 'DIAMONDS' ? 'grid-cols-[30%,24%,28%] gap-x-7  gap-y-5' : hover === 'GEMSTONES' ? 'grid-cols-[37%,17%,20%,35%] gap-x-8  gap-y-5' : hover === 'JEWELRY' ? 'grid-cols-[22%,22%,22%] gap-x-8  gap-y-5' : ''}   grid `}
                    className='w-[70%] 2xl:w-[60%] mx-auto grid grid-cols-[auto,auto,auto,auto] gap-x-10'
                >
                    {Object.entries?.(groupedData)?.map(([category, items], index) => {
                        return (
                            <div key={index} className={`h-full ${index !== Object.entries?.(groupedData).length - 1 ? 'border-r-2' : ''}`}>
                                {category !== 'NATSHP' && <h2 className='pr-[4px] font-medium text-black uppercase text-14 '>{formatCategoryName(category)}</h2>}
                                <ul className={`text-14 ${items.length >= 10 && category !== 'ABOUT' && category !== 'Design Youe Own Gemstone Rings' && category !== 'Jewelry' && hover !== 'JEWELRY' ? 'grid grid-cols-[auto_auto] gap-x-5' : ''}`}>
                                    {items.map((item, itemIndex) => {
                                        return (
                                            <div key={itemIndex} className={`${item.length === 11 ? 'grid grid-cols-[auto_auto] gap-x-1 ' : ''}`}>
                                                {Array.isArray(item) ? <div className='grid grid-cols-2 gap-x-5'> {item?.map((data, index) => {
                                                    return (<li key={index} className='mt-2  cursor-pointer hover:text-[#0D6363] opacity-[0.8] hover:opacity-[1]'> <div className='flex gap-3 gap-y-5' onClick={() => handleClick(data.Item, data.CODE)}><img src={Shape_Images[data.Item]} alt='brillient earth' className='w-6 h-6' /><span > {data.Item}</span></div> </li>)
                                                })}</div> :
                                                    <li className='mt-2  cursor-pointer hover:text-[#0D6363] opacity-[0.8] hover:opacity-[1]' onClick={() => handleClick(item.Item, item.CODE, item.DISP, itemIndex)}>{category === 'shop_by_shape' && hover !== 'GEMSTONES' ? <div className='flex gap-2 gap-y-5'><img src={Shape_Images[item.Item]} alt='brillient earth' className='w-6 h-6' /><span > {item.Item}</span></div> : item.Item}</li>}
                                            </div>

                                        )
                                    })}
                                </ul>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div> :
            <div className='' {...props}>
                <div className='flex items-center my-2' onClick={(e) => { e.stopPropagation(); props.setNavbarIndex(0) }}><FaChevronLeft className=' size-3' /><span className='text-12 font-medium'>Back</span></div>
                <a className='font-inter-400 text-lightBlack text-14  tracking-wide font-medium hover:text-[#0D6363] opacity-[0.8] hover:opacity-[1] block' href={`/${props.headerHoverItem}`} >{props.headerHoverItem === 'engagement' || props.headerHoverItem === 'wedding' ? props.headerHoverItem + ' Ring' : props.headerHoverItem === 'jewelry' ? '' : props.headerHoverItem}</a>
                <div className={``}>
                    {Object.entries(groupedData).map(([category, items], index) => (
                        <div key={index} className='h-full'>
                            <h2 className=' font-medium mt-5 text-black uppercase'>{formatCategoryName(category)}</h2>
                            <ul className={`text-14 ${items.length === 10 && category !== 'about_us' ? 'grid grid-cols-[auto_auto] gap-x-10' : ''}`}>
                                {items.map((item, itemIndex) => {
                                    return (
                                        <div key={itemIndex} className={`${item.length === 11 ? 'grid grid-cols-[auto_auto] ' : ''}`} onClick={() => { props.setNavbarIndex(0); props.isShow(false) }}>
                                            {Array.isArray(item) ? item.map((data, index) => {
                                                return (<li key={index} className='mt-2 font-inter cursor-pointer hover:text-[#0D6363] opacity-[0.8] hover:opacity-[1]'> <a href={`/${data.path}`}>{data.text}</a></li>)
                                            }) :
                                                <li className='mt-2 font-inter cursor-pointer hover:text-[#0D6363] opacity-[0.8] hover:opacity-[1]' onClick={() => handleClick(item.Item, item.CODE, item.DISP, itemIndex)}> {category === 'shop_by_shape' ? <div className='flex gap-3 gap-y-5'><img src={Shape_Images[item.Item]} alt='brillient earth' className='w-6 h-6' /><span className='mt-1'> {item.Item}</span></div> : item.Item}</li>}
                                        </div>

                                    )
                                })}
                            </ul>
                        </div>
                    ))}
                </div>
            </div>
    )
}

export default NavigationDropdown
