import React from 'react'

const Offer = ({ data }) => {
    return (
        <div className="mt-10 sm:mt-10  bg-[#183E40] text-white w-full container mx-auto">
            {data.map((data, index) => {
                return (
                    <div className="relative md:pl-[20%] xl:pl-[30%]" key={index} >
                        <div key={index} className=" flex">
                            <img src={data.image} alt="kyrah star" />

                            <div>
                                <h3 className="font-bold my-3 ">END SOON!</h3>
                                <p className='text-13'>
                                    <span className="font-bold">
                                        Receive Lab Diamond Studes
                                    </span>{" "}
                                    with purchase over $1000.
                                </p>
                                <p className='text-13'>
                                    <span className="font-bold">Surprise Earning</span>{" "}
                                    with all other purchase.
                                </p>
                                <p className='text-13'>
                                    Use code <span className="font-bold">SPARKLE</span> in
                                    cart.
                                </p>
                            </div>
                        </div>
                        <div className="absolute right-1 bottom-1  text-white text-10 underline">
                            <a href="wedding-rings">See Trems</a>
                        </div>
                    </div>
                );
            })}
        </div>
    )
}

export default Offer
