import React from 'react'
import { GoDash } from 'react-icons/go'

const Review = ({ data, title }) => {
    return (
        <div>
            <h3 className='text-30 w-full font-inter mb-5'>{`${title}`} Reviews</h3>
            <div className='flex justify-between gap-1 overflow-x-auto lg:overflow-x-hidden  scrollbar-hide'>
                {data?.map((data, index) => {
                    return (
                        <div key={index} className='hover:text-hover w-[60%] md:w-[32%] flex-shrink-0 ' >
                            <img src={data.image} alt='Kyrah Star' className='w-full' />
                            <p className='text-15 mt-2 mb-5 text-wrap'>{data.content}</p>
                            <a href='/gemstones#a' className='underline hover:color-hover'><GoDash className='inline' />{data.link}</a>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default Review
