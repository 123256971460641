import React, { useEffect, useState } from 'react';
import { MdOutlineKeyboardArrowDown } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { countryCodes } from '../../helpers/jsonData';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { RotatingLines } from 'react-loader-spinner';
import { axiosInstance } from '../../redux/api';
import { showErrorToast, showSuccessToast } from '../../components/ToastNotification';
import { useDispatch } from 'react-redux';
import { addWishlistCount } from '../../redux/slices/wishList.slice';
import { addCartListCount } from '../../redux/slices/cartList.slice';

const Login = () => {

    const navigate = useNavigate();

    // States for form fields
    const [email, setEmail] = useState('');
    const [number, setNumber] = useState('');
    const [password, setPassword] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [countryCode, setCountryCode] = useState('+91');
    const [isOpenCode, setIsOpenCode] = useState(false);
    const [searchTermCode, setSearchTermCode] = useState('');
    const [filteredCountriesCode, setFilteredCountriesCode] = useState([]);

    // States for individual field errors
    const [userNameError, setUserNameError] = useState('');
    const [passwordError, setPasswordError] = useState('');

    const [loginType, setLoginType] = useState('email')

    const dispatch = useDispatch()
    const handleSelect = (country) => {
        setSearchTermCode('')
        setCountryCode(country.code);
        setIsOpenCode(false);
    };
    useEffect(() => {
        const debounceTimeout = setTimeout(() => {
            let filtered = countryCodes;

            if (searchTermCode) {
                filtered = countryCodes.filter(item =>
                    item.code.includes(searchTermCode) || item.name.toLowerCase().includes(searchTermCode.toLowerCase())
                );
            }

            setFilteredCountriesCode(filtered);
        }, 300); // Wait for 300ms after user stops typing

        return () => clearTimeout(debounceTimeout); // Cleanup timeout on each render
    }, [searchTermCode]);

    // Function to validate email
    const validateEmail = (email) => {
        const re = /\S+@\S+\.\S+/;
        return re.test(email);
    };
    const validatePhoneNo = (phoneNo) => {
        const regex = /^\d{0,10}$/; // Ensures more than 10 digits
        return regex.test(phoneNo);
    };

    const handleUserNameChange = (e) => {
        setEmail(e.target.value);
        if (e.target.value.trim() === '') {
            setUserNameError('Email is required');
        } else if (!validateEmail(e.target.value)) {
            setUserNameError('Invalid email format');
        } else {
            setUserNameError('');
        }
    };


    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
        if (e.target.value.trim() === '') {
            setPasswordError('Password is required');
        } else {
            setPasswordError('');
        }
    };

    const isDisabled = loginType === 'email' ? !email || !password : !number || !password

    const [userInfo, setUserInfo] = useState({
        ip: '',
        city: '',
        region: '',
        country: '',
        browser: ''
    });

    useEffect(() => {
        // Fetch IP and location info from ipinfo.io
        const getIpAndLocation = async () => {
            try {
                const response = await fetch('http://ip-api.com/json'); // ip-api supports CORS by default
                if (!response.ok) {
                    throw new Error('Failed to fetch location data');
                }

                const data = await response.json();
                setUserInfo((prevState) => ({
                    ...prevState,
                    ip: data.query,
                    city: data.city,
                    region: data.regionName,
                    country: data.country,
                }));
            } catch (error) {
                console.log('Error fetching IP location:');
                // setUserInfo((prevState) => ({
                //     ...prevState,
                //     error: 'Failed to retrieve location data',
                // }));
            }
        };

        // Get browser information
        const getBrowserName = () => {
            const userAgent = navigator.userAgent;
            let browserName = "Unknown";

            if (userAgent.indexOf("Chrome") > -1) {
                browserName = "Google Chrome";
            } else if (userAgent.indexOf("Firefox") > -1) {
                browserName = "Mozilla Firefox";
            } else if (userAgent.indexOf("Safari") > -1) {
                browserName = "Apple Safari";
            } else if (userAgent.indexOf("MSIE") > -1 || userAgent.indexOf("Trident") > -1) {
                browserName = "Internet Explorer";
            }

            setUserInfo((prevState) => ({
                ...prevState,
                browser: browserName
            }));
        };

        getIpAndLocation();
        getBrowserName();
    }, []);

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault()
        if (isDisabled) {
            return;
        }

        const payload = {
            "PHONE": loginType === 'email' ? '' : number,
            "EMAIL": loginType === 'number' ? '' : email.trim(),
            "CON_CODE": loginType === 'email' ? '' : countryCode,
            "PASSWORD": password
        };

        try {
            setIsLoading(true);
            // const response = await fetch(`${process.env.REACT_APP_PUBLIC_BASE_URL}auth/login`, {
            //     method: 'POST',
            //     headers: {
            //         'Content-Type': 'application/json',
            //     },
            //     body: JSON.stringify(payload),
            // });
            const response = await axiosInstance.post('auth/login', payload)
            // const jsonResponse = await axiosInstance.post('auth/login', payload)
            // const isDecryption = process.env.REACT_APP_IS_DECRYPT
            // const response = Decryption == 'true' ? decryptData(jsonResponse.data) : jsonResponse.data.data

            if (response.data.success === true) {
                setIsLoading(false);
                showSuccessToast(response.data.message)
                localStorage.setItem('user-info', JSON.stringify(response.data.data))
                localStorage.setItem('currency', response.data.data.CURRENCY)
                dispatch(addWishlistCount(response.data.data.WISH_COUNT))
                dispatch(addCartListCount(response.data.data.CART_COUNT))
                // navigate(`/`)
                navigate(-1)
                // window.location.reload();
                // setTimeout(() => {
                //     window.location.reload()
                // }, 1000)
                const pay = {
                    "LOGIN_ID": response.data.data.USERID,
                    "LOGIN_IP": userInfo.ip,
                    "DEVICE_NAME": userInfo.browser,
                    "LOCATION": `${userInfo.city}, ${userInfo.region}, ${userInfo.country}`
                }
                await axiosInstance.post('login/add/history', pay)
            } else {
                setIsLoading(false);
                showErrorToast(response.data.message)
            }
            // Handle successful response.data, e.g., show a success message or navigate

        } catch (error) {
            showErrorToast(error.response.data.message)
            setIsLoading(false);
            // Handle errors, e.g., show an error message
        } finally {
            setIsLoading(false);
        }
    };


    const toggleDropdown = () => {
        setIsOpenCode(!isOpenCode)
    }


    return (
        <>
            <ToastContainer />
            <div className='mx-auto container mt-2'>
                {/* <div className='text-start'>
                    <span className='text-12 text-lightBlack font-inter'>
                        <span onClick={() => navigate("/")} className="cursor-pointer hover:underline">Home</span> / Customer Service / Account
                    </span>
                </div> */}

                <div className='mt-5 w-[90%] md:w-[50%] lg:w-[30%] mx-auto'>
                    <div className='w-full mx-auto flex justify-center flex-row'>
                        <div className='w-full mt-10'>
                            <h1 className='font-inter font-semibold text-25 text-center'>SignIn with Kyrahstar</h1>
                            <p className='font-inter font-normal text-14 text-center mt-2'>
                                If you have a Kyrahstar customer account, please sign in.
                            </p>
                        </div>
                    </div>

                    <form onSubmit={handleSubmit}>
                        <div className='mt-10 w-full'>
                            {/* Mobile Number and Email */}
                            {loginType === 'email' ? <div className='flex flex-row justify-center items-center gap-5 w-full'>
                                <div className='flex flex-col w-full'>
                                    <input
                                        type='text'
                                        placeholder='Enter Email'
                                        value={email}
                                        onChange={handleUserNameChange}
                                        className='border border-[#ccc] rounded-lg p-3 text-15 w-full outline-none my-2'
                                    />
                                    {userNameError && <p className="text-red-500 text-14 font-inter">{userNameError}</p>}
                                </div>
                            </div> :
                                <>

                                    {/* <div className='flex flex-row justify-center items-center gap-5 w-full'>
                                <div className='flex flex-col w-[30%]'>
                                    <input
                                        type='number'
                                        placeholder='Enter Phone Number'
                                        value={number}
                                        // onChange={handleNumberChange}
                                        onChange={(e) => {
                                            let inputValue = e.target.value;
                                            const regex = /^\d{0,10}$/;
                                            if (inputValue === '') {
                                                setUserNameError('Phone Number is Required.');
                                                setNumber(inputValue); // Only update the value if valid
                                            } else if (!regex.test(inputValue)) {
                                                setUserNameError('You can not add more than ten digits Phone Number');
                                            } else {
                                                setUserNameError('');
                                                setNumber(inputValue); // Only update the value if valid
                                            }
                                        }}
                                        className='border  border-[#ccc] rounded-lg p-3 text-15 w-full outline-none my-2'
                                    />
                                    {userNameError && <p className="text-red-500 text-14 font-inter">{userNameError}</p>}
                                </div>
                            </div> */}
                                    <div className='flex flex-row justify-center items-center gap-5 w-full'>
                                        {/* Country Code dropdown */}
                                        <div className='flex flex-col w-full relative'>
                                            <div className='border border-[#ccc] rounded-lg p-3 text-15 w-full outline-none my-2 flex flex-row'>
                                                <div onClick={toggleDropdown} className='flex items-center cursor-pointer outline-none'>
                                                    {/* <img src={selectedCountry.svg} alt={`Flag of ${selectedCountry.name}`} className="w-8 h-6 mr-2" /> */}
                                                    <span className='text-15'>{countryCode}</span>
                                                    <span className={`transform ml-2 transition-transform ${isOpenCode ? 'rotate-180' : 'rotate-0'}`}>
                                                        <MdOutlineKeyboardArrowDown size={20} />
                                                    </span>
                                                </div>

                                                {isOpenCode && (
                                                    <div className='absolute z-10 top-[51px] right-0 border border-[#ccc] h-[420px] overflow-auto rounded-lg mt-1 bg-white w-full'>
                                                        {/* Search Input */}
                                                        <input
                                                            type="text"
                                                            placeholder="Search..."
                                                            value={searchTermCode}
                                                            onChange={(e) => setSearchTermCode(e.target.value)}
                                                            className="p-2 border-b border-[#ccc] w-full outline-none"
                                                        />
                                                        {/* Filtered Countries List */}
                                                        {filteredCountriesCode.length > 0 ? (
                                                            filteredCountriesCode.map((country) => {
                                                                return (
                                                                    <div
                                                                        key={country.id}
                                                                        className='flex items-center p-3 cursor-pointer hover:bg-gray-100'
                                                                        onClick={() => handleSelect(country)}
                                                                    >
                                                                        <img src={country.svg} alt={`Flag of ${country.name}`} className="w-8 h-6 mr-2" />
                                                                        <span>{country.code}</span>
                                                                    </div>
                                                                )
                                                            })
                                                        ) : (
                                                            <div className="p-3 text-gray-500">No countries found</div>
                                                        )}
                                                    </div>
                                                )}

                                                <div className='flex flex-col w-full'>
                                                    <input
                                                        type="number"
                                                        placeholder="Mobile Number"
                                                        value={number}
                                                        onChange={(e) => {
                                                            let inputValue = e.target.value;
                                                            const regex = /^\d{0,10}$/;
                                                            if (inputValue === '') {
                                                                setUserNameError('Phone Number is Required.');
                                                                setNumber(inputValue); // Only update the value if valid
                                                            } else if (!regex.test(inputValue)) {
                                                                setUserNameError('You can not add more than ten digits Phone Number');
                                                            } else {
                                                                setUserNameError('');
                                                                setNumber(inputValue); // Only update the value if valid
                                                            }
                                                        }}
                                                        className="px-2 w-full outline-none"
                                                    />
                                                </div>
                                            </div>
                                            {userNameError && <p className="text-red-500 text-14 font-inter">{userNameError}</p>}
                                        </div>
                                    </div>
                                </>
                            }

                            {/* Password and Confirm Password */}
                            <div className='flex flex-row justify-center items-center gap-5 w-full'>
                                <div className='flex flex-col w-full'>
                                    <input
                                        type='password'
                                        placeholder='Password'
                                        value={password}
                                        onChange={handlePasswordChange}
                                        className='border border-[#ccc] rounded-lg p-3 text-15 outline-none w-full my-2'
                                    />
                                    {passwordError && <p className=" text-red-500 text-14 font-inter">{passwordError}</p>}
                                </div>
                            </div>
                            <div className='flex justify-end'>
                                <p className='mb-2 hover:underline  hover:cursor-pointer w-fit ' onClick={() => { navigate('/accounts/password-reset'); }}>Forgot Your Password?</p>
                            </div>
                            <div className='flex justify-center items-center flex-col'>
                                <p>OR</p>
                                {loginType === 'email' ? <p className='cursor-pointer underline hover:text-footerBackground' onClick={() => { setLoginType('number'); setPassword(''); setEmail(''); setUserNameError(''); setPasswordError('') }}>SignIn with Phone Number</p> :
                                    <p className='cursor-pointer underline hover:text-footerBackground' onClick={() => { setLoginType('email'); setPassword(''); setNumber(''); setUserNameError(''); setPasswordError('') }}>SignIn with Email</p>}
                            </div>
                            {/* Submit Button */}
                            {
                                isLoading ?
                                    <button
                                        className={`bg-footerBackground flex flex-row justify-center cursor-pointer text-white w-full rounded-full p-3 mt-8`}
                                    >
                                        <RotatingLines
                                            visible={true}
                                            height="20"
                                            width="20"
                                            strokeWidth="5"
                                            strokeColor='white'
                                            animationDuration="0.75"
                                            ariaLabel="rotating-lines-loading"
                                            wrapperStyle={{}}
                                            wrapperClass=""
                                        />
                                    </button>
                                    :
                                    <button
                                        type='submit'
                                        className={`${isDisabled ? 'bg-gray-200 cursor-not-allowed text-black' : 'bg-footerBackground cursor-pointer text-white'} w-full rounded-full p-3 mt-8`}
                                        // onClick={handleSubmit}
                                        disabled={isDisabled}
                                    >
                                        Login
                                    </button>
                            }
                            {/* <button
                            className={`${isDisabled ? 'bg-gray-200 cursor-not-allowed text-black' : 'bg-footerBackground cursor-pointer text-white'} w-[50%] rounded-lg p-3 mt-8`}
                            onClick={handleSubmit}
                            disabled={isDisabled}
                        >
                            Create Account
                        </button> */}
                        </div>
                    </form>
                    <div className='cursor-pointer underline hover:text-footerBackground flex justify-center mt-5' onClick={() => navigate('/register')}> Create New Account?</div>
                </div>
            </div>
        </>
    );
};

export default Login;
