import React from 'react'
import Button from './Button.js'

const Banner = ({ data, ...props }) => {
    return (
        <div className='relative'  >
            {
                data.map((data, index) => {
                    return (
                        <div key={index} {...props}>
                            <div className="w-full mt-5">
                                <picture>
                                    <source media="(min-width:0px) and (max-width:499px)" srcSet={data.image[0]} />
                                    <source media="(min-width:500px) and (max-width:1023px)" srcSet={data.image[1]} />
                                    <source media="(min-width:1024px) " srcSet={data.image[2]} />
                                    <img src={data.imageUrl} alt="Flowers" className='w-auto' />
                                </picture>
                                {/* <img src={data.image} className='w-full h-full ' alt="Kyrah Star" /> */}
                            </div>

                            <div className='lg:absolute lg:w-[53%] 2xl:w-[40%]  items-center h-full lg:right-0 justify-center flex  lg:bottom-0 '>
                                <div className='flex flex-col w-[90%] sm:w-[80%] md:w-[75%] text-center lg:text-left sm:text-center mt-[3%] sm:mt-10 lg:mt-0'>
                                    <h1 className=' text-42 sm:text-42 lg:text-bannerHeading lg:leading-none font-inter font-bannerHeading'>
                                        {data.title}
                                    </h1>

                                    <span className=' text-bannerDescription  font-inter mt-3 mb-5 font-bannerDescription'>
                                        {data.subtitle}
                                    </span>
                                    <div>
                                        <Button btn1={data.buttons[0]} btn2={data.buttons[1]} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })
            }
        </div>
    )
}

export default Banner;
