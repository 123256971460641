import { Modal } from 'antd'
import React, { useEffect, useLayoutEffect, useState } from 'react'
import { FaChevronDown, FaChevronLeft, FaChevronRight, FaChevronUp } from 'react-icons/fa'
import { GoHeart, GoHeartFill } from 'react-icons/go'
import { IoStar } from 'react-icons/io5'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { ClipLoader } from 'react-spinners'
import { ToastContainer } from 'react-toastify'
import { decryptData } from '../../components/decrypt'
import DesignRingComponent from '../../components/DesignRingComponent'
import { showErrorToast, showSuccessToast } from '../../components/ToastNotification'
import { RING_DETAILS, Shape_Images } from '../../helpers/jsonData'
import { axiosInstance } from '../../redux/api'
import { addCartListCount } from '../../redux/slices/cartList.slice'
import { addSelectedSize } from '../../redux/slices/sizeData.slice'
import { addWishlistCount } from '../../redux/slices/wishList.slice'
import { decryptURL, encryptURL } from '../../utils/encrypt'
import { Images } from '../../components/Image'
import { isValidImageUrl } from '../../utils/isValidImage'
import { IoIosStar, IoIosStarOutline, IoMdStar } from 'react-icons/io'
import moment from 'moment/moment'
import Slider from 'react-slick'

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


const ProductDetail = () => {
    const [selectedDiamondOrigin, setSelectedDiamondOrigin] = useState('')

    const [selectedHoverDiamondOrigin, setSelectedHoverDiamondOrigin] = useState('')
    const [ringDetail, setRingDetail] = useState(true)
    const [productDetails, setProductDetails] = useState();
    const [loading, setLoading] = useState(false);
    const [isReadMore, setIsReadMore] = useState(true);
    const [hoveredMetal, setHoveredMetal] = useState(null);
    const [selectedMetal, setSelectedMetal] = useState(productDetails?.PRO_DETAIL[0]?.METAL || "White Gold");
    const [showVariants, setShowVariants] = useState();
    const [selectedVariantId, setSelectedVariantId] = useState(null);
    const navigate = useNavigate()
    const data = useParams();
    const { diamond } = useParams();
    const [skuName, variantId, shape] = data?.skuname?.split('-');
    const Shape = shape ? `-${shape}` : ''
    const [sizeDropdown, setSizeDropdown] = useState(false)
    const [priceBackUp, setPriceBackUp] = useState(false)

    const { size } = useSelector((state) => state.sizeData)
    const userInfo = JSON.parse(localStorage.getItem('user-info'))

    const [isWishList, setIsWishList] = useState(false)

    const { wishListCount } = useSelector(state => state.wishList)

    const dispatch = useDispatch()
    // const currency = JSON.parse(localStorage.getItem('user-info'))?.CURRENCY ?? 'INR'
    const currency = userInfo?.CURRENCY ?? 'INR'

    const [ringSizeData, setRingSizeData] = useState([])
    const sizeData = useSelector(state => state.sizeData.selectedSize)
    const [selectedSize, setSelectedSize] = useState(sizeData)

    const { search } = useLocation()
    const userData = JSON.parse(localStorage.getItem('user-info'))

    const [reviewData, setReviewData] = useState()
    const [reviewImageModalOpen, setReviewImageModalOpen] = useState(false)
    const [selectedReview, setSelectedReview] = useState()
    //----------- PRODUCT API HANDLER -----------
    const fetchProductDetailsData = async () => {
        setLoading(true)
        try {
            const requestBody = {
                PRD_VARIANT_ID: variantId,
                SKUNO: decryptURL(skuName),
                S_CODE: shape ? shape : "",
                CURR: currency
            }
            const response = await axiosInstance.post('data/product-details', requestBody);
            const isDecryption = process.env.REACT_APP_IS_DECRYPT
            setProductDetails(isDecryption == 'true' ? decryptData(response.data).data : response.data.data.data)
            // setProductDetails(response?.data?.data)
            // setSelectedSize(isDecryption == 'true' ? decryptData(response.data).data?.PRO_DETAIL[0]?.PRD_SIZE : response.data.data.data?.PRO_DETAIL[0]?.PRD_SIZE)
            dispatch(addSelectedSize(isDecryption == 'true' ? decryptData(response.data).data?.PRO_DETAIL[0]?.PRD_SIZE : response.data.data.data?.PRO_DETAIL[0]?.PRD_SIZE))
            // setSelectedSize(response?.data?.data?.PRO_DETAIL[0]?.PRD_SIZE)
            setIsWishList(isDecryption == 'true' ? decryptData(response.data).data?.PRO_DETAIL[0]?.IS_WISH : response.data.data.data?.PRO_DETAIL[0]?.IS_WISH)
            setRingSizeData(isDecryption == 'true' ? decryptData(response.data).data?.RING_SIZE?.find(data => +data?.PRD_SIZE === +decryptData(response.data).data?.PRO_DETAIL[0]?.PRD_SIZE) : response.data.data.data?.RING_SIZE?.find(data => +data?.PRD_SIZE === +response.data.data.data?.PRO_DETAIL[0]?.PRD_SIZE))
            if (selectedSize === 0) {
                setSelectedSize(isDecryption == 'true' ? decryptData(response.data).data?.PRO_DETAIL[0]?.PRD_SIZE : response.data.data.data?.PRO_DETAIL[0]?.PRD_SIZE)
            } else if (search) {
                setSelectedSize(search.split('=')[1])
            }
            getReview({ PRD_ID: isDecryption == 'true' ? decryptData(response.data).data?.PRO_DETAIL[0]?.PRD_ID : response.data.data.data?.PRO_DETAIL[0]?.PRD_ID, PRD_VARIANT_ID: isDecryption == 'true' ? decryptData(response.data).data?.PRO_DETAIL[0]?.PRD_VARIANT_ID : response.data.data.data?.PRO_DETAIL[0]?.PRD_VARIANT_ID })
            // setIsWishList(response?.data?.data?.PRO_DETAIL[0]?.IS_WISH)
            // console.log(response.data.data.data?.PRO_DETAIL[0]?.PRD_SIZE, 'a')

        } catch (e) {
            console.log(e)
            setLoading(false)
        }
    }
    const getReview = async ({ PRD_ID, PRD_VARIANT_ID }) => {
        try {
            const requestBody = {
                "PRD_ID": PRD_ID,
                "PRD_VARIANT_ID": PRD_VARIANT_ID,
            }
            const response = await axiosInstance.post('review', requestBody);
            const isDecryption = process.env.REACT_APP_IS_DECRYPT
            setReviewData(isDecryption == 'true' ? decryptData(response.data).data : response.data.data.data)
            setLoading(false)
        } catch (e) {
            console.log(e)
        }
    }

    useLayoutEffect(() => {
        fetchProductDetailsData()
    }, [selectedVariantId, variantId, shape])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [variantId, skuName])

    useEffect(() => {
        setSelectedMetal(productDetails?.PRO_DETAIL[0]?.METAL || "White Gold");
        setSelectedDiamondOrigin(productDetails?.PRO_DETAIL[0]?.AVAIL_DIAM_TYPE == 1 ? "Natural" : productDetails?.PRO_DETAIL[0]?.AVAIL_DIAM_TYPE == 2 ? "Lab Grown" : "Lab Grown");
    }, [productDetails]);

    const ringShapesAvailable = productDetails?.PRO_DETAIL[0]?.RING_SHAPE_AVAIL || "";

    const ringShapesArray = ringShapesAvailable.split(',').filter(Boolean);

    const diamondData = ringShapesArray.map(shape => ({
        name: shape,
        image: Shape_Images[shape]
    }));


    useEffect(() => {
        // getSize()
        window.addEventListener('click', () => setSizeDropdown(false))
        return (
            () => window.removeEventListener('click', () => setSizeDropdown(false))
        )
    }, [])

    // const CustomPrevArrow = (props) => (
    //     <button {...props} className="slick-arrow rounded-full  p-3 opacity-60 hover:opacity-100 absolute left-[-10px] md:left-[-22px] top-[50%] translate-y-[-50%] z-10">
    //         <FaChevronLeft size={12} color="black" />
    //     </button>
    // );
    // const CustomNextArrow = (props) => (
    //     <button {...props} className="slick-arrow rounded-full  p-3 opacity-60 hover:opacity-100 absolute right-[-10px] md:right-[-22px] top-[50%] translate-y-[-50%] z-10">
    //         <FaChevronRight size={12} color="black" />
    //     </button>
    // );
    // const settings2 = {
    //     dots: false,
    //     infinite: false,
    //     speed: 500,
    //     slidesToShow: 3,
    //     slidesToScroll: 2,
    //     prevArrow: <CustomPrevArrow />,
    //     nextArrow: <CustomNextArrow />
    // };
    // const settings3 = {
    //     dots: false,
    //     infinite: false,
    //     speed: 500,
    //     slidesToShow: 3.5,
    //     slidesToScroll: 1,
    //     prevArrow: <CustomPrevArrow />,
    //     nextArrow: <CustomNextArrow />
    // };
    // const settings4 = {
    //     dots: false,
    //     infinite: false,
    //     speed: 500,
    //     slidesToShow: 8.5,
    //     slidesToScroll: 1,
    //     prevArrow: <CustomPrevArrow />,
    //     nextArrow: <CustomNextArrow />
    // };
    const showDiamondOrigin = (item) => {
        setSelectedDiamondOrigin(item)
    }

    const handleMouseLeave = () => {
        if (!selectedVariantId) {
            setShowVariants(false);
        }
    };

    const handleVariantClick = (variantId) => {
        setSelectedVariantId(variantId);
        navigate(`/product-detail/${encodeURI(skuName)}-${variantId}`, { replace: true });

        // This will force a page reload after navigation
        // setTimeout(() => {
        //     window.location.reload();
        // }, 0);

        setShowVariants(false);
    };


    // const metalOrder = ["white gold", "yellow gold", "rose gold", "platinum"];
    // const sortedVariants = [...(productDetails?.METAL || [])]?.sort(
    //     (a, b) => metalOrder.indexOf(a.MT_NAME.toLowerCase()) - metalOrder.indexOf(b.MT_NAME.toLowerCase())
    // );

    const metalOrder = ["14K White Gold", "14K Rose Gold", "14K Yellow Gold", '18K White Gold', '18K Yellow Gold', 'Platinum'];

    const sortedVariants = [...(productDetails?.METAL || [])].sort((a, b) => {
        const indexA = metalOrder.indexOf(a?.MT_NAME) !== -1 ? metalOrder.indexOf(a.MT_NAME) : Infinity;
        const indexB = metalOrder.indexOf(b?.MT_NAME) !== -1 ? metalOrder.indexOf(b.MT_NAME) : Infinity;
        return indexA - indexB;
    });
    const [priceDifference, setPriceDiffrence] = useState()

    useEffect(() => {
        if (productDetails) {
            const selectedMetalDetails = productDetails?.METAL.find(metal => metal.MT_NAME === (hoveredMetal || selectedMetal));

            const productDetails1 = productDetails?.PRO_DETAIL[0];

            // Calculate the price difference if metals are different
            const priceDifference =
                selectedMetalDetails?.MT_NAME !== productDetails1?.METAL
                    ? selectedMetalDetails?.PRICE - productDetails1?.PRICE
                    : 0;
            setPriceDiffrence(priceDifference > 0 ? ` +${Math.abs(priceDifference)}` : (priceDifference == 0) ? '' : ` -${Math.abs(priceDifference)}`)
        }

    }, [productDetails, hoveredMetal, selectedMetal])


    const handleNavigationDiamond = () => {
        console.log(selectedDiamondOrigin, 'or')
        if (selectedSize) {
            if (diamond) {
                navigate(`/purchase-review/${skuName}-${variantId}${Shape}/${diamond}`)
            } else {
                navigate(`/engagement-rings/start-with-a-diamond/${skuName}-${variantId}${Shape}&${selectedDiamondOrigin == 'Lab Grown' ? true : false}`)
            }
        } else showErrorToast('Please Select Ring Size')
    }
    const handleAddToBag = async () => {
        if (userInfo?.USERID) {
            // const shape = shapes.Shape.find(data => data.Items === diamondDetails?.[0]?.Shape)
            let payload = {
                "USERID": userInfo?.USERID,
                "SKUNO": skuName ? decryptURL(skuName) : 0,
                "PRD_VARIANT_ID": variantId ? variantId : 0,
                "S_CODE": shape ? shape : '',
                "STOCK_ID": data?.StockID ?? '',
                "IS_ONLY_DIAMOND": skuName ? false : true,
                "IS_REMOVE": false,
                "QUANTITY": 1,
                "PRD_SIZE": selectedSize
            }
            try {
                const response = await axiosInstance.post('cart/add', payload);
                // const jsonResponse = await axiosInstance.post('wishlist/add', payload);
                // const isDecryption = process.env.REACT_APP_IS_DECRYPT
                // const response = Decryption == 'true' ? decryptData(jsonResponse.data) : jsonResponse.data.data
                if (response.data.success) {
                    setIsWishList(true)
                    showSuccessToast(response.data.message)
                    dispatch(addCartListCount(response?.data?.data?.[0]?.[0]?.CART_COUNT))
                    navigate('/shop/cart')
                }
            } catch (e) {
                showErrorToast(e.response.data.message)
                // setLoading(false)
            }
        } else {
            navigate('/login')
        }
    }

    const addToWishList = async () => {
        if (userInfo?.USERID) {
            const payload = {
                "USERID": userInfo?.USERID,
                "SKUNO": decryptURL(skuName),
                "PRD_VARIANT_ID": variantId,
                "S_CODE": shape ? shape : "",
                "STOCK_ID": diamond ? decryptURL(diamond) : "",
                "IS_ONLY_DIAMOND": false,
                "IS_REMOVE": false
            }
            try {
                const response = await axiosInstance.post('wishlist/add', payload);
                // const jsonResponse = await axiosInstance.post('wishlist/add', payload);
                // const isDecryption = process.env.REACT_APP_IS_DECRYPT
                // const response = Decryption == 'true' ? decryptData(jsonResponse.data) : jsonResponse.data.data
                if (response.data.success) {
                    setIsWishList(true)
                    showSuccessToast(response.data.message)
                    dispatch(addWishlistCount(response?.data?.data?.[0]?.[0]?.WISH_COUNT))
                }
            } catch (e) {
                showErrorToast(e.response.data.message)
                // setLoading(false)
            }
        } else {
            navigate('/login')
        }
    }

    const removeFromWishList = async () => {
        if (userInfo?.USERID) {
            const payload = {
                "USERID": userInfo?.USERID,
                "SKUNO": decryptURL(skuName),
                "PRD_VARIANT_ID": variantId,
                "S_CODE": shape ? shape : "",
                "STOCK_ID": diamond ? decryptURL(diamond) : "",
                "IS_ONLY_DIAMOND": false,
                "IS_REMOVE": true
            }
            try {
                const response = await axiosInstance.post('wishlist/add', payload);
                // const jsonResponse = await axiosInstance.post('wishlist/add', payload);
                // const isDecryption = process.env.REACT_APP_IS_DECRYPT
                // const response = Decryption == 'true' ? decryptData(jsonResponse.data) : jsonResponse.data.data
                if (response.data.success) {
                    setIsWishList(false)
                    showSuccessToast(response.data.message)
                    dispatch(addWishlistCount(response?.data?.data?.[0]?.[0]?.WISH_COUNT))
                }
            } catch (e) {
                showErrorToast(e.response.data.message)
                // setLoading(false)
            }
        } else {
            navigate('/login')
        }
    }
    const changeSize = (size) => {
        setSelectedSize(size)
        setSizeDropdown(false)
        setRingSizeData(productDetails?.RING_SIZE?.find(data => +data.PRD_SIZE === +size))
        dispatch(addSelectedSize(+size))
    }

    useEffect(() => {
        setRingSizeData(productDetails?.RING_SIZE?.find(data => +data.PRD_SIZE === +selectedSize))
    })

    const formatDate = (dateString) => {
        // Convert UTC time to the local time zone using moment.js and format the date
        return moment.utc(dateString).local().format('MMMM DD, YYYY');
    };
    const [slidesToShow, setSlidesToShow] = useState(1);
    const CustomPrevArrow = (props) => {
        const { currentSlide, ...rest } = props;
        return (
            currentSlide !== 0 && <button button {...rest} className="slick-arrow     absolute  left-[3%] top-[50%] translate-y-[-50%] z-10 shadow-3xl" >
                <FaChevronLeft size={16} color="black" />
            </button >
        )
    }
    const CustomNextArrow = (props) => {
        const { currentSlide, slideCount, ...rest } = props
        return (
            currentSlide !== slideCount - slidesToShow && <button {...rest} className="slick-arrow  absolute  right-[3%] top-[50%] translate-y-[-50%] z-10 shadow-3xl">
                <FaChevronRight size={16} color="black" />
            </button>
        );
    }

    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: slidesToShow,
        slidesToScroll: 1,
        prevArrow: <CustomPrevArrow />,
        nextArrow: <CustomNextArrow />,
    };

    return (
        <div className='mb-5' >
            <ToastContainer />
            {/* <div className=' md:pt-3 pt-2 md:pl-8 pl-5 text-start'>
                <span className='text-12 text-lightBlack font-inter capitalize'>
                    {`Home / Engagement Rings /Petite Elodie 1.5mm `}
                </span>
            </div> */}

            {!loading && <div className={`${productDetails?.PRO_DETAIL[0]?.IS_FINAL_PRODUCT === true ? 'hidden' : 'hidden md:block'}`}>
                <DesignRingComponent
                    // settings={skuName && "true"}
                    ringInfo={{
                        "SKU_NAME": productDetails?.PRO_DETAIL[0]?.SKU_NAME,
                        "PRICE": ringSizeData?.PRICE,
                        "AVG_WIDTH": productDetails?.PRO_DETAIL[0]?.AVG_WIDTH,
                        "AVG_LENGTH": productDetails?.PRO_DETAIL[0]?.AVG_LENGTH,
                        "PRD_SIZE": ringSizeData?.PRD_SIZE,
                        "IMG_URL": productDetails?.PRO_DETAIL[0]?.IMG_URL,
                        CURR_SYMBOL: productDetails?.PRO_DETAIL[0]?.CURR_SYMBOL
                    }}
                    stockId={diamond}
                    skuno={skuName}
                    variantId={variantId}
                    Shape={shape ? shape : ""}
                    diamondInfo={{}}
                />
            </div>}

            {loading ? <div className='flex flex-row justify-center w-full h-[80vh]'> <ClipLoader className='my-[3%]' /></div> : <div className=' w-[95%] 2xl:w-[70%] container mx-auto flex flex-col md:flex-row  mt-5 md:mt-10'>
                <div className='lg:w-[60%] w-[100%] h-fit lg:sticky top-0 static'>
                    <div className='flex '>
                        {/*------------------ First Image ------------------*/}
                        {<div className='relative text-center mr-2 w-[50%]'>
                            <div className='absolute left-2 top-1.5'>
                                {/* <p className='text-10 px-3 py-1 bg-[#183E40] text-white rounded-full'>Signature Collections</p> */}
                            </div>
                            <img src={productDetails?.PRO_DETAIL[0]?.IMG_URL ? `${productDetails?.PRO_DETAIL[0]?.IMG_URL}?version=${new Date().getTime()}` : Images.notFound} alt='Kyrah Star'
                                className=' object-cover' />
                            {/* <p className='absolute bottom-1 left-[10%] text-12 sm:text-14'>{text}</p> */}
                        </div>}

                        {/*---------- Second Image (HOVER_IMG_URL) -----------*/}
                        {<div className='w-[50%]'>
                            <img
                                src={productDetails?.PRO_DETAIL[0]?.HOVER_IMG_URL
                                    ? `${productDetails?.PRO_DETAIL[0]?.HOVER_IMG_URL}?version=${new Date().getTime()}`
                                    : Images.notFound}
                                alt='Kyrah Star'
                                className=' object-cover'
                            />
                        </div>}
                    </div>

                    {/*---------- View with Carat Size range -----------*/}
                    {/* <div className='text-center  mt-2 py-5 bg-[#F9F9F8]'>
                        <p>{`View with Carat Size: ${selectedDiamondCarat[DiamondCarat - 1]} Carat`}</p>
                        <span>1/3 Carat</span>
                        <input type='range' min={1} max={11} className='accent-hover w-[50%] mt-2 mx-3' value={DiamondCarat} onChange={rangeHandler} />
                        <span>5 Carat</span>
                    </div> */}

                    <div className='flex mt-2'>
                        {/*---------- Third Image ----------*/}
                        {<div className='w-[50%] mr-2'>
                            <img
                                src={productDetails?.PRO_DETAIL[0]?.THIRD_IMG_URL ? `${productDetails?.PRO_DETAIL[0]?.THIRD_IMG_URL}?version=${new Date().getTime()}` : Images.notFound}
                                alt='Kyrah Star Engagement Ring Additional View'
                                className=' object-cover'
                            />
                        </div>}
                        {/*---------- Fourth Image ----------*/}
                        {<div className='relative text-center w-[50%]'>
                            <img src={productDetails?.PRO_DETAIL[0]?.FOURTH_IMG_URL ? `${productDetails?.PRO_DETAIL[0]?.FOURTH_IMG_URL}?version=${new Date().getTime()}` : Images.notFound} alt='Kyrah Star'
                                className=' object-cover'
                            />
                            {/* <p className='absolute bottom-1 left-[10%] text-12 sm:text-14'>{text}</p> */}
                        </div>}
                    </div>

                    <div className='flex  mt-2'>
                        {productDetails?.PRO_DETAIL[0]?.VIDEO_URL ? <div className='relative text-center w-[50%] mr-2'>
                            <iframe src={`${productDetails?.PRO_DETAIL[0]?.VIDEO_URL}?version=${new Date().getTime()}`} className='w-[108%] lg:w-[95%] xl:w-[95%]  2xl:w-full 2xl:h-[301px] h-[271px] mx-auto scrollbar-hide overflow-hidden' ></iframe>
                        </div> :
                            <div className='mr-2 w-[50%]'>
                                <img src={Images.notFound} alt='Kyrah Star'
                                    className=' object-cover '
                                />
                            </div>
                        }

                        {productDetails?.PRO_DETAIL[0]?.SECOND_VIDEO_URL ? <div className='w-[50%]'>
                            <iframe src={`${productDetails?.PRO_DETAIL[0]?.SECOND_VIDEO_URL}?version=${new Date().getTime()}`} className='w-[108%] lg:w-[95%] xl:w-[95%]  2xl:w-full 2xl:h-[301px] h-[271px] mx-auto scrollbar-hide overflow-hidden' ></iframe>
                        </div> :
                            <div className='w-[50%]'>
                                <img src={Images.notFound} alt='Kyrah Star'
                                    className=' object-cover'
                                />
                            </div>
                        }

                    </div>

                    {/* <div className='mt-2 w-[50%]'>
                        <div className={`w-full relative `}>
                            <img src='https://image.brilliantearth.com/media/diamond_ring_vto/A8/BE18959_yellow_Round_hand_zo_2_carat.png' alt='Kyrah Star' className='absolute top-0' />
                            {range < 100 && range > 0 ? <img src='https://css.brilliantearth.com/static/img/handskin/female_hand_visualizer_zo_dark2_updated.jpg' alt='Kyrah Star' className={`opacity-${range}`} /> :
                                range === 200 ? <img src='https://css.brilliantearth.com/static/img/handskin/female_hand_visualizer_zo_light_updated.jpg' alt='Kyrah Star' /> :
                                    range === 0 ? <img src='https://css.brilliantearth.com/static/img/handskin/female_hand_visualizer_zo_dark2_updated.jpg' alt='Kyrah Star' /> :
                                        <img src='https://css.brilliantearth.com/static/img/handskin/female_hand_visualizer_zo_dark_updated.jpg' alt='Kyrah Star' className={`opacity-${range - 100}`} />
                            }
                            <div className='absolute bottom-0 left-[10%]'>
                                <span className=''> Darker</span><input type='range' min={0} max={200} value={range} onChange={changeRangeHandler} className='accent-hover ' /><span>Lighter</span>
                            </div>
                        </div>
                    </div> */}
                </div>

                <div className='lg:w-[40%] w-full lg:pl-10  mt-5 lg:mt-0'>
                    <p className='text-22  font-medium mb-2 font-inter text-filterHeadingtext capitalize'>{productDetails?.PRO_DETAIL[0]?.SKU_NAME}</p>
                    {reviewData?.[1][0]?.AVG_RATING > 0 && <span className='flex mb-2'>
                        <div className='flex gap-1 text-24 '>
                            {Array(5)
                                .fill(0)
                                .map((_, index) => {
                                    const starIndex = index + 1;
                                    return (
                                        <span
                                            key={index}
                                            className="cursor-pointer"
                                        >
                                            {starIndex <= (reviewData?.[1][0]?.AVG_RATING) ? (
                                                <IoIosStar className="text-black" /> // Filled star
                                            ) : (
                                                <IoIosStarOutline className="text-gray-400" /> // Outline star
                                            )}
                                        </span>
                                    );
                                })}
                        </div>
                    </span>}
                    <p className='mb-4 text-16  font-inter'>
                        {isReadMore ? (
                            <>
                                {productDetails?.PRO_DETAIL[0]?.DESCR.slice(0, 70)}
                                {productDetails?.PRO_DETAIL[0]?.DESCR?.length >= 70 &&
                                    <>
                                        <span className='ml-1'>
                                            ...
                                        </span>
                                        <span
                                            onClick={() => setIsReadMore(!isReadMore)}
                                            className="read-or-hide  underline cursor-pointer hover:text-footerBackground"
                                        >
                                            {"Read More"}
                                        </span>
                                    </>}

                            </>
                        ) : (
                            <>
                                {productDetails?.PRO_DETAIL[0]?.DESCR}
                                <span
                                    onClick={() => setIsReadMore(!isReadMore)}
                                    className="read-or-hide ml-2 underline cursor-pointer hover:text-footerBackground"
                                >
                                    {"show less"}
                                </span>
                            </>
                        )}
                    </p>
                    {/* <div className='mb-4'>
                        <div>
                            <div className='text-14 font-inter text-black font-normal'>
                                <span className='font-medium mr-2'>View with Diamond Shape:</span>{`${diamondShape}`}
                            </div>
                        </div>
                        <Slider {...settings} className='mt-4 w-[70%] lg:w-full'>
                            {Shape_menu?.map((item, index) => {
                                return (
                                    <div key={index} className={`cursor-pointer lg:px-1.5  w-full  `} onClick={() => showDiamondShape(item)}>
                                        <img src={item.image} className={`h-10 w-10 lg:h-8 lg:w-8 bg-[#e3e3e3] rounded-full p-1  border-solid border-2 hover:border-black ${diamondShape === item.name ? 'border-hover' : ''}`} alt="Kyrah Star" />
                                    </div>
                                )
                            })}
                        </Slider>
                    </div> */}

                    {/*---------- METAL SHOW SECTION ----------*/}
                    <div className='mb-4'>
                        <div>
                            <div className='text-14 md:text-16 font-inter text-black font-normal'>
                                <span className='font-medium mr-2'>Metal:</span>
                                <span className='font-normal'>
                                    {`${hoveredMetal || selectedMetal}`}
                                    {priceDifference && productDetails?.PRO_DETAIL[0]?.IS_METAL_VARIANT && `(${priceDifference}${productDetails?.PRO_DETAIL[0]?.CURR_SYMBOL})`}
                                </span>
                            </div>
                        </div>
                        {productDetails?.PRO_DETAIL[0]?.IS_METAL_VARIANT && <div className={`mt-4 mx-auto flex gap-2 transition-all duration-300 ease-in-out overflow-hidden`}>
                            {sortedVariants?.map((metal) => {
                                const { MT_NAME } = metal;
                                return (
                                    <div
                                        key={metal.PRD_VARIANT_ID}
                                        className={`relative flex justify-center rounded-full w-9 h-9 items-center cursor-pointer
                ${selectedMetal === MT_NAME ? "border-2 border-footerBackground hover:border-footerBackground" : ""} 
                   hover:border-2 hover:border-[#eae0e0]`}
                                        onMouseEnter={() => setHoveredMetal(MT_NAME)}
                                        onMouseLeave={() => setHoveredMetal(null)}
                                        onClick={() => {
                                            if (selectedMetal === MT_NAME) {

                                            } else {
                                                handleVariantClick(metal?.PRD_VARIANT_ID)
                                            }
                                            setSelectedMetal(MT_NAME)
                                        }
                                        }
                                    >
                                        {MT_NAME.toLowerCase() === "18k white gold" && <div className="bg-silver-gradient h-8 w-8 rounded-full text-footerBackground text-12  flex items-center justify-center">18K</div>}
                                        {MT_NAME.toLowerCase() === "14k white gold" && <div className="bg-silver-gradient h-8 w-8 rounded-full text-footerBackground text-12  flex items-center justify-center">14K</div>}
                                        {MT_NAME.toLowerCase() === "18k yellow gold" && <div className="bg-gold-gradient h-8 w-8 rounded-full text-footerBackground text-12  flex items-center justify-center">18K</div>}
                                        {MT_NAME.toLowerCase() === "14k yellow gold" && <div className="bg-gold-gradient h-8 w-8 rounded-full text-footerBackground text-12  flex items-center justify-center">14K</div>}
                                        {MT_NAME.toLowerCase() === "14k rose gold" && <div className="bg-rose-gradient h-8 w-8 rounded-full text-footerBackground text-12  flex items-center justify-center">14K</div>}
                                        {MT_NAME.toLowerCase() === "platinum" && <div className="bg-platinum-gradient h-8 w-8 rounded-full text-footerBackground text-12  flex items-center justify-center">PT</div>}
                                    </div>
                                );
                            })}
                        </div>}
                    </div>

                    {/*---------- Avalibel Variants -----------*/}
                    {productDetails?.VARIANTS && productDetails.VARIANTS.length > 0 && <div className='mb-4 '>
                        <div>
                            <div className='text-14 md:text-16 font-inter text-black font-normal'>
                                <span className='font-medium mr-2 w-full '>Available Variants:</span>
                                <span className=''>
                                    {
                                        showVariants ? `${showVariants?.SKU_NAME} + (${showVariants?.PRICE}$)` : `${productDetails?.VARIANTS[0]?.SKU_NAME} + (${productDetails?.VARIANTS[0]?.PRICE}$)`
                                    }
                                </span>
                            </div>
                        </div>
                        <div
                            className={`mt-4 mx-auto flex transition-all duration-300 ease-in-out overflow-hidden`}
                            onClick={() => setShowVariants((prev) => !prev)}
                        >
                            {
                                productDetails?.VARIANTS.map((variant, index) => (
                                    <div
                                        key={index}
                                        className={`p-1.5 px-3 text-12 md:text-14 font-inter mx-1 cursor-pointer rounded-full border-solid border-2 
                        ${index === 0 ? 'border-pink-200' : 'border-gray-300'}
                        hover:border-footerBackground`}
                                        onMouseEnter={() => setShowVariants(variant)}
                                        onMouseLeave={() => setShowVariants(null)}
                                        onClick={() => handleVariantClick(variant?.PRD_VARIANT_ID)}
                                    >
                                        {`${variant?.SHORT_NAME}`}
                                    </div>
                                ))
                            }

                        </div>
                    </div>}


                    {/* <div className='mb-4'>
                        <div>
                            <div className='text-14 font-inter text-black font-normal'>
                                <span className='font-medium mr-2'>Accent Stones:</span>{`${selectedStone}`}
                            </div>
                        </div>



                        <div
                            className={`mt-4 mx-auto flex transition-all duration-300 ease-in-out overflow-hidden`}>
                            {['Diamonds', 'Sapphires + Diamonds (-$100)'].map((stone, index) => {
                                return (
                                    <div
                                        key={index}
                                        className={` px-1.5 hover:border-[#eae0e0] flex justify-start`}
                                        onClick={() => showSelectedStone(stone)}
                                        onMouseEnter={() => setAccentStoneHoverIndex(index)}
                                        onMouseLeave={() => setAccentStoneHoverIndex()}
                                    >
                                        {
                                            stone === 'Diamonds' &&
                                            <img src='https://image.brilliantearth.com/media/thumbnail/c9/77/c9775d9d19884952e375d697b0cd1789.jpg' alt='Kyrah Star' className={`rounded-full border-solid border-2 hover:border-black ${selectedStone === stone ? 'border-hover' : ''}`} />
                                        }
                                        {
                                            stone === 'Sapphires + Diamonds (-$100)' &&
                                            <img src='https://image.brilliantearth.com/media/thumbnail/e2/04/e2043ac4285d54725cbc7b35a5f3a39d.jpg' alt='Kyrah Star' className={`rounded-full border-solid border-2 hover:border-black ${selectedStone === stone ? 'border-hover' : ''}`} />
                                        }
                                    </div>
                                )
                            })}
                        </div>
                    </div>

                    <div className='mb-4'>
                        <div>
                            <div className='text-14 font-inter text-black font-normal'>
                                <span className='font-medium mr-2'>Setting Carat Weight:</span>{`${selectedWeight}`}
                            </div>
                        </div>
                        <div
                            className={`mt-4 mx-auto flex transition-all duration-300 ease-in-out overflow-hidden`}>
                            {['1/10', '2/5', '1/2'].map((weight, index) => {
                                return (
                                    <div
                                        key={index}
                                        className={` p-1.5 mx-1 rounded-full border-solid border-2 border-grey-600 hover:border-black ${selectedWeight === weight ? 'border-hover' : ''}`}
                                        onClick={() => showSelectedWeight(weight)}
                                        onMouseEnter={() => setCaratWeightHoverIndex(index)}
                                        onMouseLeave={() => setCaratWeightHoverIndex()}
                                    >{weight}
                                    </div>
                                )
                            })}
                        </div>
                    </div>

                    ---------- Diamond Origin -----------
                    <div className='mb-4'>
                        <div>
                            <div className='text-14 font-inter text-black font-normal'>
                                <span className='font-medium mr-2'>Diamond Origin:</span>{`${selectedDiamondOrigin}`}
                            </div>
                        </div>
                        <div
                            className={`mt-4 mx-auto flex transition-all duration-300 ease-in-out overflow-hidden`}>
                            {['Natural', 'Lab Grown'].map((type, index) => {
                                console.log('index:466 ', index);
                                console.log('type: 467', type);
                                return (
                                    <div
                                        key={index}
                                        className={` p-1.5 px-3 mx-1 rounded-full border-solid border-2 border-grey-600 hover:border-black ${selectedDiamondOrigin === type ? 'border-hover' : ''}`}
                                        onClick={() => showDiamondOrigin(type)}
                                    >{type}
                                    </div>
                                )
                            })}
                        </div>
                    </div> */}

                    {/*---------- Diamond Type -----------*/}
                    <div className='mb-4'>
                        <div>
                            <div className='text-14 md:text-16 font-inter text-black font-normal'>
                                <span className='font-medium mr-2'>Dimond Type:</span>{`${selectedHoverDiamondOrigin || selectedDiamondOrigin}`}
                            </div>
                        </div>
                        <div
                            className={`mt-4 mx-auto flex transition-all duration-300 ease-in-out overflow-hidden`}>
                            {
                                (() => {
                                    const availableTypes = [];
                                    if (productDetails?.PRO_DETAIL[0]?.AVAIL_DIAM_TYPE === 0) {
                                        availableTypes.push('Lab Grown');
                                    }
                                    if (productDetails?.PRO_DETAIL[0]?.AVAIL_DIAM_TYPE === 1) {
                                        availableTypes.push('Natural');
                                    }
                                    if (productDetails?.PRO_DETAIL[0]?.AVAIL_DIAM_TYPE === 2) {
                                        availableTypes.push('Lab Grown', 'Natural');
                                    }
                                    return availableTypes;
                                })().map((type, index) => (
                                    <div
                                        key={index}
                                        className={`p-1.5 text-12 md:text-14 px-3 mx-1 rounded-full font-inter border-solid border-2 cursor-pointer border-gray-300 hover:border-footerBackground ${selectedDiamondOrigin === type ? 'border-pink-200' : ''}`}
                                        onClick={() => showDiamondOrigin(type)}
                                        onMouseEnter={() => setSelectedHoverDiamondOrigin(type)}
                                        onMouseLeave={() => setSelectedHoverDiamondOrigin(null)}
                                    >
                                        {type}
                                    </div>
                                ))
                            }
                        </div>
                    </div>


                    <div>
                        <p className='text-22  font-inter font-medium'>{ringSizeData?.PRICE?.toLocaleString() ?? 0}{productDetails?.PRO_DETAIL[0]?.CURR_SYMBOL}<span className='text-14 md:text-14 font-medium font-inter ml-1'>(Setting Only) <span className='ml-3 underline text-14 font-normal   text-black  cursor-pointer' onClick={() => setPriceBackUp(true)}>Price BreakUp</span></span></p>
                    </div>

                    <div className=" w-full mx-auto my-4 flex items-center gap-3">
                        {/* {OFFER_DATA.map((data, index) => {
                            return (
                                <div className="relative" key={index}>
                                    <div key={index} className="mx-auto flex gap-4 h-20  ">
                                        <img src='https://image.brilliantearth.com/media/promotion/2024_PRODUCT_IMAGE_220x220_1712757559_578227.jpg' alt="kyrah star" />

                                        <div>
                                            <h3 className="text-12 font-bold">END SOON!</h3>
                                            <p className='text-12'>
                                                Lab Diamond Studes with purchase over $1000.
                                            </p>
                                            <p className='text-12'>
                                                Surprise Earning
                                                with all other purchase.
                                            </p>
                                            <p className='text-12'>
                                                Use code <span className="font-bold mb-3">SPARKLE</span> in
                                                cart.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="absolute right-1 bottom-1  text-white text-10 underline">
                                        <a href="wedding-rings">See Trems</a>
                                    </div>
                                </div>
                            );
                        })} */}
                        <div className='w-[30%] flex items-center gap-3 font-inter'>
                            <p className='font-semibold text-14 md:text-16'>Size: </p>
                            <div className='w-[60%]'>
                                <div
                                    onClick={(e) => { e.stopPropagation(); setSizeDropdown(!sizeDropdown) }}
                                    // onMouseEnter={() => setSizeDropdown(true)} onMouseLeave={() => setSizeDropdown(false)} 
                                    className='border    relative rounded-full flex flex-row justify-between cursor-pointer items-center border-footerBackground text-12 md:text-14 p-1.5 '>
                                    <span className='font-inter mx-auto font-semibold'>
                                        {selectedSize || 'Select'}
                                    </span>
                                    {sizeDropdown ? <FaChevronUp className='ml-0 text-10 md:text-12' /> : <FaChevronDown className='ml-0 text-10 md:text-12' />}
                                    {sizeDropdown && (
                                        <div className='absolute w-full scrollbar-custom h-[30vh] md:h-[35vh] overflow-auto  rounded-xl right-0 mt-2 text-12 md:text-14  bg-white border top-5 md:top-7 lg:top-6 border-footerBackground shadow-lg z-10'>
                                            <ul className='py-[2px]'>
                                                {size?.data?.map((item, index) => (
                                                    <li
                                                        key={index}
                                                        className='  px-[13px] py-[1px] md:px-3 md:py-2  sm:pl-[5px]  text-center hover:bg-gray-100 cursor-pointer'
                                                        onClick={() => { changeSize(item.SZ_NAME) }}
                                                    >
                                                        {item.SZ_NAME}
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    )}
                                </div>
                                {/* {variantError.MT_NAME && <p className='text-13 ml-1.5 text-[#ff0000] mb-2'>{variantError.MT_NAME}</p>} */}
                            </div>
                        </div>
                        <div className='w-[70%]  gap-3 items-center flex font-inter text-12 md:text-14'>
                            {productDetails?.PRO_DETAIL[0]?.IS_FINAL_PRODUCT === false ? <button className='w-[90%] bg-footerBackground border border-footerBackground rounded-full text-white p-1.5  ' onClick={handleNavigationDiamond}>CHOOSE THIS SETTING</button>
                                : <button className='w-[90%] bg-footerBackground border border-footerBackground rounded-full text-white p-1.5  ' onClick={handleAddToBag}>ADD TO BAG</button>}
                            {isWishList ?
                                <GoHeartFill className={`size-6 cursor-pointer text-footerBackground`} onClick={() => removeFromWishList()} />
                                :
                                <GoHeart className={`size-6 cursor-pointer text-footerBackground`} onClick={() => addToWishList()} />
                            }
                        </div>
                    </div>


                    {/* <div className='flex justify-between font-inter py-3 sm:py-5 xl:px-5 px-5 lg:px-3 bg-[#f8f8f8] '>
                        <div className='flex gap-2 items-center  hover:text-footerBackground'>
                            <FaTarpDroplet className='xl:size-6 size-6 lg:size-5' />
                            <span className='xl:text-14 text-16 lg:text-16'>Drop Hint</span>
                        </div>
                        <div className='flex gap-2 items-center  hover:text-footerBackground'>
                            <IoIosMail className='xl:size-6 size-6 lg:size-5' />
                            <span className='xl:text-14 text-16 lg:text-16'>Email</span>
                        </div>
                        <div className='flex gap-2 items-center  hover:text-footerBackground'>
                            <IoIosCall className='xl:size-6 size-6 lg:size-5' />
                            <span className='xl:text-14 text-16 lg:text-16'>800.691.0952</span>
                        </div>
                    </div>

                    <hr className='h-2' />
                    <div className='mt-5 font-inter  sm:mt-3 xl:px-5 px-5 lg:px-3'>
                        <div className='flex gap-3 '>
                            <FaTruckDroplet className='size-6' />
                            <span>Free Shipping, Free 30 Day Returns</span>
                        </div>
                        <div className='flex gap-3  mt-3'>
                            <CiCalendar className='size-10' />
                            <span>Order now and your order ships by Thu, Sep 19, depending on center diamond.</span>
                        </div>
                    </div> */}

                    <hr className='h-2 mt-3' />

                    <div>
                        <div onClick={() => { setRingDetail(!ringDetail) }} className='flex cursor-pointer mt-2 flex-row justify-between items-center'>
                            <div className='text-15  font-inter font-semibold text-black '>
                                Product Details
                            </div>
                            {
                                ringDetail ?
                                    <FaChevronUp />
                                    :
                                    <FaChevronDown />
                            }
                        </div>

                        <div
                            className={`mt-3 pr-5 transition-all text-productDetail duration-500 ease-in-out overflow-hidden ${ringDetail ? 'max-h-[1000px] opacity-100' : 'max-h-0 opacity-0'}`}>
                            {RING_DETAILS.map((data, index) => {
                                return (
                                    <div key={index} className='font-inter'>
                                        <div >
                                            {productDetails?.PRO_DETAIL[0]?.STYLE_NAME && <p className=' mt-2'><span className='font-productDetail'>Style :</span>  {productDetails?.PRO_DETAIL[0]?.STYLE_NAME}</p>}
                                            {productDetails?.PRO_DETAIL[0]?.PRONG_TYPE && <p className=' mt-2'><span className='font-productDetail'>Prong type :</span> {productDetails?.PRO_DETAIL[0]?.PRONG_TYPE}</p>}
                                            {productDetails?.PRO_DETAIL[0]?.METAL && <p className=' mt-2'><span className='font-productDetail'>Metal :</span> {productDetails?.PRO_DETAIL[0]?.METAL}</p>}
                                            {ringSizeData?.METAL_WEIGHT && <p className=' mt-2'><span className='font-productDetail'>Metal Weight :</span> {ringSizeData?.METAL_WEIGHT} g</p>}
                                            {/* {productDetails?.PRO_DETAIL[0]?.AVG_WIDTH && <p className=' mt-2'><span className='font-productDetail'>Average width :</span> {productDetails?.PRO_DETAIL[0]?.AVG_WIDTH}</p>} */}
                                            {productDetails?.PRO_DETAIL[0]?.DIMENSION && <p className=' mt-2'><span className='font-productDetail'>Dimension :</span> {productDetails?.PRO_DETAIL[0]?.DIMENSION}</p>}
                                            {productDetails?.PRO_DETAIL[0]?.SETTING && <p className=' mt-2'><span className='font-productDetail'>Setting :</span> {productDetails?.PRO_DETAIL[0]?.SETTING}</p>}
                                            {productDetails?.PRO_DETAIL[0]?.EXTRA_DET && <p className=' mt-2'><span className='font-productDetail'>Extra Details :</span> {productDetails?.PRO_DETAIL[0]?.EXTRA_DET}</p>}
                                        </div>
                                        {
                                            productDetails?.SIDE_STONES && productDetails.SIDE_STONES.length > 0 && (
                                                <>
                                                    <p className='mt-5 mb-2 text-14 md:text-15 font-normal'>Side Stones</p>
                                                    <hr className='border-b border-gray-300' />
                                                    {productDetails?.SIDE_STONES?.map((data, index) => (
                                                        <div key={index}>
                                                            {data?.SIDE_TYPE && <div className='flex gap-2 mt-2'>
                                                                <p className=''><span className='font-productDetail'>Type:</span> {data?.SIDE_TYPE}</p>
                                                            </div>}
                                                            {data.SHAPE && <div className='flex gap-2 mt-2'>
                                                                <p className=''><span className='font-productDetail'>Shape:</span> {data.SHAPE}</p>
                                                            </div>}
                                                            {data?.SIDE_METAL && <div className='flex gap-2 mt-2'>
                                                                <p className=''><span className='font-productDetail'>Metal:</span> {data.SIDE_METAL}</p>
                                                            </div>}
                                                            {data?.SIDE_DIMENSION && <div className='flex gap-2 mt-2'>
                                                                <p className=''><span className='font-productDetail'>Dimension:</span> {data.SIDE_DIMENSION}</p>
                                                            </div>}
                                                            {data.STONES && <div className='flex gap-2 mt-2'>
                                                                <p className=''><span className='font-productDetail'>Number:</span> {data.STONES}</p>
                                                            </div>}
                                                            {data.WEIGHT && <div className='flex gap-2 mt-2'>
                                                                <p className=''><span className='font-productDetail'>Min. carat total weight:</span> {data.WEIGHT}</p>
                                                            </div>}
                                                            {data.SETTING && <div className='flex gap-2 mt-2'>
                                                                <p className=''><span className='font-productDetail'>Setting:</span> {data.SETTING}</p>
                                                            </div>}
                                                            {data.COLOR && <div className='flex gap-2 mt-2'>
                                                                <p className=''><span className='font-productDetail'>Color Average Color:</span> {data.COLOR}</p>
                                                            </div>}
                                                            {data.CLARITY && <div className='flex gap-2 mt-2'>
                                                                <p className=''><span className='font-productDetail'>Clarity Average Clarity:</span> {data.CLARITY}</p>
                                                            </div>}
                                                            {data.CUT && (
                                                                <div className='flex gap-2 mt-2'>
                                                                    <p className=''><span className='font-productDetail'>Cut:</span> {data.CUT}</p>
                                                                </div>
                                                            )}
                                                            {data?.EXTRA_DET && <div className='flex gap-2 mt-2'>
                                                                <p className=''><span className='font-productDetail '>Extra Details:</span> {data.EXTRA_DET}</p>
                                                            </div>}
                                                            <hr className='my-2' />
                                                        </div>
                                                    ))}
                                                </>
                                            )
                                        }

                                        {/*-------------- Center Diamond -----------*/}
                                        {/* <p className='mt-5 mb-2 text-14'>Center Diamond</p>
                                        <hr />
                                        <p className='text-12 mt-2'>This ring can be set with :</p> */}
                                        {/* <div className='flex flex-wrap'>
                                            {data.diamond.map((diamond, index) => {
                                                return (
                                                    <div key={index} className='w-[12%] text-center mt-2'>
                                                        <img src={diamond.image} alt='Kyrah Star' className='w-[70%] mx-auto mt-2 mb-1' />
                                                        <p className='text-11'>{diamond.name}</p>
                                                    </div>
                                                )

                                            })}
                                        </div> */}
                                        <div className='flex flex-wrap gap-5'>
                                            {diamondData.map((diamond, index) => (
                                                <div key={index} className='w-[12%] text-center mt-2'>
                                                    <img src={diamond.image} alt={diamond.name} className='w-[70%] mx-auto mt-2 mb-1' />
                                                    <p className='text-12'>{diamond.name}</p> {/* Added py-2 for vertical padding */}
                                                </div>
                                            ))}
                                        </div>


                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    <hr className='h-2 ' />

                    {/*----------- Recommended Bridal Sets ----------------*/}
                    {/* <div>
                        <div onClick={() => { setRecommendedBridalSet(!recommendedBridalSet) }} className='flex cursor-pointer mt-2 flex-row justify-between items-center'>
                            <div className='text-16  font-inter font-semibold text-black '>
                                Recommended Bridal Sets
                            </div>
                            {
                                recommendedBridalSet ?
                                    <FaChevronUp />
                                    :
                                    <FaChevronDown />
                            }
                        </div>
                        <div
                            className={`mt-2 pr-5 mx-w-[100%] transition-all duration-500 ease-in-out overflow-hidden ${recommendedBridalSet ? 'max-h-[1000px] opacity-100' : 'max-h-0 opacity-0'}`}>
                            <Slider {...settings2} className='mt-4 w-full'>
                                {PopularRings.map((data, index) => {
                                    return (
                                        <div key={index} className={`cursor-pointer px-1.5  w-full  `} >
                                            <img src={data.mappings.core.imageUrl} className={` `} alt="Kyrah Star" />
                                            <p className='text-12 text-center px-3'>{data.mappings.core.name}</p>
                                        </div>
                                    )
                                })}
                            </Slider>
                        </div>

                    </div>
                    <hr className='h-2 mt-1' /> */}
                </div>
            </div>}

            {/* <div className='w-[100%] 2xl:w-[70%] container mx-auto   mt-10'>
                {
                    MAD_FOR_YOU.map((data, index) => {
                        return (
                            <div key={index} className='flex flex-col lg:flex-row justify-between bg-[#183E40] text-white py-10 sm:px-20'>
                                <div className='lg:w-[40%] w-[90%] mx-auto'>
                                    <p className='text-32 my-1 text-center lg:text-left'>{data.title}</p>
                                    <p className='text-14'>{data.content}</p>
                                </div>
                                <div className='lg:w-[45%] w-[90%] mx-auto mt-3 lg:mt-0'>
                                    {data.menu.map((menu, index) => {
                                        return (
                                            <>
                                                <hr />
                                                <div onClick={() => { index === showMenu ? setShowMenu() : setShowMenu(index) }} className='flex cursor-pointer my-2 flex-row justify-between items-center w-[100%] relative'>
                                                    <div className={`text-14 font-inter text-black font-normal `}>
                                                        <div key={index} className='flex text-white '>
                                                            <div className=''>
                                                                {menu.image === 'leaves' ? <GiThreeLeaves className='lg:size-6 size-5 lg:mt-2 mt-0 mr-2' /> : menu.image === 'recycle' ? <FaRecycle className='lg:size-6 size-5 lg:mt-2 mt-0 mr-2' /> : menu.image === 'people' ? <IoPeopleCircleOutline className='lg:size-6 size-5 lg:mt-2 mt-0 mr-2' /> : ''}
                                                            </div>
                                                            <div className='flex justify-between items-center w-100'>
                                                                <div className=''>
                                                                    <p>{menu.menu_title}</p>
                                                                    <p className='hidden lg:block'>{menu.menu_subtitle}</p>
                                                                    <div className={`mt-2 pr-5 mx-w-[100%] transition-all duration-300 ease-in-out overflow-hidden ${showMenu === index ? 'max-h-[1000px] opacity-100' : 'max-h-0 opacity-0'}}`}>
                                                                        <p>{menu.menu_content}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='absolute lg:top-2 top-1 right-0' onClick={() => setShowMenu(index)}>
                                                                {showMenu === index ? <FaChevronUp /> : <FaChevronDown />}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div >
                                            </>
                                        )
                                    })}
                                </div>
                            </div>
                        )
                    })
                }
            </div> */}

            {/* <div className='mx-auto mt-12 container w-[95%] 2xl:w-[70%]'>
                <h1 className='text-30 text-start w-full font-inter'>
                    Other Engagement Rings You'll Love
                </h1>

                <div>
                    <div className="flex flex-row gap-6 justify-between mx-auto w-full mt-6">
                        <Slider {...settings3} className='w-full flex flex-row mx-2 gap-5'>
                            {PopularRings?.map((item, index) => {
                                return (
                                    <div key={index} className={`relative px-1.5 outline-none text-[#2c2c2c]  ${index === 3 ? 'w-1/2' : 'w-full'}`}>
                                        <div className='absolute left-2 top-1.5'>
                                            <p className='text-10 px-3 py-1 bg-[#183E40] text-white rounded-full'>Signature Collections</p>
                                        </div>
                                        <ProductComponent
                                            item={item}
                                            hoverIndex={index}
                                        />
                                    </div>
                                )
                            })}
                        </Slider>
                    </div>
                </div>
            </div> */}

            {/* Review Section */}
            {
                <div className=' bg-[#f8f8f8] mt-10 font-inter'>
                    <div className='w-[95%] 2xl:w-[70%] mx-auto  lg:py-10 '>
                        <p className='lg:text-32 text-20 pt-3 lg:my-5 font-inter font-medium'>Reviews</p>
                        <div className='flex flex-col gap-2 border-b border-gray-300 pb-5'>
                            {reviewData?.[1]?.[0]?.AVG_RATING > 0 && <div>
                                <div className='text-20'>{Number(reviewData?.[1][0]?.AVG_RATING).toFixed(1)}</div>
                                <div className='flex gap-1 text-24 '>
                                    {Array(5)
                                        .fill(0)
                                        .map((_, index) => {
                                            const starIndex = index + 1;
                                            return (
                                                <span
                                                    key={index}
                                                    className="cursor-pointer"
                                                >
                                                    {starIndex <= (reviewData?.[1][0]?.AVG_RATING) ? (
                                                        <IoIosStar className="text-black" /> // Filled star
                                                    ) : (
                                                        <IoIosStarOutline className="text-gray-400" /> // Outline star
                                                    )}
                                                </span>
                                            );
                                        })}
                                </div>
                            </div>}
                            <span className='hover:underline cursor-pointer' onClick={() => navigate(userData ? `/review/${(productDetails?.PRO_DETAIL[0]?.PRD_ID)}-${encryptURL(productDetails?.PRO_DETAIL[0]?.SKUNO)}-${(productDetails?.PRO_DETAIL[0]?.PRD_VARIANT_ID)}` : '/login')}>
                                Write a Review
                            </span>
                        </div>
                        {reviewData?.[0]?.map((review, index) => {
                            return (
                                <div key={index} className='py-5 border-b border-gray-300 flex flex-col gap-2'>
                                    <div className='flex justify-between'>
                                        <p>{review?.FIRST_NAME + ' ' + review?.LAST_NAME}</p>
                                        <p>{formatDate(review?.ENTRY_DATE)}</p>
                                    </div>
                                    <div className='flex justify-between'>
                                        <div className='flex flex-col gap-2'>
                                            <div className='flex gap-1 text-20 '>
                                                {Array(5)
                                                    .fill(0)
                                                    .map((_, index) => {
                                                        const starIndex = index + 1;
                                                        return (
                                                            <span
                                                                key={index}
                                                                className="cursor-pointer"
                                                            >
                                                                {starIndex <= (review?.RATING) ? (
                                                                    <IoIosStar className="text-black" /> // Filled star
                                                                ) : (
                                                                    <IoIosStarOutline className="text-gray-400" /> // Outline star
                                                                )}
                                                            </span>
                                                        );
                                                    })}
                                            </div>
                                            <div>
                                                {review?.DESCR}
                                            </div>
                                        </div>
                                        {review?.FIRST_IMG_URL && <div className='relative w-[100px] h-[100px] md:w-[150px] md:h-[150px] border cursor-pointer' onClick={() => { setReviewImageModalOpen(true); setSelectedReview(review) }}>
                                            <img src={review?.FIRST_IMG_URL} alt='kyrah star' />
                                            <span className='absolute bottom-1 md:bottom-2 right-1 md:right-2 bg-gray-500 px-1 text-white md:text-16 text-12'>{review?.THIRD_IMG_URL ? '+2' : review?.SECOND_IMG_URL ? '+1' : ''}</span>
                                        </div>}
                                    </div>

                                </div>
                            )
                        })}
                        {reviewData?.[0].length >= 3 && <div className={`w-1/2 lg:w-[20%] mx-auto my-5  border-[1px] border-black px-6 justify-center text-black hover:bg-black hover:text-white flex flex-row py-3`} >
                            <span className='text-14 font-inter text-center'>
                                Load More
                            </span>
                        </div>}

                    </div>
                </div>
            }

            {/* <div className='w-[95%] 2xl:w-[70%] mx-auto mb-10 mt-20 lg:mt-10'>
                {
                    AS_SEE_ON_YOU.map((data, index) => {
                        return (
                            <div key={index} className=''>
                                <div className='flex flex-col lg:flex-row'>
                                    <div className='content-center text-center'>
                                        <p className='text-24 lg:mb-2 font-inter'>{data.title}</p>
                                        <p className='text-14 font-inter'>{data.subtitle}</p>
                                        <p className='text-14 font-inter'>{data.tag}</p>
                                    </div>
                                    <div className="flex flex-row gap-6 justify-between mx-auto w-[80%]  px-3  ">
                                        <Slider {...settings2} className='w-full flex flex-row mx-2 gap-5'>
                                            {data.images?.map((image, index) => {
                                                return (
                                                    <div key={index} className={` relative`}>
                                                        <img src={image} alt='Kyrah Star' className=' object-cover duration-300 lg:h-[338px] lg:w-[338px] ' />
                                                        <p className='absolute pt-[50%] opacity-0 pl-[35%] top-0 text-white hover:bg-black hover:bg-opacity-75 duration-300 hover:opacity-100 h-full w-full'>Shop Now</p>
                                                    </div>
                                                )
                                            })}
                                        </Slider>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>

            <div className='w-[95%] 2xl:w-[70%] mx-auto mt-10'>
                <div className='text-30 lg:text-36 font-inter '>
                    Recently View
                </div>
                <div className={`mt-2 pr-5 mx-w-[100%] transition-all duration-300 ease-in-out `}>
                    <Slider {...settings3} className='mt-4 w-full '>
                        {PopularRings.map((data, index) => {
                            return (
                                <div key={index} className={`cursor-pointer relative`} onMouseEnter={() => setHoverIndex(index)} onMouseLeave={() => setHoverIndex()}>
                                    <div className='absolute left-2 top-1.5'>
                                        <p className='text-10 px-3 py-1 bg-[#183E40] text-white rounded-full'>Signature Collections</p>
                                    </div>
                                    <img src={hoverIndex === index ? data.attributes.hover_link : data.mappings.core.imageUrl} className={` px-1.5`} alt="Kyrah Star" />
                                    <p className=' text-center px-3'>{data.mappings.core.name}</p>
                                    <p className=' text-center px-3'>${data.mappings.core.price}</p>
                                </div>
                            )
                        })}
                    </Slider>
                </div>
            </div> */}

            {/* Review Images Modal */}
            <Modal open={reviewImageModalOpen} footer={false} onCancel={() => setReviewImageModalOpen(false)} centered
                width="700px" // Fixed width
                style={{
                    maxWidth: '90vw', // Max width 90% of the screen size for responsiveness
                    width: '700px', // Default width for larger screens
                    maxHeight: '35vh', // Max height 90% of the viewport for larger screens
                }}// Optionally remove body padding for more control
            >
                <div className="w-full h-full py-2 flex items-center gap-5 font-inter">
                    <Slider {...settings} style={{ width: '50%', height: '35vh' }}>
                        <img src={selectedReview?.FIRST_IMG_URL} alt='kyrah star' className=' w-full h-[35vh] object-cover' />

                        {selectedReview?.SECOND_IMG_URL &&
                            <img src={selectedReview?.SECOND_IMG_URL} alt='kyrah star' className=' w-full h-[35vh] object-cover' />
                        }
                        {selectedReview?.THIRD_IMG_URL &&
                            <img src={selectedReview?.THIRD_IMG_URL} alt='kyrah star' className=' w-full h-[35vh] object-cover' />
                        }
                    </Slider>
                    <div className='flex flex-col gap-1 w-[50%]'>
                        <p className='md:text-16'>{selectedReview?.FIRST_NAME + ' ' + selectedReview?.LAST_NAME}</p>
                        <div className='flex gap-1 text-20 '>
                            {Array(5)
                                .fill(0)
                                .map((_, index) => {
                                    const starIndex = index + 1;
                                    return (
                                        <span
                                            key={index}
                                            className="cursor-pointer"
                                        >
                                            {starIndex <= (selectedReview?.RATING) ? (
                                                <IoIosStar className="text-black" /> // Filled star
                                            ) : (
                                                <IoIosStarOutline className="text-gray-400" /> // Outline star
                                            )}
                                        </span>
                                    );
                                })}
                        </div>
                        <div className='truncate flex flex-wrap md:text-16'>
                            {selectedReview?.DESCR}
                        </div>
                        <p className='md:text-16'>{formatDate(selectedReview?.ENTRY_DATE)}</p>
                    </div>
                </div>
            </Modal>

            {/* Price Break Out Modal */}
            <Modal open={priceBackUp} footer={false} onCancel={() => setPriceBackUp(false)} centered >
                <div className="w-full py-2">
                    <div className="">
                        <p className="text-center font-semibold text-[22px] font-inter text-gray-900">
                            Price Breakup
                        </p>
                        <div className='w-[75%] mx-auto'>
                            <div className='flex text-16 mt-1 '>
                                <p className='w-[75%]'>
                                    {`${productDetails?.PRO_DETAIL[0]?.METAL} (${ringSizeData?.METAL_WEIGHT || 0} g) :`}
                                </p>
                                <p className='w-[25%] text-right font-semibold'>{ringSizeData?.METAL_PRICE?.toLocaleString() || 0}{ringSizeData?.CURR_SYMBOL}</p>
                            </div>
                            <div className='flex text-16 mt-1 border-b pb-1'>
                                <p className='w-[75%]'>
                                    Making Charges :
                                </p>
                                <p className='w-[25%] text-right font-semibold'>{ringSizeData?.LABOUR_CHARGE?.toLocaleString() || 0}{ringSizeData?.CURR_SYMBOL}</p>
                            </div>
                            <div className='flex text-16 mt-1'>
                                <p className='w-[75%]'>
                                    Final Price :
                                </p>
                                <p className='w-[25%] text-right font-semibold'>{ringSizeData?.PRICE?.toLocaleString() || 0}{ringSizeData?.CURR_SYMBOL}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </div >
    )
}

export default ProductDetail
