import { createSlice } from "@reduxjs/toolkit";

export const cartList = createSlice({
    name: 'cartList',
    initialState: {
        cartListCount: 0
    },
    reducers: {
        addCartListCount: (state, action) => {
            state.cartListCount = action.payload;
        },
    }
}
)

export const { addCartListCount } = cartList.actions
export default cartList.reducer