import React from 'react'

const TermsCondition = () => {
    return (
        <div className='px-10 py-5 w-[80%] mx-auto'>
            <h1 className='text-center text-[28px] font-semibold'>
                Terms & Condition
            </h1>
            <div className='mt-5 flex flex-col gap-2 text-[18px]'>
                <h3 className='text-[22px] font-semibold'>General Terms</h3>
                <p>This services agreement contains the terms that govern your access to and use of the services and is an agreement between KyrahStar and you (if you are registering for or using the services as an individual) or the entity you are employed by or represent (if you are registering for or using as a business entity).</p>
                <p>If you are entering into this Agreement for an entity, you represent to us that you have legal authority to bind that entity. </p>
                <p>By registering for or using the services, you agree to be bound by the general terms and services.</p>
                <p>You also agree to comply with the policies which are incorporated by reference and apply to your use of the services. Together the general terms, service terms and policies are referred to as “This agreement”.</p>
                <p>Capitalised terms have the meanings given to them in this Agreement. To the extent there is a conflict between these General Terms, the Service Terms and Program Policies, the conflict will be resolved by giving precedence in the order specified in such documents, or if not specified, the following order: The General Terms, the Service Terms, and the Program Policies.</p>
            </div>
            <div className='mt-5 text-[18px]'>
                <h3 className='text-[22px] font-semibold'>1. Registration.</h3>
                <p>To register for the Services, you must create an account by completing the registration process for one or more of the Services. Use of the Services is limited to parties that can lawfully enter into and form contracts under applicable law. As part of the registration process, you must provide us with your (or your business') legal name, address, phone number and email address as well as other information we may request. Any personal data you provide to us will be handled in accordance with KyrahStar’s Privacy Notice. You will use only a name you are authorised to use in connection with a Service and will update all of the information you provide to us in connection with the Services as necessary to ensure that it remains accurate, complete, and valid at all times. You authorise us (and will provide us documentation evidencing your authorisation upon our request) to verify your information (including any updated information).</p>
            </div>
            <div className='mt-5 text-[18px]'>
                <h3 className='text-[22px] font-semibold'>2. Service Fee Payments; Receipt of Sales Proceeds.</h3>
                <div className='pl-5 mt-1'>
                    <h4 className='text-[20px]  font-medium'>2.1 Fees and Expenses:</h4>
                    <p>Fee details are described in the applicable Service Terms and Program Policies. You are responsible for payment of all applicable fees as described in this Agreement and for your expenses in connection with this Agreement.</p>
                </div>
                <div className='pl-5 mt-1'>
                    <h4 className='text-[20px]  font-medium'>2.2 Payment Methods:</h4>
                    <p>To use a Service, you must provide us with valid credit card information from a credit card or credit cards accepted by KyrahStar ("Your Credit Card") as well as valid bank account information for a bank account or bank accounts accepted by KyrahStar ("Your Bank Account").</p>
                </div>
                <div className='pl-5 mt-1'>
                    <h4 className='text-[20px]  font-medium'>2.3 Authorisations:</h4>
                    <p>You authorise us to obtain credit reports about you from time to time, to obtain credit authorisations from the issuer of Your Credit Card, and to charge Your Credit Card or debit Your Bank Account for any amounts payable by you to us (in reimbursement or otherwise).</p>
                </div>
                <div className='pl-5 mt-1'>
                    <h4 className='text-[20px]  font-medium'>2.4 Payments:</h4>
                    <p>All payments to you will be remitted to Your Bank Account. For any amounts you owe to us under this Agreement (in reimbursement or otherwise), we may elect to:</p>
                    <p>withhold, deduct or offset the amount due from or against any payments we may make to you or amounts we may owe you; or
                        collect payment or reimbursement of the amount due by any other lawful means including by:
                        charging Your Credit Card;
                        direct debiting Your Bank Account;
                        reversing or recalling prior payments made to you; or
                        sending a demand for payment to you, in which case you will be required to pay the amounts owing upon receipt.</p>
                </div>
                <div className='pl-5 mt-1'>
                    <h4 className='text-[20px]  font-medium'>2.5 Your Account:</h4>
                    <p>If we determine that your actions or performance (or that any of Your Products offered on the Site, or Your Transactions) may result in returns, chargebacks, claims, disputes, violations of Law or the Agreement, or other financial risks to KyrahStar (including any potential liability of KyrahStar to a third party), we may elect to:</p>
                    <p>establish a reserve on your Account (including by withholding amounts due to you or requiring payments from you) based on our assessment of risks to KyrahStar or third parties, and modify the amount of the required reserve from time to time by notice to you; or
                        withhold, deduct or offset an amount from or against any payments or amounts we may make to you or owe to you. The relevant amount referenced in (b) above, will be determined by KyrahStar based on our estimate of the risk, liability or obligation, and KyrahStar may retain such amount for so long as we determine the relevant risk persists, or until any related liability or obligation is discharged, whichever is sooner.</p>
                </div>
                <div className='pl-5 mt-1'>
                    <h4 className='text-[20px]  font-medium'>2.6 Prohibited activities:</h4>
                    <p>If we determine that your account has been used to engage in deceptive, fraudulent, or illegal activity, or that we (or another entity necessary to effect a payment to you) will or are likely to breach a Law if we make a payment to you, then we may, without limiting any other rights we may have, in our sole discretion temporarily or permanently withhold any relevant payments to you.</p>
                </div>
                <div className='pl-5 mt-1'>
                    <h4 className='text-[20px]  font-medium'>2.7 Security measures:</h4>
                    <p>As a security measure, we may, but are not required to, impose transaction limits on some or all customers and sellers relating to the value of any transaction or disbursement, the cumulative value of all transactions or disbursements during a period of time, or the number of transactions per day or other period of time. We will not be liable to you if we do not proceed with a transaction or disbursement that would exceed any limit established by us for a security reason.</p>
                </div>
                <div className='pl-5 mt-1'>
                    <h4 className='text-[20px]  font-medium'>2.8 Currency:</h4>
                    <p>Except as provided otherwise, all amounts contemplated in this Agreement will be expressed and displayed in your location country’s currency, and all payments contemplated by this Agreement will be made in your location country’s currency.
                    </p>
                </div>
            </div>
            <div className='mt-5 text-[18px]'>
                <h3 className='text-[22px] font-semibold'>3. Term and Termination.</h3>
                <p>The term of this Agreement will start on the date you first complete registration for or use a Service, whichever occurs first, and will continue until terminated by us or you as provided in this Agreement (the "Term"). You may at any time terminate your use of any Service immediately on notice to us via email, the Contact Us Form, or similar means. We may terminate your use of any Services or terminate this Agreement for convenience with 30 days’ advance notice. We may suspend or terminate your use of any Services immediately if we determine that:</p>
                <p>you have materially breached the Agreement and failed to cure within 7 days of a cure notice unless your breach exposes us to liability towards a third party, in which case we are entitled to reduce, or waive, the aforementioned cure period at our reasonable discretion;
                    your account has been, or our controls identify that it may be used for deceptive or fraudulent or illegal activity; or
                    your use of the Services has harmed or our controls identify that it might harm other sellers, customers, or KyrahStar’s legitimate interest. We will promptly notify you of any such termination or suspension via email or similar means, indicating the reason and any options to appeal, except where we have reason to believe that providing this information will hinder the investigation or prevention of deceptive, fraudulent, or illegal activity, or will enable you to circumvent our safeguards. Termination or suspension of a Service may occur in connection with, or result in termination or suspension of other Services. Upon termination of this Agreement, all rights and obligations of the parties under this Agreement will terminate, except that:
                    you will remain responsible for performing all of your obligations in connection with transactions entered into before termination and for any liabilities that accrued before termination, and
                    Sections 2, 3, 4, 5, 6, 7, 8, 9, 11, 14, 15, and 18 will survive termination.
                    Any terms that expressly survive according to the applicable Service Terms will also survive termination.</p>
            </div>
            <div className='mt-5 text-[18px]'>
                <h3 className='text-[22px] font-semibold'>4. Licence.</h3>
                <p>You grant us a royalty-free, non-exclusive, worldwide right and licence, for the duration of your original and derivative intellectual property rights, to use, any and all of Your Materials for the Services or other KyrahStar product or service, and to sublicense the foregoing rights to our Affiliates and operators of KyrahStar Associated Properties; provided, however, that we will not alter any of Your Trademarks from the form provided by you (except to resize trademarks to the extent necessary for presentation, so long as the relative proportions of such trademarks remain the same) and will comply with your removal requests as to specific uses of Your Materials (provided you are unable to do so using standard functionality made available to you via the applicable KyrahStar Site or Service); provided further, however, that nothing in this Agreement will prevent or impair our right to use Your Materials without your consent to the extent that such use is allowable without a licence from you or your Affiliates under applicable Law (e.g. referential use under trademark law, or valid licence from a third party).</p>
            </div>
            <div className='mt-5 text-[18px]'>
                <h3 className='text-[22px] font-semibold'>5. Representations.</h3>
                <p>Each party represents and warrants that:</p>
                <div>
                    <p>it is a business, it is duly organised, validly existing and in good standing under the laws of the jurisdiction where the business is registered;</p>
                    <p>it will hold and will maintain all applicable registrations and other authorisations needed to conduct its business and it is not under any restriction that prevents it conducting its business in the manner and for the purposes contemplated under this Agreement;</p>
                    <p>it has all requisite rights, power, and authority to enter into this Agreement, perform its obligations, and grant the rights, licences, and authorisations in this Agreement;</p>
                    <p>any information provided or made</p>
                    <p>Shipping Terms & Conditions</p>
                    <p>Shipping Options</p>
                    <div className='mt-2'>
                        <h5 className='font-medium'>KyrahStar offers the following shipping options:</h5>
                        <p>In-Store Collection (Hong Kong)</p>
                        <p>This complimentary service allows you to collect your order from designated KyrahStar stores in Hong Kong. Details of eligible stores will be provided during checkout.</p>
                        <p>Please allow an additional 1 business day for orders requiring engraving.</p>
                        <p>When collecting your order, please bring the original receipt. The collector must sign for the order upon collection.</p>
                    </div>
                    <div className='mt-2'>
                        <h5 className='font-medium'>Delivery Address Restrictions:</h5>
                        <p>KyrahStar will not deliver goods to addresses such as post office boxes, locker rooms, hotels, libraries, empty flats, or any other locations deemed unacceptable by KyrahStar.</p>

                        <h6 className='font-medium mt-1'>Territories</h6>
                        <p>Delivery is restricted to locations within specified Territories as defined in the Jurisdiction Specific Terms.</p>

                        <h6 className='font-medium mt-1'>Delivery Timeframe</h6>
                        <p>While KyrahStar endeavors to deliver goods promptly, delivery dates and times are estimates and not guaranteed.</p>
                        <p>Delays may occur due to factors beyond reasonable control, including customs clearance procedures.</p>
                        <p>If there is a delay, we may proactively contact and notify each affected customer to ensure they are aware of the latest delivery status.</p>

                        <h6 className='font-medium mt-1'>Delivery Completion</h6>
                        <p>Delivery of goods is considered complete upon their arrival at the designated delivery address or upon pick-up at a retail shop in Hong Kong.</p>

                        <h6 className='font-medium mt-1'>Insurance</h6>
                        <p>Overseas Delivery Zone are insured during transit until they reach the designated delivery address, subject to the terms of the insurance policy.</p>

                        <h6 className='font-medium mt-1'>Non-Delivery Scenarios</h6>
                        <p>If KyrahStar is unable to deliver goods due to circumstances beyond their control or due to incomplete or inaccurate information provided by customer:</p>
                        <p>Goods may be stored at KyrahStar's discretion, with associated charges for storage, taxes, duties, and re-delivery.</p>
                        <p>Alternatively, KyrahStar reserves the right to reimburse the purchase amount (after deducting applicable fees) and may dispose of the goods at their discretion.</p>

                        <h6 className='font-medium mt-1'>Delivery Service Terms</h6>
                        <p>KyrahStar reserves the right to revise delivery service terms without prior notice.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TermsCondition
