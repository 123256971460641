import React, { useEffect, useState } from 'react'
import { AiOutlinePlus } from 'react-icons/ai'
import { IoIosStar, IoIosStarOutline } from 'react-icons/io'
import { IoCloseSharp } from 'react-icons/io5'
import { useNavigate, useParams } from 'react-router-dom'
import { ClipLoader } from 'react-spinners'
import { decryptData } from '../components/decrypt'
import { axiosInstance } from '../redux/api'
import { decryptURL } from '../utils/encrypt'
import addImageInAzure from '../utils/uploadImageInAzure'
import ToastNotification, { showErrorToast, showSuccessToast } from '../components/ToastNotification'
import { azure_url } from '../utils/azureInfo'
import { RotatingLines } from 'react-loader-spinner'

const LeaveReview = () => {
    const { data } = useParams()
    const [PRD_ID, SkuNo, PRD_VAR_ID] = data.split('-')
    const [productData, setProductData] = useState()
    const [loading, setLoading] = useState(false)
    const userData = JSON.parse(localStorage.getItem('user-info'))
    const navigate = useNavigate()
    const [rating, setRating] = useState(0); // Tracks the rating (filled stars)
    const [hoverStarIndex, setHoverStarIndex] = useState(0); // Tracks the hovered star index

    const [desc, setDesc] = useState('')
    const [descError, setDescError] = useState('')
    const [rateError, setRateError] = useState('')
    const [images, setImages] = useState([]);
    const [imageError, setImageError] = useState('');

    const [loader, setLoader] = useState(false)

    const handleImageChange = (e) => {
        const newImage = e.target.files[0];
        if (newImage) {
            if (images.length < 3) {
                setImages((prevImages) => [...prevImages, newImage]);
                setImageError('');
            } else {
                setImageError('You can only upload a maximum of 3 images.');
            }
        }
    };

    const removeImage = (index) => {
        const updatedImages = images.filter((_, i) => i !== index);
        setImages(updatedImages);
    };


    const getProduct = async () => {
        setLoading(true)
        try {
            const requestBody = {
                "PRD_VARIANT_ID": PRD_VAR_ID,
                "SKUNO": decryptURL(SkuNo),
                "S_CODE": "",
                "CURR": userData?.CURR,
                "StockID": "",
                "SIZE": ''
            }
            const response = await axiosInstance.post('data/product/info', requestBody);
            const isDecryption = process.env.REACT_APP_IS_DECRYPT
            setProductData(isDecryption == 'true' ? decryptData(response.data).data : response.data.data.data)
            setLoading(false)
        } catch (e) {
            console.log(e)
            setLoading(false)
        }
    }

    useEffect(() => { getProduct() }, [])


    const handleSaveReview = async () => {
        // Validate description and rating
        if (desc === '' && rating === 0) {
            setDescError('Please enter a description.');
            setRateError('Please select a rating.');
            return
        }
        if (desc === '') {
            return setDescError('Please enter a description.');
        }
        if (rating === 0) {
            return setRateError('Please select a rating.');
        }

        // Initialize an empty array for uploaded image URLs
        let uploadedUrls = [];

        // If images are present, upload them
        if (images.length > 0) {
            const results = await Promise.allSettled(
                images.map(async (image, index) => {
                    // const blobName = `${userData?.USERID}_${PRD_ID}_${PRD_VAR_ID}_${index + 1}.${image?.name?.split('.').pop()}`;
                    const blobName = `${userData?.USERID}_${PRD_ID}_${PRD_VAR_ID}_${index + 1}.${image?.name?.split('.')[1]}`
                    const dirName = 'Review';

                    // const azureUploadImage = await addImageInAzure({
                    //     image,
                    //     blobName,
                    //     directoryName: dirName,
                    // });

                    const azureUploadImage = await addImageInAzure({
                        image: image,
                        blobName: blobName,
                        directoryName: dirName,
                    });

                    if (azureUploadImage) {
                        return `${azure_url}${dirName}/${blobName}`;
                    } else {
                        showErrorToast('Image upload failed. Please try again.');
                        return null;
                    }
                })
            );

            // Filter out successfully uploaded URLs
            uploadedUrls = results
                .filter((result) => result.status === 'fulfilled' && result.value)
                .map((result) => result.value);

        }

        // Call the API regardless of image uploads
        if (desc.trim() !== '' && desc.trim().length >= 6 && desc.trim().length <= 4096 && rating > 0) {
            handleAddReview(uploadedUrls);
        }
    };


    // const handleUploadAzureImage = async () => {
    //     if (desc === '') {
    //         setDescError('Please enter a description.')
    //     } if (rating === 0) {
    //         setRateError('Please select a rating.')
    //     }
    //     if (desc && rating > 0 && images.length > 0) {
    //         // console.log(desc, rating, images, 'review')
    //         const data = await Promise.all(images.map(async (image, index) => {

    //             console.log(image, "IMAGELOOP");
    //             console.log(index, "IMAGELindex");

    //             const blobname = `${userData?.USERID}_${PRD_ID}_${PRD_VAR_ID}_${index + 1}.${image?.name?.split('.')[1]}`
    //             const dirName = 'Review'
    //             const azureUploadImage = await addImageInAzure({
    //                 image: image,
    //                 blobName: blobname,
    //                 directoryName: dirName,
    //             });


    //             if (azureUploadImage == true) {
    //                 const ImgURL = `${azure_url}${dirName}/${blobname}`

    //                 console.log(ImgURL, "IMGURL1234545");

    //                 setImagesUrl((prev) => [...prev, ImgURL])

    //                let 

    //                 handleAddReview()
    //             }
    //             else {
    //                 showErrorToast('Image Upload Failed. Please Try Again.')
    //             }

    //         }));
    //     } else {
    //         handleAddReview()
    //     }
    // }


    // useEffect(() => {
    //     if (imagesUrl.length > 0) {
    //         handleAddReview()
    //     }
    // }, [imagesUrl])

    const handleAddReview = async (imagesUrl) => {
        setLoader(true)
        const payload = {
            "USERID": userData?.USERID,
            "PRD_ID": PRD_ID,
            "PRD_VARIANT_ID": PRD_VAR_ID,
            "RATING": rating,
            "DESCR": desc.trim(),
            "FIRST_IMG_URL": imagesUrl?.length > 0 ? imagesUrl[0] : "",
            "SECOND_IMG_URL": imagesUrl?.length > 1 ? imagesUrl[1] : "",
            "THIRD_IMG_URL": imagesUrl?.length > 2 ? imagesUrl[2] : ""
        }
        try {
            const response = await axiosInstance.post(`${process.env.REACT_APP_PUBLIC_BASE_URL}/review/save`, payload);

            if (response.data.data.message === 'Review Save successfully!') {
                setLoader(false)
                showSuccessToast(response?.message)
                navigate(`/product-detail/${SkuNo}-${PRD_VAR_ID}`)
                // window.location.reload(true)\
            } else {
                setLoader(false)
                console.log(response)
                showErrorToast(response?.message)
            }
        } catch (error) {
            setLoader(false)
            showErrorToast(error.response.data.message)
            console.error('Error occurred while submitting the form:', error);
        }
    }

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])



    return (
        loading ? <div className='flex flex-row justify-center w-full h-[80vh]'> <ClipLoader className='my-[3%]' /></div> :
            <div className='w-[95%] sm:w-[75%] lg:w-[50%] mx-auto font-inter '>
                <ToastNotification />
                <div className='flex gap-5 sm:gap-10 items-center border-b'>
                    <img src={productData?.IMG_URL} alt='kyrah star' className='w-[25%] sm:w-[20%] 2xl:w-[15%]' />
                    <div className='w-[95%]'>
                        <p className='text-18 sm:text-20'>Let us know what you think!</p>
                        <p className='text-13 sm:text-15 hover:underline cursor-pointer text-blue-500 mt-1' onClick={() => navigate(`/product-detail/${SkuNo}-${PRD_VAR_ID}`)}>{productData?.SKU_NAME}</p>

                    </div>
                </div>
                <div>
                    <div className='mt-5'>
                        <p className='relative'>Your Rating: <span className='text-16 absolute top-[-3px] text-red-500'>*</span></p>
                        <div className='flex gap-2 text-28 mt-2'>
                            {Array(5)
                                .fill(0)
                                .map((_, index) => {
                                    const starIndex = index + 1;
                                    return (
                                        <span
                                            key={index}
                                            onMouseEnter={() => setHoverStarIndex(starIndex)} // On hover, update hoverStarIndex
                                            onMouseLeave={() => setHoverStarIndex(0)} // On mouse leave, reset hoverStarIndex
                                            onClick={() => { setRating(starIndex); setRateError('') }} // On click, update rating
                                            className="cursor-pointer"
                                        >
                                            {starIndex <= (hoverStarIndex || rating) ? (
                                                <IoIosStar className="text-yellow-500" /> // Filled star
                                            ) : (
                                                <IoIosStarOutline className="text-gray-400" /> // Outline star
                                            )}
                                        </span>
                                    );
                                })}
                        </div>
                        {rateError && <p className="text-red-500 text-12 sm:text-14 font-inter">{rateError}</p>}
                    </div>
                    <div className='mt-5'>
                        <p className='relative'>Description: <span className='text-16 absolute top-[-3px] text-red-500'>*</span></p>
                        <div className='flex gap-2 text-28 mt-1'>
                            <textarea
                                rows={3}
                                placeholder='Description'
                                value={desc}
                                onChange={(e) => { setDesc(e.target.value); if (e.target.value.trim().length < 6) { setDescError("Description must have six characters.") } else if (e.target.value.trim().length > 4096) { setDescError("Description is not contain more than 4096 characters.") } else if (e.target.value === '') { setDescError('Please enter a description.') } else { setDescError('') } }}
                                className='border border-[#ccc] rounded-lg p-3 text-15 w-full outline-none my-1'
                            />
                        </div>
                        {descError && <p className="text-red-500 text-12 sm:text-14 font-inter">{descError}</p>}
                    </div>
                    <div className='mt-5'>
                        <div className='flex flex-wrap gap-4 '>
                            {images.map((image, index) => (
                                <div key={index} className='w-[100px] h-[100px] sm:w-[150px] sm:h-[150px] 2xl:w-[200px] 2xl:h-[200px]'>
                                    <div className='relative w-full h-full'>
                                        <img
                                            src={typeof image === 'string' ? `${image}?version=${new Date().getTime()}` : URL.createObjectURL(image)}
                                            alt={`image-${index}`}
                                            className='h-full w-full rounded-2xl cursor-pointer object-cover overflow-hidden'
                                            onDoubleClick={() => {
                                                const imgUrl = typeof image === 'string' ? image : URL.createObjectURL(image);
                                                window.open(imgUrl, '_blank');
                                            }}
                                        />
                                        <IoCloseSharp
                                            className='absolute cursor-pointer right-1 top-1 bg-red-500 p-1 rounded-2xl text-white'
                                            size={25}
                                            onClick={() => removeImage(index)} // Remove the image from the array
                                        />
                                    </div>
                                </div>
                            ))}

                            {/* Upload New Image */}
                            {images.length < 3 && (
                                <div className='w-[100px] h-[100px] sm:w-[150px] sm:h-[150px] 2xl:w-[200px] 2xl:h-[200px] border border-dotted cursor-pointer border-gray-500 rounded-2xl flex flex-col items-center justify-center relative'>
                                    <input
                                        type="file"
                                        accept=".jpg, .jpeg, .png"
                                        onChange={handleImageChange}
                                        className='opacity-0 cursor-pointer absolute h-[100%] w-full'
                                    />
                                    <AiOutlinePlus size={40} className='text-gray-500' />
                                </div>
                            )}
                        </div>

                        {/* Show Error Message if Max Images Reached */}
                        {imageError && <p className='text-red-500 text-12 sm:text-14 font-inter'>{imageError}</p>}
                    </div>
                    <div>
                        {loader ? <button
                            className={`bg-footerBackground flex flex-row justify-center cursor-pointer text-white w-[25%] rounded-full py-2 mt-5`}
                        >
                            <RotatingLines
                                visible={true}
                                height="20"
                                width="20"
                                strokeWidth="5"
                                strokeColor='white'
                                animationDuration="0.75"
                                ariaLabel="rotating-lines-loading"
                                wrapperStyle={{}}
                                wrapperClass=""
                            />
                        </button> : <button className='bg-footerBackground cursor-pointer text-white px-5 py-2 rounded-full w-[25%] mt-5' onClick={handleSaveReview}>
                            Save Review
                        </button>}
                    </div>
                </div>
            </div>

    )
}

export default LeaveReview
