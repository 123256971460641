import React from 'react'

const FlexView = ({ data, imageStyle, divStyle, ...props }) => {
    return (
        <div className='flex justify-between gap-5 overflow-x-auto md:overflow-x-hidden  scrollbar-hide' {...props}>
            {data?.map((item, index) => (
                <div className={`${imageStyle ? 'w-[48%] md:w-[31%] lg:w-[15%]' : divStyle ? 'w-[55%] md:w-[31%]' : 'w-[60%] md:w-[23%]'} inline-block flex-shrink-0`} key={index}>
                    <div className=' hover:text-hover'>
                        <img src={item.image} alt='Kyrah Star' className='content-cover w-full' />
                        <p className='font-medium text-16 md:text-18  font-inter mt-3'>{item.title}</p>
                    </div>
                    {item.subtitle && <p className='text-16 md:text-14  font-inter mt-3'>{item.subtitle}</p>}
                </div>
            ))}
        </div>


    )
}

export default FlexView
